import {
  Checkbox,
  Grid,
  Icon,
  IconButton,
  Stack,
  Tooltip,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

function QuestionChart(props) {
  let { enqueueSnackbar } = useSnackbar();
  let { id } = useParams();
  const [file, setFile] = useState("");

  return (
    <MDBox p={3} fontSize="14px" bgColor="#ab95ff33">
      <Grid container spacing={2} style={{ paddingLeft: "15px" }}>
        <Grid item xs={12} md={12}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <MDTypography
              fontWeight="regular"
              color="dark"
              sx={{
                fontSize: "16px",
                fontWeight: 400,
                overflowWrap: "break-word",
                width: "80%",
              }}
            >
              {props?.val?.question?.text}
            </MDTypography>
          </Stack>

          {props.val?.qualificationTypeId == 1 ||
          props.val?.qualificationTypeId == 2 ? (
            <Grid item xs={12} md={12} mt={2}>
              <MDBox
                display="flex"
                alignItems="center"
                gap="15px"
                flexWrap="wrap"
                paddingLeft="1rem"
              >
                {/* <MDBox display="flex" alignItems="center" marginLeft="6px">
                  <MDBox
                    style={{
                      border: "1px solid #aaa",
                      borderRadius: "5px",
                      width: "18px",
                      height: "18px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: props?.edit ? "pointer" : "",
                    }}
                    onClick={() => {
                      if (props?.edit) {
                        if (
                          props?.val?.answerId.length !==
                          props?.val?.question?.answers?.length
                        ) {
                          props.val.answerId = [];
                          props?.val?.question?.answers
                            .filter(
                              (option) =>
                                !props?.val?.answerId.includes(
                                  Number(option.id)
                                )
                            )
                            .map((option, i) => {
                              props.val.answerId.push(parseInt(option.id));
                            });
                        } else {
                          props.val.answerId = [];
                        }
                        let payload = [...props.qualificationQuestions];
                        payload[props.index] = props.val;
                        props.setQualificationQuestions(payload);
                      }
                    }}
                  >
                    {(props?.val?.answerId?.length ===
                      props?.val?.question?.answers?.length ||
                      true) && (
                      <MDBox
                        bgColor="info"
                        style={{
                          borderRadius: "3px",
                          width: "10px",
                          height: "10px",
                        }}
                      ></MDBox>
                    )}
                  </MDBox>
                  &nbsp;&nbsp;Select All&nbsp;
                </MDBox> */}
                <Grid container spacing={0}>
                  {props.val?.question?.answers?.map((option, i) => {
                    return (
                      <Grid item xs={12} md={12} lg={6} key={i}>
                        <MDBox display="flex" alignItems="center">
                          {/* <Checkbox
                            disabled={!props?.edit}
                            checked={true}
                            onChange={(e) => {
                              let payload = [];
                              if (!e.target.checked) {
                                payload = [...props.qualificationQuestions];
                                payload[props.index].answerId = payload[
                                  props.index
                                ].answerId.filter(
                                  (answer) => answer !== parseInt(option.id)
                                );
                              } else {
                                payload = [...props.qualificationQuestions];
                                payload[props.index].answerId.push(
                                  parseInt(option.id)
                                );
                              }
                              props.setQualificationQuestions(payload);
                            }}
                          /> */}
                          <MDTypography
                            variant="button"
                            fontWeight="regular"
                            color="dark"
                            sx={{
                              cursor: "pointer",
                              userSelect: "none",
                              ml: -1,
                            }}
                          >
                            &nbsp;&nbsp;•&nbsp;&nbsp;{option.qualificationAnswerDesc}&nbsp;
                          </MDTypography>
                        </MDBox>
                      </Grid>
                    );
                  })}
                  {/* {props.val.answerId?.length <= 0 ? (
                    <MDBox color="error" px={1} pt={2}>
                      Please select at least one field
                    </MDBox>
                  ) : (
                    ""
                  )} */}
                </Grid>
              </MDBox>
            </Grid>
          ) : props.val?.qualificationTypeId == 4 ? (
            <>
              {props?.val?.selectedRange?.map((rangeVal, i) => (
                <Grid
                  container
                  spacing={2}
                  style={{ paddingLeft: "25px" }}
                  mt={1}
                  key={i}
                >
                  <Grid item xs={10} md={10}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={5}>
                        <MDInput
                          type="number"
                          autoComplete="off"
                          variant="outlined"
                          label="min"
                          fullWidth
                          required
                          disabled={!props?.edit}
                          value={rangeVal.min}
                          onChange={(e) => {
                            let payload = [...props.qualificationQuestions];
                            payload[props.index].selectedRange[i].min =
                              e.target.value;
                            props.setQualificationQuestions(payload);
                          }}
                          error={
                            rangeVal.min === ""
                              ? true
                              : Number(rangeVal.min) < 10
                              ? true
                              : false
                          }
                          helperText={
                            rangeVal.min === ""
                              ? "This field is Required"
                              : Number(rangeVal.min) < 10
                              ? "This value can not be less then 10"
                              : ""
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <MDInput
                          type="number"
                          fullWidth
                          required
                          disabled={!props?.edit}
                          value={rangeVal.max}
                          onChange={(e) => {
                            let payload = [...props.qualificationQuestions];
                            payload[props.index].selectedRange[i].max =
                              e.target.value;
                            props.setQualificationQuestions(payload);
                          }}
                          autoComplete="off"
                          variant="outlined"
                          label="max"
                          error={
                            rangeVal.max === ""
                              ? true
                              : Number(rangeVal.min) > Number(rangeVal.max)
                              ? true
                              : Number(rangeVal.max) > 100
                              ? true
                              : false
                          }
                          helperText={
                            rangeVal.max === ""
                              ? "This field is Required"
                              : Number(rangeVal.max) > 100
                              ? "This value can not be greater than 100"
                              : Number(rangeVal.min) > Number(rangeVal.max)
                              ? "This is not be less then min"
                              : ""
                          }
                        />
                      </Grid>
                      {i !== 0 && (
                        <Grid item xs={12} md={2}>
                          <IconButton
                            disabled={!props?.edit}
                            onClick={() => {
                              let payload = [...props.qualificationQuestions];
                              payload[props.index].selectedRange.splice(i, 1);
                              props.setQualificationQuestions(payload);
                            }}
                          >
                            <Icon>delete</Icon>
                          </IconButton>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </>
          ) : (
            <>
              <Grid
                container
                spacing={2}
                style={{ paddingLeft: "25px" }}
                mt={1}
              ></Grid>
            </>
          )}
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default QuestionChart;
