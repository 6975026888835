import React, { useState, useRef, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  Box,
  Grid,
  Typography,
  Button,
  Stack,
  Tooltip,
  Backdrop,
  CircularProgress,
  IconButton,
  FormControl,
  MenuItem,
  Select,
  FormHelperText,
} from "@mui/material";
import MDBox from "components/MDBox";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import TLinkApi from "services/tlink.api";
import { Controller, useForm } from "react-hook-form";
import { EditOutlined, DeleteOutline } from "@mui/icons-material";
import Label from "components/InputLabel";
import MDInput from "components/MDInput";
import { useSnackbar } from "notistack";
import GetTemplateTypeList from "hooks/TemplateType/GetTemplateTypeList";
import EmailEditor from "react-email-editor";
import { Outlet, useLocation } from "react-router-dom";
import GetTemplateCategoryList from "hooks/TemplateCategoryType/GetTemplateCategoryTypeList";
import MDButton from "components/MDButton";

// Register custom button format for Quill editor
const registerCustomButtonFormat = (Quill) => {
  const BlockEmbed = Quill.import("blots/block/embed");

  class ButtonBlot extends BlockEmbed {
    static create(value) {
      const node = super.create();
      node.innerHTML = `
        <button style="
          padding: 10px;
          background-color: #007BFF;
          color: #fff;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          margin: 10px 0;">
          ${value.text || "Click Me"}
        </button>`;
      return node;
    }

    static value(node) {
      return { text: node.innerText };
    }
  }

  ButtonBlot.blotName = "customButton";
  ButtonBlot.tagName = "div";
  Quill.register("formats/customButton", ButtonBlot);
};

function TemplateAdd() {
  const [header, setHeader] = useState("Email Header Content Here");
  const [emailBody, setEmailBody] = useState("");
  const [footer, setFooter] = useState("Email Footer Content Here");
  const quillRef = useRef(null);
  const [data, setData] = useState("");

  const {
    templatesData,
    setTemplatesData,
    GetTemplateList,
    isEdit,
    setIsEdit,
    templates,
  } = useOutletContext();
  const [isLoading, setIsLoading] = useState(false);

  let navigate = useNavigate();
  let { id } = useParams();
  let { enqueueSnackbar } = useSnackbar();
  const { templateType } = GetTemplateTypeList();
  const { templateCategoryType } = GetTemplateCategoryList();

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    register,
    watch,
  } = useForm({
    defaultValues: {
      templateName: "",
      templateType: "",
      templateCategoryType: "",
      isDefault: "",
      templateFormat: "",
      templateConfig: "",
    },
  });
  const orgSlug = localStorage.getItem("organizationSlug");
  const panelSlug = localStorage.getItem("panelSlug");
  const getData = async () => {
    if (id) {
      if (id !== "new") {
        setTemplatesData({});
        setIsLoading(true);
        if (window.location.pathname === `/template/${id}/edit`) {
          setIsEdit({
            disabled: false,
            edit: true,
          });
        } else {
          setIsEdit({
            disabled: true,
            edit: false,
          });
        }
        try {
          if (!orgSlug || !panelSlug) {
            throw new Error("Organization slug is missing from local storage");
          }
          let result = await TLinkApi.get(`o/${orgSlug}/panel/${panelSlug}/email/template/${id}`);
          if (result) {
            setTemplatesData(result.data);
          }
          setIsLoading(false);
          // enqueueSnackbar(result?.userMessage, {
          //   variant: "success",
          // });
        } catch (e) {
          console.log(e);
          enqueueSnackbar(e?.response?.data?.result?.userMessage, {
            variant: "error",
          });
          setIsLoading(false);
        }
      }
    }
  };

  useEffect(() => {
    if (Object.keys(templatesData).length > 0) {
      setValue("templateName", templatesData.templateName);
      setValue("templateType", templatesData.templateType?.id || "");
      setValue(
        "templateCategoryType",
        templatesData.templateCategoryType?.id || ""
      );
      setValue("isDefault", templatesData.isDefault);
    } else if (Object.keys(templatesData).length <= 0) {
      setValue("templateName", "");
      setValue("templateType", "");
      setValue("templateCategoryType", "");
      setValue("isDefault", "");
    }
  }, [templatesData, setValue]);

  const Close = async () => {
    setValue("templateName", templatesData.templateName);
    setValue("templateType", templatesData.templateType?.id || "");
    setValue(
      "templateCategoryType",
      templatesData.templateCategoryType?.id || ""
    );
    setValue("isDefault", templatesData.isDefault);
  };

  useEffect(() => {
    getData();
  }, [id]);

  useEffect(() => {
    if (quillRef.current) {
      const Quill = quillRef.current.getEditor().constructor;
      registerCustomButtonFormat(Quill);
    }
  }, []);

  const handleBodyChange = (value) => setEmailBody(value);

  const handleSendEmail = () => {
    const emailContent = {
      subject: "Your Subject Here",
      header,
      body: emailBody,
      footer,
    };
  };

  const appendButton = () => {
    const editor = quillRef.current.getEditor();
    const range = editor.getSelection();
    const position = range ? range.index : 0;
    editor.insertEmbed(position, "customButton", { text: "Click Me" });
    editor.setSelection(position + 1);
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "color",
    "background",
    "script",
    "align",
    "code-block",
    "customButton",
  ];

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      [{ align: [] }],
      ["code-block"],
      ["clean"],
    ],
  };

  const label = (value, hide) => {
    return (
      <MDBox
        fontWeight="400"
        fontSize="12px"
        color="rgba(0, 0, 0, 0.85)"
        pb={0.5}
      >
        {value} {hide ? "" : <span>* &nbsp;</span>}
      </MDBox>
    );
  };

  let selectStyle = {
    height: "36.5px",

    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
    "& :hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
  };

  const save = async (data) => {
    exportHtml(data);
  };

  useEffect(() => {
    if (!id) {
      setIsEdit({ disabled: false, edit: false });
    }
  }, [id]);

  const saveData = async (payload) => {
    setIsLoading(true);
    try {
      if (!orgSlug || !panelSlug) {
        throw new Error("Organization slug is missing from local storage");
      }
      const isEditMode = isEdit?.edit ?? false;

      if (isEditMode) {
        if (!orgSlug || !panelSlug) {
          throw new Error("Organization slug is missing from local storage");
        }
        const result = await TLinkApi.put(`o/${orgSlug}/panel/${panelSlug}/email/template/${id}`, payload);
        enqueueSnackbar(
          result?.userMessage || "Template updated successfully!",
          {
            variant: "success",
          }
        );
        await getData();
        await GetTemplateList();
        setIsEdit({ disabled: true, edit: false });
        navigate(`/template/${id}`);
      } else {
        if (!orgSlug || !panelSlug) {
          throw new Error("Organization slug is missing from local storage");
        }
        const result = await TLinkApi.post(`o/${orgSlug}/panel/${panelSlug}/email/template`, payload);
        enqueueSnackbar(
          result?.userMessage || "Template created successfully!",
          {
            variant: "success",
          }
        );
        navigate(`/template/${result.data.id}`);
        await getData();
        await GetTemplateList();
      }
    } catch (e) {
      enqueueSnackbar(e?.response?.data?.result?.userMessage, {
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const emailEditorRef = useRef(null);

  const onReady = async () => {
    if (emailEditorRef.current) {
      const elements = document.getElementsByClassName("blockbuilder-branding");
      const unlayer = emailEditorRef.current.editor;
      if (id) {
        if (templatesData) {
          unlayer?.loadDesign(templatesData?.templateConfig);
        }
      } else {
        const design = {
          counters: {
            u_column: 3,
            u_row: 3,
            u_content_text: 1,
            u_content_heading: 1,
            u_content_image: 1,
            u_content_button: 1,
          },
          body: {
            id: "oM5jpuSm7G",
            rows: [
              {
                id: "wnv5eofCsF",
                cells: [1],
                columns: [
                  {
                    id: "M31ZgRBlvi",
                    contents: [
                      {
                        id: "Iy9E9mKkFo",
                        type: "text",
                        values: {
                          containerPadding: "10px",
                          anchor: "",
                          fontSize: "14px",
                          textAlign: "center", // Set text to center
                          lineHeight: "140%",
                          linkStyle: {
                            inherit: true,
                            linkColor: "#0000ee",
                            linkHoverColor: "#0000ee",
                            linkUnderline: true,
                            linkHoverUnderline: true,
                          },
                          hideDesktop: false,
                          displayCondition: null,
                          _styleGuide: null,
                          _meta: {
                            htmlID: "u_content_text_1",
                            htmlClassNames: "u_content_text",
                          },
                          selectable: true,
                          draggable: true,
                          duplicatable: true,
                          deletable: true,
                          hideable: true,
                          text: '<p style="line-height: 140%; text-align: center;">This is a new Text block. Change the text.</p>',
                          _languages: {},
                        },
                      },
                      {
                        id: "eT5MLtTrf6",
                        type: "heading",
                        values: {
                          containerPadding: "10px",
                          anchor: "",
                          headingType: "h1",
                          fontSize: "22px",
                          textAlign: "center", // Center align heading
                          lineHeight: "140%",
                          linkStyle: {
                            inherit: true,
                            linkColor: "#0000ee",
                            linkHoverColor: "#0000ee",
                            linkUnderline: true,
                            linkHoverUnderline: true,
                          },
                          hideDesktop: false,
                          displayCondition: null,
                          _styleGuide: null,
                          _meta: {
                            htmlID: "u_content_heading_1",
                            htmlClassNames: "u_content_heading",
                          },
                          selectable: true,
                          draggable: true,
                          duplicatable: true,
                          deletable: true,
                          hideable: true,
                          text: "Heading",
                          _languages: {},
                        },
                      },
                      {
                        id: "INeESYjGDp",
                        type: "image",
                        values: {
                          containerPadding: "10px",
                          anchor: "",
                          src: {
                            url: "https://cdn.tools.unlayer.com/image/placeholder.png",
                            width: 1600,
                            height: 400,
                          },
                          textAlign: "center", // Center align image
                          altText: "",
                          action: {
                            name: "web",
                            values: {
                              href: "",
                              target: "_blank",
                            },
                          },
                          hideDesktop: false,
                          displayCondition: null,
                          _styleGuide: null,
                          _meta: {
                            htmlID: "u_content_image_1",
                            htmlClassNames: "u_content_image",
                          },
                          selectable: true,
                          draggable: true,
                          duplicatable: true,
                          deletable: true,
                          hideable: true,
                        },
                      },
                      {
                        id: "EosGim3M1Y",
                        type: "button",
                        values: {
                          href: {
                            name: "web",
                            values: {
                              href: "",
                              target: "_blank",
                            },
                          },
                          buttonColors: {
                            color: "#FFFFFF",
                            backgroundColor: "#FB7D5B",
                            hoverColor: "#FFFFFF",
                            hoverBackgroundColor: "#FB7D5B",
                          },
                          size: {
                            autoWidth: true, // Ensures button width is centered
                            width: "100%",
                          },
                          fontSize: "14px",
                          lineHeight: "120%",
                          textAlign: "center", // Center align button
                          padding: "10px 20px",
                          border: {},
                          borderRadius: "4px",
                          hideDesktop: false,
                          displayCondition: null,
                          _styleGuide: null,
                          containerPadding: "10px",
                          anchor: "",
                          _meta: {
                            htmlID: "u_content_button_1",
                            htmlClassNames: "u_content_button",
                            description: "",
                          },
                          selectable: true,
                          draggable: true,
                          duplicatable: true,
                          deletable: true,
                          hideable: true,
                          text: '<span style="line-height: 16.8px;">Button Text</span>',
                          _languages: {},
                          calculatedWidth: 110,
                          calculatedHeight: 37,
                        },
                      },
                    ],
                    values: {
                      border: {},
                      padding: "0px",
                      backgroundColor: "",
                      textAlign: "center", // Center align column
                      _meta: {
                        htmlID: "u_column_1",
                        htmlClassNames: "u_column",
                      },
                    },
                  },
                ],
                values: {
                  displayCondition: null,
                  columns: false,
                  _styleGuide: null,
                  backgroundColor: "",
                  columnsBackgroundColor: "",
                  backgroundImage: {
                    url: "",
                    fullWidth: true,
                    repeat: "no-repeat",
                    size: "custom",
                    position: "left",
                  },
                  padding: "0px",
                  anchor: "",
                  hideDesktop: false,
                  textAlign: "center", // Center align row
                  _meta: {
                    htmlID: "u_row_1",
                    htmlClassNames: "u_row",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            headers: [],
            footers: [],
            values: {
              textAlign: "center", // Center body content
              _styleGuide: null,
              popupPosition: "center",
              popupWidth: "600px",
              popupHeight: "auto",
              borderRadius: "10px",

              contentVerticalAlign: "center",
              contentWidth: "500px",
              fontFamily: {
                label: "Arial",
                value: "arial,helvetica,sans-serif",
              },
              textColor: "#000000",
              popupBackgroundColor: "#FFFFFF",
              popupBackgroundImage: {
                url: "",
                fullWidth: true,
                repeat: "no-repeat",
                size: "cover",
                position: "center",
              },
              popupOverlay_backgroundColor: "rgba(0, 0, 0, 0.1)",
              popupCloseButton_position: "top-right",
              popupCloseButton_backgroundColor: "#DDDDDD",
              popupCloseButton_iconColor: "#000000",
              popupCloseButton_borderRadius: "0px",
              popupCloseButton_margin: "0px",
              popupCloseButton_action: {
                name: "close_popup",
                attrs: {
                  onClick:
                    "document.querySelector('.u-popup-container').style.display = 'none';",
                },
              },
              language: {},
              backgroundColor: "#fff8f3",
              preheaderText: "",
              linkStyle: {
                body: true,
                linkColor: "#0000ee",
                linkHoverColor: "#0000ee",
                linkUnderline: true,
                linkHoverUnderline: true,
              },
              backgroundImage: {
                url: "",
                fullWidth: true,
                repeat: "no-repeat",
                size: "custom",
                position: "left",
              },
              _meta: {
                htmlID: "u_body",
                htmlClassNames: "u_body",
              },
            },
          },
          schemaVersion: 18,
        };
        unlayer?.loadDesign(design);
      }
      return;
    }
  };

  useEffect(() => {
    onReady();
  }, [templatesData, id]);

  const exportHtml = async (formData) => {
    if (emailEditorRef.current) {
      await emailEditorRef.current.editor.exportHtml((data) => {
        const { design, html } = data;
        let payload = {
          templateName: formData.templateName,
          templateTypeId: formData.templateType,
          templateCategoryTypeId: formData.templateCategoryType,
          templateFormat: html,
          templateConfig: design,
          isDefault: formData.isDefault,
        };
        saveData(payload);
        // setData(html);
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(save)} noValidate style={{ padding: "10px" }}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Grid container spacing={2}>
        <Grid item xs={10}>
          {window.location.pathname === `/template/new` ? (
            <Typography variant="h5">{"Template"}</Typography>
          ) : (
            <Typography variant="h5">{watch("templateName")}</Typography>
          )}
        </Grid>
        <Grid item xs={2}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            pb={1}
          >
            {isEdit.disabled !== false ? (
              <MDButton
                size="small"
                variant="contained"
                color="info"
                sx={{ color: "#FFFFFF" }}
                startIcon={<EditOutlined />}
                onClick={() => {
                  navigate(`/template/${id}/edit`);
                  setIsEdit({
                    disabled: false,
                    edit: true,
                  });
                }}
              >
                Edit
              </MDButton>
            ) : (
              <MDBox display="flex" alignItems="center" gap="10px">
                <MDButton
                  variant="outlined"
                  color="dark"
                  size="small"
                  onClick={() => {
                    // getData();
                    Close();
                    if (window.location.pathname === `/template/new`) {
                      navigate(`/template`);
                    } else {
                      navigate(`/template/${id}`);
                      setIsEdit({
                        disabled: true,
                        edit: false,
                      });
                    }
                  }}
                >
                  &nbsp;cancel
                </MDButton>
                <MDButton variant="gradient" size="small" color="info" type="submit">
                  Save
                </MDButton>
              </MDBox>
            )}
          </Stack>
        </Grid>
        {isEdit.edit || window.location.pathname === `/template/new` ? (
          <Grid container sx={{ marginLeft: "5px" }} spacing={2}>
            <Grid item xs={3}>
              <Label value="Template Name" required={true} />
              <Controller
                name="templateName"
                control={control}
                rules={{
                  required: "Template Name is required!",
                }}
                render={({ field }) => (
                  <Stack mb={1}>
                    <MDInput
                      required
                      {...field}
                      fullWidth
                      variant="outlined"
                      error={errors?.templateName ? true : false}
                      helperText={
                        errors?.templateName
                          ? errors.templateName.message
                          : null
                      }
                    />
                  </Stack>
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Label value="Template Type" required={true} />
              <Controller
                name="templateType"
                control={control}
                rules={{
                  required: "Template Type is required!",
                }}
                render={({ field: { onChange, value } }) => (
                  <FormControl
                    fullWidth
                    required
                    error={errors?.templateType ? true : false}
                    size="small"
                    sx={{
                      label: {
                        color: "rgba(0, 0, 0, 0.25)",
                        fontWeight: "400",
                      },
                    }}
                  >
                    <Select
                      value={value}
                      onChange={onChange}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      sx={selectStyle}
                    //   onClose={() => setClientsSearch("")}
                    >
                      {/* <ListSubheader sx={{ marginBottom: "1rem" }}>
                        <TextField
                          sx={{
                            position: "sticky",
                            marginTop: "-16px",
                            paddingTop: "1rem",
                            zIndex: 1,
                            background: "white",
                          }}
                          size="small"
                          autoFocus
                          required
                            value={clientsSearch}
                          placeholder="Type to search..."
                          fullWidth
                            onChange={(e) => setClientsSearch(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key !== "Escape") {
                              e.stopPropagation();
                            }
                          }}
                        />
                      </ListSubheader> */}
                      {/* <MenuItem value="">
                        <em>None</em>
                      </MenuItem> */}
                      {templateType?.map((value, i) => {
                        return (
                          <MenuItem value={value.id} key={i}>
                            {value.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {errors?.templateType && (
                      <FormHelperText>
                        {errors?.templateType
                          ? errors.templateType.message
                          : null}
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Label value="Template Category Type" required={true} />
              <Controller
                name="templateCategoryType"
                control={control}
                rules={{
                  required: "Template Category Type is required!",
                }}
                render={({ field: { onChange, value } }) => (
                  <FormControl
                    fullWidth
                    required
                    error={errors?.templateCategoryType ? true : false}
                    size="small"
                    sx={{
                      label: {
                        color: "rgba(0, 0, 0, 0.25)",
                        fontWeight: "400",
                      },
                    }}
                  >
                    <Select
                      value={value}
                      onChange={onChange}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      sx={selectStyle}
                    //   onClose={() => setClientsSearch("")}
                    >
                      {/* <ListSubheader sx={{ marginBottom: "1rem" }}>
                        <TextField
                          sx={{
                            position: "sticky",
                            marginTop: "-16px",
                            paddingTop: "1rem",
                            zIndex: 1,
                            background: "white",
                          }}
                          size="small"
                          autoFocus
                          required
                            value={clientsSearch}
                          placeholder="Type to search..."
                          fullWidth
                            onChange={(e) => setClientsSearch(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key !== "Escape") {
                              e.stopPropagation();
                            }
                          }}
                        />
                      </ListSubheader> */}
                      {/* <MenuItem value="">
                        <em>None</em>
                      </MenuItem> */}
                      {templateCategoryType?.map((value, i) => {
                        return (
                          <MenuItem value={value.id} key={i}>
                            {value.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {errors?.templateCategoryType && (
                      <FormHelperText>
                        {errors?.templateCategoryType
                          ? errors.templateCategoryType.message
                          : null}
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Label value="Is Default" required={true} />
              <Controller
                name="isDefault"
                control={control}
                rules={{
                  validate: (value) =>
                    value !== undefined || "Is Default selection is required!",
                }}
                render={({ field: { onChange, value } }) => (
                  <FormControl
                    fullWidth
                    required
                    error={errors?.isDefault ? true : false}
                    size="small"
                    sx={{
                      label: {
                        color: "rgba(0, 0, 0, 0.25)",
                        fontWeight: "400",
                      },
                    }}
                  >
                    <Select
                      value={value}
                      onChange={onChange}
                      labelId="isDefault-label"
                      id="isDefault-select"
                      sx={selectStyle}
                    >
                      <MenuItem value={true}>Yes</MenuItem>
                      <MenuItem value={false}>No</MenuItem>
                    </Select>
                    {errors?.isDefault && (
                      <FormHelperText>
                        {errors.isDefault.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            </Grid>
          </Grid>
        ) : (
          <>
            <Grid container spacing={1.8} sx={{ marginLeft: "5px" }}>
              <Grid item xs={12} md={3}>
                {label("Template Name")}
                <Typography fontSize="14px" fontWeight="600">
                  {watch("templateName")}
                </Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                {label("Template Type")}
                <Typography fontSize="14px" fontWeight="600">
                  {
                    templateType?.find(
                      (data) => data.id == watch("templateType")
                    )?.name
                  }
                </Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                {label("Template Category Type")}
                <Typography fontSize="14px" fontWeight="600">
                  {
                    templateCategoryType?.find(
                      (data) => data.id == watch("templateCategoryType")
                    )?.name
                  }
                </Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                {label("Is Default")}
                <Typography fontSize="14px" fontWeight="600">
                  {watch("isDefault") ? "Yes" : "No"}
                </Typography>
              </Grid>
            </Grid>
          </>
        )}
        {isEdit.edit || window.location.pathname === `/template/new` ? (
          <>
            <EmailEditor
              style={{ height: "80vh", mt: "8px" }}
              ref={emailEditorRef}
              onReady={onReady}
            />
          </>
        ) : (
          <>
            <Box
              dangerouslySetInnerHTML={{ __html: templatesData.templateFormat }}
              sx={{ height: "80vh", mt: "8px", padding: "0.5rem" }}
            ></Box>
          </>
        )}
      </Grid>
    </form>
  );
}

export default TemplateAdd;
