import { useState } from "react";
import Grid from "@mui/material/Grid";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect } from "react";
import { Backdrop, CircularProgress, Tooltip } from "@mui/material";
import { useOutletContext } from "react-router-dom";
import SearchInput from "../../../../../../components/searchInput";
import TLinkApi from "services/tlink.api";
import MDButton from "components/MDButton";
import Colors from "../../../../../../assets/theme/base/colors";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Box,
  Button,
  Icon,
  IconButton,
} from "@mui/material";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import useTableTheme from "../../../../../../constant/TableTheme";
import { ThemeProvider } from "@mui/material";
import { useSnackbar } from "notistack";
import { useLocation } from "react-router-dom";
import moment from "moment";
import DeleteIcon from "@mui/icons-material/Delete";
import GetMemberList from "../../../../../../hooks/MemberList/GetMemberList";
import MDTypography from "../../../../../../components/MDTypography";
import MDBox from "../../../../../../components/MDBox";

function MemberTable({ open, setOpen }) {
  const navigate = useNavigate();
  const {
    member,
    getMember,
    isLoading,
    setIsLoading,
    totalLength,
    setPage,
    page,
    setSearch,
    search,
    setEntriesPerPage,
    entriesPerPage,
    sortBy,
    setSortBy,
    sortOrder,
    setSortOrder,
  } = GetMemberList();
  // const { setIsEdit, setMember } = useOutletContext();
  const [data, setData] = useState([]);
  const [status, setStatus] = useState([]);
  const [rolesData, setRolesData] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const location = useLocation();
  let { enqueueSnackbar } = useSnackbar();
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 50,
  });
  const { textColor, info } = Colors;
  const [userDetails, setUserDetails] = useState({});

  const tableTheme = useTableTheme();
  let { id } = useParams();

  async function GetRoleList() {
    setIsLoading(true);
    try {
      let data = await TLinkApi.get(`/master/list/role`);
      setRolesData(data.data);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  }

  useEffect(() => {
    if (id) {
      GetRoleList();
    }
  }, [id]);

  const fetchUserDetails = async () => {
    try {
      const response = await TLinkApi.get(`/users/current`);
      setUserDetails(response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching user data:", error);
      return null;
    }
  };
  // const fetchUserDetails = async () => {
  //   try {
  //     const orgSlug = localStorage.getItem("organizationSlug");
  //     if (!orgSlug) {
  //       throw new Error("Organization slug is missing from local storage");
  //     }
  //     const data = await TLinkApi.get(`/o/${orgSlug}/user/me`);
  //     setUserDetails(data.data);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  useEffect(() => {
    fetchUserDetails();
  }, []);

  const UpdateRole = async (payload) => {
    setIsLoading(true);
    try {
      const orgSlug = localStorage.getItem("organizationSlug");
      if (!orgSlug) {
        throw new Error("Organization slug is missing from local storage");
      }
      const result = await TLinkApi.patch(
        `/o/${orgSlug}/panel/${id}/member`,
        payload
      );

      const message = result?.data?.message || "Role updated successfully";
      enqueueSnackbar(message, { variant: "success" });
      setIsLoading(false);
      getMember();
    } catch (e) {
      setIsLoading(false);
      const errorMessage = e?.response?.data?.message || "Failed to update role. Please try again.";
      enqueueSnackbar(errorMessage, { variant: "error" });
      console.error("UpdateRole Error:", e);
    }
  };
  
  const deleterow = async (payload) => {
    setIsLoading(true);
    try {
      const orgSlug = localStorage.getItem("organizationSlug");
      if (!orgSlug) {
        throw new Error("Organization slug is missing from local storage");
      }
  
      const data = await TLinkApi.put(
        `/o/${orgSlug}/panel/${id}/member`,
        payload
      );

      enqueueSnackbar("Member deleted successfully", { variant: "success" });
      getMember();
      if (member.length === 1) {
        setData([]); 
      }
      setIsLoading(false);
    } catch (err) {
      enqueueSnackbar("Failed to delete member", { variant: "error" });
      console.error("DeleteRow Error:", err);
      setIsLoading(false);
    }
  };
  

  useEffect(() => {
    let newData = member?.length
      ? member.map((value) => ({
          ...value,
          roleName: value?.roles[0]?.name,
          statusName: value?.status?.name,
          date: moment(value?.createdAt).format("YYYY-MM-DD, h:mm:ss a"),
        }))
      : [];
    setData(newData);
  }, [member]);

  useEffect(() => {
    setPage(pagination.pageIndex);
    setEntriesPerPage(pagination.pageSize);
  }, [pagination]);

  useEffect(() => {
    getMember();
  }, [open]);

  useEffect(() => {
    if (sorting.length === 1) {
      if (sorting[0].desc === false) {
        if (sorting[0].id === "statusName") {
          setSortBy("userStatusName");
        } else if (sorting[0].id === "date") {
          setSortBy("createdAt");
        } else {
          setSortBy(sorting[0].id);
        }
        setSortOrder("asc");
      } else {
        if (sorting[0].id === "statusName") {
          setSortBy("userStatusName");
        } else if (sorting[0].id === "date") {
          setSortBy("createdAt");
        } else {
          setSortBy(sorting[0].id);
        }
        setSortOrder("desc");
      }
    } else if (sorting.length === 0) {
      setSortBy("id");
      setSortOrder("asc");
    }
  }, [sorting]);

  let columns = [
    {
      accessorKey: "id",
      header: "Member Id",
      enableSorting: true,
      enableEditing: false,
      size: 50,
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },
    {
      accessorKey: "firstName",
      header: "First Name",
      enableSorting: true,
      enableEditing: false,
      size: 50,
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      Cell: ({ cell }) => {
        const firstName = cell.getValue();
        const truncatedName =
          firstName?.length > 15
            ? firstName.substring(0, 15) + "..."
            : firstName;
        return (
          <Tooltip title={firstName}>
            <div>{`${truncatedName}`}</div>
          </Tooltip>
        );
      },
    },
    {
      accessorKey: "roleName",
      header: "Role",
      enableSorting: false,
      size: 50,
      editVariant: "select",
      editSelectOptions: rolesData.map((role) => role.name),
      muiEditTextFieldProps: ({ cell, row }) => ({
        disabled: row.original.id === parseInt(userDetails?.id),
        select: !(row.original.id === parseInt(userDetails?.id)),
        // select: true,
        value: row.original.roles.map((role) => role.name),
        onChange: (event) => {
          let payload = {
            userId: row.original.id,
            roleIds: [
              rolesData.find((val) => val.name === event.target.value).id,
            ],
          };
          UpdateRole(payload);
        },
      }),
    },
    {
      accessorKey: "statusName",
      header: "Status",
      enableSorting: true,
      enableEditing: false,
      size: 50,
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },
    {
      accessorKey: "date",
      header: "Added on",
      enableSorting: true,
      enableEditing: false,
      size: 50,
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },
    {
      accessorKey: "isActive",
      header: "Action",
      enableSorting: false,
      enableEditing: false,
      size: 50,
      muiTableHeadCellProps: {
        align: "center",
      },
      Cell: ({ row }) => (
        <Stack
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          spacing={2}
        >
          <Tooltip title="Delete Member" placement="top">
            <IconButton
              variant="outlined"
              color="dark"
              size="small"
              disabled={row.original.id === parseInt(userDetails?.id)}
              onClick={(event) => {
                if (!(row.original.id === parseInt(userDetails?.id))) {
                  let payload = {
                    userId: row.original.id,
                    roleIds: row.original.roles.map((role) => role.id),
                  };
                  deleterow(payload);
                } else {
                  enqueueSnackbar("You can't delete this user", {
                    variant: "error",
                  });
                }
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      ),
    },
  ];

  const table = useMaterialReactTable({
    columns,
    data,
    editDisplayMode: "table",
    enableEditing: true,
    enableStickyHeader: true,
    manualPagination: true,
    rowCount: totalLength,
    onPaginationChange: setPagination,
    state: { density: "compact", pagination, sorting },
    enableColumnPinning: true,
    enableGlobalFilter: true,
    enableDensityToggle: false,
    manualSorting: true,
    muiPaginationProps: {
      rowsPerPageOptions: [25, 50, 100],
    },
    onSortingChange: setSorting,
    muiTableContainerProps: {
      sx: {
        maxHeight: "calc(100vh - 240px)",
        overflowY: "auto",
      },
    },
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        width: "100%",
        borderRadius: "0",
        backgroundColor: "#fff !important",
        zIndex: 500,
      },
    },
    renderEmptyRowsFallback: () => (
      <Stack
        display="flex"
        alignItems="center"
        justifyContent="center"
        fontSize="14px"
        p={5}
      >
        No records to display
      </Stack>
    ),
  });

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container display="flex" ml="15px">
        <Grid item xs={8} display="flex" justifyContent="flex-start" mt="15px">
          {/* <MDTypography variant="h6" color="#2F2F2F">
            All Members
          </MDTypography> */}
        </Grid>
        <Grid
          item
          xs={4}
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          mt={1}
        >
          <Grid
            container
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            mr="25px"
          >
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              marginBottom={1}
            >
              <MDButton
                 variant="contained"
                 color="info"
                 sx={{ color: "#FFFFFF" }}
                 size="small"
                onClick={() => {
                  setOpen(true);
                }}
              >
                + Members
              </MDButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* <Grid item xs={12} mt={1}>
        <hr style={{ border: `1px solid #C4C4C4`, width: "100%" }} />
      </Grid> */}
      <ThemeProvider theme={tableTheme}>
        <MaterialReactTable
          table={table}
          style={{
            width: "100%",
          }}
        />
      </ThemeProvider>
    </>
  );
}

export default MemberTable;
