import ContentCopyTwoToneIcon from "@mui/icons-material/ContentCopyTwoTone";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Backdrop, CircularProgress, Grid, Icon, Switch } from "@mui/material";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import colors from "assets/theme/base/colors";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { useSnackbar } from "notistack";
import * as React from "react";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import TLinkApi from "services/tlink.api";

function Row(props) {
  const { row, isEdit, setIsEdit, setIsLoading } = props;
  const [open, setOpen] = React.useState(false);
  let { enqueueSnackbar } = useSnackbar();

  const {
    control,
    register,
    watch,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      vendorCompleted: "",
      vendorQuotaFull: "",
      vendorTerminate: "",
      vendorSecurity: "",
      s2sCompleted: "",
      s2sQuotaFull: "",
      s2sTerminate: "",
      s2sSecurity: "",
      s2sEnabled: false,
      surveyId: "",
      isRunningOnVendor: "",
    },
  });
  let s2sEnabled = watch("s2sEnabled");

  const save = async (data) => {
    let payload = {
      vendorTerminate: data.vendorTerminate,
      vendorCompleted: data.vendorCompleted,
      vendorSecurity: data.vendorSecurity,
      vendorQuotaFull: data.vendorQuotaFull,
      s2sCompleted: data.s2sEnabled ? data.s2sCompleted : "",
      s2sQuotaFull: data.s2sEnabled ? data.s2sQuotaFull : "",
      s2sSecurity: data.s2sEnabled ? data.s2sSecurity : "",
      s2sTerminate: data.s2sEnabled ? data.s2sTerminate : "",
      allocation: data.allocation,
      vcpi: data.vcpi,
      s2sEnabled: data.s2sEnabled,
      surveyId: data.surveyId,
      isRunningOnVendor: data.isRunningOnVendor ? true : false,
    };
    try {
      const orgSlug = localStorage.getItem("organizationSlug");
      const panelSlug = localStorage.getItem("panelSlug");
      console.log(orgSlug, panelSlug);
      if (!orgSlug) {
        throw new Error("Organization slug is missing from local storage");
      }
      let result = await TLinkApi.put(
        `/o/${orgSlug}/panel/${panelSlug}/survey/supplier/${row.surveySupplierId}`,
        payload
      );
      setIsLoading(false);

      enqueueSnackbar(result?.message, {
        variant: "success",
      });
      props.getTableData({});
    } catch (e) {
      enqueueSnackbar(e?.message, {
        variant: "error",
      });
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    if (Object.keys(row).length > 0) {
      setValue("vendorTerminate", row.vendorTerminate);
      setValue("vendorCompleted", row.vendorCompleted);
      setValue("vendorSecurity", row.vendorSecurity);
      setValue("vendorQuotaFull", row.vendorQuotaFull);
      setValue("s2sQuotaFull", row.s2sQuotaFull);
      setValue("s2sCompleted", row.s2sCompleted);
      setValue("s2sSecurity", row.s2sSecurity);
      setValue("s2sTerminate", row.s2sTerminate);
      setValue("allocation", row.allocation);
      setValue("vcpi", row.vcpi);
      setValue("s2sEnabled", row.s2sEnabled);
      setValue("isRunningOnVendor", row.isRunningOnVendor);
      setValue("surveyId", row.surveyId);
    }
    // eslint-disable-next-line
  }, [row]);

  function copy(text) {
    navigator.clipboard.writeText(text);
    enqueueSnackbar("URL copy Successfully!", {
      variant: "success",
    });
  }

  return (
    <React.Fragment>
      <TableRow
        sx={{ "& > *": { borderBottom: "1px solid rgba(0, 0, 0, 0.06)" } }}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="left">{row.supplierName}</TableCell>
        <TableCell scope="row" align="center">
          {row.supplierId}
        </TableCell>

        <TableCell align="center">{parseFloat(row.vcpi).toFixed(2)}</TableCell>
        <TableCell align="center">{row.allocation}</TableCell>
        <TableCell align="center">{row.starts}</TableCell>
        <TableCell align="center">{row.completes}</TableCell>
        <TableCell align="center">
          {parseFloat(row.conversion).toFixed(2)}
        </TableCell>
        <TableCell align="center">{parseFloat(row.epc).toFixed(2)}</TableCell>
        <TableCell align="center">
          {row.isRunningOnVendor ? "Running" : "Stopped"}
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 2 }}>
              <form onSubmit={handleSubmit(save)} noValidate>
                <Table size="small" aria-label="purchases">
                  <TableBody>
                    <TableRow>
                      <TableCell scope="row">
                        <MDBox
                          display="flex"
                          alignItems="center"
                          justifyContent="flex-end"
                        >
                          <IconButton
                            size="small"
                            onClick={() => {
                              setIsEdit({
                                disabled: false,
                                edit: true,
                              });
                            }}
                          >
                            <Icon color="dark">border_color_icon</Icon>
                          </IconButton>
                        </MDBox>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell scope="row">
                        <MDBox
                          p={2}
                          sx={{
                            border: "1px solid #D9D9D9",
                            borderRadius: "10px",
                          }}
                        >
                          <MDTypography
                            color="textColor"
                            sx={{ fontSize: "14px" }}
                            mb={1}
                          >
                            Survey URLs
                          </MDTypography>
                          <MDBox
                            bgColor="#F5F5F5"
                            py={0.5}
                            px={1}
                            sx={{
                              border: "1px solid #D9D9D9",
                              borderRadius: "5px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              flexDirection: "row",
                            }}
                          >
                            <MDBox
                              sx={{
                                width: "80%",
                                overflow: "hidden",
                                display: "inline-block",
                                textDecoration: "none",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {row.link}
                            </MDBox>
                            <IconButton
                              onClick={() => {
                                copy(row.link);
                              }}
                            >
                              <ContentCopyTwoToneIcon fontSize="small" />
                            </IconButton>
                          </MDBox>
                          {/* </MDBox> */}
                          {/* <MDBox p={2} sx={{ border: "1px solid #D9D9D9", borderRadius: "10px" }} mt={2}> */}
                          <MDTypography
                            color="textColor"
                            sx={{ fontSize: "14px", paddingTop: "10px" }}
                            mb={1}
                          >
                            Survey Test URLs
                          </MDTypography>

                          <MDBox
                            bgColor="#F5F5F5"
                            py={0.5}
                            px={1}
                            sx={{
                              border: "1px solid #D9D9D9",
                              borderRadius: "5px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              flexDirection: "row",
                            }}
                          >
                            <MDBox
                              sx={{
                                width: "80%",
                                overflow: "hidden",
                                display: "inline-block",
                                textDecoration: "none",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {row.testLink}
                            </MDBox>
                            <IconButton
                              onClick={() => {
                                copy(row.testLink);
                              }}
                            >
                              <ContentCopyTwoToneIcon fontSize="small" />
                            </IconButton>
                            {/* </MDBox> */}
                          </MDBox>
                        </MDBox>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell scope="row">
                        <MDBox
                          p={2}
                          sx={{
                            border: "1px solid #D9D9D9",
                            borderRadius: "10px",
                          }}
                        >
                          <MDTypography
                            color="textColor"
                            sx={{ fontSize: "14px" }}
                            mb={1}
                          >
                            Configuration
                          </MDTypography>
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                              <MDTypography
                                sx={{
                                  color: "black",
                                  fontSize: "12px",
                                  fontWeight: "500",
                                }}
                                mb={0.5}
                              >
                                Vcpi
                              </MDTypography>
                              <Controller
                                name="vcpi"
                                control={control}
                                rules={{
                                  required: "Vcpi is Required!",
                                }}
                                render={({ field }) => (
                                  <MDInput
                                    {...field}
                                    fullWidth
                                    type="number"
                                    disabled={isEdit.disabled}
                                    autoComplete="off"
                                    variant="outlined"
                                    placeholder="Vcpi"
                                    error={errors?.vcpi ? true : false}
                                    helperText={
                                      errors?.vcpi ? errors.vcpi.message : null
                                    }
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <MDTypography
                                sx={{
                                  color: "black",
                                  fontSize: "12px",
                                  fontWeight: "500",
                                }}
                                mb={0.5}
                              >
                                Allocation (%)
                              </MDTypography>
                              <Controller
                                name="allocation"
                                control={control}
                                rules={{
                                  required: "Allocation is Required!",
                                }}
                                render={({ field }) => (
                                  <MDInput
                                    {...field}
                                    fullWidth
                                    type="number"
                                    disabled={isEdit.disabled}
                                    autoComplete="off"
                                    variant="outlined"
                                    placeholder="Allocation"
                                    error={errors?.allocation ? true : false}
                                    helperText={
                                      errors?.allocation
                                        ? errors.allocation.message
                                        : null
                                    }
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <MDTypography
                                sx={{
                                  color: "black",
                                  fontSize: "12px",
                                  fontWeight: "500",
                                }}
                                mb={0.5}
                              >
                                Status
                              </MDTypography>
                              <Controller
                                name="isRunningOnVendor"
                                control={control}
                                render={({ field }) =>
                                  isEdit.disabled ? (
                                    <MDInput
                                      {...field}
                                      fullWidth
                                      type="text"
                                      disabled
                                      autoComplete="off"
                                      variant="outlined"
                                      value={
                                        field.value ? "Running" : "Stopped"
                                      }
                                      error={
                                        errors?.isRunningOnVendor ? true : false
                                      }
                                      helperText={
                                        errors?.isRunningOnVendor
                                          ? errors.isRunningOnVendor.message
                                          : null
                                      }
                                    />
                                  ) : (
                                    <Select
                                      {...field}
                                      fullWidth
                                      variant="outlined"
                                      value={
                                        field.value ? "running" : "stopped"
                                      }
                                      onChange={(e) =>
                                        field.onChange(
                                          e.target.value === "running"
                                            ? true
                                            : false
                                        )
                                      }
                                      error={!!errors?.isRunningOnVendor}
                                    >
                                      <MenuItem value="running">
                                        Running
                                      </MenuItem>
                                      <MenuItem value="stopped">
                                        Stopped
                                      </MenuItem>
                                    </Select>
                                  )
                                }
                              />
                            </Grid>
                          </Grid>
                        </MDBox>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell scope="row">
                        <MDBox
                          p={2}
                          sx={{
                            border: "1px solid #D9D9D9",
                            borderRadius: "10px",
                          }}
                        >
                          <MDTypography
                            color="textColor"
                            sx={{ fontSize: "14px" }}
                            mb={1}
                          >
                            Redirection URLs
                          </MDTypography>
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                              <MDTypography
                                sx={{
                                  color: "black",
                                  fontSize: "12px",
                                  fontWeight: "500",
                                }}
                                mb={0.5}
                              >
                                Vendor Complete
                              </MDTypography>
                              <Controller
                                name="vendorCompleted"
                                control={control}
                                rules={{
                                  required: `Vendor Complete is required!`,
                                }}
                                render={({ field }) => (
                                  <MDInput
                                    {...field}
                                    fullWidth
                                    disabled={isEdit.disabled}
                                    autoComplete="off"
                                    variant="outlined"
                                    placeholder="S2S QuotaFull"
                                    {...register("vendorCompleted", {
                                      pattern: {
                                        value:
                                          //eslint-disable-next-line
                                          /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
                                        message: "invalid URl ",
                                      },
                                    })}
                                    error={
                                      errors?.vendorCompleted ? true : false
                                    }
                                    helperText={
                                      errors?.vendorCompleted
                                        ? errors.vendorCompleted.message
                                        : null
                                    }
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <MDTypography
                                sx={{
                                  color: "black",
                                  fontSize: "12px",
                                  fontWeight: "500",
                                }}
                                mb={0.5}
                              >
                                Vendor Terminate
                              </MDTypography>
                              <Controller
                                name="vendorTerminate"
                                control={control}
                                rules={{
                                  required: "Vendor Terminate is Required!",
                                }}
                                render={({ field }) => (
                                  <MDInput
                                    {...field}
                                    fullWidth
                                    disabled={isEdit.disabled}
                                    autoComplete="off"
                                    variant="outlined"
                                    placeholder="S2S QuotaFull"
                                    {...register("vendorTerminate", {
                                      pattern: {
                                        value:
                                          //eslint-disable-next-line
                                          /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
                                        message: "invalid URl ",
                                      },
                                    })}
                                    error={
                                      errors?.vendorTerminate ? true : false
                                    }
                                    helperText={
                                      errors?.vendorTerminate
                                        ? errors.vendorTerminate.message
                                        : null
                                    }
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <MDTypography
                                sx={{
                                  color: "black",
                                  fontSize: "12px",
                                  fontWeight: "500",
                                }}
                                mb={0.5}
                              >
                                Vendor Security Terminate
                              </MDTypography>
                              <Controller
                                name="vendorSecurity"
                                control={control}
                                rules={{
                                  required: false,
                                }}
                                render={({ field }) => (
                                  <MDInput
                                    {...field}
                                    fullWidth
                                    disabled={isEdit.disabled}
                                    autoComplete="off"
                                    variant="outlined"
                                    placeholder="S2S QuotaFull"
                                    {...register("vendorSecurity", {
                                      pattern: {
                                        value:
                                          //eslint-disable-next-line
                                          /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
                                        message: "invalid URl ",
                                      },
                                    })}
                                    error={
                                      errors?.vendorSecurity ? true : false
                                    }
                                    helperText={
                                      errors?.vendorSecurity
                                        ? errors.vendorSecurity.message
                                        : null
                                    }
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <MDTypography
                                sx={{
                                  color: "black",
                                  fontSize: "12px",
                                  fontWeight: "500",
                                }}
                                mb={0.5}
                              >
                                Vendor Overallocation
                              </MDTypography>
                              <Controller
                                name="vendorQuotaFull"
                                control={control}
                                rules={{
                                  required: false,
                                }}
                                render={({ field }) => (
                                  <MDInput
                                    {...field}
                                    fullWidth
                                    disabled={isEdit.disabled}
                                    autoComplete="off"
                                    variant="outlined"
                                    placeholder="S2S QuotaFull"
                                    {...register("vendorQuotaFull", {
                                      pattern: {
                                        value:
                                          //eslint-disable-next-line
                                          /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
                                        message: "invalid URl ",
                                      },
                                    })}
                                    error={
                                      errors?.vendorQuotaFull ? true : false
                                    }
                                    helperText={
                                      errors?.vendorQuotaFull
                                        ? errors.s2sQuotaFull.message
                                        : null
                                    }
                                  />
                                )}
                              />
                            </Grid>
                          </Grid>
                        </MDBox>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell scope="row">
                        <MDBox
                          p={2}
                          sx={{
                            border: "1px solid #D9D9D9",
                            borderRadius: "10px",
                          }}
                        >
                          <MDBox
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            flexDirection="row"
                            style={{ borderBottom: "1px solid #D9D9D9" }}
                          >
                            <MDTypography
                              sx={{
                                color: "black",
                                fontSize: "12px",
                                fontWeight: "500",
                              }}
                              mb={1}
                            >
                              S2S URLs
                            </MDTypography>
                            <div style={{ color: "rgba(0, 173, 239, 1)" }}>
                              <Switch
                size="large"
                                checked={s2sEnabled}
                                disabled={isEdit.disabled}
                                onChange={(e, value) => {
                                  setValue("s2sEnabled", value);
                                }}
                              />
                            </div>
                          </MDBox>
                          {s2sEnabled && (
                            <Grid container spacing={2}>
                              <Grid item xs={12} md={6}>
                                <MDTypography
                                  sx={{
                                    color: "black",
                                    fontSize: "12px",
                                    fontWeight: "500",
                                  }}
                                  mb={0.5}
                                >
                                  S2S Complete
                                </MDTypography>
                                <Controller
                                  name="s2sCompleted"
                                  control={control}
                                  rules={{
                                    required: `S2S Completed is required!`,
                                  }}
                                  render={({ field }) => (
                                    <MDInput
                                      {...field}
                                      fullWidth
                                      disabled={isEdit.disabled}
                                      autoComplete="off"
                                      variant="outlined"
                                      placeholder="S2S Completed"
                                      {...register("s2sCompleted", {
                                        pattern: {
                                          value:
                                            //eslint-disable-next-line
                                            /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
                                          message: "invalid URl ",
                                        },
                                      })}
                                      error={
                                        errors?.s2sCompleted ? true : false
                                      }
                                      helperText={
                                        errors?.s2sCompleted
                                          ? errors.s2sCompleted.message
                                          : null
                                      }
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <MDTypography
                                  sx={{
                                    color: "black",
                                    fontSize: "12px",
                                    fontWeight: "500",
                                  }}
                                  mb={0.5}
                                >
                                  S2S Terminate
                                </MDTypography>
                                <Controller
                                  name="s2sTerminate"
                                  control={control}
                                  rules={{
                                    required: "S2S Terminate is Required!",
                                  }}
                                  render={({ field }) => (
                                    <MDInput
                                      {...field}
                                      fullWidth
                                      disabled={isEdit.disabled}
                                      autoComplete="off"
                                      variant="outlined"
                                      placeholder="S2S Terminate"
                                      {...register("s2sTerminate", {
                                        pattern: {
                                          value:
                                            //eslint-disable-next-line
                                            /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
                                          message: "invalid URl ",
                                        },
                                      })}
                                      error={
                                        errors?.s2sTerminate ? true : false
                                      }
                                      helperText={
                                        errors?.s2sTerminate
                                          ? errors.s2sTerminate.message
                                          : null
                                      }
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <MDTypography
                                  sx={{
                                    color: "black",
                                    fontSize: "12px",
                                    fontWeight: "500",
                                  }}
                                  mb={0.5}
                                >
                                  S2S Overallocation
                                </MDTypography>
                                <Controller
                                  name="s2sQuotaFull"
                                  control={control}
                                  rules={{
                                    required: false,
                                  }}
                                  render={({ field }) => (
                                    <MDInput
                                      {...field}
                                      fullWidth
                                      disabled={isEdit.disabled}
                                      autoComplete="off"
                                      variant="outlined"
                                      placeholder="S2S QuotaFull"
                                      {...register("s2sQuotaFull", {
                                        pattern: {
                                          value:
                                            //eslint-disable-next-line
                                            /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
                                          message: "invalid URl ",
                                        },
                                      })}
                                      error={
                                        errors?.s2sQuotaFull ? true : false
                                      }
                                      helperText={
                                        errors?.s2sQuotaFull
                                          ? errors.s2sQuotaFull.message
                                          : null
                                      }
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <MDTypography
                                  sx={{
                                    color: "black",
                                    fontSize: "12px",
                                    fontWeight: "500",
                                  }}
                                  mb={0.5}
                                >
                                  S2S Security Terminate
                                </MDTypography>
                                <Controller
                                  name="s2sSecurity"
                                  control={control}
                                  rules={{
                                    required: false,
                                  }}
                                  render={({ field }) => (
                                    <MDInput
                                      {...field}
                                      fullWidth
                                      disabled={isEdit.disabled}
                                      autoComplete="off"
                                      variant="outlined"
                                      placeholder="S2S Security"
                                      {...register("s2sSecurity", {
                                        pattern: {
                                          value:
                                            //eslint-disable-next-line
                                            /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
                                          message: "invalid URl ",
                                        },
                                      })}
                                      error={errors?.s2sSecurity ? true : false}
                                      helperText={
                                        errors?.s2sSecurity
                                          ? errors.s2sSecurity.message
                                          : null
                                      }
                                    />
                                  )}
                                />
                              </Grid>
                            </Grid>
                          )}
                        </MDBox>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell scope="row">
                        <Grid container spacing={1}>
                          <Grid
                            item
                            xs={12}
                            md={12}
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-end"
                            gap="10px"
                            marginBottom="20px"
                          >
                            <MDButton
                              color="info"
                              variant="contained"
                              // size="small"
                              type="submit"
                              disabled={isEdit.disabled}
                            >
                              SAVE
                            </MDButton>
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </form>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function CollapsibleTable({
  supplierTable,
  vendorsMap,
  getTableData,
  isEdit,
  setIsEdit,
  setOpen,
}) {
  const [isLoading, setIsLoading] = useState(false);
  let { textColor } = colors;
  const { setSurvey } = useOutletContext();
  const navigate = useNavigate();
  let { id } = useParams();
  return (
    <TableContainer
      component={Paper}
      sx={{ padding: "10px", height: `calc(100vh - 240px)` }}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Table
        stickyHeader
        aria-label="collapsible table"
        sx={{
          overflow: "auto",
          "& th": {
            backgroundColor: `#F5F5F5`,
            color: textColor.main,
            fontSize: "14px",
          },
          "& .MuiTableCell-root": {
            fontSize: "14px",
            fontWeight: "400",
          },
        }}
      >
        <TableHead style={{ display: "table-header-group" }}>
          <TableRow>
            <TableCell />
            <TableCell align="left" width="20%">
              Supplier Name
            </TableCell>
            <TableCell align="center">Supplier Id</TableCell>
            <TableCell align="center">Vcpi</TableCell>
            <TableCell align="center">Allocation</TableCell>
            <TableCell align="center">Starts</TableCell>
            <TableCell align="center">Completes</TableCell>
            <TableCell align="center">Conversion</TableCell>
            <TableCell align="center">EPC</TableCell>
            <TableCell align="center">Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {supplierTable.map((row, i) => (
            <Row
              key={i}
              row={row}
              vendorsMap={vendorsMap}
              getTableData={getTableData}
              isEdit={isEdit}
              setIsEdit={setIsEdit}
              setIsLoading={setIsLoading}
              isLoading={isLoading}
            />
          ))}
        </TableBody>
      </Table>
      <MDBox border="1px dashed #E0E0E0" mt={1}>
        <MDButton
          fullWidth
          variant="text"
          color="dark"
          size="small"
          onClick={() => {
            setOpen(true);
          }}
        >
          Add Supplier
        </MDButton>
      </MDBox>
    </TableContainer>
  );
}
