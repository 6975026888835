import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Client Name",
  },
  {
    id: "starts",
    numeric: true,
    disablePadding: false,
    label: "Start",
  },
  {
    id: "completes",
    numeric: true,
    disablePadding: false,
    label: "Complete",
  },
  {
    id: "revenue",
    numeric: true,
    disablePadding: false,
    label: "Revenue",
  },
  {
    id: "cost",
    numeric: true,
    disablePadding: false,
    label: "Cost",
  },
  {
    id: "profitPercentage",
    numeric: true,
    disablePadding: false,
    label: "Profit(%)",
  },
  {
    id: "profit",
    numeric: true,
    disablePadding: false,
    label: "Profit($)",
  },
  {
    id: "averageCPI",
    numeric: true,
    disablePadding: false,
    label: "Average CPI",
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead
      sx={{
        display: "table-header-group",
        background: "#f0ecffbf",'& th': {
          borderRadius: '6px 6px 0 0', 
      },}}
    >
      <TableRow sx={{ borderBottom: "0.13rem solid #D2D2D2", }}>
        {headCells.map((headCell, index) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "center" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              position: index === 0 ? "sticky" : "",
              left: 0,
              background: index === 0 ? "#cab3ff" : "",
              zIndex: index === 0 ? 2 : 0,
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              sx={{
                fontSize: "14px",
                color: index === 0 ? "black" : "",
              }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

export default function DashboardClientTable({ rows }) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const visibleRows = React.useMemo(() => {
    return stableSort(rows, getComparator(order, orderBy));
  }, [order, orderBy, rows]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
       
      }}
    >
      <Box padding="1vh" borderBottom="2px solid #BFBFBF" borderRadius={3} sx={{background:"#AB95FF"}}>
        <h5 style={{color:"white"}}>Client</h5>
      </Box>
      <Box
        sx={{
          background: "white",
          overflow: "auto",
          maxHeight: "500px",
          borderRadius: "4px",
        }}
      >
        <Table>
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {visibleRows.map((row, index) => {
              return (
                <TableRow
                  key={index}
                  sx={{ borderBottom: "0.13rem solid #D2D2D2" }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    title={row.name}
                    sx={{
                      position: "sticky",
                      left: 0,
                      fontSize: "14px",
                      fontWeight: "700",
                      background: "white",
                      width: "100px",
                      color: "black",
                    }}
                  >
                    {row.name.length > 6
                      ? row.name.substring(0, 6) + "..."
                      : row.name}
                  </TableCell>
                  <TableCell align="center">{row.starts.toFixed(2)}</TableCell>
                  <TableCell align="center">
                    {row.completes.toFixed(2)}
                  </TableCell>
                  <TableCell align="center">{row.revenue.toFixed(2)}</TableCell>
                  <TableCell align="center">{row.cost.toFixed(2)}</TableCell>
                  <TableCell align="center">
                    {(row.profitPercentage * 100).toFixed(2)}
                  </TableCell>
                  <TableCell align="center">{row.profit.toFixed(2)}</TableCell>
                  <TableCell align="center">
                    {parseFloat(row.averageCPI).toFixed(2)}
                  </TableCell>
                </TableRow>
              );
            })}
            {visibleRows.length <= 0 && (
              <TableRow
                style={{
                  height: 53,
                }}
              >
                <TableCell colSpan={10}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    No Rows
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
}
