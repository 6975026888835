import ModeEditIcon from "@mui/icons-material/ModeEdit";
import SearchIcon from "@mui/icons-material/Search";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
    Backdrop,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    MenuItem,
    Select,
    Stack,
    Switch,
    Tab,
    Typography
} from "@mui/material";
import colors from "assets/theme/base/colors";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useOutletContext, useParams } from "react-router-dom";
import TLinkApi from "services/tlink.api";
import JoiningQuestions from "../JoiningQuestions";
import ProfilingQuestions from "../ProfilingQuestions";
import TierConfig from "../TierConfig";

const MarketList = ({}) => {
  const { panel, setPanel } = useOutletContext();
  const { grey } = colors;
  const { id, panelSlug } = useParams();
  const [isEdit, setIsEdit] = useState({
    disabled: true,
    edit: false,
  });
  const [isEditMarket, setIsEditMarket] = useState({
    disabled: true,
    edit: false,
  });
  const [marketList, setMarketList] = useState([]);
  const [selectedMarket, setSelectedMarket] = useState(null);
  const [activeMarkets, setActiveMarkets] = useState(0);
  const [joiningActiveMarkets, setJoiningActiveMarkets] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [search1, setSearch1] = useState("");
  const [entriesPerPage, setEntriesPerPage] = useState(20);
  const [sortOrder, setSortOrder] = useState("desc");
  const [sortBy, setSortBy] = useState("id");
  const [selectedMarketData, setSelectedMarketData] = useState({});
  const [activeMarketList, setActiveMarketList] = useState([]);
  const [addMarketList, setAddMarketList] = useState([]);
  const [displayMarketList, setDisplayMarketList] = useState([]);
  const [deleteMarketList, setDeleteMarketList] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [checked, setChecked] = useState(false);
  const [tab, setTab] = useState("1");
  const [showSearch, setShowSearch] = useState(true);

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
    setValue,
    register,
  } = useForm({
    defaultValues: {
      currencyId: "",
      panelUnit: "",
      currencyExchangeValue: "",
      panelJoiningIsActive: false,
      profilingPoint: "",
      joiningPoint: "",
    },
  });

  let panelJoiningIsActive = watch("panelJoiningIsActive");
  let currencyId = watch("currencyId");

  const getData = async () => {
    if (id) {
        try {
          const orgSlug = localStorage.getItem("organizationSlug");
          if (!orgSlug) {
            throw new Error("Organization slug is missing from local storage");
          }
          let result = await TLinkApi.get(`/o/${orgSlug}/panel/${id}`);
          setPanel(result.data);
          setIsLoading(false);
        } catch (e) {
          console.log(e);
          setIsLoading(false);
        }
    }
  };

  const handleChange = (event, newTab) => {
    setTab(newTab);
  };

  useEffect(() => {
    if (
      marketList.length > 0 &&
      displayMarketList.length === marketList.length
    ) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [displayMarketList, addMarketList, marketList]);

  useEffect(() => {
    if (selectedMarketData) {
      setValue("currencyId", selectedMarketData?.currency?.id);
      setValue("panelUnit", selectedMarketData?.panelUnit);
      setValue(
        "currencyExchangeValue",
        selectedMarketData?.currencyExchangeValue
      );
      setValue(
        "panelJoiningIsActive",
        selectedMarketData?.panelJoiningIsActive
      );
      setValue("joiningPoint", selectedMarketData?.joiningPoint);
      setValue("profilingPoint", selectedMarketData?.profilingPoint);
    }
  }, [panel, selectedMarketData]);

  const GetMarketList = async () => {
    try {
      let data = await TLinkApi.get(`/master/list/market?search=${search}`);
      if (data.data && data.data.length > 0) {
        setMarketList(data.data);
      }
    } catch (e) {
      console.log(e);
      enqueueSnackbar(e?.response?.data.message, {
        variant: "error",
      });
    }
  };

  const GetActiveMarketList = async () => {
    try {
      const orgSlug = localStorage.getItem("organizationSlug");
      if (!orgSlug) {
        throw new Error("Organization slug is missing from local storage");
      }
      let data = await TLinkApi.get(
        `/o/${orgSlug}/panel/${id}/markets/?search=${search1}&sortOrder=${sortOrder}&sortBy=${sortBy}`
      );
      await GetMarketList();
      if (
        data.data.panelMarketConfigs &&
        data.data.panelMarketConfigs.length > 0
      ) {
        setActiveMarketList(data.data.panelMarketConfigs);
        setDisplayMarketList(
          data.data.panelMarketConfigs?.map((val) => val.market.id)
        );
        console.log(data.data.panelMarketConfigs?.map((val) => val.market.id));
      }
    } catch (e) {
      console.log(e);
      // enqueueSnackbar("No Panel Market Configs Found", {
      //   variant: "error",
      // });
    }
  };

  useEffect(() => {
    GetActiveMarketList();
  }, [search]);

  const getMarketDataById = async (marketId) => {
    if (
      activeMarketList.findIndex((val) => val.market.id == selectedMarket) > -1
    ) {
      try {
        const orgSlug = localStorage.getItem("organizationSlug");
        let data = await TLinkApi.get(
          `/o/${orgSlug}/panel/${id}/market/${marketId}`
        );
        setSelectedMarketData(data.data);
      } catch (e) {
        console.log(e);
        enqueueSnackbar(e?.response?.data?.result?.userMessage, {
          variant: "error",
        });
      }
    } else {
      setValue("currencyId", "");
      setValue("panelUnit", "");
      setValue("currencyExchangeValue", "");
      setValue("panelJoiningIsActive", "");
    }
  };

  useEffect(() => {
    if (selectedMarket) {
      getMarketDataById(selectedMarket);
    }
  }, [selectedMarket]);

  const getActiveMarkets = async () => {
    try {
      const orgSlug = localStorage.getItem("organizationSlug");
      let data = await TLinkApi.get(`/o/${orgSlug}/panel/${id}/markets/count`);
      setActiveMarkets(data.data?.activeMarkets ?? 0);
      setJoiningActiveMarkets(data.data?.joiningActiveMarkets ?? 0);
    } catch (e) {
      console.log(e);
      enqueueSnackbar("No Panel Market Configs Found", {
        variant: "error",
      });
      setActiveMarkets(0);
      setJoiningActiveMarkets(0);
    }
  };

  useEffect(() => {
    getActiveMarkets();
  }, []);

  const fetchCurrencies = async () => {
    try {
      const result = await TLinkApi.get("/master/list/currency");
      if (Array.isArray(result.data)) {
        setCurrencies(result.data);
      } else {
        console.error("Unexpected API response format for currencies", result);
      }
    } catch (e) {
      console.error("Failed to fetch currencies", e);
    }
  };

  useEffect(() => {
    fetchCurrencies();
  }, []);

  const label = (value, hide) => {
    return (
      <Box
        fontWeight="400"
        fontSize="12px"
        color="rgba(0, 0, 0, 0.85)"
        pb={0.5}
      >
        {value} {hide ? "" : <span>* &nbsp;</span>}
      </Box>
    );
  };

  const save = async (payload) => {
    if (
      activeMarketList.findIndex((val) => val.market.id == selectedMarket) > -1
    ) {
      try {
        setIsLoading(true);
        const orgSlug = localStorage.getItem("organizationSlug");
        await TLinkApi.put(
          `/o/${orgSlug}/panel/${id}/market/${selectedMarket}`,
          payload
        );
        enqueueSnackbar("Successfully saved", {
          variant: "success",
        });
        setIsEdit({ disabled: true, edit: false });
        setIsLoading(false);
        getActiveMarkets();
        GetMarketList();
        getMarketDataById(selectedMarket);
      } catch (e) {
        console.log(e);
        enqueueSnackbar(e?.response?.data.message, {
          variant: "error",
        });
        setIsLoading(false);
      }
    } else {
      try {
        setIsLoading(true);
        const orgSlug = localStorage.getItem("organizationSlug");
        payload.marketId = selectedMarket;
        await TLinkApi.post(`/o/${orgSlug}/panel/${id}/market`, payload);
        enqueueSnackbar("Successfully saved", {
          variant: "success",
        });
        setIsEdit({ disabled: true, edit: false });
        setIsLoading(false);
        setActiveMarketList([]);
        setAddMarketList([]);
        setDisplayMarketList([]);
        setDeleteMarketList([]);
        GetMarketList();
        GetActiveMarketList();
        getActiveMarkets();
      } catch (e) {
        console.log(e);
        enqueueSnackbar(e?.response?.data.message, {
          variant: "error",
        });
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    GetMarketList();
  }, [search]);

  const DeleteMarket = async (payload) => {
    setIsLoading(true);
    try {
      const orgSlug = localStorage.getItem("organizationSlug");
      let result = await TLinkApi.put(
        `/o/${orgSlug}/panel/${id}/markets`,
        payload
      );
      setIsLoading(false);
      enqueueSnackbar(result?.result?.userMessage, {
        variant: "success",
      });
    } catch (e) {
      console.log(e);
      setIsLoading(false);
      enqueueSnackbar("Something went wrong!", {
        variant: "error",
      });
    }
  };

  const handleSaveMarketList = async () => {
    if (displayMarketList.length <= 0) {
      setShowSearch(false);
    } else {
      setShowSearch(true);
    }
    if (deleteMarketList.length > 0) {
      await DeleteMarket({ marketIds: deleteMarketList });
    }
    if (addMarketList.length > 0) {
      let payload = addMarketList?.map((val) => ({
        marketId: val,
        panelJoiningIsActive: panel.panelJoiningIsActive,
        currencyId: panel.currency.id,
        panelUnit: panel.panelConversion,
        currencyExchangeValue: 123,
        joiningPoint: panel.joiningPoint,
        profilingPoint: panel.profilingPoint,
      }));
      try {
        setIsLoading(true);
        const orgSlug = localStorage.getItem("organizationSlug");
        payload.marketId = selectedMarket;
        await TLinkApi.post(`/o/${orgSlug}/panel/${id}/markets`, payload);
        enqueueSnackbar("Successfully saved", {
          variant: "success",
        });
        setSearch("");
        setIsLoading(false);
      } catch (e) {
        console.log(e);
        enqueueSnackbar(e?.response?.data.message, {
          variant: "error",
        });
        setIsLoading(false);
      }
    }
    setActiveMarketList([]);
    setAddMarketList([]);
    setDisplayMarketList([]);
    setDeleteMarketList([]);
    GetMarketList();
    GetActiveMarketList();
    getActiveMarkets();
    setIsEditMarket({
      disabled: true,
      edit: false,
    });
  };

  const handleClose = async () => {
    setValue("currencyId", selectedMarketData?.currency?.id);
    setValue("panelUnit", selectedMarketData?.panelUnit);
    setValue(
      "currencyExchangeValue",
      selectedMarketData?.currencyExchangeValue
    );
    setValue(
      "panelJoiningIsActive",
      selectedMarketData?.panelJoiningIsActive
    );
    setValue("joiningPoint", selectedMarketData?.joiningPoint);
    setValue("profilingPoint", selectedMarketData?.profilingPoint);
  };
  let selectStyle = {
    height: "36.5px",

    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
    "& :hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid
        container
        display="flex"
        alignItems="center"
        justifyContent="center"
        spacing={2}
        pt={1.8}
      >
        <Grid
          item
          xs={12}
          md={4}
          lg={4}
          sx={{
            borderRight: {
              xs: "none",
              md: `2px solid ${grey[400]}`,
            },
          }}
        >
          <Grid container display="flex" alignItems="center" p="1%">
            <Grid
              item
              xs={6}
              sm={4}
              display="flex"
              justifyContent="flex-start"
              pl={2}
            >
              {/* <MDTypography variant="h6" color="#2F2F2F">
                Markets
              </MDTypography> */}
            </Grid>
            <Grid item xs={6} sm={8}>
              <Grid
                container
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="flex-end"
                pb={1.8}
              >
                <Grid item xs={12}>
                  <MDBox
                    sx={{
                      display: "flex",
                      flexDirection: {
                        xs: "column",
                        sm: "row",
                      },
                      alignItems: "center",
                      justifyContent: "flex-end",
                      gap: {
                        xs: "0.5rem",
                        sm: "1rem",
                      },
                    }}
                  >
                    <MDBox
                      sx={{
                        color: "#7A7A7A",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <MDBox sx={{ fontSize: "0.7rem", marginRight: "0.5rem" }}>
                        Markets
                      </MDBox>
                      <MDBox
                        sx={{
                          fontWeight: "bold",
                          color: "#000",
                          fontSize: "0.8rem",
                        }}
                      >
                        {activeMarkets}
                      </MDBox>
                    </MDBox>
                    <MDBox
                      sx={{
                        color: "#7A7A7A",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <MDBox sx={{ fontSize: "0.7rem", marginRight: "0.5rem" }}>
                        Joining
                      </MDBox>
                      <MDBox
                        sx={{
                          fontWeight: "bold",
                          color: "#000",
                          fontSize: "0.8rem",
                        }}
                      >
                        {joiningActiveMarkets}
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={8}
          lg={8}
          display="flex"
          justifyContent="flex-start"
          // alignItems="center"
        >
          {selectedMarket && (
            <>
              <MDTypography
                variant="h6"
                alignItems="center"
                color="#2F2F2F"
                sx={{ marginRight: "16px", paddingTop: "5px" }}
              >
                {selectedMarketData?.market?.name}
              </MDTypography>
              <Grid item xs={12} md={8} lg={8} display="flex">
                {selectedMarket ? (
                  <TabContext value={tab}>
                    <TabList
                      onChange={handleChange}
                      aria-label="lab API tabs example"
                      sx={{
                        ml: "-8px",
                        mr: "5px",
                        // borderBottom: "1px solid #C4C4C4",
                        "& .MuiTab-root": {
                          textTransform: "none",
                          minHeight: "30px",
                          height: "35px",
                          fontWeight: 400,
                          "&:hover": {
                            backgroundColor: "#FFF8F3",
                            borderRadius: "0.5rem",
                            cursor: "pointer",
                          },
                        },
                        "& .MuiTab-textColorPrimary": {
                          color: "#303972",
                        },
                        "& .Mui-selected": {
                          color: "#303972",
                          backgroundColor: "#ab95ffd9",
                          paddingInline: "6px !important",
                          borderTopLeftRadius: "0.5rem",
                          borderTopRightRadius: "0.5rem",
                          borderBottomLeftRadius: "0.5rem",
                          borderBottomRightRadius: "0.5rem",
                          margin: "0 4px",
                        },
                        "& .MuiTabs-indicator": {
                          display: "none",
                        },
                      }}
                    >
                      <Tab label="Basic Details" value="1" />
                      <Tab label="Joining Questions" value="2" />
                      <Tab label="Profiling Questions" value="3" />
                      <Tab label="Tier Config" value="4" />
                    </TabList>
                  </TabContext>
                ) : (
                  <MDBox
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="50%"
                  >
                    <MDTypography variant="h6">
                      Active market is not selected!
                    </MDTypography>
                  </MDBox>
                )}
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <hr style={{ border: `1px solid #C4C4C4`, width: "100%" }} />
      </Grid>
      <MDBox
        pt={2}
        // style={{
        //   width: "100%",
        //   height: `calc(100vh - 220px)`,
        //   overflowY: "auto",
        // }}
      >
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              overflowY: "auto",
              height: {
                xs: "auto",
                md: "calc(100vh - 245px)",
              },
              borderRight: {
                xs: "none",
                md: `2px solid ${grey[400]}`,
              },
            }}
          >
            <Grid
              container
              display="flex"
              alignItems="center"
              justifyContent="center"
              spacing={2}
              pl={3}
            >
              <Grid item xs={12} sm={6}>
                <Stack direction="row" alignItems="center" mt={1} mb={1}>
                  {displayMarketList.length > 0 && (
                    <MDInput
                      placeholder="Search"
                      value={search}
                      sx={{
                        border: "none",
                        outline: "none",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "5px",
                          backgroundColor: "#FFF8F3",
                          "& fieldset": {
                            border: "none",
                          },
                        },
                        "& .MuiInputBase-root": {
                          border: "none",
                          outline: "none",
                        },
                        "& .MuiInputAdornment-root": {
                          border: "none",
                        },
                      }}
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon
                              sx={{ color: "#A098AE", mr: "-10px" }}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                  {displayMarketList.length <= 0 &&
                    isEditMarket.disabled !== true && (
                      <MDInput
                        placeholder="Search"
                        value={search}
                        sx={{
                          border: "none",
                          outline: "none",
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "5px",
                            backgroundColor: "#FFF8F3",
                            "& fieldset": {
                              border: "none",
                            },
                          },
                          "& .MuiInputBase-root": {
                            border: "none",
                            outline: "none",
                          },
                          "& .MuiInputAdornment-root": {
                            border: "none",
                          },
                        }}
                        onChange={(e) => {
                          setSearch(e.target.value);
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon
                                sx={{ color: "#A098AE", mr: "-10px" }}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6}>
                {isEditMarket.disabled !== false && (
                  <Box display="flex" justifyContent="flex-end">
                    <IconButton
                      style={{
                        variant: "contained",
                        color: "secondary",
                      }}
                      onClick={() => {
                        setSelectedMarket(null);
                        setIsEditMarket({ disabled: false, edit: true });
                      }}
                    >
                      <ModeEditIcon />
                    </IconButton>
                  </Box>
                )}
                {isEditMarket.disabled !== true && (
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                    gap="5px"
                  >
                    <Button
                      variant="outlined"
                      color="secondary"
                      sx={{ color: "#000000" }}
                      size="small"
                      onClick={() => {
                        GetMarketList();
                        GetActiveMarketList();
                        getActiveMarkets();
                        setIsEditMarket({
                          disabled: true,
                          edit: false,
                        });
                        setAddMarketList([]);
                        setDeleteMarketList([]);
                      }}
                    >
                      &nbsp;cancel
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ color: "#FFFFFF" }}
                      size="small"
                      onClick={handleSaveMarketList}
                    >
                      Save
                    </Button>
                  </Box>
                )}
              </Grid>
              <Grid item xs={12}>
                <FormGroup>
                  {isEditMarket.disabled === false && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checked}
                          color="secondary"
                          onClick={(e) => {
                            if (e.target.checked) {
                              let newDisplayMarketList = displayMarketList;
                              let newAddMarketList = addMarketList;
                              marketList?.map((market) => {
                                if (
                                  activeMarketList.findIndex(
                                    (val) =>
                                      val.market.id == parseInt(market?.id)
                                  ) > -1 &&
                                  !displayMarketList.includes(
                                    parseInt(market?.id)
                                  )
                                ) {
                                  if (
                                    !newDisplayMarketList.includes(
                                      parseInt(market?.id)
                                    )
                                  ) {
                                    newDisplayMarketList.push(
                                      parseInt(market?.id)
                                    );
                                  }
                                } else if (
                                  activeMarketList.findIndex(
                                    (val) =>
                                      val.market.id == parseInt(market?.id)
                                  ) <= -1
                                ) {
                                  if (
                                    !newAddMarketList.includes(
                                      parseInt(market?.id)
                                    )
                                  ) {
                                    newAddMarketList.push(parseInt(market?.id));
                                  }
                                }
                                setDisplayMarketList(newDisplayMarketList);
                                setAddMarketList(newAddMarketList);
                                setChecked(true);
                              });
                            } else {
                              let newDeleteMarketList = deleteMarketList;
                              let newDisplayMarketList = displayMarketList;
                              let newAddMarketList = addMarketList;
                              marketList?.map((market) => {
                                if (
                                  newDisplayMarketList.includes(
                                    parseInt(market?.id)
                                  )
                                ) {
                                  newDeleteMarketList.push(
                                    parseInt(market?.id)
                                  );
                                  let index = newDisplayMarketList.findIndex(
                                    (val) => val == parseInt(market?.id)
                                  );
                                  if (index > -1) {
                                    newDisplayMarketList.splice(index, 1);
                                  }
                                }
                                if (
                                  newAddMarketList.includes(
                                    parseInt(market?.id)
                                  )
                                ) {
                                  let index = newAddMarketList.findIndex(
                                    (val) => val == parseInt(market?.id)
                                  );
                                  if (index > -1) {
                                    newAddMarketList.splice(index, 1);
                                  }
                                }
                              });

                              setDisplayMarketList(newDisplayMarketList);
                              setAddMarketList(newAddMarketList);
                              setDeleteMarketList(newDeleteMarketList);
                              setChecked(false);
                            }
                          }}
                        />
                      }
                      label={"Select All"}
                    />
                  )}
                  {marketList?.length > 0 ? (
                    (isEditMarket.disabled !== false
                      ? marketList.filter((val) =>
                          displayMarketList.includes(parseInt(val.id))
                        )
                      : marketList
                    )?.map((market, i) => (
                      <MDBox
                        key={i}
                        onClick={() => {
                          if (isEditMarket.disabled !== false) {
                            setSelectedMarket(parseInt(market?.id));
                          }
                        }}
                        sx={{
                          backgroundColor:
                            selectedMarket === parseInt(market?.id)
                              ? "#FFF8F3"
                              : "transparent",
                          padding: "5px",
                          marginRight: "50px",
                          borderRadius: "5px",
                          cursor:
                            isEditMarket.disabled !== false
                              ? "pointer"
                              : "default",
                          "&:hover": {
                            backgroundColor: "#FFF8F3",
                            borderRadius: "0.5rem",
                            cursor: "pointer",
                          },
                        }}
                      >
                        {isEditMarket.disabled === false ? (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  displayMarketList.includes(
                                    parseInt(market?.id)
                                  ) ||
                                  addMarketList.includes(parseInt(market?.id))
                                }
                                color="secondary"
                                onClick={(e, value) => {
                                  if (isEditMarket.disabled === false) {
                                    if (
                                      displayMarketList.includes(
                                        parseInt(market?.id)
                                      )
                                    ) {
                                      let newDeleteMarketList =
                                        deleteMarketList;
                                      newDeleteMarketList.push(
                                        parseInt(market?.id)
                                      );
                                      setDeleteMarketList(newDeleteMarketList);
                                      let newDisplayMarketList =
                                        displayMarketList.filter(
                                          (val) => val !== parseInt(market?.id)
                                        );
                                      setDisplayMarketList(
                                        newDisplayMarketList
                                      );
                                    } else if (
                                      activeMarketList.findIndex(
                                        (val) =>
                                          val.market.id == parseInt(market?.id)
                                      ) > -1
                                    ) {
                                      let newDeleteMarketList =
                                        deleteMarketList;
                                      let index = newDeleteMarketList.findIndex(
                                        (val) => val == parseInt(market?.id)
                                      );
                                      if (index > -1) {
                                        newDeleteMarketList.splice(index, 1);
                                      }
                                      setDeleteMarketList(newDeleteMarketList);

                                      setDisplayMarketList([
                                        ...displayMarketList,
                                        parseInt(market?.id),
                                      ]);
                                    } else if (
                                      addMarketList.includes(
                                        parseInt(market?.id)
                                      )
                                    ) {
                                      let newAddMarketList =
                                        addMarketList.filter(
                                          (val) => val !== parseInt(market?.id)
                                        );
                                      setAddMarketList(newAddMarketList);
                                    } else if (
                                      !addMarketList.includes(
                                        parseInt(market?.id)
                                      )
                                    ) {
                                      setAddMarketList([
                                        ...addMarketList,
                                        parseInt(market?.id),
                                      ]);
                                    }
                                  }
                                }}
                              />
                            }
                            label={market.name}
                          />
                        ) : (
                          <span>{market?.name}</span>
                        )}
                      </MDBox>
                    ))
                  ) : (
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                      mt={2}
                    >
                      <Typography
                        fontSize="15px"
                        color="dark"
                        fontWeight="500"
                        textAlign="center"
                      >
                        No language here!
                      </Typography>
                    </Stack>
                  )}
                </FormGroup>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={8}>
            {selectedMarket ? (
              <TabContext value={tab}>
                {/* <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                  sx={{
                    ml: "-8px",
                    mr: "5px",
                    borderBottom: "1px solid #C4C4C4",
                    "& .MuiTab-root": {
                      textTransform: "none",
                      minHeight: "30px",
                      height: "35px",
                      fontWeight: 400,
                      "&:hover": {
                        backgroundColor: "#FFF8F3",
                        borderRadius: "0.5rem",
                        cursor: "pointer",
                      },
                    },
                    "& .MuiTab-textColorPrimary": {
                      color: "#555",
                    },
                    "& .Mui-selected": {
                      color: "#303972",
                      backgroundColor: "#f7d8d0",
                      paddingInline: "6px !important",
                      borderTopLeftRadius: "0.5rem",
                      borderTopRightRadius: "0.5rem",
                      borderBottomLeftRadius: "0.5rem",
                      borderBottomRightRadius: "0.5rem",
                      margin: "0 4px",
                    },
                    "& .MuiTabs-indicator": {
                      display: "none",
                    },
                  }}
                >
                  <Tab label="Basic Details" value="1" />
                  <Tab label="Joining Questions" value="2" />
                  <Tab label="Profiling Questions" value="3" />
                  <Tab label="Tier Config" value="4" />
                </TabList> */}
                <TabPanel value="1">
                  {" "}
                  {selectedMarket ? (
                    <form onSubmit={handleSubmit(save)} noValidate>
                      <MDBox sx={{ mt: "-5px" }}>
                        {/* <MDTypography variant="h6">Basic Details</MDTypography> */}
                        {isEdit.disabled !== false && (
                          <Box
                            display="flex"
                            justifyContent="flex-end"
                            alignItems="center"
                            mt={-2}
                          >
                            <IconButton
                              style={{
                                variant: "contained",
                                color: "secondary",
                              }}
                              onClick={() => {
                                setIsEdit({ disabled: false, edit: true });
                              }}
                            >
                              <ModeEditIcon />
                            </IconButton>
                          </Box>
                        )}
                        {isEdit.disabled !== true && (
                          <Box
                            display="flex"
                            justifyContent="flex-end"
                            alignItems="center"
                            gap="10px"
                            mt={-2}
                          >
                            <Button
                              variant="outlined"
                              color="secondary"
                              size="small"
                              sx={{ color: "#000000" }}
                              onClick={() => {
                                handleClose();
                                setIsEdit({
                                  disabled: true,
                                  edit: false,
                                });
                              }}
                            >
                              &nbsp;cancel
                            </Button>
                            <Button
                              variant="contained"
                              color="primary"
                              sx={{ color: "#FFFFFF" }}
                              type="submit"
                              size="small"
                              onClick={() => {
                                getActiveMarkets();
                              }}
                            >
                              Save
                            </Button>
                          </Box>
                        )}
                        {isEdit.edit === true ? (
                          <Grid container spacing={3} pt={2}>
                            <Grid item xs={12} md={4}>
                              {label("Currency")}
                              <Controller
                                name="currencyId"
                                control={control}
                                rules={{
                                  required: "Currency Id is required!",
                                }}
                                render={({ field: { onChange, value } }) => (
                                  <FormControl
                                    fullWidth
                                    required
                                    error={errors?.currencyId ? true : false}
                                    size="small"
                                  >
                                    <Select
                                      value={value}
                                      disabled={isEdit.disabled}
                                      onChange={onChange}
                                      sx={selectStyle}
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                    >
                                      {currencies?.map((currency) => (
                                        <MenuItem
                                          key={currency.id}
                                          value={currency.id}
                                        >
                                          {currency.name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                    {errors?.currencyId && (
                                      <FormHelperText>
                                        {errors?.currencyId
                                          ? errors.currencyId.message
                                          : null}
                                      </FormHelperText>
                                    )}
                                  </FormControl>
                                )}
                              />
                            </Grid>
                            <Grid item xs={12} md={4}>
                              {label("Point Conversion")}
                              <Controller
                                name="panelUnit"
                                control={control}
                                rules={{
                                  required: "Point Conversion is required!",
                                  validate: (value) =>
                                    value <= 100000 ||
                                    "Value must be less than or equal to 1,00,000!",
                                }}
                                render={({ field }) => (
                                  <MDInput
                                    {...field}
                                    fullWidth
                                    required
                                    type="number"
                                    autoComplete="off"
                                    variant="outlined"
                                    disabled={isEdit.disabled}
                                    error={errors?.panelUnit ? true : false}
                                    helperText={
                                      errors?.panelUnit
                                        ? errors.panelUnit.message
                                        : null
                                    }
                                  />
                                )}
                              />
                            </Grid>

                            <Grid item xs={12} md={4}>
                              {label("Panel Joining Status")}
                              <FormControlLabel
                                control={
                                  <Switch
                size="large"
                                    color="info"
                                    size="small"
                                    sx={{
                                      "&:hover": {
                                        backgroundColor: "#FFF8F3",
                                        borderRadius: "1rem",
                                        cursor: "pointer",
                                      },
                                    }}
                                    disabled={isEdit.disabled}
                                    checked={panelJoiningIsActive}
                                    onChange={(e, value) => {
                                      setValue("panelJoiningIsActive", value);
                                    }}
                                  />
                                }
                              />
                            </Grid>
                            <Grid item xs={12} md={4}>
                              {label("Joining Point")}
                              <Controller
                                name="joiningPoint"
                                control={control}
                                rules={{
                                  required: "Joining Point is required!",
                                  validate: (value) =>
                                    value <= 100000 ||
                                    "Value must be less than or equal to 1,00,000!",
                                }}
                                render={({ field }) => (
                                  <MDInput
                                    {...field}
                                    fullWidth
                                    required
                                    type="number"
                                    autoComplete="off"
                                    variant="outlined"
                                    disabled={isEdit.disabled}
                                    error={errors?.joiningPoint ? true : false}
                                    helperText={
                                      errors?.joiningPoint
                                        ? errors.joiningPoint.message
                                        : null
                                    }
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={12} md={4}>
                              {label("Profiling Point")}
                              <Controller
                                name="profilingPoint"
                                control={control}
                                rules={{
                                  required: "Profiling Point is required!",
                                  validate: (value) =>
                                    value <= 100000 ||
                                    "Value must be less than or equal to 1,00,000!",
                                }}
                                render={({ field }) => (
                                  <MDInput
                                    {...field}
                                    fullWidth
                                    required
                                    type="number"
                                    autoComplete="off"
                                    variant="outlined"
                                    disabled={isEdit.disabled}
                                    error={
                                      errors?.profilingPoint ? true : false
                                    }
                                    helperText={
                                      errors?.profilingPoint
                                        ? errors.profilingPoint.message
                                        : null
                                    }
                                  />
                                )}
                              />
                            </Grid>
                          </Grid>
                        ) : (
                          <Grid container spacing={3} pt={2}>
                            <Grid item xs={12} md={4}>
                              {label("Currency", true)}
                              <Typography fontSize="14px" fontWeight="600">
                                {currencies.find(
                                  (cur) =>
                                    cur.id === selectedMarketData?.currency?.id
                                )?.name || "N/A"}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              {label("Point Conversion", true)}
                              <Typography fontSize="14px" fontWeight="600">
                                {watch("panelUnit")}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              {label("Panel Joining Status", true)}
                              <FormControlLabel
                                control={
                                  <Switch
                size="large"
                                    checked={watch("panelJoiningIsActive")}
                                    color="info"
                                    size="small"
                                    disabled
                                  />
                                }
                              />
                            </Grid>
                            <Grid item xs={12} md={4}>
                              {label("Joining Point", true)}
                              <Typography fontSize="14px" fontWeight="600">
                                {selectedMarketData.joiningPoint}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              {label("Profiling Point", true)}
                              <Typography fontSize="14px" fontWeight="600">
                                {selectedMarketData.profilingPoint}
                              </Typography>
                            </Grid>
                          </Grid>
                        )}
                      </MDBox>
                    </form>
                  ) : (
                    <MDBox
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      height="50%"
                    >
                      <MDTypography variant="h6">
                        Active market is not selected!
                      </MDTypography>
                    </MDBox>
                  )}
                </TabPanel>
                <TabPanel value="2">
                  <JoiningQuestions marketId={selectedMarket} />
                </TabPanel>
                <TabPanel value="3">
                  <ProfilingQuestions marketId={selectedMarket} />
                </TabPanel>
                <TabPanel value="4">
                  <TierConfig marketId={selectedMarket} />
                </TabPanel>
              </TabContext>
            ) : (
              <MDBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="50%"
              >
                <MDTypography variant="h6">
                  Active market is not selected!
                </MDTypography>
              </MDBox>
            )}
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
};

export default MarketList;
