import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  Typography,
  Avatar,
  IconButton,
  Box,
  Backdrop,
  CircularProgress,
  Button,
} from "@mui/material";
import { SketchPicker } from "react-color";
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { EditOutlined } from "@mui/icons-material";
import Ellipse93 from "assets/images/Ellipse93.png";
import { useForm, Controller, get } from "react-hook-form";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import TLinkApi from "../../../../services/tlink.api";
import UploadIcon from "@mui/icons-material/Upload";
import imageCompression from "browser-image-compression";
import { useSnackbar } from "notistack";
import MDInput from "components/MDInput";

const UiConfig = () => {
  const {
    panel,
    setPanel,
    setIsEdit,
    isEdit,
    getData,
  } = useOutletContext();
  const [panelUi, setPanelUi] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { organizationSlug, panelSlug, id } = useParams();
  const navigate = useNavigate();
  let { enqueueSnackbar } = useSnackbar();

  // const [organizationSlug, setOrganizationSlug] = useState(
  //   localStorage.getItem("organizationSlug")
  // );
  // const [panelSlug, setPanelSlug] = useState(localStorage.getItem("panelSlug"));

  // useEffect(() => {
  //   setOrganizationSlug(localStorage.getItem("organizationSlug"));
  //   setPanelSlug(localStorage.getItem("panelSlug"));
  // }, []);

  const [uiData, setUiData] = useState({
    panelLogoUrl: "src/assets/images/Ellipse93.png",
    primaryColor: "#757575",
    secondaryColor: "#FF6347",
    backgroundColor: "#FFFFFF",
    textColor: "#000000",
  });

  const [logoFile, setLogoFile] = useState(null);
  const [logoPreview, setLogoPreview] = useState(uiData.panelLogoUrl);
  const [logoPreview2, setLogoPreview2] = useState(uiData.panelLogoUrl);
  const [edit, setEdit] = useState(true);
  const [edit2, setEdit2] = useState(true);
  const [uploadFile, setUploadFile] = useState(null);
  const options = {
    maxSizeMB: 0.5,
    maxWidthOrHeight: 400,
    useWebWorker: true,
  };

  const handleLogoUpload = (e) => {
    const file = e.target.files[0];
    setUploadFile(e.target.files[0]);
    if (file) {
      const previewUrl = URL.createObjectURL(file);
      setLogoPreview(previewUrl);
      setLogoFile(file);
      setEdit(false);
    }
  };

  const handleSecondLogoUpload = (e) => {
    const file = e.target.files[0];
    setUploadFile(e.target.files[0]);
    if (file) {
      const previewUrl = URL.createObjectURL(file);
      setLogoPreview2(previewUrl);
      setLogoFile(file);
      setEdit2(false);
    }
  };

  const handleColorChange = (name, color) => {
    setUiData((prevState) => ({
      ...prevState,
      [name]: color.hex,
    }));
    setValue(name, color.hex);
  };

  const [primaryColorPicker, setPrimaryColorPicker] = useState(false);
  const [secondaryColorPicker, setSecondaryColorPicker] = useState(false);
  const [backgroundColorPicker, setBackgroundColorPicker] = useState(false);
  const [textColorPicker, setTextColorPicker] = useState(false);
  const primaryRef = useRef();
  const secondaryRef = useRef();
  const backgroundColorRef = useRef();
  const textColorRef = useRef();

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });
  const handleClickOutside = (e) => {
    if (primaryRef.current && !primaryRef.current.contains(e.target)) {
      setPrimaryColorPicker(false);
    }
    if (secondaryRef.current && !secondaryRef.current.contains(e.target)) {
      setSecondaryColorPicker(false);
    }
    if (backgroundColorRef.current && !backgroundColorRef.current.contains(e.target)) {
      setBackgroundColorPicker(false);
    }
    if (textColorRef.current && !textColorRef.current.contains(e.target)) {
      setTextColorPicker(false);
    }
  };
  

  const getUi = async () => {
    setIsLoading(true);
    try {
      const orgSlug = localStorage.getItem("organizationSlug");
      if (!orgSlug) {
        throw new Error("Organization slug is missing from local storage");
      }
      let result = await TLinkApi.get(`/o/${orgSlug}/panel/${id}/ui/colors`);
      const panelUiData = result.data;
      setPanelUi(panelUiData);
      // setLogoPreview(
      //   panelUiData.panelLogoUrl || "src/assets/images/Ellipse93.png"
      // );
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (panelUi && panelUi.panelLogoUrl) {
      setLogoPreview(panelUi.panelLogoUrl);
    }
  }, [panelUi]);

  const getLogo = async () => {
    setIsLoading(true);
    try {
      const orgSlug = localStorage.getItem("organizationSlug");
      if (!orgSlug) {
        throw new Error("Organization slug is missing from local storage");
      }
      let result = await TLinkApi.get(`/o/${orgSlug}/panel/${id}/ui/logo`);
      setLogoPreview(result?.data?.panelLogoUrl);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (panel) {
      getLogo();
    }
  }, [panel]);

  const getSecondLogo = async () => {
    setIsLoading(true);
    try {
      const orgSlug = localStorage.getItem("organizationSlug");
      if (!orgSlug) {
        throw new Error("Organization slug is missing from local storage");
      }
      let result = await TLinkApi.get(
        `/o/${orgSlug}/panel/${id}/ui/text/logo`
      );
      setLogoPreview2(result?.data?.panelTextLogoUrl);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (panel) {
      getSecondLogo();
    }
  }, [panel]);

  useEffect(() => {
    if (panel) {
      getUi();
    }
  }, [panel]);

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
    setValue,
    register,
  } = useForm({
    defaultValues: {
      panelLogoUrl: "",
      primaryColor: "",
      secondaryColor: "",
      backgroundColor: "",
      textColor: "",
    },
  });

  useEffect(() => {
    if (panelUi) {
      setValue("panelLogoUrl", panelUi.panelLogoUrl || "");
      setValue("primaryColor", panelUi.primaryColor || "");
      setValue("secondaryColor", panelUi.secondaryColor || "");
      setValue("backgroundColor", panelUi.backgroundColor || "");
      setValue("textColor", panelUi.textColor || "");
    }
  }, [panelUi, setValue]);

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(",")[1]);
      reader.onerror = (error) => reject(error);
    });
  };

  const logoSave = async (data) => {
    setIsLoading(true);
    try {
      const orgSlug = localStorage.getItem("organizationSlug");
      if (!orgSlug) {
        throw new Error("Organization slug is missing from local storage");
      }
      const compressedFile = await imageCompression(uploadFile, options);
      const base64 = await getBase64(compressedFile);
      let initials = "data:image/png;base64,";
      let integratedBase64 = initials.concat(base64);
      const payload = {
        panelLogoUrl: integratedBase64,
      };
      let result = await TLinkApi.patch(
        `/o/${orgSlug}/panel/${id}/ui/logo`,
        payload
      );
      setEdit(true);
      setIsLoading(false);
      enqueueSnackbar(result?.result?.userMessage, {
        variant: "success",
      });
    } catch (e) {
      setIsLoading(false);
      console.log(e);
      enqueueSnackbar(e?.response?.data?.result?.userMessage, {
        variant: "error",
      });
    }
  };

  const secondLogoSave = async (data) => {
    setIsLoading(true);
    try {
      const orgSlug = localStorage.getItem("organizationSlug");
      if (!orgSlug) {
        throw new Error("Organization slug is missing from local storage");
      }
      const compressedFile = await imageCompression(uploadFile, options);
      const base64 = await getBase64(compressedFile);
      let initials = "data:image/png;base64,";
      let integratedBase64 = initials.concat(base64);
      const payload = {
        panelTextLogoUrl: integratedBase64,
      };
      let result = await TLinkApi.patch(
        `/o/${orgSlug}/panel/${id}/ui/text/logo`,
        payload
      );
      setEdit2(true);
      setIsLoading(false);
      enqueueSnackbar(result?.result?.userMessage, {
        variant: "success",
      });
    } catch (e) {
      setIsLoading(false);
      console.log(e);
      enqueueSnackbar(e?.response?.data?.result?.userMessage, {
        variant: "error",
      });
    }
  };

  const save = async (data) => {
    setIsLoading(true);
    try {
      const orgSlug = localStorage.getItem("organizationSlug");
      if (!orgSlug) {
        throw new Error("Organization slug is missing from local storage");
      }
      const payload = {
        primaryColor: data.primaryColor,
        secondaryColor: data.secondaryColor,
        backgroundColor: data.backgroundColor,
        textColor: data.textColor,
      };

      let result = await TLinkApi.patch(
        `/o/${orgSlug}/panel/${id}/ui`,
        payload
      );

      setIsEdit({ disabled: true, edit: false });
      setIsLoading(false);
      enqueueSnackbar(result?.result?.userMessage, {
        variant: "success",
      });
      navigate(`/panels/${id}/UIConfiguration`);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
      enqueueSnackbar(e?.response?.data?.result?.userMessage, {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    setIsEdit({
      disabled: true,
      edit: false,
    });
  }, []);

  const label = (value, hide) => {
    return (
      <Box
        fontWeight="400"
        fontSize="12px"
        color="rgba(0, 0, 0, 0.85)"
        pb={0.5}
      >
        {value} {hide ? "" : <span>* &nbsp;</span>}
      </Box>
    );
  };
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {/* <Grid container spacing={2}>
        <Grid item xs={12} ml={{ xs: 0, md: "15px" }} mt="15px">
          <MDTypography variant="h6" color="#2F2F2F">
            UI Configuration
          </MDTypography>
        </Grid>
        <Grid item xs={12} md={12} mt={0}>
          <hr style={{ border: `1px solid #C4C4C4`, width: "100%" }} />
        </Grid>
      </Grid> */}
      <MDBox
        pl={{ xs: 1, md: 2 }}
        pr={{ xs: 2, md: 4 }}
        style={{
          width: "100%",
          height: `calc(100vh - 240px)`,
          overflowY: "auto",
          background: "#fff",
          borderRadius: "20px",
          margin: "15px 0px"
        }}
      >
        {isEdit.disabled !== false && (
          <Box
            display="flex"
            justifyContent="flex-end"
            mr={{ xs: 2, md: 12 }}
            mt={2}
          >
            <IconButton
              style={{
                variant: "contained",
                color: "secondary",
              }}
              onClick={() => {
                setIsEdit({ disabled: false, edit: true });
              }}
            >
              <ModeEditIcon />
            </IconButton>
          </Box>
        )}
        {isEdit.disabled !== true && (
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            gap={{ xs: "10px", md: "15px" }}
            mt={{ xs: 1, md: 2 }}
            mr={{ xs: 2, md: 6 }}
          >
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              sx={{ color: "#000000" }}
              onClick={() => {
                setIsEdit({
                  disabled: true,
                  edit: false,
                });
                getUi();
              }}
            >
              &nbsp;cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              sx={{ color: "#FFFFFF" }}
              size="small"
              type="submit"
              onClick={handleSubmit(save)}
            >
              Save
            </Button>
          </Box>
        )}

        <Grid
          container
          display="flex"
          justifyContent="center"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item xs={12} md={3}>
            <Grid
              container
              direction="column"
              alignItems="center"
              spacing={2}
              ml={1}
            >
              <Avatar
                src={logoPreview || "src/assets/images/Ellipse93.png"}
                alt="Logo Preview"
                sx={{ width: "100px", height: "100px", boxShadow: 3 }}
              />
              {edit === true && (
                <Button
                  sx={{ mt: 2, color: "primary" }}
                  component="label"
                  startIcon={<EditOutlined />}
                >
                  <input
                    type="file"
                    hidden
                    accept="image/*"
                    onChange={handleLogoUpload}
                  />
                  Short Logo
                </Button>
              )}
              {edit === false && (
                <Button
                  sx={{ mt: 2, color: "primary" }}
                  onClick={logoSave}
                  startIcon={<UploadIcon />}
                >
                  Update
                </Button>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} md={3}>
            <Grid container direction="column" alignItems="center" spacing={2}>
              <Avatar
                src={logoPreview2 || "src/assets/images/Ellipse93.png"}
                alt="Second Logo Preview"
                sx={{ width: "100px", height: "100px", boxShadow: 3 }}
                imgProps={{ style: { objectFit: "contain" } }}
              />
              {edit2 === true && (
                <Button
                  sx={{ mt: 2, color: "primary" }}
                  component="label"
                  startIcon={<EditOutlined />}
                >
                  <input
                    type="file"
                    hidden
                    accept="image/*"
                    onChange={handleSecondLogoUpload}
                  />
                  Long Logo
                </Button>
              )}
              {edit2 === false && (
                <Button
                  sx={{ mt: 2, color: "primary" }}
                  onClick={secondLogoSave}
                  startIcon={<UploadIcon />}
                >
                  Update
                </Button>
              )}
            </Grid>
          </Grid>

          {isEdit.edit === true ? (
            <Grid item xs={12} md={6} mt={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} sm={6}>
                  {label("Primary Color")}
                  <Grid container spacing={1}>
                    <Grid item xs={2}>
                      <Box
                        sx={{
                          width: 30,
                          height: 30,
                          backgroundColor: watch("primaryColor"),
                          border: "1px solid #ccc",
                        }}
                      />
                    </Grid>
                    <Grid item xs={8} position="relative">
                      <Controller
                        name="primaryColor"
                        control={control}
                        rules={{
                          required: "Primary color is required!",
                        }}
                        render={({ field }) => (
                          <div ref={primaryRef}>
                            <MDInput
                              {...field}
                              size="small"
                              fullWidth
                              variant="outlined"
                              value={uiData.primaryColor}
                              onClick={(e) => setPrimaryColorPicker(true)}
                              onChange={(e) =>
                                handleColorChange("primaryColor", {
                                  hex: e.target.value,
                                })
                              }
                            />
                            {primaryColorPicker && (
                              <div style={{ position: "absolute", zIndex: 10 }}>
                                <SketchPicker
                                  color={uiData.primaryColor}
                                  onChangeComplete={(color) =>
                                    handleColorChange("primaryColor", color)
                                  }
                                  styles={{
                                    default: {
                                      picker: {
                                        width: "125px",
                                      },
                                      saturation: {
                                        width: "125px",
                                        height: "5px",
                                      },
                                      controls: {
                                        display: "none",
                                        justifyContent: "space-between",
                                        padding: "2px 0",
                                      },
                                      sliders: {
                                        padding: "2px 0",
                                      },
                                      hue: {
                                        height: "5px",
                                      },
                                      color: {
                                        width: "70px",
                                        height: "14px",
                                        borderRadius: "2px",
                                      },
                                    },
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={6} sm={6}>
                  {label("Secondary Color")}
                  <Grid container spacing={1}>
                    <Grid item xs={2}>
                      <Box
                        sx={{
                          width: 30,
                          height: 30,
                          backgroundColor: watch("secondaryColor"),
                          border: "1px solid #ccc",
                        }}
                      />
                    </Grid>
                    <Grid item xs={8} position="relative">
                      <Controller
                        name="secondaryColor"
                        control={control}
                        rules={{
                          required: "Secondary color is required!",
                        }}
                        render={({ field }) => (
                          <div ref={secondaryRef}>
                            <MDInput
                              {...field}
                              size="small"
                              fullWidth
                              variant="outlined"
                              value={uiData.secondaryColor}
                              onClick={(e) => setSecondaryColorPicker(true)}
                              onChange={(e) =>
                                handleColorChange("secondaryColor", {
                                  hex: e.target.value,
                                })
                              }
                            />
                            {secondaryColorPicker && (
                              <div style={{ position: "absolute", zIndex: 10 }}>
                                <SketchPicker
                                  color={uiData.secondaryColor}
                                  onChangeComplete={(color) =>
                                    handleColorChange("secondaryColor", color)
                                  }
                                  styles={{
                                    default: {
                                      picker: {
                                        width: "125px",
                                      },
                                      saturation: {
                                        width: "125px",
                                        height: "5px",
                                      },
                                      controls: {
                                        display: "none",
                                        justifyContent: "space-between",
                                        padding: "2px 0",
                                      },
                                      sliders: {
                                        padding: "2px 0",
                                      },
                                      hue: {
                                        height: "5px",
                                      },
                                      color: {
                                        width: "70px",
                                        height: "14px",
                                        borderRadius: "2px",
                                      },
                                    },
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={6} sm={6}>
                  {label("Background Color")}
                  <Grid container spacing={1}>
                    <Grid item xs={2}>
                      <Box
                        sx={{
                          width: 30,
                          height: 30,
                          backgroundColor: watch("backgroundColor"),
                          border: "1px solid #ccc",
                        }}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <Controller
                        name="backgroundColor"
                        control={control}
                        rules={{
                          required: "Background color is required!",
                        }}
                        render={({ field }) => (
                          <div ref={backgroundColorRef}>
                            <MDInput
                              {...field}
                              size="small"
                              fullWidth
                              variant="outlined"
                              value={uiData.backgroundColor}
                              onClick={(e) => setBackgroundColorPicker(true)}
                              onChange={(e) =>
                                handleColorChange("backgroundColor", {
                                  hex: e.target.value,
                                })
                              }
                            />
                            {backgroundColorPicker && (
                              <SketchPicker
                                color={uiData.backgroundColor}
                                onChangeComplete={(color) =>
                                  handleColorChange("backgroundColor", color)
                                }
                                styles={{
                                  default: {
                                    picker: {
                                      width: "125px",
                                    },
                                    saturation: {
                                      width: "125px",
                                      height: "5px",
                                    },
                                    controls: {
                                      display: "none",
                                      justifyContent: "space-between",
                                      padding: "2px 0",
                                    },
                                    sliders: {
                                      padding: "2px 0",
                                    },
                                    hue: {
                                      height: "5px",
                                    },
                                    color: {
                                      width: "70px",
                                      height: "14px",
                                      borderRadius: "2px",
                                    },
                                  },
                                }}
                              />
                            )}
                          </div>
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={6} sm={6}>
                  {label("Text Color")}
                  <Grid container spacing={1}>
                    <Grid item xs={2}>
                      <Box
                        sx={{
                          width: 30,
                          height: 30,
                          backgroundColor: watch("textColor"),
                          border: "1px solid #ccc",
                        }}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <Controller
                        name="textColor"
                        control={control}
                        rules={{
                          required: "Text color is required!",
                        }}
                        render={({ field }) => (
                          <div ref={textColorRef}>
                            <MDInput
                              {...field}
                              size="small"
                              fullWidth
                              variant="outlined"
                              value={uiData.textColor}
                              onClick={(e) => setTextColorPicker(true)}
                              onChange={(e) =>
                                handleColorChange("textColor", {
                                  hex: e.target.value,
                                })
                              }
                            />
                            {textColorPicker && (
                              <SketchPicker
                                color={uiData.textColor}
                                onChangeComplete={(color) =>
                                  handleColorChange("textColor", color)
                                }
                                styles={{
                                  default: {
                                    picker: {
                                      width: "125px",
                                    },
                                    saturation: {
                                      width: "125px",
                                      height: "5px",
                                    },
                                    controls: {
                                      display: "none",
                                      justifyContent: "space-between",
                                      padding: "2px 0",
                                    },
                                    sliders: {
                                      padding: "2px 0",
                                    },
                                    hue: {
                                      height: "5px",
                                    },
                                    color: {
                                      width: "70px",
                                      height: "14px",
                                      borderRadius: "2px",
                                    },
                                  },
                                }}
                              />
                            )}
                          </div>
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid item xs={6}>
              <Grid container>
                <Grid
                  container
                  spacing={2}
                  display="flex"
                  justifyContent="flex-start"
                >
                  <Grid item xs={12} md={6} sm={6}>
                    {label("Primary Color", true)}
                    <Grid container spacing={1}>
                      <Grid item xs={2}>
                        <Box
                          sx={{
                            width: 30,
                            height: 30,
                            backgroundColor: watch("primaryColor"),
                            border: "1px solid #ccc",
                          }}
                        />
                      </Grid>
                      <Grid item xs={10}>
                        <Typography fontSize="14px" fontWeight="600">
                          {watch("primaryColor")}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6} sm={6}>
                    {label("Secondary Color", true)}
                    <Grid container>
                      <Grid item xs={2}>
                        <Box
                          sx={{
                            width: 30,
                            height: 30,
                            backgroundColor: watch("secondaryColor"),
                            border: "1px solid #ccc",
                          }}
                        />
                      </Grid>
                      <Grid item xs={10}>
                        <Typography fontSize="14px" fontWeight="600">
                          {watch("secondaryColor")}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6} sm={6}>
                    {label("Background Color", true)}
                    <Grid container>
                      <Grid item xs={2}>
                        <Box
                          sx={{
                            width: 30,
                            height: 30,
                            backgroundColor: watch("backgroundColor"),
                            border: "1px solid #ccc",
                          }}
                        />
                      </Grid>
                      <Grid item xs={10}>
                        <Typography fontSize="14px" fontWeight="600">
                          {watch("backgroundColor")}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6} sm={6}>
                    {label("Text Color", true)}
                    <Grid container>
                      <Grid item xs={2}>
                        <Box
                          sx={{
                            width: 30,
                            height: 30,
                            backgroundColor: watch("textColor"),
                            border: "1px solid #ccc",
                          }}
                        />
                      </Grid>
                      <Grid item xs={10}>
                        <Typography fontSize="14px" fontWeight="600">
                          {watch("textColor")}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </MDBox>
    </>
  );
};

export default UiConfig;
