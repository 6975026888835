import MDButton from "components/MDButton";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import GetSurveyReport from "hooks/SurveyReport/GetSurveyReport";
import {
  Backdrop,
  CircularProgress,
  Grid,
  Tooltip,
  Stack,
} from "@mui/material";
import moment from "moment/moment";
import MDBox from "components/MDBox";
import SearchInput from "components/searchInput";
import Union2 from "assets/images/Union2.png";
import csvDownload from "json-to-csv-export";
import useTableTheme from "constant/TableTheme";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { ThemeProvider } from "@mui/material";

function SurveyReport() {
  let navigate = useNavigate();
  let { id } = useParams();
  const {
    surveyReport,
    isLoading,
    entriesPerPage,
    setEntriesPerPage,
    setSortOrder,
    setSortBy,
    sortBy,
    sortOrder,
    setIsLoading,
    setPage,
    page,
  } = GetSurveyReport({ id });
  const [surveyReports, setSurveyReports] = useState([]);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState([]);
  const tableTheme = useTableTheme();
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 50,
    pageSize: 50,
  });

  useEffect(() => {
    setData(surveyReport);
    setPage(pagination.pageIndex);
    setEntriesPerPage(pagination.pageSize);

    if (sorting.length === 1) {
      if (sorting[0].desc === false) {
        setSortBy(sorting[0].id);
        setSortOrder("asc");
      } else {
        setSortBy(sorting[0].id);
        setSortOrder("desc");
      }
    } else if (sorting.length === 0) {
      setSortBy("id");
      setSortOrder("asc");
    }
  }, [surveyReport, pagination, sorting]);

  useEffect(() => {
    setSurveyReports(surveyReport);
    // eslint-disable-next-line
  }, [surveyReport]);

  useEffect(() => {
    if (search && search !== "") {
      const fSurveyReports = surveyReports
        .filter((value) => {
          if (search === "") return true;
          else {
            const s = search.toLowerCase();
            let match = false;
            if (value.status && value.status.toLowerCase().includes(s)) {
              match = true;
            }
            if (
              value.vendorName &&
              value.vendorName.toLowerCase().includes(s)
            ) {
              match = true;
            }
            if (value.token && value.token.toLowerCase().includes(s)) {
              match = true;
            }
            if (value.subStatus && value.subStatus.toLowerCase().includes(s)) {
              match = true;
            }
            return match;
          }
        })
        .map((c) => c);
      setSurveyReports(fSurveyReports);
    } else {
      setSurveyReports(surveyReport);
    }
    // eslint-disable-next-line
  }, [search]);

  let columns = [
    {
      accessorKey: "surveyId",
      header: "Survey Id",
      size: 150,
    },
    {
      accessorKey: "status",
      header: "Status",
      size: 150,
    },

    {
      accessorKey: "subStatus",
      header: "Sub Status",
      align: "center",
      size: 150,
    },

    {
      accessorKey: "vendorName",
      header: "Supplier Name",
      enableSorting: false,
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      size: 150,
    },
    {
      accessorKey: "supplierToken",
      header: "Supplier Token",
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      size: 150,
    },
    {
      accessorKey: "token",
      header: "Token",
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      size: 150,
    },
    // {
    //   accessorKey: "isTest",
    //   header: "Token Type",
    //   Cell: ({ row }) => {
    //     const isTest = row.original.isTest;
    //     return isTest ? "Test" : "Live";
    //   },
    // },
    {
      accessorKey: "startDate",
      header: "Start Date",
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      size: 150,
      Cell: ({ cell }) => {
        return `${moment(cell.getValue()).format("DD-MM-YYYY")}`;
      },
    },
    {
      accessorKey: "endDate",
      header: "End Date",
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      size: 150,
      Cell: ({ cell }) => `${moment(cell.getValue()).format("DD-MM-YYYY")}`,
    },
  ];

  const table = useMaterialReactTable({
    columns,
    data,
    enableDensityToggle: false,
    enableColumnPinning: true,
    enableStickyHeader: true,
    enableGlobalFilter: false,
    onPaginationChange: setPagination,
    state: { density: "compact", pagination, sorting },
    muiTableContainerProps: {
      sx: {
        minHeight: "calc(100vh - 450px)",
        maxHeight: "calc(100vh - 350px)",
        overflowY: "auto",
      },
    },
    onSortingChange: setSorting,
    muiPaginationProps: {
      rowsPerPageOptions: [25, 50, 100],
    },
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        width: "100%",
        borderRadius: "0",
        backgroundColor: "#fff !important",
        zIndex: 500,
      },
    },
    renderEmptyRowsFallback: () => (
      <Stack
        display="flex"
        alignItems="center"
        justifyContent="center"
        fontSize="14px"
        p={5}
      >
        No records to display
      </Stack>
    ),
  });

  return (
    <MDBox>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <MDBox display="flex" justifyContent="center">
        <MDBox py={2} mt={2} borderRadius="15px" bgColor="white" width="98%">
          <Grid container spacing={2}>
            <Grid
              px={2}
              item
              xs={12}
              md={12}
              display="flex"
              justifyContent="flex-end"
              flexDirection="row"
              sx={{
                flexDirection: { xs: "column", md: "row" },
                alignItems: { xs: "flex-end", md: "center" },
              }}
              gap="15px"
            >
              <SearchInput setSearch={setSearch} search={search} />
              <MDButton
                variant="gradient"
                color="info"
                size="small"
                onClick={() => {
                  const dataToConvert = {
                    data: surveyReport,
                    filename: "Survey Report",
                    delimiter: ",",
                  };
                  csvDownload(dataToConvert);
                }}
              >
                <img
                  src={Union2}
                  width="15px"
                  height="15px"
                  style={{ paddingRight: "5px" }}
                  alt="export"
                />
                Export
              </MDButton>
            </Grid>
          </Grid>
          <ThemeProvider theme={tableTheme}>
            <MaterialReactTable
              table={table}
              style={{
                width: "100%",
              }}
            />
          </ThemeProvider>
        </MDBox>
      </MDBox>
      {/* <MDBox
        mx={2}
        mt={2}
        mb={2}
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        flexDirection="row"
        gap="10px"
      >
        <MDButton
          variant="gradient"
          color="info"
          size="small"
          onClick={() => {
            const dataToConvert = {
              data: surveyReport,
              filename: "Survey Report",
              delimiter: ",",
            };
            csvDownload(dataToConvert);
          }}
        >
          <img
            src={Union2}
            width="15px"
            height="15px"
            style={{ paddingRight: "5px" }}
            alt="export"
          />
          Export
        </MDButton>
        <MDButton
          variant="gradient"
          color="info"
          size="small"
          onClick={() => {
            navigate("/surveys");
          }}
        >
          Back
        </MDButton>
      </MDBox> */}
    </MDBox>
  );
}

export default SurveyReport;
