import {
  AppBar,
  Avatar,
  Icon,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
} from "@mui/material";
import MDBox from "components/MDBox";
import DashboardLayout from "comps/LayoutContainers/DashboardLayout";
import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Auth from "services/auth";
import PrincipalState from "services/principal.state";
import TLinkApi from "services/tlink.api";
import { useNavigate } from "react-router-dom";
import SearchInput from "components/searchInput";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import {
  useMaterialUIController,
  setMiniSidenav,
  // setOpenConfigurator,
} from "context";
import { Person } from "@mui/icons-material";
import MDAvatar from "components/MDAvatar";
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import LogoutIcon from '@mui/icons-material/Logout';

const PrivateOutlet = ({ setWidth, width, setMenuOpen, menuOpen }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [search, setSearch] = useState("");
  const [searchResults, setSearchResults] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, darkMode } = controller;

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);

  const fetchSearchResults = async () => {
    try {
      const response = await TLinkApi.get(`/global/?search=${search}`);

      setSearchResults(response.data.search);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (search.length > 3) {
      fetchSearchResults();
    } else if (search.length === 0) {
      setSearchResults({});
    }
    // eslint-disable-next-line
  }, [search]);

  const authorize = async () => {
    try {
      await Auth.authorize();
      let data = PrincipalState.getIdentity();
      if (data) setCurrentUser(data.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    authorize();
    // eslint-disable-next-line
  }, []);

  let light = false;

  const iconsStyle = ({
    palette: { dark, white, text },
    functions: { rgba },
  }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  const handleAvatarClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.clear();
    window.location.reload();
  };

  const handleUpgradePlan = () => {
    // Navigate to the upgrade plan page or handle upgrade logic
    navigate("/plan");
  };

  function stringAvatar(name) {
    return {
      children: `${name.split(" ")[0][0] ? name.split(" ")[0][0] : ""}${name.split(" ")[1][0] ? name.split(" ")[1][0] : ""
        }`,
    };
  }

  console.log(currentUser, "currentUser");
  return (
    <DashboardLayout setWidth={setWidth} width={width}>
      <MDBox mb={2}>
        <AppBar position="relative">
          <Toolbar
            style={{
              padding: "0px",
              margin: "0px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "15px"
            }}
          >
            <MDTypography color="textColor">
              Survey Hub | {currentUser?.firstName} {currentUser?.lastName}
            </MDTypography>
            {/* <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            ></IconButton> */}
            <Stack direction="row" justifyContent={"center"} spacing={2} width="50%" position={"relative"}>
              <SearchInput
                dashbord={true}
                setSearch={setSearch}
                search={search}
                setMenuOpen={setMenuOpen}
              />
              <MDBox
                position="absolute"
                bgColor="#ffff"
                zIndex="3"
                width="78%"
                top="50px"
                left="0px"
                maxHeight="200px"
                style={{
                  overflowY: "auto",
                  boxShadow:
                    " 0rem 0.625rem 0.9375rem -0.1875rem rgba(0, 0, 0, 0.1), 0rem 0.25rem 0.375rem -0.125rem rgba(0, 0, 0, 0.05)",
                }}
              >
                {Object.keys(searchResults).length > 0 && menuOpen && (
                  <>
                    {searchResults.clients && (
                      <>
                        <MDBox
                          mx={1}
                          style={{ padding: "2px", fontSize: "15px" }}
                        >
                          {searchResults.clients.length !== 0
                            ? "Clients :"
                            : ""}
                        </MDBox>

                        {searchResults.clients.map((item, i) => (
                          <MenuItem
                            key={`${i}`}
                            onClick={() => navigate(`/clients/${item.id}`)}
                          >
                            {item.name}
                          </MenuItem>
                        ))}
                      </>
                    )}
                    {searchResults.surveys && (
                      <>
                        <MDBox
                          mx={1}
                          style={{ padding: "2px", fontSize: "15px" }}
                        >
                          {searchResults.surveys.length !== 0
                            ? "Surveys :"
                            : ""}
                        </MDBox>

                        {searchResults.surveys.map((item, i) => (
                          <MenuItem
                            key={`${i}`}
                            onClick={() => navigate(`/surveys/${item.id}`)}
                          >
                            {item.name}
                          </MenuItem>
                        ))}
                      </>
                    )}
                    {searchResults.projects && (
                      <>
                        <MDBox
                          mx={1}
                          style={{ padding: "2px", fontSize: "15px" }}
                        >
                          {searchResults.projects.length !== 0
                            ? "Projects :"
                            : ""}
                        </MDBox>

                        {searchResults.projects.map((item, i) => (
                          <MenuItem
                            key={`${i}`}
                            onClick={() => navigate(`/projects/${item.id}`)}
                          >
                            {item.name}
                          </MenuItem>
                        ))}
                      </>
                    )}
                    {searchResults.projects.length === 0 &&
                      searchResults.surveys.length === 0 &&
                      searchResults.clients.length === 0 && (
                        <MDBox mb={1}>
                          <MenuItem>No result found</MenuItem>
                        </MDBox>
                      )}
                  </>
                )}
              </MDBox>
              <MDButton sx={{ display: "flex", alignItems: "flex-end" }} onClick={handleUpgradePlan} >
                <AutoAwesomeIcon sx={{ mr: "1px" }} />Upgrade&nbsp;Plan
              </MDButton>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={{ display: { md: "display-block", lg: "none" } }}
                onClick={handleMiniSidenav}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>
            </Stack>
            <IconButton onClick={handleAvatarClick}>
              <MDAvatar
                bgColor="info"
                size="sm"
                {...stringAvatar(
                  `${currentUser?.firstName ? currentUser?.firstName : ""} ${currentUser?.lastName ? currentUser?.lastName : ""}`
                )}
              />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              PaperProps={{
                style: {
                  maxHeight: 'none',
                  maxWidth: '300px',
                },
              }}
            >
              <MenuItem
                sx={{
                  pointerEvents: 'none',
                  fontWeight: 'bold',
                  cursor: 'default',
                  backgroundColor: 'transparent',
                  '&:hover': {
                    backgroundColor: 'transparent'
                  }
                }}
              >
                <MDAvatar
                  bgColor="info"
                  size="sm"
                  {...stringAvatar(
                    `${currentUser?.firstName ? currentUser?.firstName : ""} ${currentUser?.lastName ? currentUser?.lastName : ""}`
                  )}
                />
                &nbsp;{currentUser?.firstName} {currentUser?.lastName}
              </MenuItem>
              <hr />
              <MenuItem onClick={handleUpgradePlan}>
                <AutoAwesomeIcon />&nbsp;Upgrade Plan
              </MenuItem>
              <MenuItem onClick={handleLogout}>
                <LogoutIcon />&nbsp;Logout
              </MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
      </MDBox>
      <Outlet context={{ currentUser, setCurrentUser }} />
    </DashboardLayout>
  );
};

export default PrivateOutlet;
