import { Outlet } from "react-router-dom";
import React, { useState } from "react";

function Panelist() {
  const [panelist, setPanelist] = useState({});
  const [isEdit, setIsEdit] = useState({
    disabled: false,
    edit: false,
  });
  return <Outlet context={{ setIsEdit, isEdit, panelist, setPanelist }} />;
}

export default Panelist; 