import { Checkbox, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React, { useEffect } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";

const QuestionBox1 = ({ question, updateQuestion }) => {

    return (
        <MDBox p={2} fontSize="14px">
            <Grid container spacing={2} style={{ paddingLeft: "5px" }}>
                <Grid item xs={12} md={12}>
                    <MDBox display="flex" alignItems="center" gap="15px" flexWrap="wrap">
                        {/* <MDBox display="flex" alignItems="center" marginLeft="6px">
                            <MDBox
                                style={{
                                    border: "1px solid #aaa",
                                    borderRadius: "5px",
                                    width: "18px",
                                    height: "18px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    if (
                                        question.selectedAnswers.length !== question.options.length
                                    ) {
                                        question?.options
                                            .filter(
                                                (val) => !question.selectedAnswers.includes(val.id)
                                            )
                                            .map((option, i) => {
                                                question.selectedAnswers.push(option.id);
                                                return true;
                                            });
                                    } else {
                                        question.selectedAnswers = [];
                                    }
                                    updateQuestion("searchStatus", question);
                                }}
                            >
                                {question?.selectedAnswers?.length ===
                                    question?.options?.length && (
                                        <MDBox
                                            bgColor="info"
                                            style={{
                                                borderRadius: "3px",
                                                width: "10px",
                                                height: "10px",
                                            }}
                                        ></MDBox>
                                    )}
                            </MDBox>
                            &nbsp;&nbsp;
                            <span style={{ marginTop: "0.2rem" }}>Select All</span>&nbsp;
                        </MDBox> */}
                        <Grid container spacing={0}>
                            {question?.options?.map((option, i) => (
                                <Grid item xs={12} md={12} lg={6} key={i}>
                                    <MDBox display="flex" alignItems="center" ml={1}>
                                        <MDTypography
                                            fontWeight="regular"
                                            color="dark"
                                            sx={{
                                                cursor: "pointer",
                                                userSelect: "none",
                                                ml: -1,
                                                fontSize: "14px",
                                            }}
                                        >
                                            • &nbsp;{option.label}&nbsp;

                                        </MDTypography>
                                    </MDBox>
                                </Grid>
                            ))}
                        </Grid>
                        {/* {question?.selectedAnswers?.length <= 0 ? (
                            <MDBox color="error">Please select at least one field</MDBox>
                        ) : (
                            ""
                        )} */}
                    </MDBox>
                </Grid>
            </Grid>
        </MDBox>
    );
};

export default QuestionBox1;
