import React, { useState, useEffect } from "react";
import {
  Tooltip,
  Box,
  Button,
  CircularProgress,
  Backdrop,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Grid,
  Divider,
} from "@mui/material";
import { ThemeProvider } from "@mui/system";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import useTableTheme from "constant/TableTheme";
import moment from "moment";
import GetPointsList from "hooks/PointsList/GetPointsList";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { set } from "react-hook-form";
import TLinkApi from "services/tlink.api";

function RequestedPoints() {
  const {
    points,
    pointStatuses,
    getPoints,
    isLoading,
    setIsLoading,
    totalLength,
    setPage,
    page,
    setSearch,
    search,
    setEntriesPerPage,
    entriesPerPage,
    setSortBy,
    setSortOrder,
  } = GetPointsList();

  const [sorting, setSorting] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [data, setData] = useState([]);
  const [popupOpen, setPopupOpen] = useState(false);
  const [statusId, setStatusId] = useState(null);
  const organizationSlug = localStorage.getItem("organizationSlug");
  let { id } = useParams();
  let { enqueueSnackbar } = useSnackbar();

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 50,
  });

  const [rowSelection, setRowSelection] = useState({});

  useEffect(() => {
    let newSelectedRows = points.filter((point) => rowSelection[point.id]);
    setSelectedRows(newSelectedRows);
  }, [rowSelection]);

  useEffect(() => {
    setData(points);
    setPage(pagination.pageIndex);
    setEntriesPerPage(pagination.pageSize);

    if (sorting.length === 1) {
      if (sorting[0].desc === false) {
        setSortBy(sorting[0].id);
        setSortOrder("asc");
      } else {
        setSortBy(sorting[0].id);
        setSortOrder("desc");
      }
    } else if (sorting.length === 0) {
      setSortBy("id");
      setSortOrder("asc");
    }
  }, [points, pagination, sorting]);

  const handleSelect = (row) => {
    setSelectedRows((prev) =>
      prev.includes(row) ? prev.filter((r) => r !== row) : [...prev, row]
    );
  };

  const handleSave = async () => {
    setIsLoading(true);
    try {
      const orgSlug = localStorage.getItem("organizationSlug");
      if (!orgSlug) {
        throw new Error("Organization slug is missing from local storage");
      }
      const pointsRequestIds = selectedRows.map((row) => row.id);
      const payload = {
        statusId,
        pointsRequestIds,
      };
      let result = await TLinkApi.patch(
        `/o/${organizationSlug}/panel/${id}/points/verify`,
        payload
      );
      setIsLoading(false);
      enqueueSnackbar(result?.result?.userMessage, {
        variant: "success",
      });
      setPopupOpen(false);
      setSelectedRows([]);
      getPoints(
        pointStatuses.filter((status) => status.name === "Requested")[0]?.id
      );
    } catch (e) {
      setIsLoading(false);
      enqueueSnackbar(e?.response?.data?.result?.userMessage, {
        variant: "error",
      });
    }
  };

  // const openPopup = (actionType) => {
  //   if (actionType === "approve") {
  //     setStatusId(1);
  //   } else if (actionType === "reject") {
  //     setStatusId(3);
  //   }
  //   setPopupOpen(true);
  // };

  const columns = [
    // {
    //   id: "select",
    //   header: "Select",
    //   Cell: ({ row }) => (
    //     <input
    //       type="checkbox"
    //       style={{ accentColor: "#FB7D5B" }}
    //       checked={selectedRows.includes(row.original)}
    //       onChange={() => handleSelect(row.original)}
    //     />
    //   ),
    // },
    {
      accessorKey: "panelistId",
      header: "Panelist ID",
    },
    {
      accessorKey: "points",
      header: "Points",
    },
    {
      accessorKey: "source",
      header: "Source",
    },
    {
      accessorKey: "pointsRequestDate",
      header: "Points Requested On",
      Cell: ({ cell }) => (
        <Tooltip
          title={moment(cell.getValue()).format("YYYY-MM-DD, h:mm:ss a")}
        >
          <div>{moment(cell.getValue()).format("YYYY-MM-DD")}</div>
        </Tooltip>
      ),
    },
    {
      accessorKey: "adminVerifiedDate",
      header: "Points Granted On",
      Cell: ({ cell }) => (
        <Tooltip
          title={moment(cell.getValue()).format("YYYY-MM-DD, h:mm:ss a")}
        >
          <div>{moment(cell.getValue()).format("YYYY-MM-DD")}</div>
        </Tooltip>
      ),
    },
    {
      id: "actions",
      header: "Actions",
      Cell: ({ row }) => (
        <div>
          <Button
            variant="contained"
            sx={{ backgroundColor: "#3ba361", color: "white", mr: "5px" }}
            disabled={
              !(selectedRows.findIndex((r) => r.id == row.original.id) > -1)
            }
            onClick={() => {
              setStatusId(
                pointStatuses &&
                  pointStatuses.filter(
                    (status) => status.name === "Approved"
                  )[0]?.id
              );
              setPopupOpen(true);
            }}
          >
            Approve
          </Button>
          <Button
            variant="contained"
            sx={{ backgroundColor: "#de4545", color: "white" }}
            disabled={
              !(selectedRows.findIndex((r) => r.id == row.original.id) > -1)
            }
            onClick={() => {
              setStatusId(
                pointStatuses &&
                  pointStatuses.filter(
                    (status) => status.name === "Rejected"
                  )[0]?.id
              );
              setPopupOpen(true);
            }}
          >
            Reject
          </Button>
        </div>
      ),
    },
  ];

  const table = useMaterialReactTable({
    columns,
    data,
    enableStickyHeader: true,
    manualPagination: true,
    rowCount: data.length,
    onPaginationChange: setPagination,
    state: {
      density: "compact",
      pagination,
      sorting,
      rowSelection,
      selectedRows,
    },
    enableColumnPinning: true,
    enableGlobalFilter: true,
    enableDensityToggle: false,
    manualSorting: true,
    onSortingChange: setSorting,
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    getRowId: (row) => row.id,
    muiTableBodyRowProps: () => ({
      sx: { cursor: "pointer" },
    }),
    muiTableContainerProps: {
      sx: {
        minHeight: "calc(100vh - 360px)",
        maxHeight: "calc(100vh - 460px)",
        overflowY: "auto",
      },
    },
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        width: "100%",
        borderRadius: "0",
        backgroundColor: "#fff !important",
        zIndex: 500,
      },
    },
    renderEmptyRowsFallback: () => (
      <Stack
        display="flex"
        alignItems="center"
        justifyContent="center"
        fontSize="14px"
        p={5}
      >
        No records to display
      </Stack>
    ),
  });

  return (
    <Box sx={{ padding: "2px" }}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <ThemeProvider theme={useTableTheme()}>
        <MaterialReactTable table={table} style={{ width: "100%" }} />
      </ThemeProvider>

      <Dialog open={popupOpen} onClose={() => setPopupOpen(false)}>
        <DialogTitle
          style={{ borderBottom: "1px solid #828282", color: "#FB7D5B" }}
        >
          Are you sure you want to proceed with this?
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} mt={1}>
            <Grid item xs={12}>
              <strong>Total Panelist:</strong> {selectedRows.length}
            </Grid>
            <Grid item xs={12}>
              <strong>Total Points Approved:</strong>{" "}
              {selectedRows.reduce((acc, row) => acc + row.points, 0)}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ marginBottom: "5px" }}>
          <Button
            onClick={() => setPopupOpen(false)}
            variant="outlined"
            color="secondary"
            size="small"
            sx={{ color: "#FB7D5B" }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            variant="contained"
            color="secondary"
            sx={{ color: "#FFFFFF" }}
            size="small"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default RequestedPoints;
