import React, { useState, useRef, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Box, Grid, Typography, Button, Stack, Tooltip } from "@mui/material";
import TemplateList from "../templates/templateList/index";
import MDBox from "components/MDBox";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import TLinkApi from "services/tlink.api";
import { useSnackbar } from "notistack";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Register custom button format for Quill editor
const registerCustomButtonFormat = (Quill) => {
  const BlockEmbed = Quill.import("blots/block/embed");

  class ButtonBlot extends BlockEmbed {
    static create(value) {
      const node = super.create();
      node.innerHTML = `
        <button style="
          padding: 10px;
          background-color: #007BFF;
          color: #fff;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          margin: 10px 0;">
          ${value.text || "Click Me"}
        </button>`;
      return node;
    }

    static value(node) {
      return { text: node.innerText };
    }
  }

  ButtonBlot.blotName = "customButton";
  ButtonBlot.tagName = "div";
  Quill.register("formats/customButton", ButtonBlot);
};

function Template() {
  const [header, setHeader] = useState("Email Header Content Here");
  const [emailBody, setEmailBody] = useState("");
  const [footer, setFooter] = useState("Email Footer Content Here");
  const quillRef = useRef(null);

  const [templatesData, setTemplatesData] = useState({});
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);

  const [isEdit, setIsEdit] = useState({
    disabled: false,
    edit: false,
  });
  const location = useLocation();
  let navigate = useNavigate();
  let { enqueueSnackbar } = useSnackbar();
  const orgSlug = localStorage.getItem("organizationSlug");
  const panelSlug = localStorage.getItem("panelSlug");
  
  useEffect(() => {
    const initializeDefaultTemplate = () => {
      const defaultTemplate = templates.find((template) => template.isDefault);

      if (defaultTemplate && !selectedTemplateId) {
        setTemplatesData(defaultTemplate);
        setSelectedTemplateId(defaultTemplate.id);
        navigate(`/template/${defaultTemplate.id}`);
      }
    };

    if (templates.length > 0) {
      initializeDefaultTemplate();
    }
  }, [templates, selectedTemplateId, navigate]);

  const handleTemplateSelection = (template) => {
    setTemplatesData(template);
    setSelectedTemplateId(template.id);
    navigate(`/template/${template.id}`);
  };

  const GetTemplateList = async () => {
    setLoading(true);
    try {
      if (!orgSlug || !panelSlug) {
        throw new Error("Organization slug is missing from local storage");
      }
      const response = await TLinkApi.get(`o/${orgSlug}/panel/${panelSlug}/email/templates`);
      if (Array.isArray(response?.data?.panelEmailTemplates))
        setTemplates(response?.data?.panelEmailTemplates);
      setLoading(false);
      enqueueSnackbar(response?.userMessage, {
        variant: "success",
      });
    } catch (e) {
      // enqueueSnackbar(e?.response?.data?.result?.userMessage, {
      //   variant: "error",
      // });
      setLoading(false);
    }
  };

  useEffect(() => {
    GetTemplateList();
  }, []);

  return (
    <Box sx={{ margin: "auto" }}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        pb={2}
        mb={2}
        sx={{ borderBottom: "1px solid #b5b5b5", width: "100%" }}
      >
        <MDTypography fontSize="20px" fontWeight="bold" color="#303972">
          Email Template
        </MDTypography>
        <Tooltip title="Create New Template">
          <Stack direction="row" spacing={2}>
            {location.pathname !== `/template/new` ? (
              <MDButton
                variant="contained"
                color="info"
                sx={{ color: "#FFFFFF" }}
                size="small"
                onClick={() => {
                  setIsEdit({
                    disabled: false,
                    edit: true,
                  });
                  setTemplatesData({});
                  navigate(`/template/new`);
                }}
              >
                + Template
              </MDButton>
            ) : (
              ""
            )}
          </Stack>
        </Tooltip>
      </Stack>

      <Grid container spacing={2}>
        <Grid item xs={2.5}>
          <MDBox
            style={{
              borderRight: "1px solid #b5b5b5",
              height: "calc(100vh - 160px)",
              overflowY: "auto",
            }}
          >
            <TemplateList
              templates={templates}
              GetTemplateList={GetTemplateList}
              setTemplatesData={setTemplatesData}
              handleTemplateSelection={handleTemplateSelection}
            />
          </MDBox>
        </Grid>
        <Grid item xs={9.5}>
          <MDBox
            style={{
              height: "calc(100vh - 160px)",
              overflowY: "auto",
            }}
          >
            {location.pathname === `/template` ? (
              <MDBox
                display="flex"
                alignItems="center"
                justifyContent="center"
                mt={4}
                textAlign="center"
                fontSize="13px"
                fontWeight="500"
              >
                Please select a Template to view its
                <br /> content here or create a new one
              </MDBox>
            ) : (
              <Outlet
                context={{
                  templatesData,
                  setTemplatesData,
                  GetTemplateList,
                  isEdit,
                  setIsEdit,
                  templates,
                }}
              />
            )}
          </MDBox>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Template;
