import { Backdrop, CircularProgress, Grid, Icon, IconButton, Switch } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import TLinkApi from "services/tlink.api";
import DeduplicationClient from "./deduplicationSecurity";

import OtherSecurityClient from "./otherSecurity";

const SecurityConfiguration = () => {
  let navigate = useNavigate();
  const { setClient, setIsEdit, isEdit } = useOutletContext();
  const [confiqIsEdit, setConfigIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    watch,
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      maxLoi: "",
      minLoi: "",
      minLoiIsActive: false,
      maxLoiIsActive: false,
      researchDefenderScore: 0,
      uniqueIp: false,
      uniqueUser: false,
      countryMismatch: false,
      researchDefender: false,
    },
  });
  let { id } = useParams();

  let uniqueIp = watch("uniqueIp");
  let uniqueUser = watch("uniqueUser");
  let researchDefender = watch("researchDefender");
  let countryMismatch = watch("countryMismatch");
  let minLoiIsActive = watch("minLoiIsActive");
  let maxLoiIsActive = watch("maxLoiIsActive");
  let maxLoi = watch("maxLoi");
  let minLoi = watch("minLoi");

  let { enqueueSnackbar } = useSnackbar();

  const save = async (data) => {
    setIsLoading(true);
    let payload = { ...data };
    payload.minLoi = parseInt(data.minLoi);
    payload.maxLoi = parseInt(data.maxLoi);
    payload.researchDefenderScore = parseInt(data.researchDefenderScore);
    if (confiqIsEdit === true) {
      try {
        let result = await TLinkApi.put(`clients/${id}/securityConfig`, payload);
        enqueueSnackbar(result?.message, {
          variant: "success",
        });
        setIsLoading(false);
        navigate(`/clients/${id}/clientFilters/edit`);
        setIsEdit({
          disabled: false,
          edit: false,
        });
        setConfigIsEdit(false);
        setClient({});
      } catch (e) {
        enqueueSnackbar(e?.message, {
          variant: "error",
        });
      }
    } else {
      try {
        payload.clientId = parseInt(id);
        let result = await TLinkApi.post("clients/securityConfig", payload);
        enqueueSnackbar(result?.message, {
          variant: "success",
        });
        navigate(`/clients/${id}/clientFilters/edit`);
        setIsLoading(false);
        setIsEdit({
          disabled: false,
          edit: false,
        });
        setConfigIsEdit(false);
        setClient({});
      } catch (e) {
        enqueueSnackbar(e?.message, {
          variant: "error",
        });
      }
    }
  };

  const getClientSecurityConfiguration = async () => {
    setIsLoading(true);
    try {
      let result = await TLinkApi.get(`clients/${id}/securityConfig`);
      if (result && Object.keys(result).length > 0) {
        setIsEdit({
          disabled: true,
          edit: false,
        });
        setConfigIsEdit(true);
        setValue("uniqueIp", result.uniqueIp);
        setValue("countryMismatch", result.countryMismatch);
        setValue("researchDefender", result.researchDefender);
        setValue("uniqueUser", result.uniqueUser);
        setValue("researchDefenderScore", result.researchDefenderScore);
        setValue("minLoi", result.minLoi);
        setValue("maxLoi", result.maxLoi);
      } else {
        setIsEdit({
          disabled: false,
          edit: true,
        });
      }
      setIsLoading(false);
    } catch (e) {
      setIsEdit({
        disabled: false,
        edit: true,
      });
      console.log(e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (id) getClientSecurityConfiguration();
    // eslint-disable-next-line
  }, [id]);

  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <form onSubmit={handleSubmit(save)} noValidate>
        <MDBox
          style={{
            borderRadius: "16px",
            background: "white",
            minHeight: "700px",
          }}
          p={3}
          m={2}
        >
          <MDBox display="flex" justifyContent="flex-end" pb={2}>
            {isEdit.disabled !== false && (
              <IconButton
                color="dark"
                size="small"
                onClick={() => {
                  setIsEdit({
                    disabled: false,
                    edit: true,
                  });
                }}
              >
                <Icon>border_color_icon</Icon>
              </IconButton>
            )}
          </MDBox>
          <MDBox sx={{ overflowY: "auto" }}>
            <DeduplicationClient
              uniqueIp={uniqueIp}
              uniqueUser={uniqueUser}
              setValue={setValue}
              isEdit={isEdit}
            />
            <OtherSecurityClient
              researchDefender={researchDefender}
              countryMismatch={countryMismatch}
              errors={errors}
              control={control}
              setValue={setValue}
              isEdit={isEdit}
            />
            <MDBox p={2} style={{ border: "1px solid #D9D9D9", borderRadius: "16px" }} mt={2}>
              <MDTypography variant="h6" fontWeight="medium" color="textColor">
                {"Validation"}
              </MDTypography>
              <MDBox mt={0.5}>
                <hr style={{ border: "1px solid #e0e0e0", width: "100%" }} />
              </MDBox>
              <Grid container spacing={3} px={2} pt={2}>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={6}
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <MDBox ml={0.5}>
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="dark"
                      fontSize="12px"
                    >
                      Min LoI (%) :&nbsp;&nbsp;
                    </MDTypography>
                  </MDBox>
                  <MDBox mt={0.5}>
                    <Switch
                size="large"
                      checked={minLoiIsActive}
                      onChange={(e, value) => {
                        setValue("minLoiIsActive", value);
                      }}
                      disabled={isEdit.disabled}
                    />
                  </MDBox>
                  <Controller
                    name="minLoi"
                    control={control}
                    rules={{
                      required: minLoiIsActive ? "Min LoI (%) is required!" : false,
                    }}
                    render={({ field }) => (
                      <MDInput
                        {...field}
                        type="number"
                        fullWidth
                        required
                        sx={{ width: "200px" }}
                        {...register("minLoi", {
                          min: 0,
                          max: maxLoi,
                        })}
                        autoComplete="off"
                        variant="outlined"
                        disabled={isEdit.disabled || !minLoiIsActive}
                        error={errors?.minLoi ? true : false}
                        helperText={
                          errors?.minLoi && errors.minLoi.message !== ""
                            ? errors.minLoi.message
                            : errors?.minLoi?.type === "min" || errors?.minLoi?.type === "max"
                            ? minLoiIsActive
                              ? "Check Min Value should be greater than max"
                              : null
                            : null
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={6}
                  display="flex"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                >
                  <MDBox ml={0.5}>
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="dark"
                      fontSize="12px"
                    >
                      Max LoI (%) :&nbsp;&nbsp;
                    </MDTypography>
                  </MDBox>
                  <MDBox mt={0.5}>
                    <Switch
                size="large"
                      checked={maxLoiIsActive}
                      onChange={(e, value) => {
                        setValue("maxLoiIsActive", value);
                      }}
                      disabled={isEdit.disabled}
                    />
                  </MDBox>
                  <Controller
                    name="maxLoi"
                    control={control}
                    rules={{
                      required: maxLoiIsActive ? "Max LoI (%) is required!" : false,
                    }}
                    render={({ field }) => (
                      <MDInput
                        {...field}
                        sx={{ width: "200px" }}
                        type="number"
                        disabled={isEdit.disabled || !maxLoiIsActive}
                        {...register("maxLoi", {
                          min: minLoi,
                        })}
                        required
                        autoComplete="off"
                        variant="outlined"
                        error={errors?.maxLoi ? true : false}
                        helperText={
                          errors?.maxLoi && errors.maxLoi.message !== ""
                            ? errors.maxLoi.message
                            : errors?.maxLoi?.type === "min" || errors?.maxLoi?.type === "max"
                            ? maxLoiIsActive
                              ? "Check Min Value should be greater than max"
                              : null
                            : null
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </MDBox>
          </MDBox>
        </MDBox>
        {isEdit.disabled !== true && (
          <MDBox
            pt={2}
            px={2}
            mb={2}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            gap="10px"
          >
            <MDButton
              variant="outlined"
              color="dark"
              onClick={() => {
                setIsEdit({
                  disabled: false,
                  edit: false,
                });
                setClient({});
                navigate("/clients");
              }}
            >
              &nbsp;cancel
            </MDButton>
            <MDButton variant="gradient" color="info" type="submit">
              Save
            </MDButton>
          </MDBox>
        )}
      </form>
      {isEdit.disabled === true && (
        <MDBox
          py={2}
          px={2}
          mb={2}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          gap="10px"
        >
          <MDButton
            variant="outlined"
            color="dark"
            onClick={() => {
              setIsEdit({
                disabled: false,
                edit: false,
              });
              setClient({});
              navigate("/clients");
            }}
          >
            &nbsp;cancel
          </MDButton>
          <MDButton
            variant="gradient"
            color="info"
            onClick={() => {
              setIsEdit({
                disabled: false,
                edit: true,
              });
              setClient({});
              navigate(`/clients/${id}/clientFilters/edit`);
            }}
          >
            Next
          </MDButton>
        </MDBox>
      )}
    </>
  );
};

export default SecurityConfiguration;
