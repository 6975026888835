import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";

const GetTemplateTypeList = () => {
  const [templateType, setTemplateType] = useState([]);
  const [templateTypeMap, setTemplateTypeMap] = useState([]);

  const getTemplateType = async () => {
    try {
      let data = await TLinkApi.get("/master/list/email/template/type");
      setTemplateType(data.data);
      let templateTypeMap = [];
      data?.data?.map((value) => {
        templateTypeMap[value.id] = value.name;
        return true;
      });
      setTemplateTypeMap(templateTypeMap);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    getTemplateType();
  }, []);

  return { templateType, templateTypeMap, getTemplateType };
};

export default GetTemplateTypeList;
