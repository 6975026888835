import MDBox from "components/MDBox";
import { useState } from "react";
import MemberTable from "./components/MemberTable";
import AddMember from "./components/AddMember";

function MemberList() {
  const [open, setOpen] = useState(false);

  return (
    <MDBox>
      <AddMember setOpen={setOpen} open={open} />
      <MemberTable open={open} setOpen={setOpen} />
    </MDBox>
  );
}

export default MemberList;