import React, { useState } from "react";
import { Tabs, Tab, Box, Typography } from "@mui/material";
import MDBox from "@mui/material/Box";
import RequestedPoints from "./RequestedPoints";
import ApprovedPoints from "./ApprovedPoints";
import RejectedPoints from "./RejectedPoints";

function PointsApproval() {
  const [activeTab, setActiveTab] = useState(0);

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <MDBox>
      <Tabs
        value={activeTab}
        onChange={handleChange}
        aria-label="points tabs"
        sx={{
          mt: 1.5,
          borderBottom: "1px solid #C4C4C4",
          "& .MuiTab-root": {
            textTransform: "none",
            minHeight: "30px",
            height: "35px",
            fontWeight: 400,
            "&:hover": {
              backgroundColor: "#FFF8F3",
              borderRadius: "0.5rem",
              cursor: "pointer",
            },
          },
          "& .MuiTab-textColorPrimary": {
            color: "#555",
          },
          "& .Mui-selected": {
            color: "#303972",
            backgroundColor: "#ab95ffd9",
            paddingInline: "6px !important",
            borderTopLeftRadius: "0.5rem",
            borderTopRightRadius: "0.5rem",
            borderBottomLeftRadius: "0.5rem",
            borderBottomRightRadius: "0.5rem",
            margin: "0 4px",
          },
          "& .MuiTabs-indicator": {
            display: "none",
          },
        }}
      >
        <Tab label="Requested" />
        <Tab label="Approved" />
        <Tab label="Rejected" />
      </Tabs>

      <Box sx={{ paddingX: 2 }}>
        {activeTab === 0 && <RequestedPoints />}
        {activeTab === 1 && <ApprovedPoints />}
        {activeTab === 2 && <RejectedPoints />}
      </Box>
    </MDBox>
  );
}

export default PointsApproval;
