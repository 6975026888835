import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { useEffect, useState } from "react";
import { Tab, Tabs } from "@mui/material";
import MDTypography from "components/MDTypography";
import WestIcon from "@mui/icons-material/West";
import colors from "assets/theme/base/colors";
import GetProjectsList from "hooks/ProjectList/GetProjectList";

function AddProject() {
  const { setProjecStatusFilter, projectStatusFilter } = GetProjectsList();
  const location = useLocation();
  const navigate = useNavigate();
  let { textColor } = colors;
  const [value, setValue] = useState(0);

  const handleChange = (_e, newValue) => {
    setValue(newValue);
  };
  const { setIsEdit, isEdit } = useOutletContext();

  let { id, reconcileId } = useParams();
  useEffect(() => {
    if (window.location.pathname === `/projects/${id}/reconciliations`) {
      setValue(2);
    } else if (
      window.location.pathname ===
      `/projects/${id}/reconciliations/${reconcileId}`
    ) {
      setValue(2);
    } else if (
      window.location.pathname === `/projects/${id}/reconciliations/new`
    ) {
      setValue(2);
    } else if (window.location.pathname === `/projects/${id}/surveyList`) {
      setValue(1);
    } else if (window.location.pathname === `/projects/${id}/edit`) {
      setValue(0);
    } else if (window.location.pathname === `/projects/new`) {
      setValue(0);
    }

    // eslint-disable-next-line
  }, [location]);

  return (
    <MDBox>
      <MDTypography mb={1} sx={{ fontSize: "14px", fontWeight: "400" }}>
        <IconButton
          onClick={() => {
            setIsEdit({
              disabled: false,
              edit: false,
            });
            navigate("/projects");
          }}
        >
          <WestIcon fontSize="small" color="dark" />
        </IconButton>
        {id ? id : "New Project"}
        <Grid item xs={3} ml={2} mt={2}>
          <FormControl
            size="small"
            sx={{
              label: {
                color: "rgba(0, 0, 0, 0.25)",
                fontWeight: "400",
              },
            }}
          >
            <InputLabel>Status</InputLabel>
            <Select
              value={projectStatusFilter}
              onChange={(e, value) => {
                setProjecStatusFilter(e.target.value);
              }}
              sx={{ width: "100px" }}
              label="Status"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
            >
              <MenuItem value={-1}>
                <li>All</li>
              </MenuItem>
              <MenuItem value={1}>
                <li>Live</li>
              </MenuItem>
              <MenuItem value={2}>
                <li>Closed</li>
              </MenuItem>
              <MenuItem value={3}>
                <li>Awarded</li>
              </MenuItem>
              <MenuItem value={4}>
                <li>Paused</li>
              </MenuItem>
              <MenuItem value={5}>
                <li>Invoiced</li>
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </MDTypography>

      <Tabs
        textColor="primary"
        indicatorColor="primary"
        value={value}
        onChange={handleChange}
        style={{ borderBottom: `1px solid ${textColor.focus}` }}
      >
        <Tab
          label="Basic Details"
          index={0}
          component={Link}
          to={id ? `/projects/${id}/edit` : `/projects/new`}
        />
        <Tab
          label="Survey List"
          index={1}
          component={Link}
          disabled={!id}
          to={`/projects/${id}/surveyList
          
          `}
        />
        <Tab
          label="Reconciliation"
          index={2}
          component={Link}
          to={`/projects/${id}/reconciliations`}
          disabled={!id}
        />
      </Tabs>

      <Outlet
        context={{
          setIsEdit,
          isEdit,
        }}
      />
    </MDBox>
  );
}

export default AddProject;
