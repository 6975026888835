import * as React from "react";
import { useState } from "react";
import colors from "../../../../../../assets/theme/base/colors";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import {
  Backdrop,
  CircularProgress,
  Dialog,
  DialogTitle,
  TextField,
  Box,
  Button,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { Stack, Chip } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useParams } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import { useSnackbar } from "notistack";
import { ThemeProvider } from "@mui/material";
import useTableTheme from "../../../../../../constant/TableTheme";
import {
  MaterialReactTable,
  useMaterialReactTable,
  MRT_Table,
} from "material-react-table";
import TLinkApi from "services/tlink.api";

const AddMember = ({ setOpen, open }) => {
  let { textColor } = colors;
  let { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [selected, setselected] = useState([]);
  const [search, setSearch] = useState("");
  const [memberList, setMemberList] = useState([]);
  let { enqueueSnackbar } = useSnackbar();
  const [roles, setRoles] = useState([]);

  const tableTheme = useTableTheme();

  const [roleList, setRoleList] = useState([]);
  const [rolesData, setRolesData] = useState([]);
  const [displayMemberList, setDisplayMemberList] = useState([]);

  React.useEffect(() => {
    setselected([]);
    setRoleList([]);
  }, [open]);

  const handleClose = (e) => {
    setSearch("");
    setOpen(false);
  };

  const save = async () => {
    let memberData = selected.map((data) => {
      return {
        userId: data,
        roleIds: [
          displayMemberList.find((key) => key.userId === data).selectedRoleId,
        ],
      };
    });
    let payload = {
      members: memberData,
    };
    setIsLoading(true);
    try {
      const orgSlug = localStorage.getItem("organizationSlug");
      let result = await TLinkApi.post(
        `/o/${orgSlug}/panel/${id}/members`,
        payload
      );
      setOpen(false);
      setIsLoading(false);
      enqueueSnackbar(result?.result?.userMessage, {
        variant: "success",
      });
    } catch (e) {
      setIsLoading(false);
      enqueueSnackbar(e?.response.data.message, {
        variant: "error",
      });
    }
  };

  async function GetMembers() {
    try {
      const orgSlug = localStorage.getItem("organizationSlug");
      if (!orgSlug) {
        throw new Error("Organization slug is missing from local storage");
      }
      let data = await TLinkApi.get(
        `/o/${orgSlug}/panel/${id}/members/not-added`
      );
      setMemberList(data.data);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  }

  const GetRoles = async () => {
    setIsLoading(true);
    try {
      let result = await TLinkApi.get(`/master/list/role`);
      setRoles(result.data);
      setRolesData(result.data);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    let newMemberList =
      memberList.length > 0 &&
      memberList?.map((member) => {
        return {
          ...member,
          roles: member.roles[0].name,
          selectedRoleId: member.roles[0].id,
        };
      });
    setDisplayMemberList(newMemberList);
  }, [memberList]);

  React.useEffect(() => {
    setData(displayMemberList);
  }, [displayMemberList]);

  React.useEffect(() => {
    if (open === true) {
      GetRoles();
      GetMembers();
    }
  }, [open]);

  React.useEffect(() => {
    let searchData = displayMemberList.filter((value) => {
      if (
        selected?.findIndex(
          (val) =>
            displayMemberList
              .find((element) => element.userId === val)
              .userName?.toLowerCase() == value.userName?.toLowerCase()
        ) <= -1
      ) {
        return value.userName?.toLowerCase().includes(search.toLowerCase());
      }
    });
    // let newMemberList = searchData.map((member) => {
    //   return {
    //     ...member,
    //     roles: member.roles[0].name,
    //     selectedRoleId: member.roles[0].id,
    //   };
    // });
    setData(searchData);
  }, [search]);

  React.useEffect(() => {
    if (selected.length > 0) {
      let newMemberList = displayMemberList.filter(
        (val) => !selected.includes(val.userId)
      );
      setData(newMemberList);
    } else if (selected.length === 0) {
      setData(displayMemberList);
    }
  }, [selected]);

  function getInitials(str) {
    let names = str.split(" "),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  }

  function stringToHslColor(str, s, l) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    var h = hash % 360;
    return "hsl(" + h + ", " + s + "%, " + l + "%)";
  }

  const columns = [
    {
      accessorKey: "userName",
      header: "",
      enableEditing: false,
      size: 50,
      muiTableBodyCellProps: {
        align: "left",
        sx: { pl: "45px" },
      },
      Cell: ({ cell }) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "1rem",
            height: "15px",
          }}
        >
          <div
            style={{
              fontFamily: "Arial, Helvetica, sans-serif",
              width: "2rem",
              height: "2rem",
              borderRadius: "50%",
              background: "#E5DFFD",
              fontSize: "1rem",
              color: "#fff",
              textAlign: "center",
              lineHeight: "2rem",
            }}
          >
            {getInitials(cell.getValue())}
          </div>
          <span>{cell.getValue()}</span>
        </Box>
      ),
    },
    {
      accessorKey: "roles",
      header: "Role",
      size: 50,
      editVariant: "select",
      editSelectOptions: roles.map((role) => role.name),
      muiEditTextFieldProps: ({ cell, row }) => ({
        select: true,
        value: cell.getValue(),
        onChange: (event) => {
          let roleid = rolesData.find(
            (data) => data.name === event.target.value
          ).id;
          let rolename = rolesData.find(
            (data) => data.name === event.target.value
          ).name;
          let newDisplayMemberList = displayMemberList;
          newDisplayMemberList[
            newDisplayMemberList.findIndex(
              (val) => val.userId === row.original.userId
            )
          ].selectedRoleId = roleid;
          newDisplayMemberList[
            newDisplayMemberList.findIndex(
              (val) => val.userId === row.original.userId
            )
          ].roles = rolename;
          setDisplayMemberList(newDisplayMemberList);
        },
      }),
    },
  ];

  const table = useMaterialReactTable({
    columns,
    data,
    editDisplayMode: "table",
    enableEditing: true,
    initialState: { density: "compact" },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: (event) => {
        setselected([...selected, row.original.userId]);
      },
      sx: {
        cursor: "pointer",
      },
    }),
    muiTableHeadCellProps: {
      sx: {
        display: "none",
      },
    },
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        width: "100%",
        borderRadius: "0",
        backgroundColor: "#fff !important",
        zIndex: 500,
      },
    },
    renderEmptyRowsFallback: () => (
      <Stack
        display="flex"
        alignItems="center"
        justifyContent="center"
        fontSize="14px"
        p={5}
      >
        No records to display
      </Stack>
    ),
  });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <DialogTitle>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={1}
        >
          <span> {"Add Member"}</span>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Button
              variant="outlined"
              color="primary"
              sx={{ color: "#000000" }}
              size="small"
              onClick={() => {
                handleClose();
              }}
            >
              &nbsp;cancel
            </Button>

            <Button
             variant="contained"
             color="primary"
             sx={{ color: "#FFFFFF" }}
              size="small"
              onClick={() => {
                save();
              }}
              disabled={selected.length === 0}
            >
              Save
            </Button>
          </Stack>
        </Stack>
        <hr style={{ border: `1px solid #CCC`, width: "100%" }} />
      </DialogTitle>
      <Paper fullWidth>
        <TextField
          sx={{ width: "550px", marginX: "10px" }}
          placeholder="search and add member"
          value={search}
          onChange={(event) => setSearch(event.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <Stack
          direction="row"
          spacing={1}
          sx={{
            marginLeft: "10px",
            marginTop: "5px",
            overflowY: "auto",
            WebkitOverflowScrolling: "touch",
            scrollbarWidth: "none",
          }}
        >
          {selected.map((chipid) => {
            return (
              <Chip
                sx={{ backgroundColor: "info" }}
                label={
                  displayMemberList.find((element) => element.userId === chipid)
                    ?.userName
                }
                onDelete={() => {
                  setselected(selected.filter((id) => id !== chipid));
                }}
              />
            );
          })}
        </Stack>
        <MDBox borderRadius="5px" bgColor="white" width="98%">
          <ThemeProvider theme={tableTheme}>
            <MRT_Table
              table={table}
              style={{
                width: "100%",
              }}
            />
          </ThemeProvider>
        </MDBox>
      </Paper>
    </Dialog>
  );
};

export default AddMember;
