import {
  Backdrop,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Icon,
  IconButton,
  // Chip,
  InputLabel,
  MenuItem,
  Select,
  Switch,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import TLinkApi from "services/tlink.api";
// import CancelIcon from "@mui/icons-material/Cancel";
import GetLanguageList from "hooks/LanguageList/GetLanguageList";
import AllVendors from "hooks/listAllVendors/AllVendors";
import moment from "moment/moment";
import { Controller, useForm } from "react-hook-form";

function CampaignBasicDetails() {
  const navigate = useNavigate();
  const { campaign, setCampaign, isEdit, setIsEdit } = useOutletContext();
  let { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const { language, languageMap } = GetLanguageList();
  const { vendors } = AllVendors();

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
    setValue,
    register,
  } = useForm({
    defaultValues: {
      campaignName: "",
      supplierId: "",
      languageId: "",
      cpi: "",
      startDate: new Date().toISOString().slice(0, 10),
      endDate: "",
      // campaignUrl: "",
      isActive: false,
    },
  });

  let isActive = watch("isActive");
  let startDate = watch("startDate");
  // let campaignUrl = watch("campaignUrl");
  let languageId = watch("languageId");

  useEffect(() => {
    if (Object.keys(campaign).length > 0) {
      setValue("campaignName", campaign.campaignName);
      setValue("supplierId", campaign.supplierId);
      setValue("languageId", campaign.languageId);
      setValue("isActive", campaign.isActive);
      setValue("cpi", campaign.cpi);
      // setValue("campaignUrl", campaign.campaignUrl);
      setValue(
        "startDate",
        campaign.startDate
          ? moment(campaign.startDate).format("YYYY-MM-DD")
          : ""
      );
      setValue(
        "endDate",
        campaign.endDate ? moment(campaign.endDate).format("YYYY-MM-DD") : ""
      );
    }
    // eslint-disable-next-line
  }, [campaign]);

  const { id } = useParams();

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [id]);

  async function getData() {
    if (id) {
      if (id !== "new") {
        setIsLoading(true);
        if (window.location.pathname === `/campaign/${id}/edit`) {
          setIsEdit({
            disabled: false,
            edit: true,
          });
        } else {
          setIsEdit({
            disabled: true,
            edit: false,
          });
        }
        try {
          let result = await TLinkApi.get(`/campaign/${id}`);
          setCampaign(result.data);
          setIsLoading(false);
        } catch (e) {
          console.log(e);
          setIsLoading(false);
        }
      }
    }
  }

  const save = async (payload) => {
    setIsLoading(true);

    let data = { ...payload };
    // data.languageId = payload.languageId.map((val) => parseInt(val));

    try {
      if (isEdit.edit === true) {
        let result = await TLinkApi.put(`/campaign/${campaign.id}`, data);
        setIsEdit({
          disabled: false,
          edit: false,
        });
        setIsLoading(false);
        setCampaign({});
        enqueueSnackbar(result?.message, {
          variant: "success",
        });
        navigate(`/campaigns`);
      } else {
        let result = await TLinkApi.post("/campaign", data);
        setIsLoading(false);
        enqueueSnackbar(result?.message, {
          variant: "success",
        });
        setIsEdit({
          disabled: false,
          edit: false,
        });
        setCampaign({});
        navigate(`/campaigns`);
      }
    } catch (e) {
      setIsLoading(false);
      enqueueSnackbar(e?.message, {
        variant: "error",
      });
    }
  };

  let selectStyle = {
    height: "36.5px",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
    "& :hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
  };

  function copy(text) {
    navigator.clipboard.writeText(text);
    enqueueSnackbar("URL copy Successfully!", {
      variant: "success",
    });
  }

  const handleDelete = (e, value) => {
    let data = languageId.filter((val) => val !== value);
    setValue("languageId", data);
  };

  return (
    <>
      <form onSubmit={handleSubmit(save)} noValidate>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <MDBox
          bgColor="white"
          height="calc(100vh - 240px)"
          p={3}
          m={2}
          style={{ borderRadius: "16px" }}
        >
          <MDBox display="flex" justifyContent="flex-end" pb={2} px={2}>
            {isEdit.disabled !== true && (
              <MDBox
                // pt={2}
                px={2}
                mb={1}
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                gap="10px"
              >
                <MDButton
                  variant="outlined"
                  color="dark"
                  size="small"
                  onClick={() => {
                    setIsEdit({
                      disabled: false,
                      edit: false,
                    });
                    setCampaign({});
                    navigate("/campaigns");
                  }}
                >
                  &nbsp;cancel
                </MDButton>
                <MDButton variant="gradient" size="small" color="info" type="submit">
                  Save
                </MDButton>
              </MDBox>
            )}
            {isEdit.disabled !== false && (
              <IconButton
                color="dark"
                size="small"
                onClick={() => {
                  setIsEdit({
                    disabled: false,
                    edit: true,
                  });
                  navigate(`/campaigns/${id}/edit`);
                }}
              >
                <Icon>border_color_icon</Icon>
              </IconButton>
            )}
          </MDBox>
          <MDBox
            p={2}
            style={{ border: "1px solid #D9D9D9", borderRadius: "16px" }}
            mx={2}
          >
            <MDTypography
              variant="h6"
              fontWeight="medium"
              color="textColor"
              style={{ borderBottom: "1px solid #D9D9D9" }}
              mb={2}
            >
              {"Campaign Details"}
            </MDTypography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Controller
                  name="campaignName"
                  control={control}
                  rules={{
                    required: "Campaign Name is required!",
                  }}
                  render={({ field }) => (
                    <MDInput
                      {...field}
                      fullWidth
                      required
                      type="text"
                      autoComplete="off"
                      variant="outlined"
                      label="Campaign Name"
                      disabled={isEdit.disabled}
                      error={errors?.campaignName ? true : false}
                      helperText={
                        errors?.campaignName
                          ? errors.campaignName.message
                          : null
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                {/* <Controller
                  name="languageId"
                  control={control}
                  rules={{
                    required: "Language is required!",
                  }}
                  render={({ field: { value, onChange } }) => (
                    <FormControl
                      fullWidth
                      required
                      error={errors?.languageId ? true : false}
                      sx={{
                        label: {
                          color: "rgba(0, 0, 0, 0.25)",
                          fontWeight: "400",
                        },
                      }}
                      size="small"
                    >
                      <InputLabel>Country</InputLabel>
                      <Select
                        multiple
                        value={value}
                        onChange={onChange}
                        label="Country"
                        disabled={isEdit.disabled || isEdit.edit}
                        sx={selectStyle}
                        renderValue={(selected) => (
                          <div>
                            {selected.map((value) => {
                              return (
                                <Chip
                                  key={value}
                                  label={languageMap[value]}
                                  clickable
                                  disabled={isEdit.disabled || isEdit.edit}
                                  deleteIcon={
                                    <CancelIcon onMouseDown={(event) => event.stopPropagation()} />
                                  }
                                  onDelete={(e) => handleDelete(e, value)}
                                />
                              );
                            })}
                          </div>
                        )}
                      >
                        {language.map((value, i) => {
                          return (
                            <MenuItem value={value.id} key={i}>
                              {value.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {errors?.languageId && (
                        <FormHelperText>
                          {errors?.languageId ? errors.languageId.message : null}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                /> */}
                <Controller
                  name="languageId"
                  control={control}
                  rules={{
                    required: "Language Id is required!",
                  }}
                  render={({ field: { value, onChange } }) => (
                    <FormControl
                      fullWidth
                      required
                      error={errors?.languageId ? true : false}
                      sx={{
                        label: {
                          color: "rgba(0, 0, 0, 0.25)",
                          fontWeight: "400",
                        },
                      }}
                      size="small"
                    >
                      <InputLabel>language Id</InputLabel>
                      <Select
                        value={value}
                        onChange={onChange}
                        label="Supplier id"
                        disabled={isEdit.disabled || isEdit.edit}
                        sx={selectStyle}
                      >
                        {language.map((value, i) => {
                          return (
                            <MenuItem value={value.id} key={i}>
                              {value.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {errors?.languageId && (
                        <FormHelperText>
                          {errors?.languageId
                            ? errors.languageId.message
                            : null}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="supplierId"
                  control={control}
                  rules={{
                    required: "Supplier Id is required!",
                  }}
                  render={({ field: { value, onChange } }) => (
                    <FormControl
                      fullWidth
                      required
                      error={errors?.supplierId ? true : false}
                      sx={{
                        label: {
                          color: "rgba(0, 0, 0, 0.25)",
                          fontWeight: "400",
                        },
                      }}
                      size="small"
                    >
                      <InputLabel>Supplier Id</InputLabel>
                      <Select
                        value={value}
                        onChange={onChange}
                        label="Supplier id"
                        disabled={isEdit.disabled || isEdit.edit}
                        sx={selectStyle}
                      >
                        {vendors.map((value, i) => {
                          return (
                            <MenuItem value={value.id} key={i}>
                              {value.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {errors?.supplierId && (
                        <FormHelperText>
                          {errors?.supplierId
                            ? errors.supplierId.message
                            : null}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="cpi"
                  control={control}
                  rules={{
                    required: "Cpi is required!",
                  }}
                  render={({ field }) => (
                    <MDInput
                      {...field}
                      fullWidth
                      required
                      type="number"
                      autoComplete="off"
                      variant="outlined"
                      label="Cpi"
                      {...register("cpi", {
                        min: 0,
                      })}
                      disabled={isEdit.disabled}
                      error={errors?.cpi ? true : false}
                      helperText={
                        errors?.cpi && errors.cpi.message !== ""
                          ? errors.cpi.message
                          : errors?.cpi?.type === "min"
                          ? "Cpi should not be less then 0"
                          : null
                      }
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Controller
                  name="startDate"
                  control={control}
                  rules={{
                    required: "Start Date is required!",
                  }}
                  render={({ field }) => (
                    <MDInput
                      {...field}
                      fullWidth
                      disabled={isEdit.disabled}
                      required
                      autoComplete="off"
                      variant="outlined"
                      type="Date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Start Date"
                      error={errors?.startDate ? true : false}
                      helperText={
                        errors?.startDate ? errors.startDate.message : null
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="endDate"
                  control={control}
                  rules={{
                    required: "End Date is required!",
                  }}
                  render={({ field }) => (
                    <MDInput
                      {...field}
                      fullWidth
                      disabled={isEdit.disabled || startDate === ""}
                      required
                      autoComplete="off"
                      variant="outlined"
                      type="Date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      {...register("endDate", {
                        min: startDate
                          ? new Date(startDate).toISOString().split("T")[0]
                          : "",
                        max: startDate
                          ? new Date(
                              Date.now(startDate) + 30 * 24 * 60 * 60 * 1000
                            ).toISOString()
                          : "",
                      })}
                      label="End Date"
                      error={errors?.endDate ? true : false}
                      helperText={
                        errors?.endDate && errors.endDate.message !== ""
                          ? errors.endDate.message
                          : errors?.endDate?.type === "min"
                          ? "Start date cannot be greater than End date"
                          : errors?.endDate?.type === "max"
                          ? "End date cannot be greater than start Date 30 days"
                          : null
                      }
                    />
                  )}
                />
              </Grid>
              {/* <Grid item xs={12} md={6} style={{ position: "relative" }}>
                <Controller
                  name="campaignUrl"
                  control={control}
                  rules={{
                    required: "Url is required!",
                  }}
                  render={({ field }) => (
                    <MDInput
                      {...field}
                      fullWidth
                      disabled={isEdit.disabled}
                      required
                      autoComplete="off"
                      type="url"
                      variant="outlined"
                      label="Url"
                      {...register("campaignUrl", {
                        pattern: {
                          value:
                            // eslint-disable-next-line
                            /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
                          message: "invalid Link",
                        },
                      })}
                      error={errors?.campaignUrl ? true : false}
                      helperText={errors?.campaignUrl ? errors.campaignUrl.message : null}
                    />
                  )}
                />
                <Icon
                  style={{
                    position: "absolute",
                    right: 6,
                    bottom: errors?.campaignUrl ? 27 : 10,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    copy(campaignUrl);
                  }}
                >
                  copy
                </Icon>
              </Grid> */}
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Switch
                size="large"
                      disabled={isEdit.disabled}
                      checked={isActive}
                      onChange={(e, value) => {
                        setValue("isActive", value);
                      }}
                    />
                  }
                  label={
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                    >
                      &nbsp;&nbsp;Is Active&nbsp;
                    </MDTypography>
                  }
                />
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
        {/* {isEdit.disabled !== true && (
          <MDBox
            pt={2}
            px={2}
            mb={2}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            gap="10px"
          >
            <MDButton
              variant="outlined"
              color="dark"
              onClick={() => {
                setIsEdit({
                  disabled: false,
                  edit: false,
                });
                setCampaign({});
                navigate("/campaigns");
              }}
            >
              &nbsp;cancel
            </MDButton>
            <MDButton variant="gradient" color="info" type="submit">
              Save
            </MDButton>
          </MDBox>
        )} */}
      </form>
      {isEdit.disabled === true && (
        <MDBox
          pt={2}
          px={2}
          mb={2}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          gap="10px"
        >
          <MDButton
            variant="gradient"
            color="info"
            onClick={() => {
              setIsEdit({
                disabled: false,
                edit: false,
              });
              setCampaign({});
              navigate("/campaigns");
            }}
          >
            Back
          </MDButton>
        </MDBox>
      )}
    </>
  );
}

export default CampaignBasicDetails;
