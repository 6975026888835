import React, { useState, useEffect } from "react";
import {
  Tooltip,
  Box,
  Grid,
  Button,
  CircularProgress,
  Backdrop,
  Stack,
} from "@mui/material";
import { ThemeProvider } from "@mui/system";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import useTableTheme from "constant/TableTheme";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import GetRejectedPointsList from "hooks/RejectedPointsList/GetRejectedPointsList";

function RejectedPoints() {
  const navigate = useNavigate();
  const {
    rejectedPoints,
    getRejectedPoints,
    isLoading,
    totalLength,
    setPage,
    page,
    setSearch,
    search,
    setEntriesPerPage,
    entriesPerPage,
    setSortBy,
    setSortOrder,
  } = GetRejectedPointsList();
  const [sorting, setSorting] = useState([]);
  const tableTheme = useTableTheme();
  const [data, setData] = useState([]);

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 50,
  });

  useEffect(() => {
    setData(rejectedPoints);
    setPage(pagination.pageIndex);
    setEntriesPerPage(pagination.pageSize);

    if (sorting.length === 1) {
      if (sorting[0].desc === false) {
        setSortBy(sorting[0].id);
        setSortOrder("asc");
      } else {
        setSortBy(sorting[0].id);
        setSortOrder("desc");
      }
    } else if (sorting.length === 0) {
      setSortBy("id");
      setSortOrder("asc");
    }
  }, [rejectedPoints, pagination, sorting]);

  let columns = [
    {
      accessorKey: "panelistId",
      header: "Panelist ID",
      size: 50,
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },
    {
      accessorKey: "points",
      header: "Points",
      size: 50,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
    },
    {
      accessorKey: "source",
      header: "Source",
      size: 50,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
    },
    {
      accessorKey: "adminVerifiedDate",
      header: "Verified Date",
      size: 50,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
      Cell: ({ cell }) => (
        <Tooltip
          title={moment(cell.getValue()).format("YYYY-MM-DD, h:mm:ss a")}
        >
          <div>{moment(cell.getValue()).format("YYYY-MM-DD")}</div>
        </Tooltip>
      ),
    },
    {
      accessorKey: "pointsRequestDate",
      header: "Request Date",
      size: 50,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
      Cell: ({ cell }) => (
        <Tooltip
          title={moment(cell.getValue()).format("YYYY-MM-DD, h:mm:ss a")}
        >
          <div>{moment(cell.getValue()).format("YYYY-MM-DD")}</div>
        </Tooltip>
      ),
    },
  ];

  const table = useMaterialReactTable({
    columns,
    data,
    enableStickyHeader: true,
    manualPagination: true,
    rowCount: data.length,
    onPaginationChange: setPagination,
    state: { density: "compact", pagination, sorting },
    enableColumnPinning: true,
    enableGlobalFilter: true,
    enableDensityToggle: false,
    manualSorting: true,
    onSortingChange: setSorting,
    muiTableBodyRowProps: () => ({
      sx: { cursor: "pointer" },
    }),
    muiTableContainerProps: {
      sx: {
        minHeight: "calc(100vh - 380px)",
        maxHeight: "calc(100vh - 460px)",
        overflowY: "auto",
      },
    },
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        width: "100%",
        borderRadius: "0",
        backgroundColor: "#fff !important",
        zIndex: 500,
      },
    },
    renderEmptyRowsFallback: () => (
      <Stack
        display="flex"
        alignItems="center"
        justifyContent="center"
        fontSize="14px"
        p={5}
      >
        No records to display
      </Stack>
    ),
  });

  return (
    <Box sx={{ padding: "2px" }}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ThemeProvider theme={tableTheme}>
        <MaterialReactTable
          table={table}
          style={{
            width: "100%",
          }}
        />
      </ThemeProvider>
    </Box>
  );
}

export default RejectedPoints;
