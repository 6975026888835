import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TLinkApi from "services/tlink.api";

const GetPointsList = () => {
  const [points, setPoints] = useState([]);
  const [pointStatuses, setPointStatuses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [totalLength, setTotalLength] = useState(0);
  const [search, setSearch] = useState("");
  const [entriesPerPage, setEntriesPerPage] = useState(50);
  const [sortBy, setSortBy] = useState("id");
  const [sortOrder, setSortOrder] = useState("asc");
  let { id } = useParams();

  const organizationSlug = localStorage.getItem("organizationSlug");
  const panelSlug = localStorage.getItem("panelSlug");

  const getPointStatuses = async () => {
    setIsLoading(true);
    try {
      const response = await TLinkApi.get("/master/list/points/status");
      setPointStatuses(response.data);
    } catch (error) {
      console.error("Error fetching point statuses:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getPoints = async (statusId) => {
    setIsLoading(true);
    try {
      const orgSlug = localStorage.getItem("organizationSlug");
      if (!orgSlug) {
        throw new Error("Organization slug is missing from local storage");
      }
      const response = await TLinkApi.get(
        `/o/${organizationSlug}/panel/${id}/points/status/${statusId}?search=${search}&page=${
          page + 1
        }&limit=${entriesPerPage}&sortOrder=${sortOrder}&sortBy=${sortBy}`
      );
      setPoints(response.data);
    } catch (error) {
      console.error("Error fetching points data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getPointStatuses();
  }, []);

  useEffect(() => {
    if (pointStatuses?.length > 0) {
      const firstStatusId = pointStatuses.filter(
        (status) => status.name === "Requested"
      )[0]?.id;
      if (firstStatusId) {
        getPoints(firstStatusId);
      }
    }
  }, [pointStatuses, page, search, entriesPerPage, sortBy, sortOrder]);

  return {
    points,
    pointStatuses,
    getPoints,
    isLoading,
    setIsLoading,
    totalLength,
    setPage,
    page,
    setSearch,
    search,
    setEntriesPerPage,
    entriesPerPage,
    setSortBy,
    setSortOrder,
  };
};

export default GetPointsList;
