import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TLinkApi from "services/tlink.api";

const GetRejectedPointsList = () => {
  const [rejectedPoints, setRejectedPoints] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [totalLength, setTotalLength] = useState(0);
  const [search, setSearch] = useState("");
  const [entriesPerPage, setEntriesPerPage] = useState(50);
  const [sortBy, setSortBy] = useState("id");
  const [sortOrder, setSortOrder] = useState("asc");
  let { id } = useParams();

  const organizationSlug = localStorage.getItem("organizationSlug");

  const getRejectedPoints = async () => {
    setIsLoading(true);
    try {
      const orgSlug = localStorage.getItem("organizationSlug");
      if (!orgSlug) {
        throw new Error("Organization slug is missing from local storage");
      }
      const response = await TLinkApi.get(
        `/o/${organizationSlug}/panel/${id}/points/status/3?search=${search}&page=${
          page + 1
        }&limit=${entriesPerPage}&sortOrder=${sortOrder}&sortBy=${sortBy}`
      );
      setRejectedPoints(response.data);
    } catch (error) {
      console.error("Error fetching points data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getRejectedPoints();
  }, [page, search, entriesPerPage, sortBy, sortOrder]);

  return {
    rejectedPoints,
    getRejectedPoints,
    isLoading,
    totalLength,
    setPage,
    page,
    setSearch,
    search,
    setEntriesPerPage,
    entriesPerPage,
    setSortBy,
    setSortOrder,
  };
};

export default GetRejectedPointsList;
