import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
    Autocomplete,
    Backdrop,
    Button,
    CircularProgress,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    MenuItem,
    Popper,
    Select,
    Switch,
    TextField,
    useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import QuestionBox1 from "comps/Cards/Checkbox1";
import MinMax from "comps/Cards/InputFields";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import TLinkApi from "services/tlink.api";
import QuestionList from "./QuestionList";

const CustomPopper = styled(Popper)(({ theme }) => ({
  zIndex: theme.zIndex.modal + 1,
}));

function ProfilingQuestions({ marketId }) {
  let { setIsEdit, isEdit } = useOutletContext();
  let { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  const [quesLoading, setQuesLoading] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [searchedQuestion, setSearchedQuestion] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [query, setQuery] = useState("");
  const [height, setHeight] = useState(false);
  const [sortOrder, setSortOrder] = useState(-1);
  const [market, setMarket] = useState();
  const isSmallScreen = useMediaQuery("(max-width:1000px)");

  const [answers, setAnswers] = useState([]);
  const [ids, setIds] = useState({
    qualificationId: null,
    qualificationQuestionId: null,
  });

  const {
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      questionStatus: "standard",
      searchStatus: null,
    },
  });

  let searchStatus = watch("searchStatus");

  useEffect(() => {
    if (marketId) {
      setMarket(marketId);
    } else {
      setMarket(3);
    }
  }, [marketId]);

  const getQuestions = async () => {
    setIsLoading(true);
    try {
      const orgSlug = localStorage.getItem("organizationSlug");
      if (!orgSlug) {
        throw new Error("Organization slug is missing from local storage");
      }
      let data = await TLinkApi.get(
        `/o/${orgSlug}/panel/${id}/profiling/questions/market/${market}`
      );
      if (Array.isArray(data.data)) {
        let questionData = data.data;
        questionData.sort((a, b) => a.sortOrder - b.sortOrder);
        setQuestions(questionData);
      } else {
        setQuestions([]);
      }
    } catch (error) {
      enqueueSnackbar("Panel profiling questions do not exist", {
        variant: "error",
      });
      setQuestions([]);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (market) {
      getQuestions();
    }
  }, [market]);

  useEffect(() => {
    let newSortOrder = questions[questions.length - 1]?.sortOrder;
    setSortOrder(newSortOrder);
  }, [questions]);

  const getSearchedQuestions = async () => {
    setIsLoading(true);
    try {
      const orgSlug = localStorage.getItem("organizationSlug");
      if (!orgSlug) {
        throw new Error("Organization slug is missing from local storage");
      }
      let data = await TLinkApi.get(
        `/o/${orgSlug}/master/qualifications/market/${market}/qualificationCategoryType/1?search=${searchText}`
      );
      if (Array.isArray(data.data)) {
        setSearchedQuestion(data.data);
      } else {
        setSearchedQuestion([]);
      }
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.result?.userMessage, {
        variant: "error",
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (market) {
      getSearchedQuestions();
    }
  }, [searchText, market]);

  const handleCheck = () => {
    if (searchStatus) {
      if (
        searchStatus.qualificationTypeId === 1 ||
        searchStatus.qualificationTypeId === 2
      ) {
        return (
          <QuestionBox1 question={searchStatus} updateQuestion={setValue} />
        );
      } else if (searchStatus.qualificationTypeId === 4) {
        return <MinMax question={searchStatus} updateQuestion={setValue} />;
        // } else {
        //   return <AnswerText question={searchStatus} updateQuestion={setValue} />;
      }
    }
  };

  useEffect(() => {
    if (searchStatus) {
      setIds({
        qualificationId: searchStatus.id,
        qualificationQuestionId: searchStatus?.question?.id,
      });
    }
  }, [searchStatus]);

  useEffect(() => {
    if (ids && ids?.qualificationId && ids?.qualificationQuestionId) {
      getAnswers();
    }
  }, [ids?.qualificationId]);

  const getAnswers = async () => {
    setIsLoading(true);
    try {
      const orgSlug = localStorage.getItem("organizationSlug");
      if (!orgSlug) {
        throw new Error("Organization slug is missing from local storage");
      }
      let response = await TLinkApi.get(
        `/o/${orgSlug}/master/qualifications/market/${market}/qualification/${ids.qualificationId}/qualificationQuestion/${ids?.qualificationQuestionId}`
      );
      setAnswers(response.data);
      setIsLoading(false);
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.result?.userMessage, {
        variant: "error",
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (answers && answers?.question?.answers) {
      let payload = { ...searchStatus };
      if (
        payload.qualificationTypeId === 1 ||
        payload.qualificationTypeId === 2
      ) {
        payload.options = answers.question.answers?.map((answer) => {
          return {
            id: answer?.id,
            label: answer?.text,
          };
        });
        payload.selectedAnswers = [];
      } else if (payload.qualificationTypeId === 3) {
        payload.text = "text";
      } else if (payload.qualificationTypeId === 4) {
        payload.selectedRange = [
          {
            min: "10",
            max: "100",
          },
        ];
      }
      setValue("searchStatus", payload);
    }
  }, [answers]);

  const save = async () => {
    setIsLoading(true);
    try {
      const orgSlug = localStorage.getItem("organizationSlug");
      if (!orgSlug) {
        throw new Error("Organization slug is missing from local storage");
      }
      const sortOrderToUse = questions.length > 0 ? sortOrder + 1 : 1;
      let payload = {
        qualificationId: searchStatus.id,
        sortOrder: sortOrderToUse,
        marketId: market,
      };
      let response = await TLinkApi.post(
        `/o/${orgSlug}/panel/${id}/profiling/question`,
        payload
      );
      enqueueSnackbar("Question added successfully", { variant: "success" });
      getQuestions();
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.result?.userMessage, {
        variant: "error",
      });
    }
    setIsLoading(false);
    setValue("searchStatus", null);
  };

  const onDragEnd = async (result) => {
    const { destination } = result;
    if (!destination) {
      return;
    }
    const reorderedSections = Array.from(questions);
    const [moved] = reorderedSections.splice(result.source.index, 1);
    reorderedSections.splice(
      Number(result && result.destination && result?.destination?.index),
      0,
      moved
    );

    const updatedQuestion = reorderedSections.map((question, index) => ({
      ...question,
      sortOrder: index + 1,
    }));
    await setQuestions(updatedQuestion);
    QuestionSortOrder(updatedQuestion);
  };

  const QuestionSortOrder = async (updatedQuestion) => {
    setIsLoading(true);
    let payload = updatedQuestion.map((val) => {
      return {
        qualificationId: val.qualificationId,
        sortOrder: val.sortOrder,
        marketId: market,
      };
    });
    try {
      const orgSlug = localStorage.getItem("organizationSlug");
      if (!orgSlug) {
        throw new Error("Organization slug is missing from local storage");
      }
      await TLinkApi.patch(
        `/o/${orgSlug}/panel/${id}/profiling/question`,
        payload
      );
      await getQuestions();
      enqueueSnackbar("Question sort successfully", {
        variant: "success",
      });
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      enqueueSnackbar("Oopss! somthig went wrong", {
        variant: "error",
      });
      setIsLoading(false);
    }
  };

  // For not showing question once selected from dropdown
  const [selectedQuestions, setSelectedQuestions] = useState(() => {
    const saved = localStorage.getItem("selectedQuestions");
    return saved ? JSON.parse(saved) : [];
  });

  useEffect(() => {
    localStorage.setItem(
      "selectedQuestions",
      JSON.stringify(selectedQuestions)
    );
  }, [selectedQuestions]);

  useEffect(() => {
    if (Array.isArray(searchedQuestion) && searchedQuestion.length > 0) {
      setSelectedQuestions((prev) =>
        prev.filter((q) => searchedQuestion.some((sq) => sq.id === q.id))
      );
    }
  }, [searchedQuestion]);

  return (
    <MDBox
      style={{
        borderRadius: "16px",
      }}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          ml={{ xs: "5px", sm: "10px", md: !marketId ? "15px" : "0px" }}
          mt={{ xs: "10px", sm: "12px", md: !marketId ? "15px" : "-6px" }}
          mb={{ xs: "10px", sm: "12px", md: !marketId ? "0px" : "15px" }}
        >
          {/* <MDTypography variant="h6" color="#2F2F2F">
            Profiling Questions
          </MDTypography> */}
        </Grid>
        {!marketId && (
          <Grid item xs={12} md={12} mt={-1}>
            <hr style={{ border: `1px solid #C4C4C4`, width: "100%" }} />
          </Grid>
        )}
      </Grid>
      <form>
        <MDBox
          bgColor="white"
          style={{
            height: `calc(100vh - 400px)`,
            overflowY: "auto",
            paddingBottom: "1rem",
          }}
        >
          <MDBox
            position="sticky"
            top="0"
            zIndex={5}
            ml={{ xs: "5px", sm: "10px", md: !marketId ? "15px" : "-14px" }}
          >
            <Grid container spacing={0}>
              <Grid
                item
                xs={12}
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
                gap="5px"
                style={{
                  backgroundColor: "#ffffff",
                  height: "47px",
                  fontSize: "14px",
                  fontWeight: "500",
                  borderBottom: "1px solid rgba(0, 0, 0, 0.06)",
                }}
              >
                <MDBox
                  width="15%"
                  px={{ xs: 1, sm: 2 }}
                  color="#B5B7C0"
                  sx={{
                    width: {
                      xs: "30%",
                      sm: "25%",
                      md: "20%",
                      lg: "15%",
                    },
                    fontSize: {
                      xs: "10px",
                      sm: "12px",
                      md: "14px",
                      lg: "15px",
                    },
                  }}
                  style={{
                    borderRight: "1px solid rgba(0, 0, 0, 0.06)",
                  }}
                >
                  Type
                </MDBox>

                <MDBox
                  width="72%"
                  px={{ xs: 1, sm: 2 }}
                  color="#B5B7C0"
                  sx={{
                    width: {
                      xs: "60%",
                      sm: "55%",
                      md: "50%",
                      lg: "72%",
                    },
                    fontSize: {
                      xs: "10px",
                      sm: "12px",
                      md: "14px",
                      lg: "15px",
                    },
                  }}
                  // style={{ borderRight: "1px solid rgba(0, 0, 0, 0.06)" }}
                >
                  Question
                </MDBox>
                <MDBox
                  color="#B5B7C0"
                  // style={{ borderRight: "1px solid rgba(0, 0, 0, 0.06)" }}
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    width: { xs: "20%", sm: "20%", md: "15%", lg: "9.5%" },
                  }}
                  style={{
                    borderLeft: "1px solid rgba(0, 0, 0, 0.06)",
                  }}
                >
                  Action
                </MDBox>
                <MDBox
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="center"
                  sx={{ width: { xs: "10%", sm: "10%", md: "7%", lg: "5%" } }}
                  style={{ borderRight: "1px solid rgba(0, 0, 0, 0.06)" }}
                ></MDBox>
              </Grid>
            </Grid>
          </MDBox>
          {questions && questions.length > 0 ? (
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="qualificationId">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {questions.map((value, index) => (
                      <Draggable
                        key={String(value.qualificationId)}
                        draggableId={String(value.qualificationId)}
                        index={index}
                      >
                        {(provided) => (
                          <QuestionList
                            val={value}
                            index={index}
                            getQuestions={getQuestions}
                            isEdit={isEdit}
                            setQuestions={setQuestions}
                            questions={questions}
                            setIsEdit={setIsEdit}
                            provided={provided}
                            market={market}
                          />
                        )}
                      </Draggable>
                    ))}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          ) : (
            <MDTypography variant="h6" align="center" sx={{ marginTop: 2 }}>
              No questions added yet!
            </MDTypography>
          )}

          <MDBox
            mt={3}
            style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.06)" }}
          >
            <MDBox
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
              gap="5px"
              style={{
                height: "47px",
                fontSize: "14px",
                fontWeight: "500",
                color: "black",
              }}
            >
              <MDBox
                width="15%"
                px={{ xs: 1, sm: 1, md: 2 }}
                sx={{
                  width: {
                    xs: "10%",
                    md: "20%",
                    lg: "15%",
                  },
                }}
                style={{
                  // borderRight: "1px solid rgba(0, 0, 0, 0.06)",
                  borderLeft: "1px solid rgba(0, 0, 0, 0.06)",
                }}
              >
                <Controller
                  name="questionStatus"
                  control={control}
                  rules={{
                    required: "Question Status is required!",
                  }}
                  render={({ field: { onChange, value } }) => (
                    <FormControl
                      fullWidth
                      required
                      error={errors?.questionStatus ? true : false}
                      size="small"
                    >
                      <Select
                        value={value}
                        onChange={onChange}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        placeholder="Language List"
                        sx={{
                          height: "36.5px",
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "1px solid #D9D9D9",
                            borderRadius: "0px",
                          },
                          "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                            border: "1px solid #D9D9D9",
                            borderRadius: "0px",
                          },
                          "& :hover .MuiOutlinedInput-notchedOutline": {
                            border: "1px solid #D9D9D9",
                            borderRadius: "0px",
                          },
                        }}
                      >
                        <MenuItem value="standard">Standard</MenuItem>
                        {/* <MenuItem value="custom">Custom</MenuItem> */}
                      </Select>
                      {errors?.questionStatus && (
                        <FormHelperText>
                          {errors?.questionStatus
                            ? errors.questionStatus.message
                            : null}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </MDBox>
              <MDBox
                px={{ xs: 1, sm: 1, md: 2 }}
                width="72%"
                sx={{
                  width: {
                    xs: "60%",
                    sm: "55%",
                    md: "50%",
                    lg: "72%",
                  },
                }}
              >
                <Controller
                  name="searchStatus"
                  control={control}
                  rules={{
                    required: "Question Status is required!",
                  }}
                  render={({ field: { value, onChange } }) => (
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={
                        Array.isArray(searchedQuestion)
                          ? searchedQuestion.filter(
                              (option) =>
                                Array.isArray(selectedQuestions) &&
                                !selectedQuestions.some(
                                  (selected) => selected?.id === option?.id
                                )
                            )
                          : []
                      }
                      getOptionLabel={(option) => option?.question?.text || ""}
                      isOptionEqualToValue={(option, value) =>
                        option?.id === value?.id
                      }
                      value={value}
                      onChange={(e, value) => {
                        if (value) {
                          setSelectedQuestions((prev) => [...prev, value]);
                        }
                        onChange(JSON.parse(JSON.stringify(value)));
                        setHeight(true);
                      }}
                      loading={quesLoading}
                      onInputChange={(_, val) => {
                        if (val !== undefined) {
                          setQuery(val);
                        } else {
                          setQuery("");
                        }
                      }}
                      renderOption={(props, option) => (
                        <li {...props} key={option.id}>
                          {option?.question?.text || "No Questions available"}
                        </li>
                      )}
                      PopperComponent={(props) => (
                        <CustomPopper
                          {...props}
                          placement={isSmallScreen ? "top-start" : "top-start"}
                        />
                      )}
                      sx={{
                        "& .MuiInputBase-input": {
                          height: "1.2rem",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "1px solid #D9D9D9",
                          borderRadius: "0px",
                        },
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Question List"
                          size="small"
                        />
                      )}
                    />
                  )}
                />
              </MDBox>
              <MDBox
                width="10%"
                // style={{
                //   borderRight: "1px solid rgba(0, 0, 0, 0.06)",
                //   borderLeft: "1px solid rgba(0, 0, 0, 0.06)",
                // }}
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
                sx={{
                  width: { xs: "20%", sm: "15%", md: "10%" },
                }}
              >
                <Switch
                size="large" color="secondary" disabled />
              </MDBox>
              <MDBox
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
                sx={{ width: { xs: "10%", sm: "10%", md: "7%", lg: "5%" } }}
                // style={{ borderRight: "1px solid rgba(0, 0, 0, 0.06)" }}
              >
                <IconButton
                  sx={{ transform: height === true ? "rotate(180deg)" : "" }}
                  onClick={() => {
                    if (height === false) {
                      setHeight(true);
                    } else {
                      setHeight(false);
                    }
                  }}
                >
                  <KeyboardArrowDownIcon />
                </IconButton>
              </MDBox>
            </MDBox>
            {searchStatus !== null && (
              <MDBox
                style={{ display: height === false && "none" }}
                bgColor="#ab95ff33"
                padding="1.5rem"
              >
                <MDBox
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  gap="10px"
                >
                  <MDTypography
                    fontWeight="regular"
                    color="dark"
                    sx={{
                      fontSize: { xs: "14px", sm: "16px" },
                      fontWeight: 400,
                      width: { xs: "100%", sm: "80%" },
                      overflowWrap: "break-word",
                    }}
                  >
                    {searchStatus?.question?.text}
                  </MDTypography>

                  <MDBox
                    display="flex"
                    alignItems="center"
                    gap="20px"
                    flexDirection="row"
                  >
                    <Button
                      variant="outlined"
                      color="secondary"
                      sx={{ color: "#000000" }}
                      size="small"
                      onClick={() => {
                        setValue("searchStatus", null);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ color: "#FFFFFF" }}
                      size="small"
                      onClick={() => {
                        save();
                      }}
                    >
                      Add
                    </Button>
                  </MDBox>
                </MDBox>

                {handleCheck()}
              </MDBox>
            )}
          </MDBox>
        </MDBox>
      </form>
    </MDBox>
  );
}

export default ProfilingQuestions;
