import { Grid, Card, Box } from "@mui/material";

export function DisplayCard({ value, name, optionIcon, color }) {
  const formatCash = (n) => {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(2) + "K";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(2) + "M";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(2) + "B";
    if (n >= 1e12) return +(n / 1e12).toFixed(2) + "T";
  };

  return (
    <Card
      sx={{
        height: "120px",
        width: "100%",
        background: `linear-gradient(${color})`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "1rem",
      //   transition: 'box-shadow 0.3s ease', 
      //   '&:hover': {
      //   boxShadow: '10px 10px 15px rgba(0, 0, 0, 0.3)', // Hover shadow
      // },
      }}
    >
      <Grid container spacing={2}>
        <Grid
          item
          xs={8}
          display="flex"
          flexDirection={"column"}
          justifyContent={"flex-start"}
          alignItems={"flex-start"}
        >
          <Box sx={{ fontWeight: "bold", fontSize: "35px" }}>
            {formatCash(Number(value).toFixed(2))}
          </Box>
          <Box
            sx={{
              fontWeight: "normal",
              fontSize: "14px",
              fontWeight: "500",
              color: "rgba(0, 0, 0, 0.50)",
            }}
          >
            {name}
          </Box>
        </Grid>
        <Grid
          item
          xs={4}
          justifyContent="flex-end"
          alignItems={"center"}
          display={"flex"}
        >
          <Grid justifyContent="center" alignItems={"center"}>
            {optionIcon}
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
}
