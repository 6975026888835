import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Button, IconButton, Switch } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MDBox from "components/MDBox";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useParams } from "react-router-dom";
import TLinkApi from "services/tlink.api";
import QuestionChart from "../QuestionChart";

const QuestionList = ({
  val,
  index,
  getQuestions,
  isEdit,
  questions,
  setQuestions,
  setIsEdit,
  provided,
  market,
}) => {
  const [i, setI] = useState(null);
  let { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const [edit, setEdit] = useState(false);

  const activeQualification = async ({ val, value }) => {
    try {
      const orgSlug = localStorage.getItem("organizationSlug");
      const qualificationId = val?.qualificationId;
      if (!orgSlug) {
        throw new Error("Organization slug is missing from local storage");
      }
      let result = await TLinkApi.put(
        `/o/${orgSlug}/panel/${id}/profiling/question/${qualificationId}/market/${market}`,
        {
          isActive: value,
        }
      );
      enqueueSnackbar(result?.result?.userMessage, {
        variant: "success",
      });
      getQuestions();
      handleClose();
      setI(null);
    } catch (e) {
      console.log(e);
      enqueueSnackbar(e?.response?.data?.result?.userMessage, {
        variant: "error",
      });
    }
  };

  const deleteQualification = async () => {
    setIsLoading(true);
    try {
      const orgSlug = localStorage.getItem("organizationSlug");
      const qualificationId = val?.qualificationId;
      if (!orgSlug) {
        throw new Error("Organization slug is missing from local storage");
      }

      let result = await TLinkApi.del(
        `/o/${orgSlug}/panel/${id}/profiling/question/${qualificationId}/market/${market}`
      );

      enqueueSnackbar(result?.result?.userMessage, {
        variant: "success",
      });
      getQuestions();
      handleDeleteClose();
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      enqueueSnackbar(e?.response?.data?.result?.userMessage, {
        variant: "error",
      });
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteClickOpen = () => {
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  return (
    <MDBox
      mt={3}
      style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.06)" }}
      key={index}
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
    >
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <span>{"Are you sure?"}</span>
          <hr />
        </DialogTitle>

        <DialogContent>
          <DialogContentText>Do you want to continue?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            sx={{ color: "#000000" }}
            color="secondary"
            size="small"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="outlined"
            color="primary"
            sx={{ color: "#FFFFFF" }}
            size="small"
            onClick={() => {
              activeQualification({ val, value: false });
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={deleteOpen} onClose={handleDeleteClose}>
        <DialogTitle>
          <span>{"Are you sure?"}</span>
          <hr />
        </DialogTitle>

        <DialogContent>
          <DialogContentText>Do you want to continue?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            sx={{ color: "#000000" }}
            color="secondary"
            size="small"
            onClick={handleDeleteClose}
          >
            Cancel
          </Button>
          <Button
           variant="contained"
           color="primary"
           sx={{ color: "#FFFFFF" }}
            size="small"
            onClick={() => {
              deleteQualification();
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <MDBox
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        gap="5px"
        style={{
          fontSize: "14px",
          fontWeight: "500",
          color: "black",
        }}
      >
        <MDBox
          width="15%"
          px={1}
          sx={{
            width: {
              xs: "10%",
              md: "20%",
              lg: "15%",
            },
          }}
          style={{
            // borderRight: "1px solid rgba(0, 0, 0, 0.06)",
            borderLeft: "1px solid rgba(0, 0, 0, 0.06)",
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <DragIndicatorIcon sx={{ fontSize: "1.5rem !important" }} />
          <MDBox
            sx={{
              fontSize: { xs: "10px", md: "12px", lg: "15px" },
            }}
          >
            Standard
          </MDBox>
        </MDBox>
        <MDBox
          px={1}
          width="72%"
          height="auto"
          flexWrap="wrap"
          // display="flex"
          sx={{
            width: { xs: "40%", md: "50%", lg: "72%" },
            fontSize: { xs: "10px", md: "12px", lg: "15px" },
            overflowWrap: "break-word",
          }}
        >
          {val?.question?.questionText}
        </MDBox>
        <MDBox
          width="10%"
          sx={{
            width: { xs: "20%", md: "20%", lg: "10%" },
            fontSize: { xs: "10px", md: "12px", lg: "15px" },
          }}
          // style={{
          //   borderRight: "1px solid rgba(0, 0, 0, 0.06)",
          //   borderLeft: "1px solid rgba(0, 0, 0, 0.06)",
          // }}
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
        >
          <IconButton
            sx={{ marginTop: "0.2rem" }}
            onClick={() => {
              handleDeleteClickOpen();
            }}
          >
            <DeleteOutlineIcon />
          </IconButton>
          <Switch
                size="large"
            color="info"
            size="small"
            sx={{"&:hover": {
                  backgroundColor: "#F0F0F0",
                  borderRadius: "1rem",
                  cursor: "pointer",
                }}}
            checked={val.isActive}
            onChange={(e, value) => {
              if (value === false) {
                handleClickOpen();
              } else {
                activeQualification({ val, value });
              }
              setEdit(false);
              setI(null);
            }}
          />
        </MDBox>
        <MDBox
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          sx={{ width: { xs: "10%", md: "10%", lg: "5%" } }}
          style={{ borderRight: "1px solid rgba(0, 0, 0, 0.06)" }}
        >
          <IconButton
            sx={{ transform: index === i ? "rotate(180deg)" : "" }}
            onClick={() => {
              if (index === null) {
                setI(index);
              } else if (index !== i) {
                setI(index);
              } else {
                setI(null);
              }
            }}
          >
            <KeyboardArrowDownIcon />
          </IconButton>
        </MDBox>
      </MDBox>
      <MDBox style={{ display: i === index ? "" : "none" }}>
        <QuestionChart
          val={val}
          index={index}
          questions={questions}
          setQuestions={setQuestions}
          getQuestions={getQuestions}
          setI={setI}
          isEdit={isEdit}
        />
      </MDBox>
    </MDBox>
  );
};

export default QuestionList;
