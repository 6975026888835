import {
    Backdrop,
    CircularProgress,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    ThemeProvider,
    Tooltip
} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import { Stack } from "@mui/system";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import SearchInput from "components/searchInput";
import useTableTheme from "constant/TableTheme";
import GetListOfClients from "hooks/ListOfClients/GetListOfClients";
import GetProjectsList from "hooks/ProjectList/GetProjectList";
import GetProjectManagerList from "hooks/ProjectManagerList/GetProjectManagerList";
import {
    MaterialReactTable,
    useMaterialReactTable,
} from "material-react-table";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";

function ProjectsList() {
  const navigate = useNavigate();
  const { setIsEdit } = useOutletContext();
  const { clients } = GetListOfClients();
  const { projectManager, setManagerSearch, managerSearch } =
    GetProjectManagerList();
  const [filtersLength, setFiltersLength] = useState({
    clientLength: 0,
    projectLength: 0,
    statusLength: 0,
  });

  const {
    projects,
    isLoading,
    totalLength,
    setPage,
    page,
    setSearch,
    search,
    setEntriesPerPage,
    entriesPerPage,
    setProjecStatusFilter,
    projectStatusFilter,
    setSortBy,
    setSortOrder,
    clientFilter,
    setClientFilter,
    projectManagerFilter,
    setProjectManagerFilter,
  } = GetProjectsList();

  let rows = projects;
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 50,
  });
  const [sorting, setSorting] = useState([]);
  const tableTheme = useTableTheme();

  const [searchClient, setSearchClient] = useState("");
  const [searchClientList, setSearchClientList] = useState([]);
  const [statusSearch, setStatusSearch] = useState("");
  const [searchStatusList, setSearchStatusList] = useState([]);
  const [statusList, setStatusList] = useState([]);

  useEffect(() => {
    let payload = { ...filtersLength };
    payload.clientLength = clients.length;
    payload.projectLength = projectManager.length;
    setFiltersLength(payload);
  }, [projectManager, clients]);

  useEffect(() => {
    setData(projects);
    setPage(pagination.pageIndex);
    setEntriesPerPage(pagination.pageSize);
    if (sorting.length === 1) {
      if (sorting[0].desc === false) {
        setSortBy(sorting[0].id);
        setSortOrder("asc");
      } else {
        setSortBy(sorting[0].id);
        setSortOrder("desc");
      }
    } else if (sorting.length === 0) {
      setSortBy("id");
      setSortOrder("asc");
    }
  }, [projects, pagination, sorting]);

  let columns = [
    {
      accessorKey: "id",
      header: "Project Id",
      size: 50,
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      Cell: ({ cell }) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            setIsEdit({
              disabled: true,
              edit: false,
            });
            navigate(`/projects/${cell.getValue()}`);
          }}
        >
          {cell.getValue()}
        </div>
      ),
    },
    {
      accessorKey: "clientName",
      header: "Client Name",
      size: 50,
      enableSorting: false,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
      headerAlign: "center",
      Cell: ({ cell }) => {
        const clientName = cell.getValue();
        const truncatedName =
          clientName?.length > 15
            ? clientName.substring(0, 15) + "..."
            : clientName;
        return (
          <Tooltip title={cell.getValue()} placement="bottom-start">
            <div>{truncatedName}</div>
          </Tooltip>
        );
      },
    },
    {
      accessorKey: "name",
      header: "Project Name",
      size: 50,
      enableSorting: false,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
      headerAlign: "center",
      Cell: ({ cell }) => {
        const name = cell.getValue();
        const truncatedName =
          name?.length > 15 ? name.substring(0, 15) + "..." : name;
        return (
          <Tooltip title={name} placement="bottom-start">
            <div>{truncatedName}</div>
          </Tooltip>
        );
      },
    },
    {
      accessorKey: "startDate",
      header: "Start Date",
      size: 50,
      headerAlign: "center",
      Cell: ({ cell }) => {
        return (
          <Tooltip
            title={moment(cell.getValue()).format("YYYY-MM-DD, h:mm:ss a")}
          >
            <div>{moment(cell.getValue()).format("YYYY-MM-DD")}</div>
          </Tooltip>
        );
      },
    },

    {
      accessorKey: "endDate",
      header: "End Date",
      size: 50,
      headerAlign: "center",
      Cell: ({ cell }) => {
        return (
          <Tooltip
            title={moment(cell.getValue()).format("YYYY-MM-DD, h:mm:ss a")}
          >
            <div>{moment(cell.getValue()).format("YYYY-MM-DD")}</div>
          </Tooltip>
        );
      },
    },
    {
      accessorKey: "totalRevenue",
      header: "Total Revenue",
      size: 50,
      headerAlign: "center",
      Cell: ({ cell }) => {
        const revenue = parseFloat(cell.getValue());
        const roundedRevenue = Math.round(revenue * 100) / 100;
        return roundedRevenue.toFixed(2);
      },
    },
    {
      accessorKey: "totalCost",
      header: "Total Cost",
      size: 50,
      headerAlign: "center",
      Cell: ({ cell }) => {
        const cost = parseFloat(cell.getValue());
        const roundedCost = Math.round(cost * 100) / 100;
        return roundedCost.toFixed(2);
      },
    },
    {
      accessorKey: "status",
      header: "Status",
      align: "center",
      size: 50,
      enableSorting: false,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
      headerAlign: "center",
      Cell: ({ cell }) => {
        return cell.getValue() === 1
          ? "Live"
          : cell.getValue() === 2
          ? "Closed"
          : cell.getValue() === 3
          ? "Awarded"
          : cell.getValue() === 4
          ? "Paused"
          : cell.getValue() === 5
          ? "Invoiced"
          : cell.getValue();
      },
    },
  ];

  const table = useMaterialReactTable({
    columns,
    data,
    enableStickyHeader: true,
    manualPagination: true,
    rowCount: totalLength,
    onPaginationChange: setPagination,
    state: { density: "compact", pagination, sorting },
    enableColumnPinning: true,
    enableGlobalFilter: false,
    enableDensityToggle: false,
    manualSorting: true,
    muiPaginationProps: {
      rowsPerPageOptions: [25, 50, 100],
    },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: (event) => {
        navigate(`/projects/${row.original.id}`);
      },
      sx: {
        cursor: "pointer",
      },
    }),
    onSortingChange: setSorting,
    muiTableContainerProps: {
      sx: {
        minHeight: "calc(100vh - 340px)",
        maxHeight: "calc(100vh - 350px)",
        overflowY: "auto",
      },
    },
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        width: "100%",
        borderRadius: "0",
        backgroundColor: "#fff !important",
        zIndex: 500,
      },
    },
    renderEmptyRowsFallback: () => (
      <Stack
        display="flex"
        alignItems="center"
        justifyContent="center"
        fontSize="14px"
        p={5}
      >
        No records to display
      </Stack>
    ),
  });

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <MDBox pb={3} width="100%">
        {/* <MDTypography color="textColor" ml="1%">
          Projects
        </MDTypography> */}
        <MDBox display="flex" justifyContent="center">
          <MDBox py={2} borderRadius="5px" bgColor="white" width="100%">
            <MDTypography
              color="textColor"
              ml="1%"
              mb="1%"
              mr="1%"
              fontSize="1.25rem"
              display="flex"
              justifyContent="space-between"
            >
              Project List
              <FormGroup>
                <FormControlLabel
                  control={<Switch
                size="large" defaultChecked />}
                  label="Manual"
                />
              </FormGroup>
            </MDTypography>
            <Grid container spacing={2}>
              <Grid
                item
                xs={9}
                display="flex"
                justifyContent="flex-start"
                flexDirection="row"
              >
                <Grid container spacing={2}>
                  {/* <Grid item xs={2} ml={"15px"}>
                    <FormControl
                      fullWidth
                      size="small"
                      sx={{
                        label: {
                          fontWeight: "400",
                        },
                      }}
                    >
                      <InputLabel>Clients</InputLabel>
                      <Select
                        multiple
                        value={Array.isArray(clientFilter) ? clientFilter : []}
                        label="Clients"
                        MenuProps={{
                          autoFocus: false,
                        }}
                        renderValue={(selected) => (
                          <div>
                            {selected.slice(0, 1).map((option, index) => (
                              <span>
                                {
                                  searchClientList.find(
                                    (item) => Number(item.id) === Number(option)
                                  )?.name
                                }
                              </span>
                            ))}
                            {selected?.length > 1 && (
                              <Chip
                                sx={{ backgroundColor: "white" }}
                                label={`+${selected?.length - 1} more`}
                                style={{
                                  height: "20px",
                                  marginTop: "3px",
                                }}
                              />
                            )}
                          </div>
                        )}
                      >
                        <ListSubheader sx={{ marginBottom: "1rem" }}>
                          <TextField
                            sx={{
                              position: "sticky",
                              marginTop: "-16px",
                              paddingTop: "1rem",
                              zIndex: 1,
                              background: "white",
                            }}
                            size="small"
                            autoFocus
                            value={searchClient}
                            placeholder="Type to search..."
                            fullWidth
                            onChange={(e) => setSearchClient(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key !== "Escape") {
                                e.stopPropagation();
                              }
                            }}
                            InputProps={{
                              endAdornment: searchClient && (
                                <InputAdornment
                                  position="end"
                                  onClick={() => setSearchClient("")}
                                  style={{ cursor: "pointer" }}
                                >
                                  <ClearIcon sx={{ fontSize: "small" }} />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </ListSubheader>
                        <MenuItem
                        // onClick={() => {
                        //   // setOnload(false);
                        //   if (
                        //     Array.isArray(clientFilter) &&
                        //     clientFilter.length === searchClientList?.length
                        //   ) {
                        //     setTimeout(() => {
                        //       setClientFilter([]);
                        //     }, 200);
                        //   } else {
                        //     setTimeout(() => {
                        //       setClientFilter(
                        //         searchClientList?.map((value) => value.id)
                        //       );
                        //     }, 200);
                        //   }
                        // }}
                        >
                          <Checkbox
                            checked={
                              Array.isArray(clientFilter) &&
                              clientFilter.length ===
                                searchClientList?.length &&
                              searchClientList?.length > 0
                            }
                          />
                          Select All
                        </MenuItem>

                        {Array.isArray(searchClientList) &&
                          searchClientList.map((value, i) => (
                            <MenuItem
                              value={value.id}
                              key={i}
                              onClick={() => {
                                if (clientFilter.includes(value.id)) {
                                  let payload = clientFilter?.filter(
                                    (val) => val != value.id
                                  );
                                  // setOnload(false);
                                  setTimeout(() => {
                                    setClientFilter(payload);
                                  }, 200);
                                } else {
                                  let payload = [...clientFilter];
                                  payload.push(value.id);
                                  // setOnload(false);
                                  setTimeout(() => {
                                    setClientFilter(payload);
                                  }, 200);
                                }
                              }}
                            >
                              <Checkbox
                                checked={
                                  Array.isArray(clientFilter) &&
                                  clientFilter.includes(value.id)
                                }
                              />
                              {value.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid> */}
                  {/* <Grid item xs={2}>
                    <FormControl
                      fullWidth
                      size="small"
                      sx={{
                        label: {
                          fontWeight: "400",
                        },
                      }}
                    >
                      <InputLabel>Project Manager</InputLabel>
                      <Select
                        multiple
                        value={
                          Array.isArray(projectManagerFilter)
                            ? projectManagerFilter
                            : []
                        }
                        label="Project Manager"
                        MenuProps={{
                          autoFocus: false,
                        }}
                        renderValue={(selected) => (
                          <div>
                            {selected.slice(0, 1).map((option, index) => (
                              <span>
                                {
                                  projectManager.find(
                                    (item) => Number(item.id) === Number(option)
                                  )?.fullName
                                }
                              </span>
                            ))}
                            {selected?.length > 1 && (
                              <Chip
                                sx={{ backgroundColor: "white" }}
                                label={`+${selected?.length - 1} more`}
                                style={{
                                  height: "20px",
                                  marginTop: "3px",
                                }}
                              />
                            )}
                          </div>
                        )}
                      >
                        <ListSubheader sx={{ marginBottom: "1rem" }}>
                          <TextField
                            sx={{
                              position: "sticky",
                              marginTop: "-16px",
                              paddingTop: "1rem",
                              zIndex: 1,
                              background: "white",
                            }}
                            size="small"
                            autoFocus
                            required
                            value={managerSearch}
                            placeholder="Type to search..."
                            fullWidth
                            onChange={(e) => setManagerSearch(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key !== "Escape") {
                                e.stopPropagation();
                              }
                            }}
                            InputProps={{
                              endAdornment: managerSearch && (
                                <InputAdornment
                                  position="end"
                                  onClick={() => setManagerSearch("")}
                                  style={{ cursor: "pointer" }}
                                >
                                  <ClearIcon sx={{ fontSize: "small" }} />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </ListSubheader>
                        <MenuItem
                        // onClick={() => {
                        //   if (
                        //     Array.isArray(projectManagerFilter) &&
                        //     projectManagerFilter.length ===
                        //       projectManager.length
                        //   ) {
                        //     // setOnload(false);
                        //     setTimeout(() => {
                        //       setProjectManagerFilter([]);
                        //     }, 200);
                        //   } else {
                        //     // setOnload(false);
                        //     setTimeout(() => {
                        //       setProjectManagerFilter(
                        //         projectManager?.map((value) =>
                        //           String(value.id)
                        //         )
                        //       );
                        //     }, 200);
                        //   }
                        // }}
                        >
                          <Checkbox
                            checked={
                              Array.isArray(projectManagerFilter) &&
                              projectManagerFilter.length ===
                                projectManager.length
                            }
                          />
                          Select All
                        </MenuItem>
                        {projectManager?.map((value, index) => (
                          <MenuItem
                            key={index}
                            value={String(value.id)}
                            // onClick={() => {
                            //   if (
                            //     projectManagerFilter.includes(String(value.id))
                            //   ) {
                            //     let payload = projectManagerFilter?.filter(
                            //       (val) => String(val) !== String(value.id)
                            //     );
                            //     // setOnload(false);
                            //     setTimeout(() => {
                            //       setProjectManagerFilter(payload);
                            //     }, 200);
                            //   } else {
                            //     let payload = [...projectManagerFilter];
                            //     payload.push(String(value.id));
                            //     // setOnload(false);
                            //     setTimeout(() => {
                            //       setProjectManagerFilter(payload);
                            //     }, 200);
                            //   }
                            // }}
                          >
                            <Checkbox
                              checked={
                                Array.isArray(projectManagerFilter) &&
                                projectManagerFilter.includes(String(value.id))
                              }
                            />
                            {value.fullName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid> */}

                  <Grid item xs={3} ml={2}>
                    <FormControl
                      size="small"
                      sx={{
                        label: {
                          color: "rgba(0, 0, 0, 0.25)",
                          fontWeight: "400",
                        },
                      }}
                    >
                      <InputLabel>Status</InputLabel>
                      <Select
                        value={projectStatusFilter}
                        onChange={(e, value) => {
                          setProjecStatusFilter(e.target.value);
                        }}
                        sx={{ width: "200px" }}
                        label="Status"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                      >
                        <MenuItem value={-1}>All</MenuItem>
                        <MenuItem value={1}>Live</MenuItem>
                        <MenuItem value={2}>Closed</MenuItem>
                        <MenuItem value={3}>Awarded</MenuItem>
                        <MenuItem value={4}>Paused</MenuItem>
                        <MenuItem value={5}>Invoiced</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                px={2}
                xs={3}
                display="flex"
                justifyContent="flex-end"
                flexDirection="row"
                gap={2}
              >
                <SearchInput setSearch={setSearch} search={search} />

                <MDButton
                  variant="contained"
                  color="info"
                  size="small"
                  onClick={() => {
                    navigate("/projects/new");
                    setIsEdit({
                      disabled: false,
                      edit: false,
                    });
                  }}
                >
                  +Project
                </MDButton>
              </Grid>
            </Grid>
            <ThemeProvider theme={tableTheme}>
              <MaterialReactTable
                table={table}
                style={{
                  width: "100%",
                }}
              />
            </ThemeProvider>
          </MDBox>
        </MDBox>
      </MDBox>
    </>
  );
}

export default ProjectsList;
