import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Backdrop,
  CircularProgress,
  IconButton,
  Button,
} from "@mui/material";
import TLinkApi from "services/tlink.api";
import { useSnackbar } from "notistack";
import MDTypography from "components/MDTypography";
import Tier from "./tier";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { useParams } from "react-router-dom";

function TierConfig({ marketId }) {
  const [isEdit, setIsEdit] = useState({
    disabled: true,
    edit: false,
  });
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [entriesPerPage, setEntriesPerPage] = useState(20);
  const [sortOrder, setSortOrder] = useState("desc");
  const [sortBy, setSortBy] = useState("id");
  const [tiers, setTiers] = useState([]);
  const [masterTiers, setMasterTiers] = useState([]);
  const [tierData, setTierData] = useState([]);

  const [errorData, setErrorData] = useState([]);
  const [market, setMarket] = useState();
  const { id } = useParams();

  useEffect(() => {
    if (marketId) {
      setMarket(marketId);
    }
  }, [marketId]);

  const GetAllTiers = async () => {
    setIsLoading(true);
    try {
      const orgSlug = localStorage.getItem("organizationSlug");
      if (!orgSlug) {
        throw new Error("Organization slug is missing from local storage");
      }
      const data = await TLinkApi.get(
        `/o/${orgSlug}/panel/${id}/market/${market}/tiers?search=${search}&sortOrder=${sortOrder}&sortBy=${sortBy}&page=${
          page + 1
        }&limit=${entriesPerPage}`
      );
      const fetchedTiers = data?.data?.panelTierConfigs || [];
      setTiers(fetchedTiers);
      if (fetchedTiers.length > 0) {
        let newTierData = masterTiers.map((mTier) => {
          if (
            fetchedTiers.findIndex((tier) => tier.tierType.id === mTier.id) > -1
          ) {
            return {
              tierTypeId: mTier.id,
              minimumBalance: fetchedTiers.find(
                (tier) => tier.tierType.id === mTier.id
              ).minimumBalance,
              minimumPoints: fetchedTiers.find(
                (tier) => tier.tierType.id === mTier.id
              ).minimumPoints,
              maximumPoints: fetchedTiers.find(
                (tier) => tier.tierType.id === mTier.id
              ).maximumPoints,
              tierName: fetchedTiers.find(
                (tier) => tier.tierType.id === mTier.id
              ).tierName,
            };
          }
        });
        setTierData(newTierData);
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      enqueueSnackbar("No panel tier configs found!!!", {
        variant: "error",
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (masterTiers.length > 0 && market) {
      GetAllTiers();
    }
  }, [page, search, entriesPerPage, sortOrder, sortBy, masterTiers, market]);

  const GetMasterTiers = async () => {
    setIsLoading(true);
    try {
      const data = await TLinkApi.get(`/master/list/tier/type`);
      setMasterTiers(data?.data || []);
      if (data?.data.length > 0) {
        let newTierData = data?.data.map((value) => {
          return {
            tierTypeId: value.id,
            minimumBalance: 0,
            minimumPoints: 0,
            maximumPoints: 0,
            marketId: market,
            tierName: value.name,
          };
        });
        setTierData(newTierData);
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      enqueueSnackbar(
        e?.response?.data.message,
        {
          variant: "error",
        }
      );
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (market) {
      GetMasterTiers();
    }
  }, [market]);

  const Save = async () => {
    if (errorData.length === 0) {
      setIsLoading(true);
      try {
        let payload = tierData.map((data) => {
          return { ...data, marketId: market };
        });
        const orgSlug = localStorage.getItem("organizationSlug");
        if (!orgSlug) {
          throw new Error("Organization slug not found in local storage.");
        }
        if (tiers.length === 0) {
          let result = await TLinkApi.post(
            `/o/${orgSlug}/panel/${id}/market/tiers`,
            payload
          );
          if (result?.result?.userMessage) {
            enqueueSnackbar(result.result.userMessage, {
              variant: "success",
            });
          }
          GetAllTiers();
          GetMasterTiers();
          setIsEdit({
            disabled: false,
            edit: true,
          });
        } else {
          let payload = tierData.map((data) => {
            return { ...data, marketId: market };
          });
          let result = await TLinkApi.put(
            `/o/${orgSlug}/panel/${id}/market/tiers/update`,
            payload
          );
          if (result?.result?.userMessage) {
            enqueueSnackbar(result.result.userMessage, {
              variant: "success",
            });
          }
          GetAllTiers();
          GetMasterTiers();
          setIsEdit({
            disabled: true,
            edit: false,
          });
        }
        setIsEdit({
          disabled: true,
          edit: false,
        });
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        enqueueSnackbar(e?.response?.data?.result?.userMessage || e.message, {
          variant: "error",
        });
      }
    }
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid
        container
        spacing={2}
        style={{
          width: "100%",
          height: `calc(100vh - 340px)`,
          overflowY: "auto",
        }}
      >
        <Grid item xs={6} mt={!marketId ? "15px" : "-6px"}>
          {/* <MDTypography variant="h6" color="#2F2F2F">
            Tier
          </MDTypography> */}
        </Grid>

        <Grid item xs={6} md={6}>
          {isEdit.disabled !== false && (
            <Box display="flex" justifyContent="flex-end">
              <IconButton
                style={{
                  variant: "contained",
                  color: "secondary",
                }}
                onClick={() => {
                  setIsEdit({ disabled: false, edit: true });
                }}
              >
                <ModeEditIcon />
              </IconButton>
            </Box>
          )}
          {isEdit.disabled !== true && (
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              gap="10px"
            >
              <Button
                variant="outlined"
                color="secondary"
                sx={{ color: "#000000" }}
                size="small"
                onClick={() => {
                  setIsEdit({
                    disabled: true,
                    edit: false,
                  });
                  GetAllTiers();
                }}
              >
                &nbsp;Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                sx={{ color: "#FFFFFF" }}
                onClick={Save}
                size="small"
              >
                Save
              </Button>
            </Box>
          )}
        </Grid>
        <Grid item xs={12} md={12}>
          <Box sx={{ width: "100%" }}>
            {tierData.length > 0 &&
              tierData.map((data) => (
                <div style={{ marginBottom: "40px" }}>
                  <Tier
                    tierIndex={data?.tierTypeId}
                    tierData={tierData}
                    setTierData={setTierData}
                    GetAllTiers={GetAllTiers}
                    GetMasterTiers={GetMasterTiers}
                    isEdit={isEdit}
                    setIsEdit={setIsEdit}
                    errorData={errorData}
                    setErrorData={setErrorData}
                    market={market}
                  />
                </div>
              ))}
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default TierConfig;
