import { Backdrop, CircularProgress } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";
import CollapsibleTable from "./SupplierTable";
import AddSupplier from "./AddSupplier";
import AllVendors from "hooks/listAllVendors/AllVendors";

function SupplierSurvey() {
  const navigate = useNavigate();
  const { setSurvey, setIsEdit, isEdit } = useOutletContext();
  const [supplierTable, setSupplierTable] = useState([]);
  const { vendors, vendorsMap } = AllVendors();
  const [vendorsList, setVendorList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);

  let { id } = useParams();

  useEffect(() => {
    if (id) {
      setIsEdit({
        disabled: true,
        edit: false,
      });
    }
    // eslint-disable-next-line
  }, [id]);

  let getTableData = async () => {
    setIsLoading(true);
    try {
      const orgSlug = localStorage.getItem("organizationSlug");
      const panelSlug = localStorage.getItem("panelSlug");
      console.log(orgSlug, panelSlug);
      if (!orgSlug) {
        throw new Error("Organization slug is missing from local storage");
      }
      let result = await TLinkApi.get(`/o/${orgSlug}/panel/${panelSlug}/survey/${id}/supplier`);
      setSupplierTable(result);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    if (id !== undefined) getTableData();
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    var ids = supplierTable.map((el) => {
      return el.supplierId;
    });
    var data = vendors.filter((el) => !ids.includes(el.id)).map((val) => val);
    setVendorList(data);
    // eslint-disable-next-line
  }, [supplierTable, vendors]);

  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <AddSupplier
        vendorsList={vendorsList}
        setOpen={setOpen}
        open={open}
        getTableData={getTableData}
      />
      <MDBox pt={2} mx={2}>
        <CollapsibleTable
          supplierTable={supplierTable}
          vendorsMap={vendorsMap}
          getTableData={getTableData}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          setOpen={setOpen}
        />

        {/* <MDBox
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          mt={2}
          mb={2}
          gap="10px"
        >
          <MDButton
            variant="outlined"
            color="dark"
            onClick={() => {
              navigate(`/surveys`);
              setSurvey({});
              setIsEdit({
                disabled: false,
                edit: false,
              });
            }}
          >
            Cancel
          </MDButton>
          <MDButton
            variant="gradient"
            color="info"
            onClick={() => {
              setSurvey({});
              navigate(`/surveys/${id}/securityConfig/edit`);
            }}
          >
            next
          </MDButton>
        </MDBox> */}
      </MDBox>
    </>
  );
}

export default SupplierSurvey;
