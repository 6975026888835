import React, { useState, useEffect } from "react";
import MDBox from "../../../../components/MDBox";
import {
  Link,
  Outlet,
  useLocation,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { Grid, IconButton, Typography, Stack, Tabs, Tab } from "@mui/material";
import { Backdrop, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import WestIcon from "@mui/icons-material/West";
import colors from "assets/theme/base/colors";
import MDTypography from "components/MDTypography";
import TLinkApi from "services/tlink.api";
// import PanelHubApi from "../../../../services/panelhub.api";
// import TabsComponent from "../../../../components/Tabs/Tabs";
// import BackIcon from "../../../../assets/images/Back.png";

function AddPanelist() {
  const [isLoading, setIsLoading] = useState(false);
  let { textColor } = colors;
  const [value, setValue] = useState(0);

  const handleChange = (_e, newValue) => {
    setValue(newValue);
  };
  let { id } = useParams();
  let navigate = useNavigate();
  const location = useLocation();

  const [organizationSlug, setOrganizationSlug] = useState(localStorage.getItem("organizationSlug"));
  const [panelSlug, setPanelSlug] = useState(localStorage.getItem("panelSlug"));

  useEffect(() => {
    setOrganizationSlug(localStorage.getItem("organizationSlug"));
    setPanelSlug(localStorage.getItem("panelSlug"));
  }, []);

  const { setIsEdit, isEdit, panelist, setPanelist } = useOutletContext();

  useEffect(() => {
    if (window.location.pathname === `/panelist/${id}/profiling`) {
      setValue(1);
    } else if (window.location.pathname === `/panelist/${id}/address`) {
      setValue(2);
    } else if (window.location.pathname === `/panelist/${id}/points`) {
      setValue(3);
    } else if (window.location.pathname === `/panelist/${id}`) {
      setValue(0);
    } else if (window.location.pathname === `/panelist/new`) {
      setValue(0);
    }
    // eslint-disable-next-line
  }, [location]);

  const getData = async () => {
    if (id) {
      if (id !== "new") {
        setIsLoading(true);
        if (window.location.pathname === `/o/${organizationSlug}/panel/${panelSlug}/panelists/${id}/edit`) {
          setIsEdit({
            disabled: false,
            edit: true,
          });
        } else {
          setIsEdit({
            disabled: true,
            edit: false,
          });
        }
        try {
          const orgSlug = localStorage.getItem("organizationSlug");
          const panelSlug = localStorage.getItem("panelSlug");
          if (!orgSlug) {
            throw new Error("Organization slug is missing from local storage");
          }
          let result = await TLinkApi.get(
            `/o/${orgSlug}/panel/${panelSlug}/panelist/${id}`
          );
          setPanelist(result.data);
          setIsLoading(false);
        } catch (e) {
          console.log(e);
          setIsLoading(false);
        }
      }
    }
  };

  useEffect(() => {
    if (id) {
      getData();
    }
  }, [id]);


  return (
    <MDBox>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid item spacing={0} >
        <MDBox>
          <MDTypography sx={{ fontSize: "16px", fontWeight: "400", alignItems: "Center" }}>
            <IconButton
              onClick={() => {
                if (setIsEdit) {
                  setIsEdit({ disabled: false, edit: false });
                }
                setPanelist && setPanelist({});
                navigate("/panelist");
              }}
            >
              <WestIcon fontSize="small" color="dark" />
            </IconButton>
            {id ? panelist.firstName : "New Panel"}
          </MDTypography>
        </MDBox>
        <Tabs
          textColor="primary"
          indicatorColor="primary"
          value={value}
          onChange={handleChange}
          style={{ borderBottom: `1px solid ${textColor.focus}` }}
        >
          <Tab
            label="Basic Details"
            index={0}
            component={Link}
            to={id ? `/panelist/${id}` : `/panelist/new`}
          />
          <Tab
            label="Profiling"
            index={1}
            component={Link}
            disabled={!id}
            to={`/panelist/${id}/profiling`}
          />
          <Tab
            label="Address"
            index={2}
            component={Link}
            to={`/panelist/${id}/address`}
            disabled={!id}
          />
          <Tab
            label="Points"
            index={3}
            component={Link}
            to={`/panelist/${id}/points`}
            disabled={!id}
          />
        </Tabs>
        <Outlet context={{ setIsEdit, setPanelist,panelist, value, setValue }} />
        {/* <TabsComponent
          setValue={setStepPos}
          value={stepPos}
          labels={steps}
          tabpanels={
            <Outlet
              context={{
                stepPos,
                setStepPos,
                setIsEdit,
                isEdit,
                panelist,
                setPanelist,
                getData,
                isLoading,
                setIsLoading,
              }}
            />
          }
        /> */}
      </Grid>
    </MDBox>
  );
}

export default AddPanelist;
