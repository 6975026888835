import { Checkbox, Grid, Icon, IconButton, Stack, Tooltip } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TLinkApi from "services/tlink.api";
import UploadIcon from "@mui/icons-material/Upload";
import * as XLSX from "json-as-xlsx";

function QualificationChart(props) {
  let { enqueueSnackbar } = useSnackbar();
  const [editError, setEditError] = useState(false);
  let { id } = useParams();
  const [file, setFile] = useState("");
  const [zipCodeText, setZipCodeText] = useState("");

  const handleFile = async (value) => {
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data);
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const jsonData = XLSX.utils.sheet_to_json(worksheet, {
      header: 1,
      defval: "",
    });
    const zipCode = jsonData.join();
    setZipCodeText(zipCode);
  };

  useEffect(() => {
    if (file) {
      handleFile(file);
    }
  }, [file]);

  const editAns = async (value) => {
    if (!editError) {
      let qualifications = {};
      if (value.qualificationTypeId === 4) {
        let range = value.selectedRange?.map((value) => {
          return `${value.min}-${value.max}`;
        });
        qualifications = {
          qualificationId: value.qualificationId,
          answerText: range,
          qualificationTypeId: value.qualificationTypeId,
        };
      } else if (value.qualificationTypeId === 3) {
        qualifications = {
          qualificationId: value.qualificationId,
          answerText: [`${zipCodeText}`],
          // answerText: value?.text,
          qualificationTypeId: value.qualificationTypeId,
        };
      } else {
        qualifications = {
          qualificationId: value.qualificationId,
          answerId: value.answerId,
          qualificationTypeId: value.qualificationTypeId,
        };
      }

      try {
        const orgSlug = localStorage.getItem("organizationSlug");
        const panelSlug = localStorage.getItem("panelSlug");
        console.log(orgSlug, panelSlug);
        if (!orgSlug) {
          throw new Error("Organization slug is missing from local storage");
        }
        let result = await TLinkApi.put(
          `/o/${orgSlug}/panel/${panelSlug}/survey/qualification/conditions/${value.surveyQualificationId}`,
          {
            qualifications,
          }
        );
        enqueueSnackbar(result?.message, {
          variant: "success",
        });
        props.getQualificationQuestions();
        props.setI(null);
        props.setEdit(false);
        setFile("");
      } catch (e) {
        enqueueSnackbar(e?.response?.data.message, {
          variant: "error",
        });
      }
    }
  };

  useEffect(() => {
    if (
      props.qualificationQuestions[props.index]?.qualificationTypeId === 3 &&
      props.qualificationQuestions[props.index]?.text?.length > 0 &&
      props.qualificationQuestions[props.index]?.text[0] === ""
    ) {
      setEditError(true);
    } else if (
      props.qualificationQuestions[props.index]?.selectedRange?.length > 0 &&
      props.qualificationQuestions[props.index]?.qualificationTypeId === 4
    ) {
      let error = props.qualificationQuestions[props.index]?.selectedRange?.some(
        (val) =>
          val.min === "" ||
          val.max === "" ||
          Number(val.min) > Number(val.max) ||
          Number(val.min) < 10 ||
          Number(val.max) > 100
      );
      setEditError(error);
    } else if (
      props.val.answerId.length <= 0 &&
      (props.qualificationQuestions[props.index]?.qualificationTypeId === 1 ||
        props.qualificationQuestions[props.index]?.qualificationTypeId === 2)
    ) {
      setEditError(true);
    } else {
      setEditError(false);
    }

    // eslint-disable-next-line
  }, [props.qualificationQuestions]);

  return (
    <MDBox p={3} fontSize="14px" bgColor={!props.val.isActive ? "#f5f5f5" : "#E6F7FD"}>
      <Grid container spacing={2} style={{ paddingLeft: "10px" }}>
        <Grid item xs={12} md={12}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <MDTypography
              fontWeight="regular"
              color="dark"
              sx={{
                fontSize: "16px",
                fontWeight: 400,
                overflowWrap: "break-word",
                width: props?.edit ? "80%" : "100%",
              }}
            >
              {props?.val?.question?.questionText}
            </MDTypography>

            <MDBox display="flex" alignItems="center" gap="20px" flexDirection="row">
              {props.val?.qualificationCategoryTypeId == 1 && props.val?.qualificationTypeId == 3 && (
                <Grid item xs={12} display="flex" alignItems="center">
                  <>
                    <MDButton
                      variant="outlined"
                      color="dark"
                      fullWidth
                      size="small"
                      disabled={!props?.edit ? true : false}
                      sx={{
                        borderColor: "#d2d6da",
                        height: "36px",
                        borderRadius: "0rem",
                        display: !props?.edit ? "none" : "",
                        boxShadow: "none !important",
                        "&:focus:not(:hover)": {
                          boxShadow: "none !important",
                        },
                      }}
                      htmlFor="files"
                      onClick={() => {
                        document.getElementById("files").click();
                      }}
                    >
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between !important"
                        width="100%"
                      >
                        {/* <Tooltip title="csv, text or xls file is allowed to upload" placement="top"> */}
                          <span>{file !== "" ? file?.name : "Upload file"}</span>
                          <UploadIcon sx={{ marginLeft: "5px", fontSize: "1rem" }} />
                        {/* </Tooltip> */}
                      </Stack>
                    </MDButton>
                    <input
                      id="files"
                      onChange={(e) => {
                        setFile(e.target.files[0]);
                      }}
                      required
                      type="file"
                      style={{ display: "none" }}
                      label="File Upload"
                      autoComplete="off"
                      accept="text/plain, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      variant="outlined"
                    />
                  </>
                </Grid>
              )}

              <Stack
                direction="row"
                alignItems="center"
                spacing={2}
                sx={{ display: !props?.edit ? "none" : "" }}
              >
                <MDButton
                  variant="contained"
                  color="secondary"
                  size="small"
                  onClick={() => {
                    props.setEdit(false);
                    props.setI(null);
                    props.getQualificationQuestions();
                  }}
                >
                  cancel
                </MDButton>
                <MDButton
                  variant="contained"
                  color="info"
                  size="small"
                  disabled={!props?.edit ? true : false}
                  onClick={() => {
                    editAns(props.val);
                  }}
                >
                  Save
                </MDButton>
              </Stack>
            </MDBox>
          </Stack>

          {props.val?.qualificationTypeId === 1 || props.val?.qualificationTypeId === 2 ? (
            <Grid item xs={12} md={12} mt={2}>
              <MDBox
                display="flex"
                alignItems="center"
                gap="15px"
                flexWrap="wrap"
                paddingLeft="1rem"
              >
                <MDBox display="flex" alignItems="center" marginLeft="6px">
                  <MDBox
                    style={{
                      border: "1px solid #aaa",
                      borderRadius: "5px",
                      width: "18px",
                      height: "18px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: props?.edit ? "pointer" : "",
                    }}
                    onClick={() => {
                      if (props?.edit) {
                        if (props?.val?.answerId.length !== props?.val?.question?.answers.length) {
                          props.val.answerId = [];
                          props?.val?.question?.answers
                            .filter((option) => !props?.val?.answerId.includes(Number(option.id)))
                            ?.map((option, i) => {
                              props.val.answerId.push(parseInt(option.id));
                            });
                        } else {
                          props.val.answerId = [];
                        }
                        let payload = [...props.qualificationQuestions];
                        payload[props.index] = props.val;
                        props.setQualificationQuestions(payload);
                      }
                    }}
                  >
                    {props?.val?.answerId.length === props?.val?.question?.answers.length && (
                      <MDBox
                        bgColor="info"
                        style={{
                          borderRadius: "3px",
                          width: "10px",
                          height: "10px",
                        }}
                      ></MDBox>
                    )}
                  </MDBox>
                  &nbsp;&nbsp;Select All&nbsp;
                </MDBox>
                <Grid container spacing={0}>
                  {props.val?.question?.answers?.map((option, i) => {
                    return (
                      <Grid item xs={12} md={12} lg={6} key={i}>
                        <MDBox display="flex" alignItems="center">
                          <Checkbox
                            disabled={!props?.edit}
                            checked={props.val.answerId.includes(parseInt(option.id))}
                            onChange={(e) => {
                              let payload = [];
                              if (!e.target.checked) {
                                payload = [...props.qualificationQuestions];
                                payload[props.index].answerId = payload[
                                  props.index
                                ].answerId.filter((answer) => answer !== parseInt(option.id));
                              } else {
                                payload = [...props.qualificationQuestions];
                                payload[props.index].answerId.push(parseInt(option.id));
                              }
                              props.setQualificationQuestions(payload);
                            }}
                          />
                          <MDTypography
                            variant="button"
                            fontWeight="regular"
                            color="dark"
                            sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                          >
                            &nbsp;&nbsp;{option.qualificationAnswerDesc}&nbsp;
                          </MDTypography>
                        </MDBox>
                      </Grid>
                    );
                  })}
                  {props.val.answerId.length <= 0 ? (
                    <MDBox color="error" px={1} pt={2}>
                      Please select at least one field
                    </MDBox>
                  ) : (
                    ""
                  )}
                </Grid>
              </MDBox>
            </Grid>
          ) : props.val?.qualificationTypeId === 4 ? (
            <>
              {props?.val?.selectedRange?.map((rangeVal, i) => (
                <Grid container spacing={2} style={{ paddingLeft: "25px" }} mt={1} key={i}>
                  <Grid item xs={10} md={10}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={5}>
                        <MDInput
                          type="number"
                          autoComplete="off"
                          variant="outlined"
                          label="min"
                          fullWidth
                          required
                          disabled={!props?.edit}
                          value={rangeVal.min}
                          onChange={(e) => {
                            let payload = [...props.qualificationQuestions];
                            payload[props.index].selectedRange[i].min = e.target.value;
                            props.setQualificationQuestions(payload);
                          }}
                          error={
                            rangeVal.min === "" ? true : Number(rangeVal.min) < 10 ? true : false
                          }
                          helperText={
                            rangeVal.min === ""
                              ? "This field is Required"
                              : Number(rangeVal.min) < 10
                              ? "This value can not be less then 10"
                              : ""
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <MDInput
                          type="number"
                          fullWidth
                          required
                          disabled={!props?.edit}
                          value={rangeVal.max}
                          onChange={(e) => {
                            let payload = [...props.qualificationQuestions];
                            payload[props.index].selectedRange[i].max = e.target.value;
                            props.setQualificationQuestions(payload);
                          }}
                          autoComplete="off"
                          variant="outlined"
                          label="max"
                          error={
                            rangeVal.max === ""
                              ? true
                              : Number(rangeVal.min) > Number(rangeVal.max)
                              ? true
                              : Number(rangeVal.max) > 100
                              ? true
                              : false
                          }
                          helperText={
                            rangeVal.max === ""
                              ? "This field is Required"
                              : Number(rangeVal.max) > 100
                              ? "This value can not be greater than 100"
                              : Number(rangeVal.min) > Number(rangeVal.max)
                              ? "This is not be less then min"
                              : ""
                          }
                        />
                      </Grid>
                      {i !== 0 && (
                        <Grid item xs={12} md={2}>
                          <IconButton
                            disabled={!props?.edit}
                            onClick={() => {
                              let payload = [...props.qualificationQuestions];
                              payload[props.index].selectedRange.splice(i, 1);
                              props.setQualificationQuestions(payload);
                            }}
                          >
                            <Icon>delete</Icon>
                          </IconButton>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              ))}
              <MDBox display="flex" alignItems="center" justifyContent="flex-end" width="70%">
                <MDButton
                  variant="text"
                  color="dark"
                  disabled={!props?.edit}
                  onClick={() => {
                    let payload = [...props.qualificationQuestions];
                    payload[props.index].selectedRange.push({ min: 10, max: 100 });
                    props.setQualificationQuestions(payload);
                  }}
                >
                  + add more input
                </MDButton>
              </MDBox>
            </>
          ) : (
            <>
              <Grid container spacing={2} style={{ paddingLeft: "25px" }} mt={1}>
                <Grid item xs={10} md={10}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={5}>
                      <MDInput
                        type="text"
                        autoComplete="off"
                        variant="outlined"
                        label="Answer Text"
                        fullWidth
                        // required
                        disabled={!props?.edit}
                        value={props?.val?.text?.length > 0 ? props?.val?.text[0] : ""}
                        onChange={(e) => {
                          setZipCodeText(e.target.value);
                          let payload = [...props.qualificationQuestions];
                          payload[props.index].text[0] = e.target.value;
                          props.setQualificationQuestions(payload);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default QualificationChart;