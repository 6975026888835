import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, IconButton, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const TemplatePreviewPopup = ({ templateData, setTemplateData }) => {
  const handleClose = () => {
    setTemplateData(null);
  };
  return (
    <Dialog
      open={templateData ? true : false}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          color="black"
        >
          Template Preview ({templateData?.templateName})
          <IconButton size="small" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Stack>
        <hr style={{ marginTop: "0.2rem" }} />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Box
            dangerouslySetInnerHTML={{
              __html: templateData?.templateFormat,
            }}
            sx={{ height: "auto" }}
          ></Box>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default TemplatePreviewPopup;
