import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TLinkApi from "services/tlink.api";

const GetMemberList = () => {
  const [member, setMember] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [totalLength, setTotalLength] = useState(0);
  const [search, setSearch] = useState("");
  const [entriesPerPage, setEntriesPerPage] = useState(50);
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortBy, setSortBy] = useState("id");
  const [limit, setLimit] = useState(50); 
  const { id } = useParams();

  const getMember = async () => {
    if (search === "") {
      setIsLoading(true);
    }
    try {
      const orgSlug = localStorage.getItem("organizationSlug");
      if (!orgSlug) {
        throw new Error("Organization slug is missing from local storage");
      }
      let data = await TLinkApi.get(
        `/o/${orgSlug}/panel/${id}/members?search=${search}&sortOrder=${sortOrder}&sortBy=${sortBy}&page=${
          page + 1
        }&limit=${entriesPerPage}`
      );
      setMember(data?.data?.users);
      setTotalLength(data?.data?.totalLength);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    getMember();
  }, [page, search, entriesPerPage, sortBy, sortOrder]);

  return {
    member,
    getMember,
    isLoading,
    setIsLoading,
    totalLength,
    setPage,
    page,
    setSearch,
    search,
    setEntriesPerPage,
    entriesPerPage,
    sortBy,
    setSortBy,
    sortOrder,
    setSortOrder,
    limit,
    setLimit
  };
};

export default GetMemberList;
