import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";

const GetSurveyList = ( id ,clientId) => {
  const [surveys, setSurveys] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [totalLength, setTotalLength] = useState(0);
  const [filter, setFilter] = useState(2);
  const [entriesPerPage, setEntriesPerPage] = useState(50);
  const[clientFilter,setClientFilter]=useState(clientId)

  const getSurveys = async () => {
    if (search === "") {
      setIsLoading(true);
    }

    try {
      const orgSlug = localStorage.getItem("organizationSlug");
      const panelSlug = localStorage.getItem("panelSlug");
      console.log(orgSlug, panelSlug);
      if (!orgSlug) {
        throw new Error("Organization slug is missing from local storage");
      }
      let data = await TLinkApi.get(
        `/o/${orgSlug}/panel/${panelSlug}/survey?projectId=${id}&search=${search}&filter=${filter}&page=${page}&limit=${entriesPerPage}&clientFilter=${clientFilter}`
      );
      setSurveys(data.data.surveyData);
      setTotalLength(data.data.totalLenght);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    if (id !== "") {
      getSurveys();
    }
    // eslint-disable-next-line
  }, [id, page, search, filter,clientFilter,entriesPerPage]);
  return {
    surveys,
    getSurveys,
    isLoading,
    totalLength,
    setPage,
    page,
    setSearch,
    search,
    filter,
    setFilter,
    entriesPerPage,
    setEntriesPerPage,
    setClientFilter,
    clientFilter
  };
};

export default GetSurveyList;
