import { Download } from "@mui/icons-material";
import {
    Backdrop,
    Box,
    CircularProgress,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Switch,
    ThemeProvider,
    Tooltip,
    Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import SearchInput from "components/searchInput";
import useTableTheme from "constant/TableTheme";
import GetLanguageList from "hooks/LanguageList/GetLanguageList";
import GetListOfClients from "hooks/ListOfClients/GetListOfClients";
import GetProjectManagerList from "hooks/ProjectManagerList/GetProjectManagerList";
import GetSurveyBasicList from "hooks/SurveyBasicList/GetSurveyBasicList";
import GetSurveyStatusList from "hooks/SurveyStatusList/GetSurveyStatusList";
import csvDownload from "json-to-csv-export";
import {
    MaterialReactTable,
    useMaterialReactTable,
} from "material-react-table";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";

function SurveyList({ projectId }) {
  const navigate = useNavigate();
  const {
    surveys,
    isLoading,
    totalLength,
    setPage,
    page,
    setSearch,
    search,
    setFilter,
    setClientFilter,
    clientFilter,
    setMarketFilter,
    marketFilter,
    setProjectManagerFilter,
    projectManagerFilter,
    manualFilter,
    setManualFilter,
    filter,
    entriesPerPage,
    setEntriesPerPage,
    setSortOrder,
    setSortBy,
    sortBy,
    sortOrder,
    setIsLoading,
  } = GetSurveyBasicList("2", -1, -1, -1);
  const { setSurvey, setIsEdit } = useOutletContext();
  const { clients } = GetListOfClients();
  const { language } = GetLanguageList();
  const { projectManager } = GetProjectManagerList();
  const { surveyStatusList } = GetSurveyStatusList();

  let rows = surveys;

  const [tempMarketFilter, setTempMarketFilter] = useState(marketFilter || []);
  const [tempClientFilter, setTempClientFilter] = useState(clientFilter || []);
  const [tempProjectManagerFilter, setTempProjectManagerFilter] = useState(projectManagerFilter || []);
  const [tempStatusFilter, setTempStatusFilter] = useState(filter  || []);

  console.log(tempStatusFilter,"aaaa")
  const applyFilters = () => {
    setMarketFilter(tempMarketFilter);
    setClientFilter(tempClientFilter);
    setProjectManagerFilter(tempProjectManagerFilter);
    setFilter(tempStatusFilter);
  };


  const [sorting, setSorting] = useState([]);
  const tableTheme = useTableTheme();
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 50,
    pageSize: 50,
  });

  useEffect(() => {
    setData(surveys);
    setPage(pagination.pageIndex);
    setEntriesPerPage(pagination.pageSize);

    if (sorting.length === 1) {
      if (sorting[0].desc === false) {
        setSortBy(sorting[0].id);
        setSortOrder("asc");
      } else {
        setSortBy(sorting[0].id);
        setSortOrder("desc");
      }
    } else if (sorting.length === 0) {
      setSortBy("id");
      setSortOrder("asc");
    }
  }, [surveys, pagination, sorting]);

  let columns = [
    {
      accessorKey: "id",
      header: "Id",
      size: 50,
      Cell: ({ cell }) => {
        const id = cell.getValue();
        return id ? (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              setIsEdit({
                disabled: true,
                edit: false,
              });
              navigate(`/surveys/${cell.getValue()}`);
            }}
          >
            {cell.getValue()}
          </div>
        ) : null;
      },
    },
    {
      accessorKey: "surveyName",
      header: "Survey Name",
      size: 50,
      enableSorting: false,
      headerAlign: "center",
    },
    {
      accessorKey: "buyerName",
      header: "Buyer Name",
      size: 50,
      align: "center",
      headerAlign: "center",
      // Cell: ({ row }) => {
      //   const name = row?.values?.buyerName || "";
      //   const truncatedName = name.length > 7 ? name.substring(0, 7) + "..." : name;
      //   return (
      //     <Tooltip title={name}>
      //       <div>{truncatedName}</div>
      //     </Tooltip>
      //   );
      // },
    },

    {
      accessorKey: "sampleRequired",
      header: "Samp. Req.",
      align: "center",
      size: 50,
      headerAlign: "center",
    },
    {
      accessorKey: "revenue",
      header: "Rev.",
      align: "center",
      size: 50,
      headerAlign: "center",
      // Cell: ({ row }) => {
      //   const revenue = parseFloat(row?.values?.revenue);
      //   const roundedRevenue = Math.round(revenue * 100) / 100;
      //   return roundedRevenue.toFixed(2);
      // },
    },
    {
      accessorKey: "loi",
      header: "LOI",
      align: "center",
      size: 50,
      headerAlign: "center",
    },

    {
      accessorKey: "ir",
      header: "IR",
      align: "center",
      size: 50,
      headerAlign: "center",
    },
    {
      accessorKey: "cpi",
      header: "CPI",
      size: 50,
      align: "center",
      headerAlign: "center",
      // Cell: ({ row }) => {
      //   const cpi = parseFloat(row?.values?.cpi);
      //   const roundedCpi = Math.round(cpi * 100) / 100;
      //   return roundedCpi.toFixed(2);
      // },
    },

    {
      accessorKey: "starts",
      header: "Starts",
      align: "center",
      size: 50,
      headerAlign: "center",
    },

    {
      accessorKey: "completes",
      header: "Completes",
      align: "center",
      size: 50,
      headerAlign: "center",
    },
    {
      accessorKey: "epc",
      header: "EPC",
      align: "center",
      size: 50,
      headerAlign: "center",
      // Cell: ({ row }) => {
      //   return parseFloat(
      //     row?.original?.starts <= 0 ? 0 : row.original.revenue / row?.original?.starts
      //   ).toFixed(2);
      // },
    },
    {
      accessorKey: "conversion",
      header: "Conv(%)",
      align: "center",
      size: 50,
      headerAlign: "center",
      // Cell: ({ row }) => {
      //   return (
      //     <div style={{ width: "20px" }}>
      //       {parseFloat(
      //         row?.original?.starts <= 0 ? 0 : row.original.completes / row?.original?.starts
      //       ).toFixed(2)}
      //     </div>
      //   );
      // },
    },
    {
      accessorKey: "clientOverallEpc",
      header: "Cli. Epc",
      align: "center",
      size: 50,
      headerAlign: "center",
      // Cell: ({ row }) => {
      //   const epc = row?.original?.clientOverallEpc;
      //   return epc !== null && epc !== undefined ? parseFloat(epc).toFixed(2) : "NA";
      // },
    },
    {
      accessorKey: "clientOverallConversion",
      header: "Cli. conv.",
      align: "center",
      size: 50,
      headerAlign: "center",
      // Cell: ({ row }) => {
      //   const conversion = row?.original?.clientOverallConversion;
      //   return conversion !== null && conversion !== undefined
      //     ? parseFloat(conversion).toFixed(2)
      //     : "NA";
      // },
    },
    {
      accessorKey: "countryCode",
      header: "Country",
      align: "center",
      size: 50,
      headerAlign: "center",
    },
    {
      accessorKey: "createdAt",
      header: "Created at",
      size: 50,
      headerAlign: "center",
      Cell: ({ cell }) => {
        if (!cell.getValue()) {
          return <div>NA</div>;
        } else {
          const createdAtTime = moment(cell.getValue());
          const formattedDateTime = createdAtTime.format(
            "Do MMM YYYY, h:mm:ss a"
          );
          const truncatedName =
            formattedDateTime?.length > 8
              ? formattedDateTime?.substring(0, 8) + "..."
              : formattedDateTime;
          return data ? (
            <Tooltip
              title={moment(cell.getValue()).format("YYYY-MM-DD, h:mm:ss a")}
            >
              <div>{moment(cell.getValue()).format("YYYY-MM-DD")}</div>
            </Tooltip>
          ) : null;
        }
      },
    },

    {
      accessorKey: "lastCompleted",
      header: "Last Complete",
      size: 50,
      headerAlign: "center",
      Cell: ({ cell }) => {
        if (!cell.getValue()) {
          return <div>NA</div>;
        } else {
          const lastCompletedTime = moment(cell.getValue());
          const formattedDateTime = lastCompletedTime.format(
            "Do MMM YYYY, h:mm:ss a"
          );
          const truncatedName =
            formattedDateTime?.length > 8
              ? formattedDateTime?.substring(0, 8) + "..."
              : formattedDateTime;
          return data ? (
            <Tooltip
              title={moment(cell.getValue()).format("YYYY-MM-DD, h:mm:ss a")}
            >
              <div>{moment(cell.getValue()).format("YYYY-MM-DD")}</div>
            </Tooltip>
          ) : null;
        }
      },
    },

    {
      accessorKey: "surveyStatusName",
      header: "Status",
      align: "center",
      size: 50,
      headerAlign: "center",
    },
  ];

  const table = useMaterialReactTable({
    columns,
    data,
    enableStickyHeader: true,
    manualPagination: true,
    rowCount: totalLength,
    onPaginationChange: setPagination,
    state: { density: "compact", pagination, sorting },
    enableColumnPinning: true,
    enableGlobalFilter: false,
    enableDensityToggle: false,
    manualSorting: true,
    muiPaginationProps: {
      rowsPerPageOptions: [25, 50, 100],
    },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        navigate(`/surveys/${row.original.id}`);
      },
      sx: {
        cursor: "pointer",
      },
    }),
    onSortingChange: setSorting,
    muiTableContainerProps: {
      sx: {
        minHeight: "calc(100vh - 340px)",
        maxHeight: "calc(100vh - 350px)",
        overflowY: "auto",
      },
    },
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        width: "100%",
        borderRadius: "0",
        backgroundColor: "#fff !important",
        zIndex: 500,
      },
    },
    renderEmptyRowsFallback: () => (
      <Stack
        display="flex"
        alignItems="center"
        justifyContent="center"
        fontSize="14px"
        p={5}
      >
        No records to display
      </Stack>
    ),
  });

  return (
    <MDBox width="100%">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* <MDBox>
        <MDTypography color="textColor" ml="1%">
          Surveys
        </MDTypography>
      </MDBox> */}
      {/* <MDBox display="flex" justifyContent="center"> */}
      <MDBox p={2} my={3} borderRadius="5px" bgColor="white" width="100%">
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography variant="body1" color="GrayText" marginBottom={2}>
            Survey List
          </Typography>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Switch
                size="large"
            // variant="contained"
              color="info"
              checked={manualFilter}
              onChange={(e) => {
                setManualFilter(!manualFilter);
              }}
            />
            <Typography variant="body2" color="GrayText">Manual</Typography>
          </Box>
        </Box>
        <Grid container spacing={2}>
          <Grid
            px={2}
            item
            xs={12}
            md={12}
            display="flex"
            justifyContent="flex-end"
            flexDirection="row"
            sx={{
              flexDirection: { xs: "column", md: "row" },
              alignItems: { xs: "flex-end", md: "center" },
            }}
            gap="15px"
          >
            <Grid item xs={12} md={2}>
              <FormControl fullWidth>
                <InputLabel>Markets</InputLabel>
                <Select
                multiple
                  value={tempMarketFilter}
                  label="Markets"
                  onChange={(e) => {
                    setTempMarketFilter(e.target.value);
                  }}
                >
                  <MenuItem value={-1}>All</MenuItem>
                  {language?.map((value, i) => (
                    <MenuItem value={value.id} key={i}>
                      {value.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={2}>
              <FormControl fullWidth>
                <InputLabel>Clients</InputLabel>
                <Select
                multiple
                  value={tempClientFilter}
                  label="Clients"
                  onChange={(e) => {
                    setTempClientFilter(e.target.value);
                  }}
                >
                  <MenuItem value={-1}>All</MenuItem>
                  {clients?.map((value, i) => (
                    <MenuItem value={value.id} key={i}>
                      {value.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={2}>
              <FormControl fullWidth>
                <InputLabel>Project Manager</InputLabel>
                <Select
                multiple 
                  value={tempProjectManagerFilter}
                  label="Project Manage"
                  onChange={(e) => {
                    setTempProjectManagerFilter(e.target.value);
                  }}
                >
                  <MenuItem value={-1}>All</MenuItem>
                  {projectManager?.map((value, i) => (
                    <MenuItem value={value.id} key={i}>
                      {value.fullName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={2}>
              <FormControl fullWidth>
                <InputLabel>Status</InputLabel>
                <Select
                multiple
                  value={tempStatusFilter}
                  label="Status"
                  onChange={(e) => {
                    setTempStatusFilter(e.target.value);
                  }}
                >
                  <MenuItem value={-1}>All</MenuItem>
                  {surveyStatusList?.map((value, i) => {
                    return (
                      <MenuItem key={i} value={value.id}>
                        {value.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <MDButton variant="contained" size="small" color="info" onClick={applyFilters}>
              Apply
            </MDButton>
            <SearchInput setSearch={setSearch} search={search} />
            <Tooltip title="Download">
              <MDButton
                variant="contained"
                color="info"
                size="small"
                onClick={() => {
                  const dataToConvert = {
                    data: surveys,
                    filename: `SurveyPage${page}`,
                    delimiter: ",",
                  };
                  csvDownload(dataToConvert);
                }}
              >
                <Download />
              </MDButton>
            </Tooltip>
            <MDButton
              variant="contained"
              color="info"
              size="small"
              onClick={() => {
                navigate("/surveys/new");
                setSurvey({});
                setIsEdit({
                  disabled: false,
                  edit: false,
                });
              }}
            >
              +Survey
            </MDButton>
          </Grid>
        </Grid>
        <ThemeProvider theme={tableTheme}>
          <MaterialReactTable
            table={table}
            filterVariant="multi-select"
            style={{
              width: "100%",
            }}
          />
        </ThemeProvider>
      </MDBox>
      {/* </MDBox> */}
    </MDBox>
  );
}

export default SurveyList;
