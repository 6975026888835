import { Outlet } from "react-router-dom";
import React, { useState } from "react";

function Panels() {
  const [panel, setPanel] = useState({});
  const [isEdit, setIsEdit] = useState({
    disabled: false,
    edit: false,
  });
  return <Outlet context={{ setIsEdit, isEdit, panel, setPanel }} />;
}

export default Panels;
