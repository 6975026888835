import { IconButton, Tab, Tabs } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Link, useNavigate, useOutletContext, useParams, Outlet } from "react-router-dom";
import WestIcon from "@mui/icons-material/West";
import colors from "assets/theme/base/colors";
import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";

const AddPanels = () => {
  const navigate = useNavigate();
  let { textColor } = colors;
  const [value, setValue] = useState(0);

  const handleChange = (_e, newValue) => {
    setValue(newValue);
  };

  // Corrected the usage of useOutletContext
  const { panel,setPanel, setIsEdit, isEdit } = useOutletContext();
  const [isLoading, setIsLoading] = useState(false);
  const [organizationSlug, setOrganizationSlug] = useState(
    localStorage.getItem("organizationSlug")
  );
  let { id } = useParams();
  const getData = async () => {
    if (id) {
      if (id !== "new") {
        setIsLoading(true);
        if (
          window.location.pathname ===
          `/o/${organizationSlug}/panels/${id}/edit`
        ) {
          setIsEdit({
            disabled: false,
            edit: true,
          });
        } else {
          setIsEdit({
            disabled: true,
            edit: false,
          });
        }
        try {
          const orgSlug = localStorage.getItem("organizationSlug");
          if (!orgSlug) {
            throw new Error("Organization slug is missing from local storage");
          }
          let result = await TLinkApi.get(`/o/${orgSlug}/panel/${id}`);
          setPanel(result.data);
          setIsLoading(false);
        } catch (e) {
          console.log(e);
          setIsLoading(false);
        }
      }
    }
  };

  useEffect(() => {
    if (id) {
      getData();
    }
  }, [id]);
  useEffect(() => {
    if (window.location.pathname === `/panels/${id}/UIConfiguration`) {
      setValue(1);
    } else if (window.location.pathname === `/panels/${id}/members`) {
      setValue(2);
    } else if (window.location.pathname === `/panels/${id}/markets`) {
      setValue(3);
    } else if (window.location.pathname === `/panels/${id}/points`) {
      setValue(4);
    } else if (window.location.pathname === `/panels/${id}/edit`) {
      setValue(0);
    } else if (window.location.pathname === `/panels/${id}`) {
      setValue(0);
    } else if (window.location.pathname === `/panels/new`) {
      setValue(0);
    }
    // eslint-disable-next-line
  }, [location]);

  return (
    <MDBox>
      <MDBox
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <MDBox>
          <MDTypography
            mb={1}
            sx={{ fontSize: "16px", fontWeight: "400", alignItems: "Center" }}
          >
            <IconButton
              onClick={() => {
                if (setIsEdit) {
                  setIsEdit({ disabled: false, edit: false });
                }
                setPanel && setPanel({});
                navigate("/panels");
              }}
            >
              <WestIcon fontSize="small" color="dark" />
            </IconButton>
            {id ? panel.name : "New Panel"}
          </MDTypography>
        </MDBox>
        <MDBox></MDBox>
      </MDBox>
      <Tabs
        textColor="primary"
        indicatorColor="primary"
        value={value}
        onChange={handleChange} // Fixed
        style={{ borderBottom: `1px solid ${textColor.focus}` }}
      >
        <Tab
          label="Basic Details"
          index={0}
          component={Link}
          to={id ? `/panels/${id}/edit` : `/panels/new`}
        />
        <Tab
          label="UI Configuration"
          index={1}
          component={Link}
          disabled={!id}
          to={`/panels/${id}/UIConfiguration`}
        />
        <Tab
          label="Members"
          index={2}
          component={Link}
          to={`/panels/${id}/members`}
          disabled={!id}
        />
        <Tab
          label="Markets"
          index={3}
          component={Link}
          to={`/panels/${id}/markets`}
          disabled={!id}
        />
        <Tab
          label="Points"
          index={3}
          component={Link}
          to={`/panels/${id}/points`}
          disabled={!id}
        />
      </Tabs>
      <Outlet context={{ setIsEdit, isEdit, setPanel,panel, value, setValue, getData }} />
    </MDBox>
  );
};

export default AddPanels;
