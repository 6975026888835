import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import MDInput from "components/MDInput";
import TLinkApi from "services/tlink.api";
import { useParams } from "react-router-dom";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import dayjs from "dayjs";
import { DateField, TimeField } from "@mui/x-date-pickers";
import MDTypography from "components/MDTypography";
import { useSnackbar } from "notistack";
import Papa from "papaparse";
import utc from "dayjs/plugin/utc";

const EmailBatchCreatePopup = ({
  addBatch,
  setAddBatch,
  fetchAllEmailBatch,
  fetchEmailBatchCount,
}) => {
  const [type, setType] = useState(1);
  const [panelListId, setPanelListId] = useState([]);
  const [search, setSearch] = useState("");
  const [panelList, setPanelist] = useState([]);
  const [templateId, setTemplateId] = useState("");
  const [templateList, setTemplateList] = useState([]);
  const [step, setStep] = useState(1);
  const [pasteList, setPasteList] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPanelist, setSelectedPanelist] = useState([]);
  const [templateData, setTemplateData] = useState(null);
  dayjs.extend(utc);

  let { enqueueSnackbar } = useSnackbar();

  const currentDateTime = new Date();
  const formattedDateTime =
    currentDateTime.getFullYear() +
    "-" +
    String(currentDateTime.getMonth() + 1).padStart(2, "0") +
    "-" +
    String(currentDateTime.getDate()).padStart(2, "0") +
    "T" +
    String(currentDateTime.getHours() + 1).padStart(2, "0") +
    ":" +
    String(currentDateTime.getMinutes()).padStart(2, "0");
  const [scheduleDateAndTime, setScheduleDateAndTime] = useState(
    dayjs(formattedDateTime)
  );

  let { id } = useParams();

  const resetState = () => {
    setType(1);
    setStep(1);
    setPanelListId([]);
    setPasteList("");
    setTemplateId("");
    setScheduleDateAndTime(dayjs(formattedDateTime));
    setSelectedPanelist([]);
    setSearch("");
    setError("");
  };

  const handleClose = () => {
    setAddBatch(false);
    resetState();
  };
  const orgSlug = localStorage.getItem("organizationSlug");
  const panelSlug = localStorage.getItem("panelSlug");

  const fetchPanelList = async () => {
    try {
      if (!orgSlug || !panelSlug) {
        throw new Error("Organization slug is missing from local storage");
      }
      const response = await TLinkApi.get(`master/list/o/${orgSlug}/panel/${panelSlug}/survey/${id}/panelist`);
      setPanelist(
        response?.data && Array.isArray(response?.data) ? response.data : []
      );
    } catch (error) {
      console.log("Error fetching panelists from API:", error);
    }
  };

  const fetchTemplateList = async () => {
    try {
      if (!orgSlug || !panelSlug) {
        throw new Error("Organization slug is missing from local storage");
      }
      const response = await TLinkApi.get(`o/${orgSlug}/panel/${panelSlug}/email/templates`);
      setTemplateList(
        response?.data?.panelEmailTemplates
          ? response.data.panelEmailTemplates
          : []
      );
    } catch (error) {
      console.log("Error fetching panelists from API:", error);
    }
  };

  useEffect(() => {
    if (addBatch) {
      fetchPanelList();
      fetchTemplateList();
    }
  }, [addBatch]);

  const handleSubmit = async () => {
    setIsLoading(true);
    let payload = {
      templateId: templateId,
      panelistIds:
        type === 1
          ? selectedPanelist
            .filter((item) => !isNaN(item) && item !== null && item !== "")
            .map((item) => Number(item))
          : type === 2
            ? panelListId
            : pasteList
              .split(/[\n]+/)
              .filter((item) => !isNaN(item) && item !== null && item !== "")
              .map((item) => Number(item)),
      scheduleDateAndTime: dayjs(scheduleDateAndTime).utc().format(),
    };
    try {
      let result = await TLinkApi.post(`/o/${orgSlug}/panel/${panelSlug}/survey/${id}/email/batches`, payload);
      await fetchAllEmailBatch();
      await fetchEmailBatchCount();
      setIsLoading(false);
      handleClose();
      enqueueSnackbar(result?.result?.userMessage, {
        variant: "success",
      });
    } catch (e) {
      enqueueSnackbar(e?.response.data.result.userMessage, {
        variant: "error",
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (
      type === 3 &&
      pasteList
        .split(/[\n]+/)
        .find((item) => isNaN(item) || item === null || item === "")
    ) {
      setError("Only number are allowed");
    } else {
      setError("");
    }
  }, [pasteList]);

  useEffect(() => {
    if (step === 3) {
      if (!dayjs(scheduleDateAndTime).isValid()) {
        setError("Selected time not valid");
      } else if (scheduleDateAndTime < new Date()) {
        setError("Selected time cannot be earlier than the current time.");
      } else {
        setError("");
      }
    } else {
      setError("");
    }
  }, [scheduleDateAndTime]);

  const handleFileUpload = (file) => {
    if (!file) return;
    if (file.type !== "text/csv") {
      alert("Please upload a valid CSV file.");
      return;
    }
    Papa.parse(file, {
      header: false,
      skipEmptyLines: true,
      complete: (result) => {
        const data = result.data;
        const isHeader = data[0] && isNaN(data[0][0]);
        if (isHeader) {
          Papa.parse(file, {
            header: true,
            skipEmptyLines: true,
            complete: (result) => {
              const data = result.data;
              const headers = result.meta.fields[0];
              console.log("Parsed CSV Data with Header:", data);
              const panelistIds = data
                .filter((row) => row[headers])
                .map((row) => row[headers]);
              if (panelistIds.length > 0) {
                setSelectedPanelist(panelistIds);
                setError("");
              } else {
                setError("CSV format is incorrect or Panelist IDs are missing");
                enqueueSnackbar(
                  "CSV format is incorrect or Panelist IDs are missing.",
                  {
                    variant: "error",
                  }
                );
              }
            },
            error: (error) => {
              setError("Error reading the CSV file.");
              console.error("Error parsing CSV with header:", error);
              enqueueSnackbar("Error reading the CSV file.", {
                variant: "error",
              });
            },
          });
        } else {
          const panelistIds = data.filter((row) => row[0]).map((row) => row[0]);
          if (panelistIds.length > 0) {
            setSelectedPanelist(panelistIds);
            setError("");
          } else {
            setError("CSV format is incorrect or Panelist IDs are missing.");
            enqueueSnackbar(
              "CSV format is incorrect or Panelist IDs are missing.",
              {
                variant: "error",
              }
            );
          }
        }
      },
      error: (error) => {
        setError("Error reading the CSV file.");
        console.error("Error parsing CSV:", error);
        enqueueSnackbar("Error reading the CSV file.", {
          variant: "error",
        });
      },
    });
  };

  const GetTemplateData = async () => {
    setIsLoading(true);
    try {
      if (!orgSlug || !panelSlug) {
        throw new Error("Organization slug is missing from local storage");
      }
      const result = await TLinkApi.get(`o/${orgSlug}/panel/${panelSlug}/email/template/${templateId}`);
      if (result) {
        setTemplateData(result.data);
      }
      setIsLoading(false);
      enqueueSnackbar(result?.result?.userMessage, {
        variant: "success",
      });
    } catch (e) {
      console.log(e);
      enqueueSnackbar(e?.response?.data?.result?.userMessage, {
        variant: "error",
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (templateId && step === 4) {
      GetTemplateData();
    }
  }, [templateId, step]);

  return (
    <Dialog open={addBatch} maxWidth={step === 4 ? "md" : "sm"} fullWidth>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <DialogTitle id="alert-dialog-title">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          color="black"
        >
          {step === 1
            ? "Generate Email batch (Choose Options)"
            : step === 2
              ? "Template"
              : step === 3
                ? "Select Date and Time"
                : "Preview"}
          <IconButton size="small" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Stack>
        <hr style={{ marginTop: "0.2rem" }} />
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {step === 1 ? (
            <Stack spacing={2}>
              <Stack direction="row" spacing={1}>
                <Button
                  variant={type === 1 ? "contained" : "outlined"}
                  color="secondary"
                  sx={{ color: type === 1 ? "#fff" : "#FB7D5B" }}
                  size="small"
                  onClick={() => {
                    setType(1);
                    setPanelListId([]);
                    setPasteList("");
                  }}
                  autoFocus
                >
                  CSV
                </Button>
                <Button
                  variant={type === 2 ? "contained" : "outlined"}
                  color="secondary"
                  sx={{ color: type === 2 ? "#fff" : "#FB7D5B" }}
                  size="small"
                  onClick={() => {
                    setType(2);
                    setSelectedPanelist([]);
                    setPasteList("");
                  }}
                  autoFocus
                >
                  DropDown
                </Button>
                <Button
                  variant={type === 3 ? "contained" : "outlined"}
                  color="secondary"
                  sx={{ color: type === 3 ? "#fff" : "#FB7D5B" }}
                  size="small"
                  onClick={() => {
                    setType(3);
                    setSelectedPanelist([]);
                    setPanelListId([]);
                  }}
                  autoFocus
                >
                  MultiSelect
                </Button>
              </Stack>
              {type === 1 ? (
                <TextField
                  fullWidth
                  margin="normal"
                  type="file"
                  inputProps={{
                    accept: ".csv",
                  }}
                  onChange={(e) => handleFileUpload(e.target.files[0])}
                />
              ) : type === 2 ? (
                <FormControl
                  fullWidth
                  required
                  sx={{
                    label: {
                      color: "black",
                      fontWeight: "400",
                    },
                  }}
                  size="small"
                >
                  <InputLabel>Panelist</InputLabel>
                  <Select
                    multiple
                    value={panelListId}
                    onChange={(e) => {
                      setPanelListId(e.target.value);
                    }}
                    label="Panelist"
                    onClose={() => setSearch("")}
                  >
                    <ListSubheader sx={{ marginBottom: "1rem" }}>
                      <MDInput
                        sx={{
                          position: "sticky",
                          marginTop: "-16px",
                          paddingTop: "1rem",
                          zIndex: 1,
                          background: "white",
                        }}
                        size="small"
                        autoFocus
                        required
                        value={search}
                        placeholder="Type to search..."
                        fullWidth
                        onChange={(e) => setSearch(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key !== "Escape") {
                            e.stopPropagation();
                          }
                        }}
                      />
                    </ListSubheader>
                    {panelList
                      .filter((data) => {
                        return data.name
                          .toLowerCase()
                          .includes(search.toLowerCase());
                      })
                      ?.map((value, i) => {
                        return (
                          <MenuItem value={value.id} key={i}>
                            {value.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              ) : (
                <Stack spacing={1}>
                  <TextField
                    autoFocus={true}
                    label="Options"
                    type="text"
                    fullWidth
                    variant="outlined"
                    multiline
                    value={pasteList}
                    rows={10}
                    onChange={(e) => {
                      setPasteList(e.target.value);
                    }}
                  />
                  {error && (
                    <p style={{ color: "red", fontSize: "12px" }}>{error}</p>
                  )}
                </Stack>
              )}
            </Stack>
          ) : step === 2 ? (
            <FormControl
              fullWidth
              required
              sx={{
                label: {
                  color: "rgba(0, 0, 0, 0.25)",
                  fontWeight: "400",
                },
              }}
              size="small"
            >
              <Select
                value={templateId}
                onChange={(e) => {
                  setTemplateId(e.target.value);
                }}
                placeholder="Panelist"
                onClose={() => setSearch("")}
              >
                <ListSubheader sx={{ marginBottom: "1rem" }}>
                  <MDInput
                    sx={{
                      position: "sticky",
                      marginTop: "-16px",
                      paddingTop: "1rem",
                      zIndex: 1,
                      background: "white",
                    }}
                    size="small"
                    autoFocus
                    required
                    value={search}
                    placeholder="Type to search..."
                    fullWidth
                    onChange={(e) => setSearch(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key !== "Escape") {
                        e.stopPropagation();
                      }
                    }}
                  />
                </ListSubheader>
                {templateList
                  .filter((data) => {
                    return data?.templateName
                      ?.toLowerCase()
                      .includes(search.toLowerCase());
                  })
                  ?.map((value, i) => {
                    return (
                      <MenuItem value={value.id} key={i}>
                        {value.templateName}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          ) : step === 3 ? (
            <Grid container spacing={2}>
              <Grid item xs={7}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateCalendar
                    value={scheduleDateAndTime}
                    onChange={(newValue) => {
                      setScheduleDateAndTime(newValue);
                    }}
                    minDate={dayjs(new Date())}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={5}>
                <Stack spacing={2} mt={3}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateField
                      value={scheduleDateAndTime}
                      onChange={(newValue) => {
                        setScheduleDateAndTime(newValue);
                      }}
                      disabled
                    />
                    <TimeField
                      value={scheduleDateAndTime}
                      onChange={(newValue) => {
                        setScheduleDateAndTime(newValue);
                      }}
                    />
                    {error && (
                      <p style={{ color: "red", fontSize: "12px" }}>{error}</p>
                    )}
                  </LocalizationProvider>
                </Stack>
              </Grid>
            </Grid>
          ) : (
            <Stack spacing={1}>
              <Stack direction="row" alignItems="center" spacing={2}>
                <MDTypography variant="button" sx={{ fontWeight: 600 }}>
                  Total Emails :
                </MDTypography>
                <MDTypography variant="button" sx={{ fontWeight: 900 }}>
                  {type === 1
                    ? selectedPanelist.length
                    : type == 2
                      ? panelListId.length
                      : pasteList
                        .split(/[\n]+/)
                        .filter(
                          (item) => !isNaN(item) && item !== null && item !== ""
                        ).length}
                </MDTypography>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={2}>
                <MDTypography variant="button" sx={{ fontWeight: 600 }}>
                  Schedule Time :
                </MDTypography>
                <MDTypography variant="button" sx={{ fontWeight: 900 }}>
                  {new Intl.DateTimeFormat("en-US", {
                    year: "numeric",
                    month: "long", // You can use "short" or "numeric" for different formats
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true, // 12-hour format
                  }).format(new Date(scheduleDateAndTime))}
                </MDTypography>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={2}>
                <MDTypography variant="button" sx={{ fontWeight: 600 }}>
                  Template Name :
                </MDTypography>
                <MDTypography variant="button" sx={{ fontWeight: 900 }}>
                  {
                    templateList.find((val) => val.id === templateId)
                      ?.templateName
                  }
                </MDTypography>
              </Stack>
              <Stack pt={1}>
                <Box
                  dangerouslySetInnerHTML={{
                    __html: templateData?.templateFormat,
                  }}
                  sx={{ height: "auto" }}
                ></Box>
              </Stack>
            </Stack>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          sx={{ color: "#FFFFFF", mb: 1 }}
          disabled={
            error
              ? true
              : step === 1
                ? type === 1 && selectedPanelist.length <= 0
                  ? true
                  : type === 2 && panelListId.length <= 0
                    ? true
                    : type === 3 && pasteList.replace(/\s/g, "").length <= 0
                      ? true
                      : false
                : !templateId
                  ? true
                  : false
          }
          size="small"
          onClick={() => {
            if (step === 4) {
              handleSubmit();
              setError("");
            } else {
              setStep(step + 1);
              setSearch("");
              setError("");
            }
          }}
          autoFocus
        >
          {step !== 4 ? "Next" : "Send"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EmailBatchCreatePopup;
