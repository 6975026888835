import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";

const GetSurveyReport = ({ id }) => {
  const [surveyReport, SetSurveyReport] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [totalLength, setTotalLength] = useState(0);
  const [entriesPerPage, setEntriesPerPage] = useState(50);
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortBy, setSortBy] = useState("id");

  const getSurveyReport = async () => {
    setIsLoading(true);
    try {
      const orgSlug = localStorage.getItem("organizationSlug");
      const panelSlug = localStorage.getItem("panelSlug");
      console.log(orgSlug, panelSlug);
      if (!orgSlug) {
        throw new Error("Organization slug is missing from local storage");
      }
      let data = await TLinkApi.get(`/o/${orgSlug}/panel/${panelSlug}/survey/${id}/survey-report`);
      SetSurveyReport(data.data);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getSurveyReport();
    // eslint-disable-next-line
  }, [id]);

  return {
    getSurveyReport,
    surveyReport,
    isLoading,
    totalLength,
    setPage,
    page,
    setSearch,
    search,
    sortOrder,
    setSortOrder,
    sortBy,
    setSortBy,
    setEntriesPerPage,
    entriesPerPage,
  };
};

export default GetSurveyReport;
