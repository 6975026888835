import {
  Stack,
  Typography,
  Tooltip,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import colors from "assets/theme/base/colors";
import MDBox from "components/MDBox";
import React, { useState, useEffect } from "react";
import MDTypography from "components/MDTypography";
import { DeleteOutline } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import TLinkApi from "services/tlink.api";

const TemplateList = ({ templates, GetTemplateList, setTemplatesData }) => {
  const { secondary, primary } = colors;
  let { id } = useParams();
  let { enqueueSnackbar } = useSnackbar();
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const orgSlug = localStorage.getItem("organizationSlug");
  const panelSlug = localStorage.getItem("panelSlug");
  const handleDeleteClick = () => {
    setShowDeleteDialog(true);
  };
  const confirmDelete = async () => {
    setIsLoading(true);
    setShowDeleteDialog(false); // Close dialog on confirm
    try {
      if (!orgSlug || !panelSlug) {
        throw new Error("Organization slug is missing from local storage");
      }
      let result = await TLinkApi.del(`o/${orgSlug}/panel/${panelSlug}/email/template/${id}`);
      await GetTemplateList();
      setTemplatesData({});
      if (templates.length > 1) {
        navigate(`/template/${templates[0].id}`);
      } else {
        setTemplatesData([]);
      }
      setIsLoading(false);
      enqueueSnackbar(result?.userMessage, {
        variant: "success",
      });
    } catch (e) {
      setIsLoading(false);
      enqueueSnackbar(e?.response?.data?.result?.userMessage, {
        variant: "error",
      });
    }
  };

  return (
    <div style={{ paddingInline: "10px", paddingBottom: "10px" }}>
      <Stack
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 5,
        }}
      >
        <MDTypography fontSize="18px" fontWeight="bold" color="#303972">
          Template List
        </MDTypography>
      </Stack>
      {templates?.length > 0 ? (
        templates.map((template) => (
          <MDBox
            id={`${template.id}`}
            onClick={() => {
              navigate(`${template.id}`);
            }}
            sx={{
              borderBottom:
                Number(id) === template.id
                  ? `1px solid ${primary.main}`
                  : `1px solid #E4E4E4`,
              background:
                Number(id) === template.id
                  ? `${secondary.light} !important`
                  : "",
              cursor: "pointer",
              "&:hover": {
                background: `${secondary.light} !important`,
              },
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            p={1}
            key={template.id}
          >
            <Typography sx={{ fontWeight: "600 !important", fontSize: "14px" }}>
              {template?.templateName}
            </Typography>
            <Typography
              sx={{ fontSize: "12px", color: "#757575", marginLeft: "20px" }}
            >
              {template?.templateCategoryType?.name}
            </Typography>

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
            >
              <Tooltip title="Delete Template" placement="top">
                <IconButton
                  sx={{
                    display:
                      window.location.pathname === `/template/new` ||
                      window.location.pathname === `/template/${id}/edit`
                        ? "none"
                        : "flex",
                    direction: "row",
                    justifyContent: "flex-end",
                  }}
                  onClick={() => handleDeleteClick(template)}
                >
                  <DeleteOutline color="error" />
                </IconButton>
              </Tooltip>
            </Stack>
          </MDBox>
        ))
      ) : (
        <MDBox
          display="flex"
          alignItems="center"
          justifyContent="center"
          mt={3}
          mr={5}
          textAlign="center"
          fontSize="13px"
          fontWeight="500"
        >
          There are no templates yet!
        </MDBox>
      )}

      {/* Confirmation Dialog */}
      <Dialog
        open={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this template?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            sx={{ color: "#FB7D5B" }}
            size="small"
            onClick={() => setShowDeleteDialog(false)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            sx={{ color: "#FFFFFF" }}
            size="small"
            onClick={confirmDelete}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TemplateList;
