import React, { useState, useEffect } from "react";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Badge from "@mui/material/Badge";
import PersonIcon from "@mui/icons-material/Person";
import PublicIcon from "@mui/icons-material/Public";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import RequestPageIcon from "@mui/icons-material/RequestPage";
import {
  Button,
  Grid,
  Card,
  CardContent,
  Avatar,
  Typography,
  IconButton,
  Pagination,
  Box,
  CircularProgress,
  Menu,
  MenuItem,
  ListItemIcon,
  Tooltip,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";
import MDButton from "../../../../../src/components/MDButton";
import TLinkApi from "services/tlink.api";
import MDTypography from "../../../../components/MDTypography";
import { useNavigate, useParams } from "react-router-dom";
import MDInput from "../../../../components/MDInput";
import { useSnackbar } from "notistack";
import { useOutletContext } from "react-router-dom";
import colors from "../../../../assets/theme/base/colors";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const AllPanels = ({ newSlug, setNewSlug }) => {
  const { setIsEdit } = useOutletContext();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(7);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedPanelId, setSelectedPanelId] = useState(null);
  const [search, setSearch] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  let { enqueueSnackbar } = useSnackbar();
  const [openDialog, setOpenDialog] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams();

  const [organizationSlug, setOrganizationSlug] = useState(
    localStorage.getItem("organizationSlug")
  );

  useEffect(() => {
    setOrganizationSlug(localStorage.getItem("organizationSlug"));
  }, []);

  const fetchData = async (page, searchQuery = "", sortOrder = "asc") => {
    setLoading(true);
    try {
      const orgSlug = localStorage.getItem("organizationSlug");
      if (!orgSlug) {
        throw new Error("Organization slug is missing from local storage");
      }
      const response = await TLinkApi.get(
        `/o/${orgSlug}/panels/?search=${searchQuery}&sortBy=name&sortOrder=${sortOrder}&page=${page}&limit=${itemsPerPage}`
      );
      const panels = response.data.panels;
      const totalLength = response.data.totalLength;

      setData(panels);
      setTotalPages(Math.ceil(totalLength / itemsPerPage));
      setLoading(false);

      if (panels.length === 0) {
        setError("Data not found.");
      } else {
        setError(null);
      }
    } catch (error) {
      setError("Failed to fetch data.");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(page, search, sortOrder);
  }, [page, search, sortOrder]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleMoreClick = (event, panelId) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedPanelId(panelId);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedPanelId(null);
  };

  const handleDelete = async (panelId) => {
    setLoading(true);
    try {
      const orgSlug = localStorage.getItem("organizationSlug");
      if (!orgSlug) {
        throw new Error("Organization slug is missing from local storage");
      }
      const response = await TLinkApi.del(`/o/${orgSlug}/panel/${panelId}`);
      enqueueSnackbar("Panel Deleted!!!", {
        variant: "success",
      });
      setOpenDialog(false);
      handleMenuClose();
      fetchData(page, search, sortOrder);
    } catch (error) {
      enqueueSnackbar("You Don't have Permission !!!", {
        variant: "error",
      });
    }
    setLoading(false);
  };

  // const setDefault = async (panelId) => {
  //   setLoading(true);
  //   try {
  //     const orgSlug = localStorage.getItem("organizationSlug");
  //     if (!orgSlug) {
  //       throw new Error("Organization slug is missing from local storage");
  //     }
  //     const payload = {
  //       isDefault: true,
  //     };
  //     const response = await TLinkApi.patch(
  //       `/o/${orgSlug}/panel/${panelId}/default`,
  //       payload
  //     );
  //     enqueueSnackbar("Panel Set as Default", {
  //       variant: "success",
  //     });
  //     handleMenuClose();
  //     fetchData(page, search, sortOrder);
  //   } catch (error) {
  //     enqueueSnackbar("Failed to Set default!", {
  //       variant: "error",
  //     });
  //     setError("Failed to set default item!");
  //   }
  //   setLoading(false);
  // };

  const handleSortToggle = () => {
    const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(newSortOrder);
  };

  const onSearchChange = (value) => {
    setSearch(value);
    setPage(1);
  };

  const handleDeleteClick = () => {
    setOpenDialog(true);
  };

  const confirmDelete = () => {
    handleDelete(selectedPanelId);
    setOpenDialog(false);
  };

  const cancelDelete = () => {
    setOpenDialog(false);
  };

  return (
    <Box>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "10px",
          marginTop: "20px",
        }}
      >
        <MDTypography fontSize="20px" fontWeight="bold" color="#303972">
          Panels
        </MDTypography>
        <Grid
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "10px",
          }}
        >
          <MDInput
            placeholder="Search"
            value={search}
            sx={{
              height: "40px",
              border: "none",
              outline: "none",
              "& .MuiOutlinedInput-root": {
                borderRadius: "5px",
                backgroundColor: "#ffffff",
                "& fieldset": {
                  border: "none",
                },
              },
              "& .MuiInputBase-root": {
                height: "40px",
                border: "none",
                outline: "none",
              },
              "& .MuiInputAdornment-root": {
                height: "40px",
                border: "none",
              },
            }}
            onChange={({ currentTarget }) =>
              onSearchChange(currentTarget.value)
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{ color: "#A098AE" }} />
                </InputAdornment>
              ),
            }}
          />
          <Tooltip
            title={sortOrder === "asc" ? "Sort Ascending" : "Sort Descending"}
            arrow
          >
            <Button
              onClick={handleSortToggle}
              sx={{
                ml: "6px",
                marginRight: "6px",
                height: "27px",
                width: "26px",
                color: "#A098AE",
                fontSize: "small",
                backgroundColor: "#FFFFFF",
                "&:hover": {
                  backgroundColor: "#FFFFFF",
                },
              }}
            >
              {sortOrder === "asc" ? (
                <ArrowDownwardIcon />
              ) : (
                <ArrowUpwardIcon />
              )}
            </Button>
          </Tooltip>
        </Grid>
      </Grid>

      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      ) : error ? (
        <Grid item xs={12} mt={6}>
          <Typography variant="body1" color="textSecondary" align="center">
            No records to display
          </Typography>
        </Grid>
      ) : (
        <>
          {/* <Grid container spacing={3}>
            {data.map((item) => (
              <Grid item xs={12} sm={6} md={4} key={item.id}>
                <Card
                  sx={{
                    height: "100%",
                    borderRadius: "12px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    backgroundColor: "#FFFFFF",
                    position: "relative",
                  }}
                >
                  <CardContent>
                    <Box display="flex" justifyContent="flex-end">
                      <Grid
                        container
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="row"
                        spacing={2}
                        sx={{ cursor: "pointer" }}
                        onClick={() =>
                          navigate(`/o/${organizationSlug}/panels/${item.id}`)
                        }
                      >
                        <Grid
                          item
                          xs={4}
                          display="contents"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Avatar
                            sx={{
                              width: 48,
                              height: 48,
                              backgroundColor: "#C1BBEB",
                              marginBottom: "30px",
                            }}
                          >
                            {item.panelLogoUrl ? (
                              <img
                                src={item.panelLogoUrl}
                                alt="Logo"
                                style={{ width: "100%" }}
                              />
                            ) : (
                              <Typography variant="h6" color="White">
                                {item.name
                                  .split(" ")
                                  .map((word) => word[0])
                                  .join("")
                                  .toUpperCase()}
                              </Typography>
                            )}
                          </Avatar>
                        </Grid>
                        <Grid
                          item
                          xs={8}
                          display="flex"
                          flexDirection="column"
                          justifyContent="flex-start"
                          alignItems="left"
                        >
                        <Tooltip title={item.name} placement="top-start" arrow>
                          <Typography
                            fontWeight="bold"
                            sx={{
                              fontSize: "16px",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            {item.name.length > 11
                              ? `${item.name.substring(0, 11)}...`
                              : item.name}
                            {item.isDefault && (
                              <Badge
                                sx={{
                                  marginTop: "14px",
                                  marginRight: "26px",
                                  "& .MuiBadge-badge": {
                                    backgroundColor: "#E8E4FF",
                                    color: "#5D4EB2",
                                    border: "1px solid #5D4EB2",
                                  },
                                }}
                                badgeContent="Default"
                              ></Badge>
                            )}
                          </Typography>
                          </Tooltip>
                          <Typography
                            sx={{ color: "#A098AE", fontSize: "14px" }}
                          >
                            {item.slug}
                          </Typography>
                          <Typography
                            sx={{
                              color: "#A098AE",
                              fontSize: "14px",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            Joining Status
                            <Typography
                              sx={{
                                color: "White",
                                fontSize: "12px",
                                borderRadius: "30px",
                                padding: "2px 10px 2px 10px",
                                marginBottom: "2px",
                                backgroundColor: item.panelJoiningIsActive ? "#5D4EB2" : "#B5B7C0",
                              }}
                            >
                              {item.panelJoiningIsActive
                                ? "Active"
                                : "Inactive"}
                            </Typography>
                          </Typography>
                          <Typography
                            sx={{
                              color: "#A098AE",
                              fontSize: "14px",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            Panel Status
                            <Typography
                              sx={{
                                color: "White",
                                fontSize: "12px",
                                borderRadius: "25px",
                                padding: "2px 10px 2px 10px",
                                backgroundColor: item.isActive ? "#5D4EB2" : "#B5B7C0",
                              }}
                            >
                              {item.isActive ? "Active" : "Inactive"}
                            </Typography>
                          </Typography>
                        </Grid>
                      </Grid>
                      <IconButton
                        sx={{
                          position: "absolute",
                          bottom: "130px",
                          borderRadius: "50%",
                          left: "83%",
                        }}
                        onClick={(e) => handleMoreClick(e, item.id)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </Box>
                  </CardContent>
                  <hr sx={{ color: "#A098AE" }} />
                  <Grid
                    item
                    xs={12}
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="top"
                    color={"#89878D"}
                    sx={{ cursor: "pointer", paddingLeft: "16px" }}
                    onClick={() =>
                      navigate(`/o/${organizationSlug}/panels/${item.id}`)
                    }
                  >
                    <Grid
                      item
                      xs={6}
                      display="flex"
                      flexDirection="row"
                      justifyContent="center"
                      alignItems="top"
                      sx={{ fontSize: "14px" }}
                    >
                      Active Panelists <br />
                      {item.totalPanelists}
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      display="flex"
                      flexDirection="row"
                      justifyContent="center"
                      alignItems="top"
                      marginRight={4}
                      sx={{ fontSize: "14px" }}
                    >
                      Active Markets <br />
                      {item.totalMarkets}
                    </Grid>
                  </Grid>
                </Card>
                <Menu
                  anchorEl={anchorEl}
                  open={selectedPanelId === item.id}
                  onClose={handleMenuClose}
                >
                  {item.isDefault ? (
                    <MenuItem onClick={() => handleDelete(item.id)}>
                      <ListItemIcon>
                        <DeleteIcon fontSize="small" />
                      </ListItemIcon>
                      Delete
                    </MenuItem>
                  ) : (
                    <>
                      <MenuItem onClick={() => handleDelete(item.id)}>
                        <ListItemIcon>
                          <DeleteIcon fontSize="small" />
                        </ListItemIcon>
                        Delete
                      </MenuItem>
                      <hr />
                      <MenuItem onClick={() => setDefault(item.id)}>
                        <ListItemIcon>
                          <CheckCircleIcon fontSize="small" />
                        </ListItemIcon>
                        Set As Default
                      </MenuItem>
                    </>
                  )}
                </Menu>
              </Grid>
            ))}
          </Grid> */}
          <Grid container spacing={5} rowSpacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <Stack sx={{ padding: "1rem", height: "90%" }}>
                <IconButton
                  sx={{
                    padding: "0px !important",
                    width: "100%",
                    height: "100%",
                    borderRadius: "0.5rem",
                    background: "#ffff",
                  }}
                  onClick={() => {
                    navigate(`/panels/new`);
                    setIsEdit({
                      disabled: false,
                      edit: false,
                    });
                  }}
                >
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      border: "1px dashed black",
                      borderRadius: "0.5rem",
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <AddCircleOutlineIcon sx={{ fontSize: "2.5rem" }} />
                    <Typography
                      fontSize="16px"
                      fontWeight="500"
                      sx={{ color: "#6B6F7B" }}
                    >
                      Add New Panel
                    </Typography>
                  </Stack>
                </IconButton>
              </Stack>
            </Grid>
            {data.map((item) => (
              <Grid item xs={12} sm={6} md={3} key={item.id}>
                <Card
                  // onClick={() =>
                  //   navigate(`/o/${organizationSlug}/panels/${item.id}`)
                  // }
                  sx={{
                    height: "90%",
                    borderRadius: "12px",
                    boxShadow: "none !important",
                    // backgroundColor: colors.secondary.light,
                    backgroundColor: "#fffff",
                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    padding: "1rem",
                    "&:hover": {
                      backgroundColor: "#fff",
                      boxShadow: "rgba(0, 0, 0, 0.3) 0px 10px 20px 0px",
                    },
                  }}
                >
                  {/* <CardContent
                    sx={{ boder: "1px solid black" }}
                    onClick={() =>
                      navigate(`/o/${organizationSlug}/panels/${item.id}`)
                    }
                  >
                   
                  </CardContent> */}
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    sx={{ width: "100%" }}
                    pt={1}
                    onClick={() => navigate(`/panels/${item.id}`)}
                  >
                    {item.isDefault ? (
                      <Badge
                        sx={{
                          marginLeft: "2rem",
                          marginTop: "1rem",
                          "& .MuiBadge-badge": {
                            padding: "0.8rem",

                            backgroundColor: colors.secondary.light,
                            color: colors.secondary.main,
                            fontSize: "10px",
                          },
                        }}
                        // badgeContent="Default"
                      ></Badge>
                    ) : (
                      <div></div>
                    )}
                    <Avatar
                      sx={{
                        marginLeft: item.isDefault ? "" : "2rem",
                        width: 50,
                        height: 50,
                        backgroundColor: "#ab95ffd9",
                        // marginBottom: "4px",
                        marginTop: "10px",
                      }}
                    >
                      {item.panelLogoUrl ? (
                        <img
                          src={item.panelLogoUrl}
                          alt="Logo"
                          style={{ width: "100%" }}
                        />
                      ) : (
                        <Typography variant="h6" color="White">
                          {item.name
                            .split(" ")
                            .map((word) => word[0])
                            .join("")
                            .toUpperCase()}
                        </Typography>
                      )}
                    </Avatar>

                    <IconButton
                      size="small"
                      onClick={(e) => handleMoreClick(e, item.id)}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </Stack>

                  <Grid container spacing={1}>
                    <Grid
                      item
                      xs={12}
                      lg={12}
                      container
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                      onClick={() => navigate(`/panels/${item.id}`)}
                    >
                      <Tooltip title={item.name} placement="top-start" arrow>
                        <Typography
                          fontSize="16px"
                          variant="h6"
                          fontWeight="bold"
                        >
                          {item.name.length > 14
                            ? `${item.name.substring(0, 14)}...`
                            : item.name}
                        </Typography>
                      </Tooltip>
                      <Tooltip title={item.slug} placement="bottom-start" arrow>
                        <Typography fontSize="10px" variant="h6">
                          {item.slug.length > 14
                            ? `${item.slug.substring(0, 14)}...`
                            : item.slug}
                        </Typography>
                      </Tooltip>
                    </Grid>
                    {/* <Divider sx={{ width: "100%" }} /> */}
                    <Grid item xs={12} textAlign="left">
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        mt={1}
                        sx={{ cursor: "pointer" }}
                        onClick={() => navigate(`/panels/${item.id}/points`)}
                      >
                        <Box display="flex" alignItems="center">
                          <Box
                            sx={{
                              width: 24,
                              height: 24,
                              borderRadius: "50%",
                              backgroundColor: "#ab95ffd9",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              mr: 1,
                              mb: 1,
                            }}
                          >
                            <RequestPageIcon fontSize="small" />
                          </Box>
                          <Tooltip
                            title="Total Requested"
                            placement="bottom-start"
                            arrow
                          >
                            <Typography
                              fontSize="11px"
                              variant="h6"
                              fontWeight="bold"
                              mb={1}
                            >
                              Total Requested
                            </Typography>
                          </Tooltip>
                        </Box>
                        <Box display="flex" alignItems="center">
                          <Tooltip
                            title="Total Requested"
                            placement="bottom-start"
                            arrow
                          >
                            <Typography
                              fontSize="11px"
                              variant="h6"
                              fontWeight="bolder"
                              mb={1}
                            >
                              {item.totalRequestedPoints}
                            </Typography>
                          </Tooltip>
                        </Box>
                      </Box>
                      <hr sx={{ width: "100%" }} />
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        mt={1}
                        sx={{ cursor: "pointer" }}
                        onClick={() => navigate(`/panels/${item.id}/points`)}
                      >
                        <Box display="flex" alignItems="center">
                          <Box
                            sx={{
                              width: 24,
                              height: 24,
                              borderRadius: "50%",
                              backgroundColor: "#ab95ffd9",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              mr: 1,
                              mb: 1,
                            }}
                          >
                            <MonetizationOnIcon fontSize="small" />
                          </Box>
                          <Tooltip
                            title="Total Redeemed"
                            placement="bottom-start"
                            arrow
                          >
                            <Typography
                              fontSize="11px"
                              variant="h6"
                              fontWeight="bold"
                              mb={1}
                            >
                              Total Redeemed
                            </Typography>
                          </Tooltip>
                        </Box>
                        <Box display="flex" alignItems="center">
                          <Tooltip
                            title="Total Redeemed"
                            placement="bottom-start"
                            arrow
                          >
                            <Typography
                              fontSize="11px"
                              variant="h6"
                              fontWeight="bolder"
                              mb={1}
                            >
                              {item.totalRedeemedPoints}
                            </Typography>
                          </Tooltip>
                        </Box>
                      </Box>
                      <hr sx={{ width: "100%" }} />
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        mt={1}
                        sx={{ cursor: "pointer" }}
                        onClick={() => navigate(`/panelist`)}
                      >
                        <Box display="flex" alignItems="center">
                          <Box
                            sx={{
                              width: 24,
                              height: 24,
                              borderRadius: "50%",
                              backgroundColor: "#ab95ffd9",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              mr: 1,
                              mb: 1,
                            }}
                          >
                            <PersonIcon fontSize="small" />
                          </Box>
                          <Tooltip
                            title="Active Panelists"
                            placement="bottom-start"
                            arrow
                          >
                            <Typography
                              fontSize="11px"
                              variant="h6"
                              fontWeight="bold"
                              mb={1}
                            >
                              Active Panelists
                            </Typography>
                          </Tooltip>
                        </Box>
                        <Box display="flex" alignItems="center">
                          <Tooltip
                            title="Active Panelists"
                            placement="bottom-start"
                            arrow
                          >
                            <Typography
                              fontSize="11px"
                              variant="h6"
                              fontWeight="bolder"
                              mb={1}
                            >
                              {item.totalPanelists}
                            </Typography>
                          </Tooltip>
                        </Box>
                      </Box>
                      <hr sx={{ width: "100%" }} />
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        mt={1}
                        sx={{ cursor: "pointer" }}
                        onClick={() => navigate(`/panels/${item.id}/markets`)}
                      >
                        <Box display="flex" alignItems="center">
                          <Box
                            sx={{
                              width: 24,
                              height: 24,
                              borderRadius: "50%",
                              backgroundColor: "#ab95ffd9",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              mr: 1,
                              mb: 1,
                            }}
                          >
                            <PublicIcon fontSize="small" />
                          </Box>
                          <Tooltip
                            title="Total Markets"
                            placement="bottom-start"
                            arrow
                          >
                            <Typography
                              fontSize="11px"
                              variant="h6"
                              fontWeight="bold"
                              mb={1}
                            >
                              Active Market
                            </Typography>
                          </Tooltip>
                        </Box>
                        <Box display="flex" alignItems="center">
                          <Tooltip
                            title="Total Markets"
                            placement="bottom-start"
                            arrow
                          >
                            <Typography
                              fontSize="11px"
                              variant="h6"
                              fontWeight="bolder"
                              mb={1}
                            >
                              {item.totalMarkets}
                            </Typography>
                          </Tooltip>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Card>
                <Menu
                  anchorEl={anchorEl}
                  open={selectedPanelId === item.id}
                  onClose={handleMenuClose}
                >
                  <MenuItem onClick={handleDeleteClick}>
                    <ListItemIcon>
                      <DeleteIcon fontSize="small" />
                    </ListItemIcon>
                    Delete
                  </MenuItem>
                </Menu>
                {/* Delete Confirmation Dialog */}
                <Dialog
                  open={openDialog}
                  onClose={(event, reason) => {
                    if (reason !== "backdropClick") {
                      cancelDelete();
                    }
                  }}
                  BackdropProps={{
                    style: { backgroundColor: "rgba(0, 0, 0, 0.1)" },
                  }}
                  PaperProps={{
                    style: { width: "400px" },
                  }}
                  disableEscapeKeyDown
                >
                  <DialogTitle>Confirm Deletion</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Are you sure you want to delete this Panel ?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={cancelDelete}
                      variant="outlined"
                      color="secondary"
                      size="small"
                      sx={{ color: "#000000" }}
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={confirmDelete}
                      variant="contained"
                      color="primary"
                      sx={{ color: "#FFFFFF" }}
                      size="small"
                    >
                      Delete
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid>
            ))}
          </Grid>
          <Box display="flex" justifyContent="right" mt={2}>
            {totalPages > 1 && (
              <Pagination
                count={totalPages}
                page={page}
                onChange={handlePageChange}
                color="primary"
              />
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default AllPanels;
