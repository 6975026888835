import React, { useEffect, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { Typography, Box, Stack, Grid, Backdrop, CircularProgress } from "@mui/material";
import { ThemeProvider } from "@mui/material";
import KeyboardBackspaceSharpIcon from "@mui/icons-material/KeyboardBackspaceSharp";
import MDTypography from "../../../../components/MDTypography";
import GetPanelBasicList from "../../../../hooks/PanelBasicList/GetPanelBasicList";
import { useNavigate, useParams } from "react-router-dom";
import useTableTheme from "constant/TableTheme";

const AllPanelList = () => {
  const tableTheme = useTableTheme();
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    panelists,
    getPanelists,
    isLoading,
    totalLength,
    setPage,
    page,
    setSearch,
    search,
    setEntriesPerPage,
    entriesPerPage,
    setSortOrder,
    setSortBy,
    sortBy,
    sortOrder,
  } = GetPanelBasicList();
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 50,
  });
  const [sorting, setSorting] = useState([]);

  const [pSlug, setPSlug] = useState();
  const [organizationSlug, setOrganizationSlug] = useState(
    localStorage.getItem("organizationSlug")
  );
  const [panelSlug, setPanelSlug] = useState();

  useEffect(() => {
    setOrganizationSlug(localStorage.getItem("organizationSlug"));
    setPanelSlug(localStorage.getItem("panelSlug"));
  }, [pSlug]);

  useEffect(() => {
    setData(panelists);
  }, [panelists]);

  useEffect(() => {
    setPage(pagination.pageIndex);
    setEntriesPerPage(pagination.pageSize);
  }, [pagination]);

  useEffect(() => {
    if (sorting.length === 1) {
      if (sorting[0].desc === false) {
        if (sorting[0].id === "country.name") {
          setSortBy("countryName");
        } else if (sorting[0].id === "source.name") {
          setSortBy("sourceName");
        } else if (sorting[0].id === "organization.name") {
          setSortBy("organizationName");
        } else {
          setSortBy(sorting[0].id);
        }
        setSortOrder("asc");
      } else {
        if (sorting[0].id === "country.name") {
          setSortBy("countryName");
        } else if (sorting[0].id === "source.name") {
          setSortBy("sourceName");
        } else if (sorting[0].id === "organization.name") {
          setSortBy("organizationName");
        } else {
          setSortBy(sorting[0].id);
        }
        setSortOrder("desc");
      }
    } else if (sorting.length === 0) {
      setSortBy("id");
      setSortOrder("asc");
    }
  }, [sorting]);

  const columns = [
    {
      accessorKey: "id",
      header: "Id",
      size: 30,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
    },
    {
      accessorKey: "firstName",
      header: "Name",
      size: 30,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
      Cell: ({ cell }) => (cell.getValue() ? cell.getValue() : "NA"),
    },
    {
      accessorKey: "dob",
      header: "DOB",
      size: 30,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
      Cell: ({ cell }) => (cell.getValue() ? cell.getValue() : "NA"),
    },
    {
      accessorKey: "gender",
      header: "Gender",
      size: 30,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
      Cell: ({ cell }) => (cell.getValue() ? cell.getValue() : "NA"),
    },
    {
      accessorKey: "organization.name",
      header: "Organization",
      size: 30,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
      Cell: ({ cell }) => (cell.getValue() ? cell.getValue() : "NA"),
    },
    {
      accessorKey: "country.name",
      header: "Country",
      size: 30,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
      Cell: ({ cell }) => (cell.getValue() ? cell.getValue() : "NA"),
    },
    {
      accessorKey: "zipCode",
      header: "Zip Code",
      size: 30,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
      Cell: ({ cell }) => (cell.getValue() ? cell.getValue() : "NA"),
    },
    {
      accessorKey: "isEmailVerified",
      header: "Email Status",
      size: 30,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
      Cell: ({ cell }) => (cell.getValue() ? "Verified" : "Not Verified"),
    },
    {
      accessorKey: "isJoiningComplete",
      header: "Joining Status",
      size: 30,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
      Cell: ({ cell }) => (cell.getValue() ? "Complete" : "Incomplete"),
    },
    {
      accessorKey: "isProfilingComplete",
      header: "Profile Status",
      size: 30,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
      Cell: ({ cell }) => (cell.getValue() ? "Complete" : "Incomplete"),
    },
    {
      accessorKey: "isRegistrationComplete",
      header: "Registration Status",
      size: 30,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
      Cell: ({ cell }) => (cell.getValue() ? "Complete" : "Incomplete"),
    },
    {
      accessorKey: "source.name",
      header: "Source",
      size: 30,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
      Cell: ({ cell }) => (cell.getValue() ? cell.getValue() : "NA"),
    },
    {
      accessorKey: "createdAt",
      header: "Joining Date",
      size: 30,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
      Cell: ({ cell }) => new Date(cell.getValue()).toLocaleString(),
    },
  ];

  const table = useMaterialReactTable({
    columns,
    data,
    enableStickyHeader: true,
    manualPagination: true,
    rowCount: totalLength,
    onPaginationChange: setPagination,
    state: { density: "compact", pagination, sorting },
    enableColumnPinning: true,
    enableGlobalFilter: true,
    enableDensityToggle: false,
    manualSorting: true,
    muiPaginationProps: {
      rowsPerPageOptions: [25, 50, 100],
    },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: (event) => {
        navigate(
          `/panelist/${row.original.id}`
        );
      },
      sx: {
        cursor: "pointer",
      },
    }),
    onSortingChange: setSorting,
    muiTableContainerProps: {
      sx: {
        minHeight: "calc(100vh - 260px)",
        maxHeight: "calc(100vh - 350px)",
        overflowY: "auto",
      },
    },
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        width: "100%",
        borderRadius: "15px",
        padding: "10px",
        backgroundColor: "#fff !important",
        zIndex: 500,
      },
    },
    renderEmptyRowsFallback: () => (
      <Stack
        display="flex"
        alignItems="center"
        justifyContent="center"
        fontSize="14px"
        p={5}
      >
        No records to display
      </Stack>
    ),
  });

  return (
    <>
      {/* Loader */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    <Box sx={{ padding: "2px" }}>
      <Grid container alignItems={"center"} gap={2} width={"fit-content"}>
          <Typography fontSize={"19px"} fontWeight={400} color="Black">
            Panelists
          </Typography>
        </Grid>
      <ThemeProvider theme={tableTheme} >
        <MaterialReactTable
          table={table}
          style={{
            width: "100%",
          }}
        />
      </ThemeProvider>
    </Box>
    </>
  );
};

export default AllPanelList;
