import MDBox from "components/MDBox";
import React from "react";

const Label = ({ value, required }) => {
  return (
    <MDBox fontWeight="400" fontSize="12px" color="rgba(0, 0, 0, 0.85)" pb={1}>
      {required && <span style={{ color: "red" }}>* &nbsp;</span>}
      {value}
    </MDBox>
  );
};
export default Label;
