import * as React from "react";
import {
  TextField,
  Grid,
  Select,
  MenuItem,
  FormControl,
  Checkbox,
  Dialog,
  Typography,
  DialogTitle,
  DialogContent,
  Stack,
  IconButton,
  Chip,
  Box,
  Backdrop,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import Add from "@mui/icons-material/Add";
import {
  ArrowLeft,
  ArrowRight,
  BorderColor,
  Info,
  InfoOutlined,
} from "@mui/icons-material";
import colors from "assets/theme/base/colors";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import { useSnackbar } from "notistack";
import TLinkApi from "services/tlink.api";
import GetNewLanguageList from "hooks/NewLanguageList/GetNewLanguageList";
import GetLanguageCountryMap from "hooks/LanguageCountryMap/GetLanguageCountryMap";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { set } from "react-hook-form";

function CustomQualification({
  CloseCustomQualification,
  langId,
  surveyId,
  setCustomId,
  setValueUpdate,
  getQualificationList,
}) {
  const { newLanguage } = GetNewLanguageList();
  const { languageCountryMap } = GetLanguageCountryMap();
  const [isLoading, setIsLoading] = useState(false);
  const [selectEdit, setSelectEdit] = useState(false);
  const [listType, setListType] = useState([]);
  const [selectOpen, setSelectOpen] = useState(false);
  const [langIds, setLangIds] = useState([]);
  const [value, setValue] = useState("");
  const [canSave, setCanSave] = useState(true);
  const [openLang, setOpenLang] = useState([]);
  const [currentFocus, setCurrentFocus] = useState(null);

  let { enqueueSnackbar } = useSnackbar();
  let { info, primary } = colors;
  const [qualification, setQualifications] = useState({
    name: "",
    type_id: 1,
    category_id: 2,
    question: [],
  });

  React.useEffect(() => {
    console.log("openLang", openLang);
  }, [openLang]);

  const postCustomQualification = async () => {
    setIsLoading(true);
    let payload = {
      name: qualification.name,
      qualificationTypeId: qualification.type_id,
      qualificationCategoryId: 7,
      qualificationCategoryTypeId: qualification.qualificationCategoryTypeId,
      qualificationQuestions: qualification.question.map((val) => {
        if (qualification.type_id === 3) {
          return {
            questionText: val?.text,
            languageId: val?.languge_id,
            answers: val?.answers,
          };
        } else {
          return {
            questionText: val?.text,
            languageId: val?.languge_id,
            answers: val?.answers.map((ans) => {
              return {
                qualificationAnswerDesc: ans,
              };
            }),
          };
        }
      }),
    };
    try {
      let result = await TLinkApi.post(`qualifications`, payload);
      let localQualification = result.data.qualification.questions.find(
        (val) => Number(val.languageId) === Number(langId)
      );
      if (localQualification && surveyId) {
        await getQualificationList(localQualification.questionText);
        setCustomId(Number(localQualification.qualificationId));
      }
      enqueueSnackbar("Qualification created sucessfully", {
        variant: "success",
      });
      setIsLoading(false);
      CloseCustomQualification();
      setSelectEdit(false);
      setSelectOpen(false);
      setLangIds([]);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
      enqueueSnackbar(e?.data?.message ? e.data?.message : "", {
        variant: "error",
      });
    }
  };

  let selectStyle = {
    height: "36.5px",

    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
    "& :hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
  };

  const label = (value, hide) => {
    return (
      <MDBox
        fontWeight="400"
        fontSize="12px"
        color="rgba(0, 0, 0, 0.85)"
        pb={0.5}
      >
        {value} {hide ? "" : <span>* &nbsp;</span>}
      </MDBox>
    );
  };

  const MenuProps = {
    autoFocus: false,
    sx: {
      "&& .Mui-selected": {
        backgroundColor: "#F2F7EC",
      },
    },
    PaperProps: {
      style: {
        maxHeight: "300px",
        minWidth: "500px",
      },
    },
  };

  const handleDeleteOption = (event, itemToDelete) => {
    event.preventDefault();
    event.stopPropagation();
    const newSelectedOptions = langIds.filter(
      (option) => option !== itemToDelete
    );
    setLangIds(newSelectedOptions);

    let payload = [...openLang];
    if (openLang.includes(Number(itemToDelete))) {
      payload = openLang.filter((val) => val !== Number(itemToDelete));
    }
    setOpenLang(payload);
  };

  const ref = React.useRef(null);
  const scroll = (scrollOffset) => {
    ref.current.scrollLeft += scrollOffset;
  };

  React.useEffect(() => {
    let payload = { ...qualification };
    let data = langIds.map((id) => {
      let obj = payload?.question?.find((obj) => obj.languge_id === id);
      if (payload.type_id === 3) {
        return {
          text: obj?.text ? obj?.text : "",
          languge_id: id,
          answers: Array.isArray(obj?.answers)
            ? ""
            : obj?.answers
            ? obj?.answers
            : "",
        };
      } else {
        return {
          text: obj?.text ? obj?.text : "",
          languge_id: id,
          answers:
            obj?.answers && Array.isArray(obj?.answers) ? obj.answers : [],
        };
      }
    });
    payload.question = data;
    setQualifications(payload);
  }, [langIds, qualification.type_id]);

  React.useEffect(() => {
    let check = false;
    if (qualification.type_id !== 3) {
      check = qualification.question.some(
        (val) => val.text === "" || val.answers.length <= 0
      );
    } else {
      check = qualification.question.some((val) => val.text === "");
    }
    if (
      qualification.name === "" ||
      qualification.type_id === null ||
      qualification.question.length <= 0 ||
      check
    ) {
      setCanSave(false);
    } else {
      setCanSave(true);
    }
  }, [qualification]);

  React.useEffect(() => {
    setListType(newLanguage);
  }, [newLanguage]);

  React.useEffect(() => {
    console.log(listType);
    console.log(languageCountryMap);
    console.log(langId);
    if (listType?.length > 0) {
      let localLangId = languageCountryMap.find(
        (val) => langId === val.langcountryid
      );
      console.log(localLangId);
      if (localLangId) {
        if (localLangId.languageid === 1) {
          setLangIds([localLangId.languageid]);
          setOpenLang([localLangId.languageid]);
        } else {
          setLangIds([localLangId.languageid, 1]);
          setOpenLang([localLangId.languageid, 1]);
        }
      } else {
        setLangIds([1]);
      }
    }
  }, [listType, languageCountryMap]);

  return (
    <Dialog
      open={true}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          padding: "0.5rem",
        },
      }}
    >
      <Backdrop sx={{ color: "#fff", zIndex: 500 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <DialogTitle sx={{ position: "sticky", top: 0 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h5">Custom Qualification</Typography>
          <MDBox display="flex" alignItems="center" gap="10px">
            <MDButton
              variant="outlined"
              color="dark"
              size="small"
              onClick={() => {
                CloseCustomQualification();
                setSelectEdit(false);
                setSelectOpen(false);
                setLangIds([]);
              }}
            >
              cancel
            </MDButton>
            <MDButton
              variant="gradient"
              color="info"
              size="small"
              disabled={canSave ? false : true}
              onClick={() => {
                postCustomQualification();
              }}
            >
              Save
            </MDButton>
          </MDBox>
        </Stack>
        <hr
          style={{
            backgroundColor: "#CCC",
            height: "3px",
            marginTop: "0.5rem",
          }}
        />
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            {label("Qualification Name")}
            <MDInput
              fullWidth
              value={qualification?.name}
              onChange={(e) => {
                let payload = { ...qualification };
                payload.name = e.target.value;
                setQualifications(payload);
              }}
            />
          </Grid>
          <Grid item xs={4}>
            {label(" Qualification Category Type")}
            <FormControl fullWidth>
              <Select
                size="small"
                onChange={(e) => {
                  if (setValueUpdate) {
                    setValueUpdate(
                      "questionStatus",
                      e.target.value == 2
                        ? "custom"
                        : e.target.value == 3
                        ? "trap"
                        : e.target.value == 4
                        ? "openEndedScreener"
                        : ""
                    );
                  }
                  let payload = { ...qualification };
                  payload.qualificationCategoryTypeId = e.target.value;
                  if (e.target.value == 4) {
                    payload.type_id = 3;
                  }
                  setQualifications(payload);
                }}
                value={
                  qualification?.qualificationCategoryTypeId
                    ? String(qualification?.qualificationCategoryTypeId)
                    : ""
                }
                sx={selectStyle}
              >
                <MenuItem value="2">Custom</MenuItem>
                <MenuItem value="3">Trap</MenuItem>
                <MenuItem value="4">OpenEnded Screener</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            {label(" Qualification Type")}
            <FormControl fullWidth>
              <Select
                size="small"
                onChange={(e) => {
                  let payload = { ...qualification };
                  payload.type_id = e.target.value;
                  setQualifications(payload);
                }}
                value={
                  qualification?.type_id ? String(qualification?.type_id) : ""
                }
                sx={selectStyle}
              >
                <MenuItem
                  disabled={qualification?.qualificationCategoryTypeId == 4}
                  value={1}
                >
                  Single Select
                </MenuItem>
                <MenuItem
                  disabled={qualification?.qualificationCategoryTypeId == 4}
                  value={2}
                >
                  Multiple Select
                </MenuItem>
                <MenuItem value={3}>Open Ended</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            {/* {label(" Languages")} */}
            <Stack direction="row" alignItems="center" spacing={1}>
              <MDBox
                width="100%"
                display="flex"
                alignItems="center"
                gap="0px"
                sx={{
                  border: selectOpen
                    ? `2px solid ${info.main}`
                    : "1px solid #D9D9D9",
                }}
              >
                <IconButton
                  size="small"
                  onClick={() => {
                    scroll(-200);
                  }}
                >
                  <ArrowLeft />
                </IconButton>
                <FormControl fullWidth>
                  <Select
                    open={selectOpen}
                    onClose={() => {
                      setSelectOpen(false);
                    }}
                    multiple
                    MenuProps={{
                      ...MenuProps,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                    }}
                    IconComponent={() => null}
                    renderValue={(selected) => (
                      <Box
                        ref={ref}
                        sx={{
                          display: "flex",
                          overflowX: "auto",
                          "::-webkit-scrollbar": { display: "none" },
                          gap: 1,
                          alignItems: "center",
                        }}
                      >
                        {selected.map((value) => {
                          let check = false;
                          if (qualification.type_id !== 3) {
                            check = qualification.question.filter(
                              (val) =>
                                val.text === "" || val.answers.length <= 0
                            );
                          } else {
                            check = qualification.question.filter(
                              (val) => val.text === ""
                            );
                          }
                          return selectEdit ? (
                            <Chip
                              key={value}
                              label={
                                listType.find((item) => item.id === value).name
                              }
                              variant="light"
                              color="secondary"
                              size="small"
                              disabled={value === 1 ? true : false}
                              onDelete={(event) =>
                                handleDeleteOption(event, value)
                              }
                              deleteIcon={
                                <CloseIcon
                                  fontSize="small"
                                  onMouseDown={(event) => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                  }}
                                />
                              }
                            />
                          ) : (
                            <MDBox
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                              }}
                              onMouseDown={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                              }}
                            >
                              <span>
                                {
                                  listType.find((item) => item.id === value)
                                    ?.name
                                }
                              </span>
                              <Tooltip
                                title={
                                  qualification.type_id !== 3
                                    ? "Please fill question text and answers for this language"
                                    : "Please fill question text for this language"
                                }
                              >
                                <Info
                                  color="error"
                                  sx={{
                                    display: check.find(
                                      (val) =>
                                        Number(val.languge_id) === Number(value)
                                    )
                                      ? ""
                                      : "none",
                                  }}
                                  fontSize="small"
                                />
                              </Tooltip>
                            </MDBox>
                          );
                        })}
                      </Box>
                    )}
                    size="small"
                    onChange={(e) => {
                      let payload = [...openLang];
                      payload = openLang.filter((val) =>
                        e.target.value.includes(val)
                      );
                      if (langIds.length < e.target.value.length) {
                        let lastItemIndex =
                          e.target.value[e.target.value.length - 1];
                        if (
                          lastItemIndex &&
                          !openLang.includes(Number(lastItemIndex))
                        ) {
                          payload.push(Number(lastItemIndex));
                        }
                      }
                      setOpenLang(payload);
                      setLangIds(e.target.value);
                    }}
                    value={langIds?.length > 0 ? langIds : []}
                    sx={
                      (selectStyle,
                      {
                        border: "none",
                        "& fieldset": { border: "none" },
                        ".MuiSvgIcon-root-393": {
                          visibility: "hidden",
                        },
                      })
                    }
                  >
                    {listType?.map((value, i) => {
                      let check = langIds.find((val) => val === value.id);
                      return (
                        <MenuItem
                          value={value.id}
                          key={i}
                          disabled={
                            value.id === 1 || (langIds.length > 5 && !check)
                              ? true
                              : false
                          }
                        >
                          {value.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <Stack direction={"row"} spacing={1}>
                  <IconButton
                    sx={{
                      backgroundColor: selectOpen ? "#3669EC" : "",
                      borderRadius: "4px !important",
                      padding: "0.3rem",
                    }}
                    size="small"
                    onClick={() => {
                      setSelectOpen(true);
                    }}
                  >
                    <Add sx={{ color: selectOpen ? "white !important" : "" }} />
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={() => {
                      scroll(200);
                    }}
                  >
                    <ArrowRight />
                  </IconButton>
                </Stack>
              </MDBox>
              <IconButton
                sx={{
                  backgroundColor: selectEdit ? "#3669EC !important" : "",
                  borderRadius: "4px !important",
                  padding: "0.3rem",
                }}
                size="small"
                onClick={() => {
                  setSelectEdit(!selectEdit);
                }}
              >
                <BorderColor
                  sx={{ color: selectEdit ? "white !important" : "" }}
                />
              </IconButton>
              <Tooltip
                title={
                  <span style={{ fontSize: "13px" }}>
                    You can add custom qualification for up to 6 languages
                  </span>
                }
                placement="left-end"
              >
                <IconButton size="small">
                  <InfoOutlined />
                </IconButton>
              </Tooltip>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            {qualification?.question.map((subValue, index) => {
              return (
                <Grid
                  container
                  spacing={2}
                  key={index}
                  sx={{ marginBottom: "1rem" }}
                >
                  <Grid item xs={12}>
                    <Stack
                      direction="row"
                      spacing={2}
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography variant="h6" sx={{ fontWeight: 400 }}>
                        {
                          listType.find((val) => val.id === subValue.languge_id)
                            ?.name
                        }
                      </Typography>
                      <IconButton
                        sx={{
                          transform: openLang.includes(
                            Number(subValue.languge_id)
                          )
                            ? "rotate(180deg)"
                            : "",
                        }}
                        onClick={() => {
                          let payload = [...openLang];
                          if (openLang.includes(Number(subValue.languge_id))) {
                            payload = openLang.filter(
                              (val) => val !== Number(subValue.languge_id)
                            );
                          } else {
                            payload.push(Number(subValue.languge_id));
                          }
                          setOpenLang(payload);
                        }}
                      >
                        <KeyboardArrowDownIcon />
                      </IconButton>
                    </Stack>
                    <hr
                      style={{
                        borderTop: !openLang.includes(
                          Number(subValue.languge_id)
                        )
                          ? ""
                          : `1px solid ${primary.main}`,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: !openLang.includes(Number(subValue.languge_id))
                        ? "none"
                        : "",
                    }}
                  >
                    {label("Question Text")}
                    <MDInput
                      fullWidth
                      value={subValue?.text}
                      onChange={(e) => {
                        let payload = { ...qualification };
                        let indexOfObject = payload.question.findIndex(
                          (obj) => obj.languge_id === subValue.languge_id
                        );
                        payload.question[indexOfObject].text = e.target.value;
                        setQualifications(payload);
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: !openLang.includes(Number(subValue.languge_id))
                        ? "none"
                        : "",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: 400,
                        display: qualification.type_id !== 3 ? "" : "none",
                      }}
                    >
                      {"Answers Options"}
                    </Typography>

                    {qualification.type_id !== 3 ? (
                      Array.isArray(subValue?.answers) &&
                      subValue?.answers?.map((option, i) => {
                        return (
                          <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            width="100%"
                            key={i}
                          >
                            <Stack
                              direction="row"
                              spacing={0}
                              alignItems="center"
                            >
                              <Checkbox
                                defaultChecked
                                disabled
                                sx={{ paddingLeft: "0px" }}
                              />
                              <span
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "normal",
                                  marginTop: "0.2rem",
                                }}
                              >
                                {option}
                              </span>
                            </Stack>

                            <IconButton
                              size="small"
                              onClick={() => {
                                let payload = {
                                  ...qualification,
                                };
                                let indexOfObject = payload.question.findIndex(
                                  (obj) =>
                                    obj.languge_id === subValue.languge_id
                                );
                                payload.question[indexOfObject].answers.splice(
                                  i,
                                  1
                                );
                                setQualifications(payload);
                              }}
                            >
                              <DeleteOutline fontSize="small" color="error" />
                            </IconButton>
                          </Stack>
                        );
                      })
                    ) : (
                      <>
                        {label("Answer Text", true)}
                        <MDInput
                          fullWidth
                          variant="outlined"
                          value={subValue?.answers}
                          onChange={(e) => {
                            let payload = { ...qualification };
                            let indexOfObject = payload.question.findIndex(
                              (obj) => obj.languge_id === subValue.languge_id
                            );
                            payload.question[indexOfObject].answers =
                              e.target.value;
                            setQualifications(payload);
                          }}
                        />
                      </>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    sx={{
                      display: !openLang.includes(Number(subValue.languge_id))
                        ? "none"
                        : "",
                    }}
                  >
                    <TextField
                      color="black"
                      label="Qualification Answer"
                      variant="standard"
                      value={
                        currentFocus === Number(subValue.languge_id)
                          ? value
                          : ""
                      }
                      sx={{
                        width: "330px",
                        marginTop: "5px",
                        display: qualification.type_id === 3 ? "none" : "",
                      }}
                      onFocus={() => {
                        setCurrentFocus(Number(subValue.languge_id));
                      }}
                      onChange={(e) => setValue(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          if (value !== "") {
                            let payload = {
                              ...qualification,
                            };
                            let indexOfObject = payload.question.findIndex(
                              (obj) => obj.languge_id === subValue.languge_id
                            );
                            payload.question[indexOfObject].answers.push(value);
                            setQualifications(payload);
                            setValue("");
                          }
                        }
                      }}
                    />
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
export default CustomQualification;
