import React, { useState, useEffect } from "react";
import {
  Chip,
  Grid,
  CircularProgress,
  Backdrop,
  Stack,
  Box,
} from "@mui/material";
import MDTypography from "components/MDTypography";
import { useParams, useOutletContext } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import useTableTheme from "constant/TableTheme";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import TLinkApi from "services/tlink.api";

function PanelistPoints() {
  const [isLoading, setIsLoading] = useState(false);
  const { panelist, setIsEdit } = useOutletContext();
  const [data, setData] = useState([]);
  let { id } = useParams();
  const tableTheme = useTableTheme();

  const [page, setPage] = useState(0);
  const [totalLength, setTotalLength] = useState(0);
  const [search, setSearch] = useState("");
  const [entriesPerPage, setEntriesPerPage] = useState(50);
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortBy, setSortBy] = useState("id");
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 50,
  });
  const [sorting, setSorting] = useState([]);

  const statusColor = {
    Approved: "success",
    Rejected: "error",
    Requested: "warning",
  };

  const formatDateTime = (value) => {
    if (!value) return null;
    const date = new Date(value);
    return date.toLocaleString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
      timeZone: "UTC",
    });
  };

  const getPoints = async () => {
    setIsLoading(true);
    try {
      const orgSlug = localStorage.getItem("organizationSlug");
      const panelSlug = localStorage.getItem("panelSlug");
      if (!orgSlug) {
        throw new Error("Organization slug is missing from local storage");
      }
      let result = await TLinkApi.get(
        `/o/${orgSlug}/panel/${panelSlug}/panelist/points/${id}/?search=${search}&sortOrder=${sortOrder}&sortBy=${sortBy}&page=${
          page + 1
        }&limit=${entriesPerPage}`
      );
      console.log("API Response:", result.data);
      setData(result.data.panelistPoints);
      setTotalLength(result.data.totalLength);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getPoints();
  }, [search, sortBy, sortOrder, page, entriesPerPage]);

  useEffect(() => {
    setPage(pagination.pageIndex);
    setEntriesPerPage(pagination.pageSize);
  }, [pagination]);

  useEffect(() => {
    if (sorting.length === 1) {
      if (sorting[0].desc === false) {
        if (sorting[0].id === "source.name") {
          setSortBy("sourceName");
        } else if (sorting[0].id === "status.name") {
          setSortBy("statusName");
        } else {
          setSortBy(sorting[0].id);
        }
        setSortOrder("asc");
      } else {
        if (sorting[0].id === "source.name") {
          setSortBy("sourceName");
        } else if (sorting[0].id === "status.name") {
          setSortBy("statusName");
        } else {
          setSortBy(sorting[0].id);
        }
        setSortOrder("desc");
      }
    } else if (sorting.length === 0) {
      setSortBy("id");
      setSortOrder("asc");
    }
  }, [sorting]);

  const columns = [
    {
      accessorKey: "createdAt",
      header: "Date & Time",
      size: 150,
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      Cell: ({ cell }) => {
        const value = cell.getValue();
        return value ? formatDateTime(value) : null;
      },
    },
    {
      accessorKey: "points",
      header: "Points",
      size: 150,
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      Cell: ({ cell }) => {
        const value = cell.getValue();
        return value !== undefined ? value : null;
      },
    },
    {
      accessorKey: "source.name",
      header: "Source Name",
      size: 150,
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      Cell: ({ cell }) => {
        const value = cell.getValue();
        return value || null;
      },
    },
    {
      accessorKey: "status.name",
      header: "Status",
      size: 150,
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      Cell: ({ cell }) => {
        const value = cell.getValue();
        return (
          <Chip
            label={value || "Unknown"}
            color={statusColor[value] || "default"}
            variant="outlined"
          />
        );
      },
    },
  ];

  const table = useMaterialReactTable({
    columns,
    data: data.length > 0 ? data : [],
    enableStickyHeader: true,
    manualPagination: true,
    rowCount: totalLength,
    onPaginationChange: setPagination,
    state: { density: "compact", pagination, sorting },
    enableColumnPinning: true,
    enableGlobalFilter: true,
    enableDensityToggle: false,
    manualSorting: true,
    muiPaginationProps: {
      rowsPerPageOptions: [25, 50, 100],
    },
    onSortingChange: setSorting,
    muiTableContainerProps: {
      sx: {
        height: "calc(100vh - 350px)",
        overflowY: "auto",
      },
    },
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        width: "100%",
        borderRadius: "0",
        backgroundColor: "#fff !important",
        zIndex: 500,
      },
    },
    renderEmptyRowsFallback: () => (
      <Stack
        display="flex"
        alignItems="center"
        justifyContent="center"
        fontSize="14px"
        p={5}
      >
        No records to display
      </Stack>
    ),
  });
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* <Grid container spacing={2}>
        <Grid item xs={12} ml="15px" mt="15px">
          <MDTypography variant="h6" color="#2F2F2F">
            Points
          </MDTypography>
        </Grid>
        <Grid item xs={12} md={12} mt={-1}>
          <hr style={{ border: `1px solid #C4C4C4`, width: "100%" }} />
        </Grid>
      </Grid> */}
      <Box
        mt={1}
        sx={{
          borderRadius: "15px",
          overflow: "hidden", // Optional: Ensures child elements respect the borderRadius
        }}
      >
        <ThemeProvider theme={tableTheme}>
          <MaterialReactTable
            table={table}
            style={{
              width: "100%",
            }}
          />
        </ThemeProvider>
      </Box>
    </>
  );
}

export default PanelistPoints;
