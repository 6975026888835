import React, { useEffect } from "react";
import {
  Grid,
  Backdrop,
  CircularProgress,
  Typography,
  Box,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useOutletContext } from "react-router-dom";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

function PanelListBasicDetails() {
  const { panelist, setIsEdit, isLoading } = useOutletContext();

  console.log({ panelist, setIsEdit, isLoading });

  const { setValue, watch } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      dob: "",
      gender: "",
      organization: "",
      zipCode: "",
      source: "",
      country: "",
      isActive: true,
      isEmailVerified: "false",
      isJoiningComplete: "false",
      isProfilingComplete: "false",
      isRegistrationComplete: "fasle",
    },
  });

  useEffect(() => {
    if (setIsEdit) {
      setIsEdit({
        disabled: true,
        edit: false,
      });
    } else {
      console.error("setIsEdit is not defined or is not a function");
    }
  }, [setIsEdit]);

  useEffect(() => {
    if (panelist) {
      setValue("firstName", panelist?.firstName || "N/A");
      setValue("lastName", panelist?.lastName || "N/A");
      setValue("dob", panelist?.dob || "N/A");
      setValue("gender", panelist?.gender || "N/A");
      setValue("organization", panelist?.organization?.name || "N/A");
      setValue("zipCode", panelist?.zipCode || "N/A");
      setValue("source", panelist?.source?.name || "N/A");
      setValue("country", panelist?.country?.name || "N/A");
      setValue("isActive", panelist?.isActive || "N/A");
      setValue("isEmailVerified", panelist?.isEmailVerified);
      setValue("isJoiningComplete", panelist?.isJoiningComplete);
      setValue("isProfilingComplete", panelist?.isProfilingComplete);
      setValue("isRegistrationComplete", panelist?.isRegistrationComplete);
    }
  }, [panelist, setValue]);

  const label = (value, hide) => {
    return (
      <Box
        fontWeight="400"
        fontSize="12px"
        color="rgba(0, 0, 0, 0.85)"
        pb={0.5}
      >
        {value} {hide ? "" : <span>* &nbsp;</span>}
      </Box>
    );
  };

  return (
    <MDBox sx={{background:"#fff",borderRadius:"15px",}}>
      <Backdrop
          sx={{ color: "#fff",  zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <MDBox
        p={5}
        px={20}
        style={{
          width: "100%",
          height: `calc(100vh - 240px)`,
          overflowY: "auto",
        }}
        mt={1}
      >
        <Grid container spacing={1.8}>
          <Grid item xs={12} md={4}>
            {label("First Name", true)}
            <Typography fontSize="14px" fontWeight="600">
              {watch("firstName")}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            {label("Last Name", true)}
            <Typography fontSize="14px" fontWeight="600">
              {watch("lastName")}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            {label("Date of Birth", true)}
            <Typography fontSize="14px" fontWeight="600">
              {watch("dob")}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            {label("Gender", true)}
            <Typography fontSize="14px" fontWeight="600">
              {watch("gender")}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            {label("Organization", true)}
            <Typography fontSize="14px" fontWeight="600">
              {watch("organization")}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            {label("Zip Code", true)}
            <Typography fontSize="14px" fontWeight="600">
              {watch("zipCode")}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            {label("Source", true)}
            <Typography fontSize="14px" fontWeight="600">
              {watch("source")}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            {label("Email Status", true)}
            <Typography fontSize="14px" fontWeight="600">
              {watch("isEmailVerified") ? "Verified" : "Not Verified"}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            {label("Joining Status", true)}
            <Typography fontSize="14px" fontWeight="600">
              {watch("isJoiningComplete") ? "Complete" : "Incomplete"}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            {label("Profile Status", true)}
            <Typography fontSize="14px" fontWeight="600">
              {watch("isProfilingComplete") ? "Complete" : "Incomplete"}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            {label("Registration Status", true)}
            <Typography fontSize="14px" fontWeight="600">
              {watch("isRegistrationComplete") ? "Complete" : "Incomplete"}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            {label("Country", true)}
            <Typography fontSize="14px" fontWeight="600">
              {watch("country")}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            {label("Status", true)}
            <Typography fontSize="14px" fontWeight="600">
              {watch("isActive") ? "Active" : "Inactive"}
            </Typography>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

export default PanelListBasicDetails;
