import ModeEditIcon from "@mui/icons-material/ModeEdit";
import {
    Backdrop,
    Box,
    Button,
    CircularProgress,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    Switch,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import MDInput from "components/MDInput";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import TLinkApi from "services/tlink.api";
import MDBox from "../../../../components/MDBox";

function PanelsBasicDetails() {
  const navigate = useNavigate();
  const {
    panel,
    setPanel,
    setIsEdit,
    isEdit = { disabled: true, edit: false }, // Default value for isEdit to prevent undefined access
    getData,
  } = useOutletContext();
  let { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [currencies, setCurrencies] = useState([]);

  const [organizationSlug, setOrganizationSlug] = useState(
    localStorage.getItem("organizationSlug")
  );
  const [panelSlug, setPanelSlug] = useState(localStorage.getItem("panelSlug"));
  const [isSlugEditable, setIsSlugEditable] = useState(false);
  useEffect(() => {
    setOrganizationSlug(localStorage.getItem("organizationSlug"));
    setPanelSlug(localStorage.getItem("panelSlug"));
  }, []);

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      name: "",
      slug: "",
      panelJoiningIsActive: false,
      currencyId: "",
      panelConversion: "",
      description: "",
      isDefault: false,
      isActive: false,
      profilingPoint: "",
      joiningPoint: "",
    },
  });

  useEffect(() => {
    if (window.location.pathname === `/panels/new`) {
      setIsSlugEditable(true); // Enable slug editing for /panels/new
      setValue("name", "");
      setValue("slug", "");
    } else {
      setIsSlugEditable(false); // Disable slug editing for other paths
      if (panel) {
        setValue("name", panel?.name);
        setValue("slug", panel?.slug);
      }
    }
  }, [panel]);

  // useEffect(() => {
  //   if (!id) {
  //     setIsEdit({ disabled: false, edit: false });
  //   }
  // }, [id, setIsEdit]);

  let panelJoiningIsActive = watch("panelJoiningIsActive");
  let currencyId = watch("currencyId");
  let panelConversion = watch("panelConversion");
  let isDefault = watch("isDefault");
  let isActive = watch("isActive");

  useEffect(() => {
    setIsEdit({
      disabled: true,
      edit: false,
    });
  }, [setIsEdit]);

  useEffect(() => {
    console.log(panel, "000");
    if (window.location.pathname === `/panels/new`) {
      setValue("name", "");
      setValue("slug", "");
      setValue("panelJoiningIsActive", false);
      setValue("currencyId", "");
      setValue("panelConversion", "");
      setValue("isDefault", false);
      setValue("isActive", false);
      setValue("description", "");
      setValue("joiningPoint", "");
      setValue("profilingPoint", "");
    } else if (panel) {
      setValue("name", panel?.name);
      setValue("slug", panel?.slug);
      setValue("panelJoiningIsActive", panel?.panelJoiningIsActive);
      setValue("currencyId", panel?.currency?.id);
      setValue("panelConversion", panel?.panelConversion);
      setValue("isDefault", panel?.isDefault);
      setValue("isActive", panel?.isActive);
      setValue("description", panel?.description);
      setValue("joiningPoint", panel?.joiningPoint);
      setValue("profilingPoint", panel?.profilingPoint);
    }
  }, [panel, organizationSlug]);

  const fetchCurrencies = async () => {
    try {
      setIsLoading(true);
      const result = await TLinkApi.get("/master/list/currency");
      if (Array.isArray(result.data)) {
        setCurrencies(result.data);
      } else {
        console.error("Unexpected API response format for currencies", result);
      }
    } catch (e) {
      console.error("Failed to fetch currencies", e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCurrencies();
  }, []);

  const { id } = useParams();

  useEffect(() => {
    if (!id) {
      setIsEdit({ disabled: false, edit: false });
    }
  }, [setIsEdit, id]);

  const label = (value, hide) => (
    <Box fontWeight="400" fontSize="12px" color="rgba(0, 0, 0, 0.85)" pb={0.5}>
      {value} {hide ? "" : <span>* &nbsp;</span>}
    </Box>
  );

  const save = async (data) => {
    setIsLoading(true);
    try {
      const orgSlug = localStorage.getItem("organizationSlug");
      if (!orgSlug) {
        throw new Error("Organization slug is missing from local storage");
      }
      if (isEdit.edit === true) {
        let result = await TLinkApi.put(`/o/${orgSlug}/panel/${id}`, data);
        await getData();
        setIsEdit({
          disabled: true,
          edit: false,
        });
        setIsLoading(false);
        enqueueSnackbar(result?.result?.userMessage, {
          variant: "success",
        });
        navigate(`/panels/${id}`);
      } else {
        let result = await TLinkApi.post(`/o/${orgSlug}/panel`, data);
        setIsLoading(false);
        enqueueSnackbar(result?.result?.userMessage, {
          variant: "success",
        });
        await getData();
        setIsEdit({
          disabled: true,
          edit: false,
        });
        navigate(`/panels/${result.data.id}`);
      }
    } catch (e) {
      setIsLoading(false);
      console.log(e);
      enqueueSnackbar(e?.response?.data?.result?.userMessage, {
        variant: "error",
      });
    }
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <form onSubmit={handleSubmit(save)} noValidate>
        {/* <Grid container spacing={2}>
          <Grid item xs={12} ml={{ xs: 0, md: "15px" }} mt="15px">
            <MDTypography variant="h6" color="#2F2F2F">
              Basic Details
            </MDTypography>
          </Grid>
          <Grid item xs={12} md={12} mt={0}>
            <hr style={{ border: `1px solid #C4C4C4`, width: "100%" }} />
          </Grid>
        </Grid> */}
        <MDBox
          pt={1}
          px={{ xs: 2, sm: 4, md: 8 }}
          style={{
            width: "100%",
            height: `calc(100vh - 240px)`,
            overflowY: "auto",
            background: "#fff",
            borderRadius: "20px",
            margin: "15px 0px",
          }}
        >
          {isEdit.disabled !== false && (
            <Box display="flex" justifyContent="flex-end" mb={1}>
              <IconButton
                style={{
                  variant: "contained",
                  color: "secondary",
                }}
                onClick={() => {
                  setIsEdit({ disabled: false, edit: true });
                }}
              >
                <ModeEditIcon />
              </IconButton>
            </Box>
          )}
          {isEdit.disabled !== true && (
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              gap="10px"
              mt={1}
              mr={1}
            >
              <Button
                variant="outlined"
                color="secondary"
                sx={{ color: "#000000" }}
                size="small"
                onClick={() => {
                  // getData();
                  setIsEdit({
                    disabled: true,
                    edit: false,
                  });
                }}
              >
                &nbsp;cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                sx={{ color: "#FFFFFF" }}
                size="small"
                type="submit"
              >
                Save
              </Button>
            </Box>
          )}
          {isEdit.edit === true || isEdit.disabled === false ? (
            <Grid container spacing={2} pt={2}>
              <Grid item xs={12} sm={6} md={4}>
                {label("Panel Name")}
                <Controller
                  name="name"
                  control={control}
                  rules={{
                    required: "Name is required!",
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      required
                      type="text"
                      autoComplete="off"
                      variant="outlined"
                      disabled={isEdit.disabled}
                      error={!!errors?.name}
                      helperText={errors?.name?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                {label("Point Conversion")}
                <Controller
                  name="panelConversion"
                  control={control}
                  rules={{
                    required: "Point Conversion is required!",
                    validate: (value) =>
                      value <= 100000 ||
                      "Value must be less than or equal to 1,00,000!",
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      required
                      type="number"
                      autoComplete="off"
                      variant="outlined"
                      disabled={isEdit.disabled}
                      error={!!errors?.panelConversion}
                      helperText={errors?.panelConversion?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                {label("Slug")}
                <Controller
                  name="slug"
                  control={control}
                  rules={{
                    required: "Slug is required!",
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      required
                      type="text"
                      autoComplete="off"
                      variant="outlined"
                      disabled={!isSlugEditable}
                      error={!!errors?.slug}
                      helperText={errors?.slug?.message}
                    />
                  )}
                />
              </Grid>
              <Tooltip title="Type to Search" placement="bottom-end" arrow>
                <Grid item xs={12} sm={6} md={4}>
                  {label("Currency")}
                  <Controller
                    name="currencyId"
                    control={control}
                    rules={{
                      required: "Currency Id is required!",
                    }}
                    render={({ field: { onChange, value } }) => (
                      <FormControl
                        fullWidth
                        required
                        error={errors?.currencyId ? true : false}
                        size="small"
                      >
                        <Autocomplete
                          options={currencies}
                          getOptionLabel={(option) => option.name}
                          value={
                            currencies.find(
                              (currency) => currency.id === value
                            ) || null
                          }
                          onChange={(event, newValue) => {
                            onChange(newValue ? newValue.id : null);
                          }}
                          disabled={isEdit.disabled}
                          renderInput={(params) => (
                            <MDInput
                              {...params}
                              error={!!errors?.currencyId}
                              helperText={
                                errors?.currencyId
                                  ? errors.currencyId.message
                                  : null
                              }
                            />
                          )}
                        />
                      </FormControl>
                    )}
                  />
                </Grid>
              </Tooltip>
              <Grid item xs={12} sm={6} md={4}>
                {label("Joining Point")}
                <Controller
                  name="joiningPoint"
                  control={control}
                  rules={{
                    required: "Joining Point is required!",
                    validate: (value) =>
                      value <= 100000 ||
                      "Value must be less than or equal to 1,00,000!",
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      required
                      type="number"
                      autoComplete="off"
                      variant="outlined"
                      disabled={isEdit.disabled}
                      error={!!errors?.joiningPoint}
                      helperText={errors?.joiningPoint?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                {label("Profiling Point")}
                <Controller
                  name="profilingPoint"
                  control={control}
                  rules={{
                    required: "Profiling Point is required!",
                    validate: (value) =>
                      value <= 100000 ||
                      "Value must be less than or equal to 1,00,000!",
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      required
                      type="number"
                      autoComplete="off"
                      variant="outlined"
                      disabled={isEdit.disabled}
                      error={!!errors?.profilingPoint}
                      helperText={errors?.profilingPoint?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                {label("Panel Joining Active")}
                <FormControlLabel
                  control={
                    <Switch
                size="large"
                      color="info"
                      size="small"
                      disabled={isEdit.disabled}
                      checked={panelJoiningIsActive}
                      onChange={(e, value) => {
                        setValue("panelJoiningIsActive", value);
                      }}
                    />
                  }
                />
              </Grid>
              {/* <Grid item xs={12} sm={6} md={4}>
                {label("Panel Default")}
                <FormControlLabel
                  control={
                    <Switch
                size="large"
                      color="info"
                      size="small"
                      disabled={isEdit.disabled}
                      checked={isDefault}
                      onChange={(e, value) => {
                        setValue("isDefault", value);
                      }}
                    />
                  }
                />
              </Grid> */}
              <Grid item xs={12} sm={6} md={4}>
                {label("Panel Active")}
                <FormControlLabel
                  control={
                    <Switch
                size="large"
                      color="info"
                      size="small"
                      disabled={isEdit.disabled}
                      checked={isActive}
                      onChange={(e, value) => {
                        setValue("isActive", value);
                      }}
                    />
                  }
                />
              </Grid>
              <Grid item xs={12}>
                {label("Description", true)}
                <Controller
                  name="description"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      multiline
                      placeholder="Type in here…"
                      rows={4}
                      disabled={isEdit.disabled}
                      {...field}
                      fullWidth
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                {label("Name", true)}
                <Typography
                  fontSize="14px"
                  fontWeight="600"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    maxWidth: "100%",
                  }}
                >
                  {panel?.name?.length > 30
                    ? `${panel.name.substring(0, 30)}...`
                    : panel?.name || "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                {label("Point Conversion", true)}
                <Typography fontSize="14px" fontWeight="600">
                  {panel?.panelConversion || "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                {label("Slug", true)}
                <Typography
                  fontSize="14px"
                  fontWeight="600"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    maxWidth: "100%",
                  }}
                >
                  {panel?.slug?.length > 30
                    ? `${panel.slug.substring(0, 30)}...`
                    : panel?.slug || "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                {label("Currency", true)}
                <Typography fontSize="14px" fontWeight="600">
                  {currencies.find((cur) => cur.id === panel?.currency?.id)
                    ?.name || "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                {label("Joining Point", true)}
                <Typography fontSize="14px" fontWeight="600">
                  {panel?.joiningPoint || "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                {label("Profiling Point")}
                <Typography fontSize="14px" fontWeight="600">
                  {panel?.profilingPoint || "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                {label("Panel Joining Active")}
                <FormControlLabel
                  control={
                    <Switch
                size="large"
                      checked={watch("panelJoiningIsActive")}
                      color="info"
                      size="small"
                      disabled
                    />
                  }
                />
              </Grid>
              {/* <Grid item xs={12} sm={6} md={4}>
                {label("Panel Default")}
                <FormControlLabel
                  control={
                    <Switch
                size="large"
                      checked={watch("isDefault")}
                      color="info"
                      size="small"
                      disabled
                    />
                  }
                />
              </Grid> */}
              <Grid item xs={12} sm={6} md={4}>
                {label("Panel Active")}
                <FormControlLabel
                  control={
                    <Switch
                size="large"
                      checked={watch("isActive")}
                      color="info"
                      size="small"
                      disabled
                    />
                  }
                />
              </Grid>
              <Grid item xs={12}>
                {label("Description", true)}
                <Typography
                  fontSize="14px"
                  fontWeight="600"
                  sx={{
                    display: "-webkit-box",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    WebkitLineClamp: 12,
                    WebkitBoxOrient: "vertical",
                    wordWrap: "break-word",
                    maxWidth: "100%",
                    whiteSpace: "normal",
                  }}
                >
                  {panel?.description?.slice(0, 500000) || "N/A"}
                </Typography>
              </Grid>
            </Grid>
          )}
        </MDBox>
      </form>
    </>
  );
}
export default PanelsBasicDetails;
