import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Backdrop,
  CircularProgress,
  Stack,
} from "@mui/material";
import MDBox from "../../../../components/MDBox";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import MDTypography from "../../../../components/MDTypography";
import { useParams } from "react-router-dom";
import moment from "moment";
import TLinkApi from "services/tlink.api";

function Profiling() {
  const [isLoading, setIsLoading] = useState(false);
  const [profiling, setProfiling] = useState([]);

  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [entriesPerPage, setEntriesPerPage] = useState(50);
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortBy, setSortBy] = useState("id");

  let { id } = useParams();

  const getProfiling = async () => {
    setIsLoading(true);

    try {
      const orgSlug = localStorage.getItem("organizationSlug");
      const panelSlug = localStorage.getItem("panelSlug");

      if (!orgSlug || !panelSlug) {
        throw new Error(
          "Organization or panel slug is missing from local storage"
        );
      }

      const result = await TLinkApi.get(
        `/o/${orgSlug}/panel/${panelSlug}/panelist/profiling/${id}/?search=${search}&sortOrder=${sortOrder}&sortBy=${sortBy}&page=${
          page + 1
        }&limit=${entriesPerPage}`
      );
      if (Array.isArray(result.data.panelistProfiling)) {
        setProfiling(result.data.panelistProfiling);
      } else {
        console.error(
          "Expected an array but got:",
          result.data.panelistProfiling
        );
        setProfiling([]);
      }

      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getProfiling();
  }, []);

  return (
    <MDBox sx={{background:"#fff", borderRadius:"15px"}} mt={1}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* <Grid container spacing={2}>
        <Grid item xs={12} ml="15px" mt="15px">
          <MDTypography variant="h6" color="#2F2F2F">
            Profiling
          </MDTypography>
        </Grid>
        <Grid item xs={12} md={12} mt={-1}>
          <hr style={{ border: `1px solid #C4C4C4`, width: "100%" }} />
        </Grid>
      </Grid> */}
      <MDBox
        style={{
          height: "calc(100vh - 240px)",
          overflowY: "auto",
        }}
      >
        <Grid container>
          {profiling.length > 0 ? (
            profiling?.map((response, index) => (
              <Grid
                item
                xs={12}
                key={index}
                sx={{
                  borderBottom: "1px solid #C1BBEB",
                  mt: "20px",
                }}
              >
                <Typography
                  variant="body2"
                  color="#AB95FF"
                  ml={4}
                  sx={{ fontSize: "0.875rem", lineHeight: "1.1" }}
                >
                  <span
                    style={{
                      fontSize: "1.4rem",
                      lineHeight: "0",
                      color: "#AFAFAF",
                    }}
                  >
                    {/* • */}
                  </span>{" "}
                  {response.question.text}
                </Typography>
                {response.answers?.map((ans, idx) => (
                  <Typography
                    variant="body2"
                    key={idx}
                    ml={4}
                    mt={1}
                    sx={{
                      color: "#424242",
                      fontSize: "0.875rem",
                      lineHeight: "1.2",
                      fontWeight: "light"
                    }}
                  >
                    {ans.text}
                  </Typography>
                ))}
                <Grid
                  container
                  justifyContent="flex-end"
                  alignItems="center"
                  ml={-4}
                >
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <AccessTimeIcon
                      sx={{ color: "#424242", fontSize: "small" }}
                    />
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "#424242",
                        fontSize: "0.75rem",
                        marginLeft: "2px",
                      }}
                    >
                      {response?.createdAt
                        ? moment(
                            moment(response.createdAt).local().format()
                          ).fromNow()
                        : "NA"}
                    </span>
                  </span>
                </Grid>
              </Grid>
            ))
          ) : (
            <Grid item xs={12} mt={2}>
              <Typography variant="body1" color="textSecondary" align="center">
                No records to display
              </Typography>
            </Grid>
          )}
        </Grid>
      </MDBox>
    </MDBox>
  );
}

export default Profiling;
