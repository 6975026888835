import MDBox from "components/MDBox";
import featuresLogo from "../../../assets/images/logos/featuresLogo.png"
import MDTypography from "components/MDTypography";
import { Button } from "@mui/material";

const PlanCard = ({imgIcon,planName,discountPrice,price,buttonText , features , currentplan, billingPeriod, onSelect }) => {
    return (
        <MDBox  sx={{display:"flex", flexDirection:"column", background:currentplan? "#DDDDDD": "white", width: "320px" ,borderRadius:"25px" }}>
            <MDBox padding={2} paddingBottom={0} >
                <img src={imgIcon} style={{ width: "50px" }} />
                <MDTypography variant="h3" color="black" sx={{fontSize:"26px", fontWeight:"medium",paddingTop:"10px" }}>
                    {planName}
                </MDTypography>
            </MDBox>
            <MDBox paddingX={2} sx={{ display: "flex", alignItems: "center", gap: "10px" }} >
                <MDTypography variant="h1" color="black" fontSize={"42px"} sx={{fontWeight:"500"}}>
                    ${discountPrice}
                </MDTypography>
                <MDBox sx={{ display: "flex", flexDirection: "column", gap: "0px" }}>
                    <MDTypography sx={{textDecoration:"line-through",  lineHeight:"14px" }} color="black" >
                        $ {price}
                    </MDTypography>
                    <MDTypography sx={{ fontSize:"12px" ,color:"#475467"}}>
                        / {billingPeriod}
                    </MDTypography>
                </MDBox>
            </MDBox>
            <MDBox padding={2} sx={{ flexGrow: 1,}}>
            {features.map((feature, index) => (
                    <MDTypography key={index} color="black" sx={{ display: "flex", alignItems: "flex-start", marginBottom: 1,fontSize:"16px", gap:"1px"}}>
                        <img src={featuresLogo} style={{width:"20px"}} alt=""  />{feature}
                    </MDTypography>
                ))}
            </MDBox>
            <MDBox margin={2}>
            <Button 
                onClick={onSelect} 
                disabled={currentplan} 
                sx={{
                background: currentplan ? "white" : "linear-gradient(180deg, #9F70FF 0%, #7732FF 100%)", 
                color: currentplan ? "#475467" : "#fff",
                "&:hover": {
                    background: currentplan ? "white" : "linear-gradient(180deg, #9F70FF 0%, #7732FF 100%)", 
                    color: currentplan ? "#475467" : "#fff"
                }
                }}  
                fullWidth
            >
                {buttonText}
            </Button>
            </MDBox>
        </MDBox>
    )
}
export default PlanCard;