import MDBox from "components/MDBox";
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { useEffect, useState } from "react";
import {
  IconButton,
  Tab,
  Tabs,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import WestIcon from "@mui/icons-material/West";
import MDTypography from "components/MDTypography";
import GetListOfProjects from "hooks/ListOfProjects/GetListOfProjects";
import colors from "assets/theme/base/colors";
import GetSurveyStatusList from "hooks/SurveyStatusList/GetSurveyStatusList";

function AddSurvey() {
  const [quotaQualificationQuestions, setQuotaQualificationQuestions] =
    useState([]);
  const [langId, setLangId] = useState(3);
  const location = useLocation();
  const { projects } = GetListOfProjects();
  const { surveyStatusList } = GetSurveyStatusList();
  let navigate = useNavigate();
  let { textColor } = colors;

  const [value, setValue] = useState(0);

  const handleChange = (_e, newValue) => {
    setValue(newValue);
  };

  const { survey, setSurvey, setIsEdit, isEdit } = useOutletContext();

  let { id } = useParams();

  useEffect(() => {
    if (window.location.pathname === `/surveys/${id}/qualification/edit`) {
      setValue(1);
    } else if (window.location.pathname === `/surveys/${id}/quota/edit`) {
      setValue(2);
    } else if (window.location.pathname === `/surveys/${id}/supplier/edit`) {
      setValue(3);
    } else if (
      window.location.pathname === `/surveys/${id}/securityConfig/edit`
    ) {
      setValue(4);
    } else if (window.location.pathname === `/surveys/${id}/report/edit`) {
      setValue(5);
    } else if (window.location.pathname === `/surveys/${id}/edit`) {
      setValue(0);
    } else if (window.location.pathname === `/surveys/new`) {
      setValue(0);
    } else if (window.location.pathname === `/surveys/${id}/panelists/edit`) {
      setValue(6);
    } else if (window.location.pathname === `/surveys/${id}/emails/edit`) {
      setValue(7);
    }
  }, [location]);

  useEffect(() => {
    projects.filter((value) => {
      if (survey.projectId === value.id) {
        return true;
      }
    });
  });

  const projectName = projects.find(
    (project) => project.id === survey.projectId
  )?.name;

  return (
    <MDBox>
      <MDBox
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <MDBox>
          <MDTypography
            mb={1}
            sx={{ fontSize: "16px", fontWeight: "400", alignItems: "Center" }}
          >
            <IconButton
              onClick={() => {
                setIsEdit({
                  disabled: false,
                  edit: false,
                });
                setSurvey({});
                navigate("/surveys");
              }}
            >
              <WestIcon fontSize="small" color="dark" />
            </IconButton>
            {id ? survey.surveyName : "New Survey"}
          </MDTypography>
          <MDBox style={{ display: "flex", alignItems: "center" }}>
            <FormControl style={{ minWidth: "100px" }}>
              <InputLabel>Status</InputLabel>
              <Select label="Status" defaultValue={2}>
                <MenuItem value={-1}>All</MenuItem>
                {surveyStatusList?.map((value, i) => {
                  return (
                    <MenuItem key={i} value={value.id}>
                      {value.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <MDTypography sx={{ fontSize: "16px", fontWeight: "400" }}>
              {projectName ? (
                <ul style={{ display: "flex" }}>
                  <li
                    style={{ marginLeft: "30px", cursor: "pointer" }}
                    onClick={(e) => {
                      navigate(`/projects/${survey.projectId}`);
                    }}
                  >
                    {projectName}
                  </li>
                  <li
                    style={{ marginLeft: "30px", cursor: "pointer" }}
                    onClick={(e) => {
                      navigate("/surveys");
                    }}
                  >
                    Surveys
                  </li>
                  <li style={{ marginLeft: "30px" }}>{id ? id : ""}</li>
                </ul>
              ) : (
                ""
              )}
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox></MDBox>
      </MDBox>
      <Tabs
        textColor="primary"
        indicatorColor="primary"
        value={value}
        onChange={handleChange}
        style={{ borderBottom: `1px solid ${textColor.focus}` }}
      >
        <Tab
          label="Basic Details"
          index={0}
          component={Link}
          to={id ? `/surveys/${id}/edit` : `/surveys/new`}
        />
        <Tab
          label="qualification"
          index={1}
          component={Link}
          disabled={!id}
          to={`/surveys/${id}/qualification/edit`}
        />
        <Tab
          label="quota"
          index={2}
          component={Link}
          to={`/surveys/${id}/quota/edit`}
          disabled={!id}
        />
        <Tab
          label="supplier"
          index={3}
          component={Link}
          to={`/surveys/${id}/supplier/edit`}
          disabled={!id}
        />
        <Tab
          label="Security Config"
          index={4}
          component={Link}
          to={`/surveys/${id}/securityConfig/edit`}
          disabled={!id}
        />
        <Tab
          label="Survey Reports"
          index={5}
          component={Link}
          to={`/surveys/${id}/report/edit`}
          disabled={!id}
        />
        <Tab
          label="Panelists"
          index={6}
          component={Link}
          to={`/surveys/${id}/panelists/edit`}
          disabled={!id}
        />
        <Tab
          label="Emails"
          index={7}
          component={Link}
          to={`/surveys/${id}/emails/edit`}
          disabled={!id}
        />
      </Tabs>
      <Outlet
        context={{
          quotaQualificationQuestions,
          setQuotaQualificationQuestions,
          survey,
          setSurvey,
          setIsEdit,
          isEdit,
          setLangId,
          langId,
        }}
      />
    </MDBox>
  );
}

export default AddSurvey;
