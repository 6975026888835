import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Grid,
  Typography,
  Box,
  Stack,
  IconButton,
  Button,
} from "@mui/material";
import MDInput from "components/MDInput";

function Tier({
  tierIndex,
  GetAllTiers,
  GetMasterTiers,
  tierData,
  setTierData,
  isEdit,
  setIsEdit,
  errorData,
  setErrorData,
  market,
}) {
  const {
    control,
    watch,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    formState,
  } = useForm({
    mode: "all",
    defaultValues: {
      tierTypeId: "",
      minimumBalance: "",
      minimumPoints: "",
      maximumPoints: "",
      tierName: "",
    },
  });

  let tierTypeId = watch("tierTypeId");
  let minimumBalance = watch("minimumBalance");
  let minimumPoints = watch("minimumPoints");
  let maximumPoints = watch("maximumPoints");
  let tierName = watch("tierName");

  useEffect(() => {
    if (tierData && tierIndex) {
      setValue(
        "tierTypeId",
        tierData.find((tier) => tier?.tierTypeId === tierIndex).tierTypeId
      );
      setValue(
        "minimumBalance",
        tierData.find((tier) => tier?.tierTypeId === tierIndex).minimumBalance
      );
      setValue(
        "minimumPoints",
        tierData.find((tier) => tier?.tierTypeId === tierIndex).minimumPoints
      );
      setValue(
        "maximumPoints",
        tierData.find((tier) => tier?.tierTypeId === tierIndex).maximumPoints
      );
      setValue(
        "tierName",
        tierData.find((tier) => tier?.tierTypeId === tierIndex).tierName
      );
    }
  }, [tierData, tierIndex]);

  useEffect(() => {
    if (Object.keys(formState.errors).length === 0) {
      let newErrorData = errorData;
      let index = newErrorData.findIndex(
        (error) => error.tierTypeId === tierIndex
      );
      if (index > -1) {
        newErrorData.splice(index, 1);
      }
      setErrorData(newErrorData);
    } else {
      let newErrorData = errorData;
      let index = newErrorData.findIndex(
        (error) => error.tierTypeId === tierIndex
      );
      if (index > -1) {
        newErrorData[index] = {
          tierTypeId: tierIndex,
          errors: formState.errors,
        };
      } else {
        newErrorData.push({
          tierTypeId: tierIndex,
          errors: formState.errors,
        });
      }
      setErrorData(newErrorData);
    }
  }, [formState]);

  const label = (value, hide) => (
    <Box fontWeight="400" fontSize="12px" color="rgba(0, 0, 0, 0.85)" pb={0.5}>
      {value} {hide ? "" : <span>* &nbsp;</span>}
    </Box>
  );

  return (
    <>
      <form>
        <Box sx={{ marginBottom: "2rem" }}>
          {isEdit.edit === true ? (
            <>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography
                  variant="h6"
                  sx={{
                    marginBottom: "0.5rem",
                    fontWeight: 600,
                    color: "#333",
                  }}
                >
                  {"Tier" + tierIndex}
                </Typography>
                <Stack
                  direction="row"
                  spacing={20}
                  sx={{
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <Grid item xs={12} md={2}>
                    <Controller
                      name="tierName"
                      control={control}
                      rules={{
                        required: "Tier Name is required!",
                      }}
                      render={({ field }) => (
                        <MDInput
                          label="Tier Name"
                          {...field}
                          fullWidth
                          required
                          autoComplete="off"
                          {...register("tierName", {
                            onChange: (e) => {
                              let newTierData = tierData;
                              newTierData.find(
                                (tier) => tier.tierTypeId === tierIndex
                              ).tierName = e.target.value;
                              setTierData(newTierData);
                            },
                          })}
                          variant="outlined"
                          disabled={isEdit.disabled}
                          error={errors?.tierName ? true : false}
                          helperText={
                            errors?.tierName ? errors.tierName.message : null
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Controller
                      name="minimumPoints"
                      control={control}
                      rules={{
                        required: "Minimum Points is required!",
                      }}
                      render={({ field }) => (
                        <MDInput
                          label="Minimum Points"
                          {...field}
                          fullWidth
                          required
                          type="number"
                          autoComplete="off"
                          {...register("minimumPoints", {
                            onChange: (e) => {
                              let newTierData = tierData;
                              newTierData.find(
                                (tier) => tier.tierTypeId === tierIndex
                              ).minimumPoints = parseInt(e.target.value);
                              setTierData(newTierData);
                            },
                          })}
                          variant="outlined"
                          disabled={isEdit.disabled}
                          error={errors?.minimumPoints ? true : false}
                          helperText={
                            errors?.minimumPoints
                              ? errors.minimumPoints.message
                              : null
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Controller
                      name="maximumPoints"
                      control={control}
                      rules={{
                        required: "MaximumPoints is required!",
                      }}
                      render={({ field }) => (
                        <MDInput
                          label="Maximum Points"
                          {...field}
                          fullWidth
                          required
                          type="number"
                          autoComplete="off"
                          {...register("maximumPoints", {
                            onChange: (e) => {
                              let newTierData = tierData;
                              newTierData.find(
                                (tier) => tier.tierTypeId === tierIndex
                              ).maximumPoints = parseInt(e.target.value);
                              setTierData(newTierData);
                            },
                          })}
                          variant="outlined"
                          disabled={isEdit.disabled}
                          error={errors?.maximumPoints ? true : false}
                          helperText={
                            errors?.maximumPoints
                              ? errors.maximumPoints.message
                              : null
                          }
                        />
                      )}
                    />
                  </Grid>
                </Stack>
              </Box>
            </>
          ) : (
            <Box sx={{ marginBottom: "3rem" }}>
              <Typography
                variant="h6"
                sx={{
                  marginBottom: "0.5rem",
                  fontWeight: 600,
                  color: "#333",
                }}
              >
                {"Tier" + tierTypeId}
              </Typography>
              <Stack
                direction="row"
                spacing={10}
                sx={{
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  marginBottom: "1rem",
                }}
              >
                <Grid item xs={12} md={3}>
                  {label("Tier Name")}
                  <Typography fontSize="14px" fontWeight="400">
                    {tierName || "N/A"}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                  {label("Minimum Points")}
                  <Typography fontSize="14px" fontWeight="400">
                    {minimumPoints || "N/A"}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                  {label("Maximum Points")}
                  <Typography fontSize="14px" fontWeight="400">
                    {maximumPoints || "N/A"}
                  </Typography>
                </Grid>
              </Stack>
            </Box>
          )}
        </Box>
      </form>
    </>
  );
}

export default Tier;
