import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Stack,
  ThemeProvider,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import React, { useEffect, useMemo, useState } from "react";
import useTableTheme from "constant/TableTheme";
import MDTypography from "components/MDTypography";
import EmailDetailIcon from "assets/images/email-detail.svg";
import PanelistDetailIcon from "assets/images/panelist-detail.svg";
import SurveyDetailIcon from "assets/images/survey-detail.svg";
import SystemCapacityIcon from "assets/images/system-capacity.svg";
import TLinkApi from "services/tlink.api";
import { useParams } from "react-router-dom";
import EmailBatchCreatePopup from "./components/EmailCreate";
import { Delete } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import TemplatePreviewPopup from "./components/TemplatePreview";
import RefreshIcon from "@mui/icons-material/Refresh";
import MDButton from "components/MDButton";

const statusColor = (type) => {
  if (type === 1) {
    return "#fc8900";
  } else if (type === 2) {
    return "#0385ff";
  } else if (type === 3) {
    return "#fce903";
  } else if (type === 4) {
    return "#03ff0e";
  } else if (type === 5) {
    return "#ff0308";
  }
};

const statusBackgroundColor = (type) => {
  if (type === 1) {
    return "#ffead1";
  } else if (type === 2) {
    return "#E2F1FF";
  } else if (type === 3) {
    return "#fffcd9";
  } else if (type === 4) {
    return "#E1F7E2";
  } else if (type === 5) {
    return "#ffd4d5";
  }
};

const EmailsTable = () => {
  const tableTheme = useTableTheme();
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 50,
  });
  const [sorting, setSorting] = useState([]);
  const [totalLength, setTotalLength] = useState(0);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortBy, setSortBy] = useState("batchId");
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [entriesPerPage, setEntriesPerPage] = useState(50);
  const [batchCount, setBatchCount] = useState(null);
  const [addBatch, setAddBatch] = useState(false);
  const [templateData, setTemplateData] = useState(null);
  let { enqueueSnackbar } = useSnackbar();

  let { id } = useParams();
  const orgSlug = localStorage.getItem("organizationSlug");
  const panelSlug = localStorage.getItem("panelSlug");

  const fetchAllEmailBatch = async () => {
    setIsLoading(true);

    try {
      if (!orgSlug || !panelSlug) {
        throw new Error("Organization slug is missing from local storage");
      }
      const response = await TLinkApi.get(
        `/o/${orgSlug}/panel/${panelSlug}/survey/${id}/email/batches/?search=${search}&sortOrder=${sortOrder}&sortBy=${sortBy}&page=${page + 1
        }&limit=${entriesPerPage}`
      );
      setData(
        response.data.surveyEmailBatches ? response.data.surveyEmailBatches : []
      );
      setTotalLength(response.data.totalLength);
      setIsLoading(false);
    } catch (error) {
      console.log("Error fetching panelists from API:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAllEmailBatch();
  }, [search, sortBy, sortOrder, page, entriesPerPage]);

  const fetchEmailBatchCount = async () => {
    setIsLoading(true);

    try {
      if (!orgSlug || !panelSlug) {
        throw new Error("Organization slug is missing from local storage");
      }
      const response = await TLinkApi.get(`/o/${orgSlug}/panel/${panelSlug}/survey/${id}/email/counts`);
      setBatchCount(response.data);
      setIsLoading(false);
    } catch (error) {
      console.log("Error fetching panelists from API:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchEmailBatchCount();
  }, []);

  const deleterow = async (batchId) => {
    try {
      setIsLoading(true);

      const data = await TLinkApi.del(`/survey/${id}/email/batch/${batchId}`);
      await fetchAllEmailBatch();
      await fetchEmailBatchCount();
      setIsLoading(false);
      enqueueSnackbar(data?.result?.userMessage, {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar(err?.response?.data?.result?.userMessage, {
        variant: "error",
      });
      setIsLoading(false);
      console.log(err);
    }
  };

  const GetTemplateData = async (templateId) => {
    setIsLoading(true);
    try {
      if (!orgSlug || !panelSlug) {
        throw new Error("Organization slug is missing from local storage");
      }
      const result = await TLinkApi.get(`o/${orgSlug}/panel/${panelSlug}/email/template/${templateId}`);
      if (result) {
        setTemplateData(result.data);
      }
      setIsLoading(false);
      enqueueSnackbar(result?.userMessage, {
        variant: "success",
      });
    } catch (e) {
      console.log(e);
      enqueueSnackbar(e?.response?.data?.result?.userMessage, {
        variant: "error",
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (sorting?.length === 1) {
      if (sorting[0].desc === false) {
        setSortBy(sorting[0].id);
        setSortOrder("asc");
      } else {
        setSortBy(sorting[0].id);
        setSortOrder("desc");
      }
    } else if (sorting.length === 0) {
      setSortBy("batchId");
      setSortOrder("asc");
    }
  }, [sorting]);

  useEffect(() => {
    setPage(pagination.pageIndex);
    setEntriesPerPage(pagination.pageSize);
  }, [pagination]);

  const totalScore = useMemo(() => {
    const totalPoints = data.reduce(
      (acc, row) => Number(acc) + Number(row.total),
      0
    );
    return Number(totalPoints);
  }, [data]);

  const sentScore = useMemo(() => {
    const sentPoints = data.reduce(
      (acc, row) => Number(acc) + Number(row.sent),
      0
    );
    return Number(sentPoints);
  }, [data]);

  let columns = [
    {
      accessorKey: "batchId",
      header: "Batch ID",
      size: 150,
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      muiTableFooterCellProps: {
        align: "center",
      },
      Footer: (row) => (
        <MDTypography variant="body2" sx={{ fontWeight: "700" }}>
          Total
        </MDTypography>
      ),
    },
    {
      accessorKey: "total",
      header: "Total",
      size: 150,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        sx: { textAlign: "left" },
      },
      Footer: (row) => (
        <Stack>
          <Box color="warning.main">{totalScore}</Box>
        </Stack>
      ),
    },
    // {
    //   accessorKey: "sent",
    //   header: "Sent",
    //   size: 150,
    //   muiTableHeadCellProps: {
    //     align: "left",
    //   },
    //   muiTableBodyCellProps: {
    //     sx: { textAlign: "left" },
    //   },
    //   Footer: (row) => (
    //     <Stack>
    //       <Box color="warning.main">{sentScore}</Box>
    //     </Stack>
    //   ),
    // },
    {
      accessorKey: "failed",
      header: "Failed",
      size: 75,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        sx: { textAlign: "left" },
      },
      Cell: (row) => {
        const failed = row?.row?.original?.failed; // Failed value
        return (
          <Typography variant="caption" sx={{ color: "#f44336" }}>
            {failed}
          </Typography>
        );
      },
    },
    {
      accessorKey: "delivered",
      header: "Delivered",
      size: 75,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        sx: { textAlign: "left" },
      },
      Cell: (row) => {
        const delivered = row?.row?.original?.delivered; // Delivered value
        return (
          <Typography variant="caption" sx={{ color: "#4caf50" }}>
            {delivered}
          </Typography>
        );
      },
    },
    {
      accessorKey: "jobStatusName",
      header: "Status",
      size: 150,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        sx: { textAlign: "left" },
      },
      Cell: (row) => {
        return (
          <div
            style={{
              borderRadius: "0.8rem",
              width: "max-content",
              padding: "0.3rem 0.5rem",
              fontSize: "10px",
              color: statusColor(row?.row?.original?.jobStatus.id),
              background: statusBackgroundColor(
                row?.row?.original?.jobStatus.id
              ),
              border: `1px solid ${statusColor(
                row?.row?.original?.jobStatus.id
              )}`,
            }}
          >
            {row?.row?.original?.jobStatus?.name}
          </div>
        );
      },
    },
    {
      accessorKey: "deliverd",
      header: "Actions",
      size: 150,
      enableSorting: false,
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      Cell: ({ row }) => (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <IconButton
            size="small"
            onClick={() => {
              GetTemplateData(row?.original?.templateId);
            }}
          >
            <RemoveRedEyeIcon fontSize="small" />
          </IconButton>

          <IconButton
            disabled={
              row?.original?.jobStatus?.id === 1 ||
                row?.original?.jobStatus?.id === 5
                ? false
                : true
            }
            size="small"
            onClick={() => {
              deleterow(row?.original?.batchId);
            }}
          >
            <Delete fontSize="small" color="error" />
          </IconButton>
        </Stack>
      ),
    },
  ];

  const table = useMaterialReactTable({
    columns,
    data,
    enableStickyHeader: true,
    manualPagination: true,
    rowCount: totalLength,
    onPaginationChange: setPagination,
    state: { density: "compact", pagination, sorting, globalFilter: search },
    enableColumnPinning: true,
    enableGlobalFilter: true,
    enableDensityToggle: false,
    enableStickyFooter: true,
    manualSorting: true,
    muiPaginationProps: {
      rowsPerPageOptions: [25, 50, 100],
    },
    muiTableContainerProps: {
      sx: {
        minHeight: "calc(100vh - 440px)",
        maxHeight: "calc(100vh - 350px)",
        overflowY: "auto",
      },
    },
    onSortingChange: setSorting,
    onGlobalFilterChange: setSearch,
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        width: "100%",
        borderRadius: "0",
        backgroundColor: "#fff !important",
        zIndex: 500,
      },
    },
    muiTableFooterProps: {
      sx: {
        backgroundColor: "#fff",
      },
    },
    renderEmptyRowsFallback: () => (
      <Stack
        display="flex"
        alignItems="center"
        justifyContent="center"
        fontSize="14px"
        p={5}
      >
        No records to display
      </Stack>
    ),
  });

  return (
    <Box
      mt={2}
      style={{
        backgroundColor: "#fff",
        borderRadius: "15px",
      }}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 2000 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <TemplatePreviewPopup
        templateData={templateData}
        setTemplateData={setTemplateData}
      />
      <EmailBatchCreatePopup
        setAddBatch={setAddBatch}
        addBatch={addBatch}
        fetchAllEmailBatch={fetchAllEmailBatch}
        fetchEmailBatchCount={fetchEmailBatchCount}
      />
      <Stack
        sx={{ padding: "1rem" }}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack direction="row" alignItems="center" spacing={4}>
          <MDTypography variant="h5" color="black">
            Emails
          </MDTypography>
          <Stack direction="row" alignItems="center" spacing={1}>
            <img
              src={PanelistDetailIcon}
              style={{
                height: "4cap",
              }}
            />
            <Stack>
              <MDTypography variant="body2" color="gray">
                {Number(batchCount?.panelistsCount)}
              </MDTypography>
              <MDTypography
                variant="caption"
                color="black"
                sx={{ fontWeight: 700 }}
              >
                Total Panelist
              </MDTypography>
            </Stack>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            <img
              src={SurveyDetailIcon}
              style={{
                height: "4cap",
              }}
            />
            <Stack>
              <MDTypography variant="body2" color="gray">
                {Number(batchCount?.surveyApplicablePanelistsCount)}
              </MDTypography>
              <MDTypography
                variant="caption"
                color="black"
                sx={{ fontWeight: 700 }}
              >
                Survey Panelist
              </MDTypography>
            </Stack>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            <img
              src={EmailDetailIcon}
              style={{
                height: "4cap",
              }}
            />
            <Stack>
              <MDTypography variant="body2" color="gray">
                {Number(batchCount?.emailApplicablePanelistsCount)}
              </MDTypography>
              <MDTypography
                variant="caption"
                color="black"
                sx={{ fontWeight: 700 }}
              >
                Email Applicable
              </MDTypography>
            </Stack>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            <img
              src={SystemCapacityIcon}
              style={{
                height: "4cap",
              }}
            />
            <Stack>
              <MDTypography variant="body2" color="gray">
                {Number(batchCount?.systemCapacity)}
              </MDTypography>
              <MDTypography
                variant="caption"
                color="black"
                sx={{ fontWeight: 700 }}
              >
                System Capacity
              </MDTypography>
            </Stack>
          </Stack>
        </Stack>
        <Stack direction="row" spacing={2}>
          <Tooltip title="Refresh Page">
            <IconButton
              color="primary"
              onClick={() => window.location.reload()}
            >
              <RefreshIcon />
            </IconButton>
          </Tooltip>
          <Tooltip
            title={
              Number(batchCount?.systemCapacity) === 0
                ? "System has no capacity"
                : Number(batchCount?.systemCapacity) === totalScore
                  ? "Your system capacity is full you can't add batch"
                  : ""
            }
          >
            <div>
              <MDButton
                variant="contained"
                color="info"
                sx={{ color: "#FFFFFF", mb: 1 }}
                size="small"
                disabled={
                  Number(batchCount?.systemCapacity) === 0
                    ? true
                    : Number(batchCount?.systemCapacity) === totalScore
                      ? true
                      : false
                }
                onClick={() => {
                  setAddBatch(true);
                }}
              >
                + Email batch
              </MDButton>
            </div>
          </Tooltip>
        </Stack>
      </Stack>
      <hr style={{ border: `1px solid #C4C4C4`, width: "100%" }} />
      <ThemeProvider theme={tableTheme}>
        <MaterialReactTable
          table={table}
          style={{
            width: "100%",
          }}
        />
      </ThemeProvider>
    </Box>
  );
};

export default EmailsTable;
