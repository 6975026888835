import { useState, useEffect } from "react";
import { Grid, Typography, Tabs, Tab, Backdrop, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import KeyboardBackspaceSharpIcon from "@mui/icons-material/KeyboardBackspaceSharp";
import { useNavigate } from "react-router-dom";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import PlanCard from "./plan-card";
import BasicPlanIcon from "../../assets/images/logos/basicPlanIcon.png";
import advancedPlanIcon from "../../assets/images/logos/advancedPlanIcon.png";
import PlusPlanIcon from "../../assets/images/logos/PlusPlanIcon.png";
import TLinkApi from "services/tlink.api";
import { useSnackbar } from "notistack";

const Plan = () => {
  const [plans, setPlans] = useState([]);
  const [value, setValue] = useState("Monthly");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null); 
  const [openDialog, setOpenDialog] = useState(false); 
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const fetchPlans = async () => {
    setIsLoading(true);
    try {
      const response = await TLinkApi.get(`/subscription`);
      setPlans(response.data.Plans);
    } catch (error) {
      console.error("Error fetching plans:", error);
    } finally {
      setIsLoading(false);
    }
  };
  
  const getImageForPlan = (planName) => {
    if (planName.includes("Basic")) return BasicPlanIcon;
    if (planName.includes("Advanced")) return advancedPlanIcon;
    if (planName.includes("Premium")) return PlusPlanIcon;
    return null;
  };

  const fetchUserData = async () => {
    try {
      const response = await TLinkApi.get(`/users/current`);
      console.log("User Data:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching user data:", error);
      return null;
    }
  };
  
  const handleSelectPlan = (plan) => {
    setSelectedPlan(plan);  
    setOpenDialog(true);    
  };  

  const handleProceed = async () => {
    setIsLoading(true);
    setOpenDialog(false);
  
    try {
      const response = await TLinkApi.post(`/subscription/upgradeplan`, {
        newPlanId: selectedPlan.id,
        oldPlanId: plans.find((plan) => plan.isActive)?.id, // have to add the concept of billing month
      });
      await handleRequest();
    } catch (error) {
      enqueueSnackbar("Error to generate upgrade plan request:", {
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };
  

  const handleRequest = async () => {
    setIsLoading(true);
    setOpenDialog(false);
  
    const userData = await fetchUserData();
    const accountId = userData?.id;
    const oldSubscriptionsId = plans.find((plan) => plan.isActive)?.id;       // have to add the concept of billing month 
    const newSubscriptionsId = selectedPlan?.id;

    console.log("Request Payload:", {
      accountId: accountId,
      oldSubscriptionsId: oldSubscriptionsId,
      newSubscriptionsId: newSubscriptionsId,
    });
  
    // Checking for null values
    if (!accountId || !oldSubscriptionsId || !newSubscriptionsId) {
      enqueueSnackbar("Missing required subscription data", { variant: "error" });
      setIsLoading(false);
      return;
    }
  
    try {
      const response = await TLinkApi.post(`/subscription/createsubscriptionrequest`, {
        accountId: accountId,
        oldSubscriptionId: oldSubscriptionsId,
        newSubscriptionId: newSubscriptionsId,
        status: 'pending',
      });
      enqueueSnackbar("Request to upgrade plan has been generated!", { variant: "success" });
    } catch (error) {
      console.error("Error to generate upgrade plan request:", error);
      enqueueSnackbar("Error to generate upgrade plan request", { variant: "error" });
    }
     finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    setOpenDialog(false); 
  };

  useEffect(() => {
    fetchPlans();
  }, []);

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid
        container
        alignItems={"center"}
        justifyContent={"space-between"}
        marginBottom={5}
        paddingRight={4}
        marginTop={5}
      >
        <Grid container alignItems={"center"} gap={2} width={"fit-content"}>
          <KeyboardBackspaceSharpIcon color="black" onClick={handleBack} sx={{cursor: "pointer"}}/>
          <Typography fontSize={"32px"} fontWeight={400} color="Black">
            Subscription Plan
          </Typography>
        </Grid>
        <MDBox>
          <Tabs
            value={value}
            onChange={handleChange}
            sx={{
              padding: "2px",
              paddingLeft: "3px",
              paddingBottom: "3px",
              background: "#A289FD",
              borderRadius: "10px",
              minHeight: "30px",
              "& .MuiTabs-indicator": { display: "none" },
            }}
          >
            <Tab
              value="Monthly"
              label="Monthly"
              sx={{
                marginRight: "4px",
                padding: "0px",
                minHeight: "35px",
                background: value === "Monthly" ? "white" : "",
                color: value === "Monthly" ? "#000" : "#fff",
                borderBottom: value === "Monthly" ? "0px" : "",
                borderRadius: "10px",
              }}
            />
            <Tab
              value="Annually"
              label="Annually"
              sx={{
                padding: "0px",
                minHeight: "35px",
                background: value === "Annually" ? "white" : "",
                color: value === "Annually" ? "#000" : "#fff",
                borderBottom: value === "Annually" ? "0px" : "",
                borderRadius: "10px",
              }}
            />
          </Tabs>
        </MDBox>
      </Grid>

      <MDBox sx={{ maxHeight: "calc(100vh - 155px)", overflow: "auto" }}>
        {value === "Monthly" && (
          <MDBox sx={{ display: "flex", justifyContent: "center", alignItem: "center", gap: "20px" }}>
            {plans.map((plan, index) => (
              <PlanCard
                key={index}
                imgIcon={getImageForPlan(plan.subscriptionName)}
                planName={plan.subscriptionName}
                discountPrice={plan.monthlyDiscountedCost}
                price={plan.monthlyCost}
                buttonText={plan.isActive ? "Current Plan" : "Select Plan"}
                features={Object.values(plan.features)}
                // currentplan={plan.isActive}      // have to add the concept of billing month 
                billingPeriod="per month"
                onSelect={() => handleSelectPlan(plan)}  
              />
            ))}
          </MDBox>
        )}

        {value === "Annually" && (
          <MDBox
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItem: "center",
              gap: "20px",
            }}
          >
            {plans.map((plan, index) => (
              <PlanCard
                key={index}
                imgIcon={getImageForPlan(plan.subscriptionName)}
                planName={plan.subscriptionName}
                discountPrice={plan.annualDiscountedCost}
                price={plan.annualCost}
                buttonText={plan.isActive ? "Current Plan" : "Select Plan"}
                features={Object.values(plan.features)}
                // currentplan={plan.isActive}            // have to add the concept of billing month 
                billingPeriod="per year"
                onSelect={() => handleSelectPlan(plan)}  
              />
            ))}
          </MDBox>
        )}
      </MDBox>

      <Dialog open={openDialog} onClose={handleCancel}>
        <DialogTitle>Confirm upgrade</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you want to upgrade to the {selectedPlan?.subscriptionName} plan ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleCancel} color="secondary" variant="outlined">
            Cancel
          </MDButton>
          <MDButton onClick={handleProceed} color="info" variant="contained">
            Proceed
          </MDButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Plan;
