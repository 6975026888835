import DoneIcon from "@mui/icons-material/Done";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListSubheader,
  MenuItem,
  Modal,
  Select,
  Stack,
  Switch,
  TextField,
  ThemeProvider,
  Tooltip,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import useTableTheme from "constant/TableTheme";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TLinkApi from "services/tlink.api";

function PanelistsTable() {
  const [isLoading, setIsLoading] = useState(false);
  const [panelists, setPanelists] = useState([]);
  const [panelist, setPanelist] = useState([]); // this is for dropdown data
  const [filteredPanelist, setFilteredPanelist] = useState([]);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 50,
  });
  const [sorting, setSorting] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [openModal, setOpenModal] = useState(false);
  const [selectedPanelist, setSelectedPanelist] = useState([]);
  const [points, setPoints] = useState("");

  const [editing, setEditing] = useState(null);
  const [newPoints, setNewPoints] = useState("");

  let { id } = useParams();
  const tableTheme = useTableTheme();

  const [search, setSearch] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortBy, setSortBy] = useState("id");
  const [page, setPage] = useState(0);
  const [totalLength, setTotalLength] = useState(0);
  const [entriesPerPage, setEntriesPerPage] = useState(50);

  const fetchAllPanelists = async () => {
    setIsLoading(true);
    try {
      const orgSlug = localStorage.getItem("organizationSlug");
      const panelSlug = localStorage.getItem("panelSlug");
      if (!orgSlug) {
        throw new Error("Organization slug is missing from local storage");
      }
      const response = await TLinkApi.get(
        `/o/${orgSlug}/panel/${panelSlug}/survey/${id}/allpanelist`
      );
      setPanelist(response.data);
      setFilteredPanelist(response.data);
      setIsLoading(false);
    } catch (error) {
      console.log("Error fetching panelists from API:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (search) {
      const filtered = panelist.filter((p) =>
        p.name.toLowerCase().includes(search.toLowerCase())
      );
      setFilteredPanelist(filtered);
    } else {
      setFilteredPanelist(panelist);
    }
  }, [search, panelist]);

  useEffect(() => {
    fetchAllPanelists();
  }, []);

  const getPanelists = async () => {
    setIsLoading(true);
    try {
      const orgSlug = localStorage.getItem("organizationSlug");
      const panelSlug = localStorage.getItem("panelSlug");
      if (!orgSlug) {
        throw new Error("Organization slug is missing from local storage");
      }

      let result = await TLinkApi.get(
        `/o/${orgSlug}/panel/${panelSlug}/survey/${id}/panelists?search=${search}&sortOrder=${sortOrder}&sortBy=${sortBy}&page=${
          page + 1
        }&limit=${entriesPerPage}`
      );
      setData(result.data);
      setTotalLength(result.data.totalLength);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getPanelists();
  }, [search, sortBy, sortOrder, page, entriesPerPage]);

  useEffect(() => {
    setPage(pagination.pageIndex);
    setEntriesPerPage(pagination.pageSize);
  }, [pagination]);

  useEffect(() => {
    if (sorting?.length === 1) {
      if (sorting[0].desc === false) {
        setSortBy(sorting[0].id);
        setSortOrder("asc");
      } else {
        setSortBy(sorting[0].id);
        setSortOrder("desc");
      }
    } else if (sorting.length === 0) {
      setSortBy("id");
      setSortOrder("asc");
    }
  }, [sorting]);

  const handleOpenModal = () => {
    setOpenModal(true);
    fetchAllPanelists();
  };
  const handleCloseModal = () => {
    setSelectedPanelist([]);
    setPoints("");
    setOpenModal(false);
  };

  const handleAddPanelist = async () => {
    setIsLoading(true);
    try {
      const orgSlug = localStorage.getItem("organizationSlug");
      const panelSlug = localStorage.getItem("panelSlug");

      if (!selectedPanelist || selectedPanelist.length === 0 || !points) {
        enqueueSnackbar(
          "Please fill both the fields i.e. Panelists & Points !!!",
          {
            variant: "error",
          }
        );
        return;
      }

      const payload = {
        panelistIds: selectedPanelist,
        points: points,
        surveyId: id,
      };

      const response = await TLinkApi.post(
        `/o/${orgSlug}/panel/${panelSlug}/survey/panelist/add`,
        payload
      );
      if (response.result.resultCode === 2000) {
        enqueueSnackbar("Panelist added successfully", {
          variant: "success",
        });
        getPanelists();
        handleCloseModal();
      } else {
        enqueueSnackbar("Error adding panelist:", response.data.message);
      }
    } catch (error) {
      enqueueSnackbar("API error:", error, {
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdate = async (panelistId, newPoints, currentStatus = null) => {
    setIsLoading(true);
    try {
      const orgSlug = localStorage.getItem("organizationSlug");
      const panelSlug = localStorage.getItem("panelSlug");

      if (!orgSlug || !panelSlug) {
        enqueueSnackbar("Missing organization or panel slug", {
          variant: "error",
        });
        return;
      }

      const payload = {
        panelistId: panelistId,
        surveyId: id,
        ...(newPoints !== null ? { points: newPoints } : {}),
        ...(currentStatus !== null ? { isActive: !currentStatus } : {}),
      };

      const response = await TLinkApi.patch(
        `/o/${orgSlug}/panel/${panelSlug}/survey/panelist/update`,
        payload
      );

      if (response.result.resultCode === 2000) {
        enqueueSnackbar(
          newPoints !== null
            ? "Points updated successfully"
            : "Panelist action updated successfully",
          { variant: "success" }
        );
        getPanelists();
        setEditing(null);
        setNewPoints("");
      } else {
        enqueueSnackbar("Error updating panelist:", response.data.message, {
          variant: "error",
        });
      }
    } catch (error) {
      enqueueSnackbar("API error:", error, { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const columns = [
    {
      accessorKey: "panelistId",
      header: "Panelist ID",
      size: 150,
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },
    {
      accessorKey: "panelistName",
      header: "Panelists Name",
      size: 150,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        sx: { textAlign: "left" },
      },
    },
    {
      accessorKey: "points",
      header: "Number of Points",
      size: 150,
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      Cell: ({ row }) => {
        const ref = React.useRef(null);

        useEffect(() => {
          const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
              setEditing(null);
              setNewPoints("");
            }
          };

          document.addEventListener("mousedown", handleClickOutside);

          return () => {
            document.removeEventListener("mousedown", handleClickOutside);
          };
        }, []);

        return editing === row.original.panelistId ? (
          <div ref={ref}>
            <TextField
              value={newPoints}
              onChange={(e) => {
                const value = e.target.value;
                if (value >= 0 || value === "") {
                  setNewPoints(value);
                }
              }}
              onBlur={() =>
                handleUpdate(row.original.panelistId, newPoints, null)
              }
              onKeyPress={(e) => {
                if (e.key === "-" || e.key === "+" || e.key === "e") {
                  e.preventDefault();
                }
              }}
              autoFocus
              size="small"
              type="number"
            />
            <Tooltip title="Click to update points">
              <IconButton
                onClick={() =>
                  handleUpdate(row.original.panelistId, newPoints, null)
                }
                style={{ marginLeft: "1px" }}
              >
                <DoneIcon sx={{ color: "#5B4CB4" }} />
              </IconButton>
            </Tooltip>
          </div>
        ) : (
          <div
            onDoubleClick={() => {
              setEditing(row.original.panelistId);
              setNewPoints(row.original.points);
            }}
          >
            <Tooltip title="Double Click to Edit Points">
              {row.original.points}
            </Tooltip>
          </div>
        );
      },
    },
    {
      accessorKey: "isActive",
      header: "Action",
      size: 150,
      enableSorting: false,
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      Cell: ({ row }) => (
        <Tooltip title="Click to Change Action State">
          <Switch
            size="large"
            checked={row.original.isActive}
            color="info"
            onChange={() =>
              handleUpdate(row.original.panelistId, null, row.original.isActive)
            }
          />
        </Tooltip>
      ),
    },
  ];

  const table = useMaterialReactTable({
    columns,
    data,
    enableStickyHeader: true,
    manualPagination: true,
    rowCount: totalLength,
    onPaginationChange: setPagination,
    state: { density: "compact", pagination, sorting },
    enableColumnPinning: true,
    enableGlobalFilter: true,
    enableDensityToggle: false,
    manualSorting: true,
    muiPaginationProps: {
      rowsPerPageOptions: [25, 50, 100],
    },
    muiTableContainerProps: {
      sx: {
        minHeight: "calc(100vh - 420px)",
        maxHeight: "calc(100vh - 450px)",
        overflowY: "auto",
      },
    },
    onSortingChange: setSorting,
    muiTableBodyRowProps: {
      sx: {
        cursor: "pointer",
        "&:hover": {
          backgroundColor: "#F5F5F5",
        },
        overflowY: "auto",
        border: "1px solid #E0E0E0",
      },
    },
    muiTableBodyCellProps: {
      sx: {
        fontSize: "14px",
        fontWeight: "400",
        textAlign: "center",
      },
    },
    muiTableHeadCellProps: {
      sx: {
        fontSize: "14px",
        fontWeight: "700",
        textAlign: "center",
        color: "important",
        zIndex: 500,
      },
    },
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        width: "100%",
        borderRadius: "0",
        backgroundColor: "#fff !important",
        zIndex: 500,
      },
    },
    renderEmptyRowsFallback: () => (
      <Stack
        display="flex"
        alignItems="center"
        justifyContent="center"
        fontSize="14px"
        p={5}
      >
        No records to display
      </Stack>
    ),
  });

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 2000 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* <Grid container spacing={2}>
        <Grid item xs={12} ml="15px" mt="15px" mr="15px">
          <MDTypography
            variant="h6"
            color="#2F2F2F"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            Panelists
          </MDTypography>
        </Grid>
        <Grid item xs={12} md={12} mt={-1}>
          <hr style={{ width: "100%" }} />
        </Grid>
      </Grid> */}
      <MDBox py={2} mt={2} borderRadius="15px" bgColor="white" width="99%">
        <Grid container spacing={2}>
          <Grid
            px={2}
            item
            xs={12}
            md={12}
            display="flex"
            justifyContent="flex-end"
            flexDirection="row"
            sx={{
              flexDirection: { xs: "column", md: "row" },
              alignItems: { xs: "flex-end", md: "center" },
            }}
          >
            <Tooltip title="Click to Add Panelists">
              <MDButton
                variant="contained"
                color="info"
                sx={{ color: "#FFFFFF" }}
                size="small"
                onClick={handleOpenModal}
              >
                + Panelist
              </MDButton>
            </Tooltip>
          </Grid>
        </Grid>
        <ThemeProvider theme={tableTheme}>
          <MaterialReactTable
            table={table}
            style={{
              width: "100%",
            }}
          />
        </ThemeProvider>
      </MDBox>
      <Modal
        open={openModal}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleCloseModal();
          }
        }}
        aria-labelledby="add-panelist-modal"
        aria-describedby="add-panelist-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 550,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: "10px",
          }}
        >
          <MDTypography
            variant="h6"
            color="#2F2F2F"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            Add Member
            <Box>
              <Button
                variant="outlined"
                color="secondary"
                size="small"
                sx={{ color: "#000", mr: 2 }}
                onClick={handleCloseModal}
              >
                Cancel
              </Button>
              <MDButton
                variant="contained"
                color="info"
                sx={{ color: "#FFFFFF" }}
                size="small"
                onClick={handleAddPanelist}
              >
                Save
              </MDButton>
            </Box>
          </MDTypography>
          <Grid item xs={12} md={12} mt={1}>
            <hr style={{ width: "100%" }} />
          </Grid>
          <FormControl fullWidth margin="normal">
            <InputLabel id="panelist-select-label">
              List of Panelists
            </InputLabel>
            <Select
              labelId="panelist-select-label"
              multiple
              value={selectedPanelist}
              onChange={(e) => setSelectedPanelist(e.target.value)}
              label="List of Panelists"
              MenuProps={{
                PaperProps: {
                  style: {
                    // maxHeight: "26%",
                    overflowY: "auto",
                  },
                },
              }}
            >
              <ListSubheader sx={{ marginBottom: "1rem" }}>
                <TextField
                  sx={{
                    position: "sticky",
                    marginTop: "-16px",
                    paddingTop: "1rem",
                    zIndex: 1,
                    background: "white",
                  }}
                  size="small"
                  autoFocus
                  required
                  value={search}
                  placeholder="Type to search..."
                  fullWidth
                  onChange={(e) => setSearch(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key !== "Escape") {
                      e.stopPropagation();
                    }
                  }}
                />
              </ListSubheader>
              {filteredPanelist && filteredPanelist.length > 0 ? (
                filteredPanelist.map((panelist) => (
                  <MenuItem key={panelist.id} value={panelist.id}>
                    {panelist.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No Panelists to add</MenuItem>
              )}
            </Select>
          </FormControl>
          <TextField
            fullWidth
            margin="normal"
            label="Points"
            type="number"
            value={points}
            onChange={(e) => setPoints(e.target.value)}
          />
        </Box>
      </Modal>
    </>
  );
}

export default PanelistsTable;
