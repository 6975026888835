import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "respondentClientStatus",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "respondentSubStatus",
    numeric: true,
    disablePadding: false,
    label: "Respondent Sub Status",
  },
  {
    id: "countOfRecords",
    numeric: true,
    disablePadding: false,
    label: "Count Of Records",
  },
  {
    id: "countPercentage",
    numeric: true,
    disablePadding: false,
    label: "Count(%)",
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead sx={{ display: "table-header-group", background: "#f0ecffbf",'& th': {
          borderRadius: '6px 6px 0 0', // Border radius applied to header cells
        }, }}>
      <TableRow sx={{ borderBottom: "0.13rem solid #D2D2D2" }}>
        {headCells.map((headCell, index) => (
          <TableCell
            key={headCell.id}
            align={"center"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              sx={{ fontSize: "14px" }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

export default function DashboardRespondentTable({ rows }) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, rows]
  );

  return (
    <Box sx={{ width: "100%" ,height: "30vh"}}>
      <Box padding="1vh" borderBottom="2px solid #BFBFBF" borderRadius={3} sx={{background:"#AB95FF"}}>
        <h5 style={{color:"white"}}>Respondent status</h5>
      </Box>
      <Table
        sx={{
          minWidth: 750,
          background: "white",
          borderRadius:"6px",
        }}
      >
        <EnhancedTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
        />
        <TableBody>
          {visibleRows.map((row, index) => {
            return (
              <TableRow
                key={index}
                sx={{ borderBottom: "0.13rem solid #D2D2D2"}}

              >
                {/* <TableCell align="center">{row?.respondentId}</TableCell> */}
                <TableCell align="center" sx={{ fontSize: "13px" }}>{row?.subStatus}</TableCell>
                <TableCell align="center" sx={{ fontSize: "13px" }}>{row?.status}</TableCell>
                <TableCell align="center" sx={{ fontSize: "13px" }}>{row?.count}</TableCell>
                <TableCell align="center" sx={{ fontSize: "13px" }}>
                  {(row?.countPercentage*100).toFixed(2)}
                </TableCell>
              </TableRow>
            );
          })}
          {visibleRows.length <= 0 && (
            <TableRow
              style={{
                height: 53,
              }}
            >
              <TableCell colSpan={10}>
                <Box display="flex" alignItems="center" justifyContent="center">
                  No Rows
                </Box>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}
