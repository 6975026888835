import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  IconButton,
  Backdrop,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useParams } from "react-router-dom";
import MDTypography from "components/MDTypography";
import MDBox from "../../../../components/MDBox";
import VerifiedIcon from '@mui/icons-material/Verified';
import TLinkApi from "services/tlink.api";

function Address() {
  const [isLoading, setIsLoading] = useState(false);
  const [addressData, setAddressData] = useState([]);
  let { id } = useParams();

  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [entriesPerPage, setEntriesPerPage] = useState(50);
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortBy, setSortBy] = useState("id");

  const getAddress = async () => {
    setIsLoading(true);
    try {
      const orgSlug = localStorage.getItem("organizationSlug");
      const panelSlug = localStorage.getItem("panelSlug");
      if (!orgSlug) {
        throw new Error("Organization slug is missing from local storage");
      }
      const result = await TLinkApi.get(
        `/o/${orgSlug}/panel/${panelSlug}/panelist/addresses/${id}/?search=${search}&sortOrder=${sortOrder}&sortBy=${sortBy}&page=${
          page + 1
        }&limit=${entriesPerPage}`
      );
      setAddressData(result.data.panelistAddresses || []);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAddress();
  }, []);

  return (
    <MDBox sx={{background:"#fff", borderRadius:"15px"}} mt={1}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* <Grid container spacing={2}>
        <Grid item xs={12} ml="15px" mt="15px">
          <MDTypography variant="h6" color="#2F2F2F">
            Address
          </MDTypography>
        </Grid>
        <Grid item xs={12} md={12} mt={-1}>
          <hr style={{ border: `1px solid #C4C4C4`, width: "100%" }} />
        </Grid>
      </Grid> */}
      <MDBox
        style={{
          height: "calc(100vh - 240px)",
          overflowY: "auto",
        }}
      >
        <Grid container spacing={2} style={{ padding: "20px" }}>
          {addressData.length > 0 ? (
            addressData.map((address, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                <Card
                  elevation={0}
                  variant="outlined"
                  style={{
                    borderRadius: 10,
                    backgroundColor: "#ab95ff33",
                    height: "100%",
                    border: "1px solid #AB95FF"
                  }}
                >
                  <CardContent
                    style={{ paddingBottom: "16px", paddingTop: "16px" }}
                  >
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Grid item>
                        <LocationOnIcon
                          style={{
                            color: "#AB95FF",
                            marginRight: "4px",
                            marginLeft: "-5px",
                          }}
                        />
                        <Typography
                          fontSize={16}
                          color="black"
                          display="inline"
                          fontWeight="bold"
                        >
                          Address Type:{" "}
                          <span style={{ color: "black" }}>
                            {address.addressType.name}
                          </span>
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Grid size="small">
                          {address.isVerified ? (
                            <VerifiedIcon style={{ width: "28px", height: "24px", color: "#AB95FF" }} />
                          ) : (
                            <VerifiedIcon style={{ width: "28px", height: "24px", color: "gray" }} />
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Typography fontSize={14} color="textSecondary" marginTop={2}>
                      Address Line 1:{" "}
                      <Tooltip title={address.address1} arrow>
                        <span style={{ color: "black" }}>
                          {address.address1.length > 15
                            ? `${address.address1.substring(0, 15)}...`
                            : address.address1}
                        </span>
                      </Tooltip>
                    </Typography>
                    <Typography fontSize={14} color="textSecondary">
                      Address Line 2:{" "}
                      <Tooltip title={address.address2} arrow>
                        <span style={{ color: "black" }}>
                          {address.address2.length > 15
                            ? `${address.address2.substring(0, 15)}...`
                            : address.address2}
                        </span>
                      </Tooltip>
                    </Typography>
                    <Typography fontSize={14} color="textSecondary">
                      City:{" "}
                      <span style={{ color: "black" }}>{address.cityName}</span>
                    </Typography>
                    <Typography fontSize={14} color="textSecondary">
                      Postal Code:{" "}
                      <span style={{ color: "black" }}>{address.zipCode}</span>
                    </Typography>
                    <Typography fontSize={14} color="textSecondary">
                      Country:{" "}
                      <span style={{ color: "black" }}>
                        {address.country.name}
                      </span>
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <Typography variant="body1" color="textSecondary" align="center">
                No records to display
              </Typography>
            </Grid>
          )}
        </Grid>
      </MDBox>
    </MDBox>
  );
}

export default Address;
