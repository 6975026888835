import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";

const GetPanelBasicList = () => {
  const [panelists, setPanelists] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [totalLength, setTotalLength] = useState(0);
  const [search, setSearch] = useState("");
  const [entriesPerPage, setEntriesPerPage] = useState(50);
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortBy, setSortBy] = useState("id");

  const getPanelists = async () => {
    
    if (search === "") {
      setIsLoading(true);
    }
    try {
      const orgSlug = localStorage.getItem("organizationSlug");
      const panelSlug = localStorage.getItem("panelSlug");
      console.log(orgSlug, panelSlug);
      if (!orgSlug) {
        throw new Error("Organization slug is missing from local storage"); 
      }

       let data = await TLinkApi.get(
        `/o/${orgSlug}/panel/${panelSlug}/panelists?search=${search}&sortOrder=${sortOrder}&sortBy=${sortBy}&page=${
          page + 1
        }&limit=${entriesPerPage}`
      );
      setPanelists(data.data.panelists);
      setTotalLength(data.data.totalLength);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    getPanelists();
  }, [search, sortBy, sortOrder]);

  useEffect(() => {
    getPanelists();
  }, [page, search, entriesPerPage, sortBy, sortOrder]);


  return {
    panelists,
    getPanelists,
    isLoading,
    totalLength,
    setPage,
    page,
    setSearch,
    search,
    setEntriesPerPage,
    entriesPerPage,
    setSortOrder,
    setSortBy,
    sortBy,
    sortOrder,
  };
};

export default GetPanelBasicList;